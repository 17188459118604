









import { Component, Vue } from "vue-property-decorator";
import { AdminGeneralSettings, GeneralSettings } from "@/network/api";
import HomePageBanners from "@/views/site-content/home-page/HomePageBanners.vue";
import HomePageCarousels from "@/views/site-content/home-page/HomePageCarousels.vue";
import ClientLogos from "@/views/site-content/home-page/ClientLogos.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";

@Component({
  name: "HomePage",
  components: { HomePageBanners, HomePageCarousels, ImageUploader, ClientLogos, ValidationProvider, ValidationObserver },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  loading = false;
  // aboutUsBannerSrc = "";
  //aboutUsBanner is key for general settings

  created() {
    // this.loadInitialData();

    // let self = this;
    // extend("preview", {
    //   validate(value) {
    //     let uploader = self.$refs.previewUploader as any;
        
    //     return {
    //       valid: uploader._selectedFile || uploader.image || value,
    //     };
    //   },
    //   computesRequired: true,
    //   message: "A {_field_} is required.",
    // });

    // configure({
    //   classes: {
    //     failed: "validation-error",
    //     invalid: "validation-error",
    //     required: "validation-error",
    //   },
    // });
  }

  // get uploadPath() {
  //   return `${process.env.VUE_APP_ROOT_API}/files`;
  // }

  // validateField (field:any) {
  //   const provider = this.$refs[field] as any;

  //   // Validate the field
  //   if(provider) {
  //     return provider!.validate();
  //   }     
  // }

  // saveAboutUsBanner(path: any) {
  //   this.aboutUsBannerSrc = path;
  //   this.loading = true
  //   AdminGeneralSettings.adminGeneralSettingsKeyPut(
  //     "aboutUsBanner",
  //     this.aboutUsBannerSrc
  //   )
  //     .then((res) => {
  //       if(res.data.succeeded) {
  //         console.log("success?", res);
  //         this.loading = false
  //       this.$message.success("Saved!");
  //       }
        
  //     })
  //     .catch((error) => {
  //       this.loading = false
  //         error.response.data.errors.map((e: any) => {
  //         this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
  //         return e.friendlyMessage;
  //       });
  //     });
  // }

  // removeImage(area: string) {
  //   if (area == "preview") {
  //     this.aboutUsBannerSrc = "";
  //   }
  // }

  // loadInitialData() {
  //   this.loading = true
  //   GeneralSettings.generalSettingsKeyGet("aboutUsBanner")
  //     .then((res) => {
  //       if (res.data.succeeded) {
  //         this.aboutUsBannerSrc = res.data.resultData as string;        
  //         console.log("about us", this.aboutUsBannerSrc);
  //       }
  //       this.loading = false
  //     })
  //     .catch((error) => {
  //       this.loading = false
  //       error.response.data.errors.map((e: any) => {        
  //         this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
  //         return e.friendlyMessage;
  //       });
  //     });
  // }
}
