
















































































































































































































import {
  ColourAdminCreateModel,
  ColourAdminListViewModel,
  ColourAdminUpdateModel,
  ColourAdminViewModel,
  FabricAdminListViewModel,
  FabricColourAdminCreateModel,
  FabricColourAdminListViewModel,
  FabricColourAdminListViewModelPaginatedList,
  FabricColourAdminUpdateModel,
  PriceAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import Pagination from '@/components/Pagination.vue'
import PriceRange from "@/components/form-items/PriceRange.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminColourPrices, AdminColours, AdminFabricColours } from "@/network/api";
import store from "@/store";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewColour",
  components: { Pagination, PriceRange, BasicSelectorField, BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField, ItemsPerPage,BasicTooltipHeading },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dirtyTimer: any = null
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false; 
  pattern: Array<any> = [];
  showBanner: boolean = true;
  colour: ColourAdminViewModel = {
    id: '',
    name: '',
    code: '',
    hexValue: '',
    patternImageUrl: null,
    isDisabled: true,
    highQualityPatternImageUrl: null
  };
  colours: Array<ColourAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newColour: ColourAdminCreateModel | ColourAdminUpdateModel = {
    name: '',
    code: '',
    hexValue: '#FF3D5C',
    patternImageUrl: null,
    isDisabled: true,
    highQualityPatternImageUrl: null
  };
  fabricColourPageNumber = 1;
  fabricColourItemPerPage = 5;
  colourFabrics: FabricColourAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  colourFabricItems: Array<FabricColourAdminListViewModel> = [];
  newColourFabric: FabricColourAdminCreateModel | FabricColourAdminUpdateModel = {
    fabricId: '',
    colourId: '',
    referenceCode: ''
  };
  selectedId = '';
  originalId = '';
  originalRef = '';
  colourFabricOptions: Array<FabricAdminListViewModel> = [];
  toggleOptionValue = false;
  loading = false
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: '',
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: ''
    }
  ];
  disable = false;
  fromPath = ''

  get role() {
    return store.getters['user_new/role']
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    let toQuery = {
      fabricColourPageNumber: this.fabricColourPageNumber,
      fabricColourItemPerPage: this.fabricColourItemPerPage
    }
    this.setQueryWatch(toQuery)
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    // let self = this;
    // extend("pattern", {
    //   validate(value) {
    //     let uploader = self.$refs.patternUploader as any;
        
    //     return {
    //       valid: uploader._selectedFile || uploader.image || value,
    //     };
    //   },
    //   computesRequired: true,
    //   message: "A {_field_} is required.",
    // });

    // extend("highQualityPattern", {
    //   validate(value) {
    //     let uploader = self.$refs.highQualityPatternUploader as any;
        
    //     return {
    //       valid: uploader._selectedFile || uploader.image || value,
    //     };
    //   },
    //   computesRequired: true,
    //   message: "A {_field_} is required.",
    // });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  
  handlePageNumberClick(page: any) {
    this.fabricColourPageNumber = page
    this.loadFabricColours();
  }

  toggleOption(visible: boolean) {    
    this.toggleOptionValue = visible;
  }

  uploadImages(): Promise<any> {
    let patternUploader = this.$refs.patternUploader as ImageUploader;
    let highQualityPatternUploader = this.$refs.highQualityPatternUploader as ImageUploader;
    let patternPromise;
    let highQualityPattern;

    if(patternUploader) {
      patternPromise = patternUploader!.uploadImage()
    }

    if(highQualityPatternUploader) {
      highQualityPattern = highQualityPatternUploader!.uploadImage()
    }

    return Promise.all([patternPromise, highQualityPattern])
  }

  getHeightAndWidthFromDataUrl = (dataURL:any) => new Promise(resolve => {
    const img = new Image()
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width
        })
      }
      img.src = dataURL
  })

  beforeImageUpload = async (file: any) => {
    const isType = (file.type === 'image/jpeg') || (file.type === 'image/png');
    const isLt2M = file.size / 1024 / 1024 < 2;
    
    if (!isType) {
      this.$message.error('Image must be JPG or PNG format.');
    }
    if (!isLt2M) {
      this.$message.error('Image size can not exceed 2MB.');
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // 
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;   

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;
      
    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // 
    // return result;
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if(isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string){
    if (area == "pattern") {
      this.newColour.patternImageUrl = null;
    } 

    if (area == "highQualityPattern") {
      this.newColour.highQualityPatternImageUrl = null;
    } 
  }

  handleConfirm() {
    if (this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>") {
        if(this.fromPath === 'Colours') {
          this.$router.back()
        } else {
          this.$router.push({ name: "Colours"});
        }
    } else if(this.confirmText === 'Yes') {
      this.deleteRow();
    } else {
      this.submitForm();
    }
  }

  editField(data: any) {
    if ((this.selectedId === data.id)
    && data.fabricId !== ''
    && data.referenceCode !== '') {
      this.disable = false
      this.saveColourFabric(data);
    } else {
      this.loadOptions(data.id);
      this.originalId = data.fabricId;
      this.selectedId = data.id;
      this.originalRef = data.referenceCode;
      this.disable = true
    }
  }

  addRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadOptions();

    if(this.colourFabricItems.length)
      this.colourFabricItems.map(item => {
        if(item.fabricName === '' || item.referenceCode === '') {
          emptyName += 1;
        }
      })

      if(emptyName === 0) {
        this.colourFabricItems.push({
        id: tempId,
        fabricId: '',
        fabricName: '',
        colourId: this.colour.id,
        colourName: this.colour.name,
        referenceCode: ''
      });
      console.log('items', this.colourFabricItems);     
      this.originalId = '';
      this.originalRef = '';
      this.selectedId = tempId;
      this.disable = true
    }
  }

  saveColourFabric(data: any) {
    if (data.fabricId !== '' && data.referenceCode !== '') {
      this.loading = true
      let regex = /^(temp).*/g;
      if (!data.id.match(regex)) {
        this.newColourFabric = {
          fabricId: data.fabricId,
          colourId: this.id,
          referenceCode: data.referenceCode
        };

        AdminFabricColours.adminFabricColoursIdPut(data.id, this.newColourFabric)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Put success", res);
              this.$message.success('Saved!')
              this.loadFabricColours();
              this.loadOptions();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      } else {
        this.newColourFabric = {
          fabricId: data.fabricId,
          colourId: this.id,
          referenceCode: data.referenceCode
        };
        AdminFabricColours.adminFabricColoursPost(this.newColourFabric)
          .then((res) => {
            if (res.data.succeeded) {
              this.$message.success('Saved!')
              console.log("fabcol Post success", res);
              this.loadFabricColours();
              this.loadOptions();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      }
      this.selectedId = "";
      this.disable = false
    }
  }

  confirmDelete(data: any) {
    this.dialogMessage = "<span><stong>Are you sure you want to delete this fabric colour?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if ((this.selectedId === data.id) && !this.selectedId.match(regex)) {
      data.fabricId = this.originalId;
      data.referenceCode = this.originalRef;
      this.selectedId = "";
      this.disable = false
    } else {
      console.log('in delete', data);
      
      this.selectedId = data.id;
      this.dialogVisible = true;
    }
    this.loadOptions();
  }

  deleteRow() {
    let regex = /^(temp).*/g;
    let index = this.colourFabricItems.length -1;
    if (this.selectedId.match(regex)) {
      this.colourFabricItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.colourFabricItems.splice(index, 1);
    } else {
      this.loading = true
      AdminFabricColours.adminFabricColoursIdDelete(this.selectedId)
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.info('Item deleted.')
          this.loadFabricColours();
          this.loadOptions();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
        });
    }
    this.selectedId = "";
    this.originalId = "";
    this.originalRef = ""
    this.loading = false
    this.disable = false
    this.dialogVisible = false;
  }

  //-------- Price Range Start

  saveNewPriceRange(range:any) {
    this.loading = true
    AdminColourPrices.adminColourPricesPost(range)
    .then((res) => {
      if (res.data.succeeded) {
        console.log("price post success", res);
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        this.dialogVisible = false;
    });
  }

  updatePriceRange(range:any) {
    this.loading = true
    AdminColourPrices.adminColourPricesIdPut(range.id, range)
    .then((res) => {
      if (res.data.succeeded) {
        console.log("price put success", res);
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        this.dialogVisible = false;
    });
  }

  deletePriceRange(id:string) {
    this.loading = true
    AdminColourPrices.adminColourPricesIdDelete(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message.info('Item deleted.')
      this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
          this.dialogVisible = false;
    });
  }

  async loadPriceRanges() {
    this.loading = true
    await AdminColourPrices.adminColourPricesGet(1, 99999999, this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.priceRanges = res.data.resultData!.items
          console.log('get price', this.priceRanges);
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
      this.loading = false
  }

  //-------- Price Range End

  submitForm() {
    this.loading = true
    this.uploadImages().then((results) => {
      console.log('result', results);
      
      if(results[0].imageUrl) {
        this.newColour.patternImageUrl = results[0].imageUrl;
      }

      if(results[1].imageUrl) {
        this.newColour.highQualityPatternImageUrl = results[1].imageUrl;
      }

      // if(this.newColour.hexValue) {
      //   this.newColour.hexValue = this.newColour.hexValue.slice(1)
      // }

        if (this.colour.id) {
          console.log('before save', this.newColour);
          
          AdminColours.adminColoursIdPut(this.colour.id, this.newColour)
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.$message.success('Saved!')
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
            });
        } else {
          AdminColours.adminColoursPost(this.newColour)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success('Saved!')
                this.dialogVisible = false;
                this.id = res.data.resultData!.id;
                this.$router.replace({name: 'EditColour', params: {id: this.id}})
                this.loadInitialData()
                .then(() => {
                  this.clearDirtyClasses();
                })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
            });
        }

    });
  }

  async loadOptions(fabricColourId: string | undefined = undefined) {
    this.loading = true
    await AdminFabricColours.adminFabricColoursGetFabricsForColourGet(this.id, fabricColourId)
      .then((res) => {
        if (res.data.succeeded) {
          console.log('get fabs for cols', fabricColourId, res);
          this.colourFabricOptions = res.data.resultData as Array<FabricAdminListViewModel>
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;   
      })
      this.loading = false
  }

  async loadFabricColours() {
    this.loading = true
    await AdminFabricColours.adminFabricColoursGet(this.fabricColourPageNumber, this.fabricColourItemPerPage, undefined, this.id)
      .then((res) => {
        if (res.data.succeeded) {
          console.log('fab colours get', res);
          
          this.colourFabrics = res.data.resultData as FabricColourAdminListViewModelPaginatedList;
          this.colourFabricItems = this.colourFabrics.items;
          this.newColourFabric = {
            fabricId: '',
            colourId: this.id,
            referenceCode: ''
          }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;   
      })
  }

  async loadInitialData() {
    if (this.id) {     
      await this.loadPriceRanges();
      await this.loadOptions();
      await this.loadFabricColours()
      this.loading = true



      await AdminColours.adminColoursIdGet(this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.colour = res.data.resultData as ColourAdminViewModel;
        this.name = this.colour.name;
        this.newColour = (({ id, ...rest }) => rest)(this.colour) as any;
        console.log('new colour', this.newColour);
        
        // if(this.newColour.hexValue) {
        //   this.newColour.hexValue = `#${this.newColour.hexValue}`
        // }

        if (this.newColour.patternImageUrl) {
          this.pattern = [{
            imageUrl: this.newColour.patternImageUrl
          }];
        }

        if (this.newColour.highQualityPatternImageUrl) {
          this.pattern = [{
            imageUrl: this.newColour.highQualityPatternImageUrl
          }];
        }
        }      
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;   
      })
      this.loading = false
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }

}
