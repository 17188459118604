
































































































































































































































































































































































import {
  CategoryAdminListViewModel,
  ProductTagViewModel,
  ProductAdminListViewModel,
  ProductBundleAdminViewModel,
  ProductBundleAdminCreateModel,
  ProductBundleAdminUpdateModel,
  PriceAdminViewModel,
} from "@/api-client";
import {
  AdminCategories,
  AdminProductBundlePrices,
  AdminProductBundles,
  AdminProducts,
  AdminProductTags,
} from "@/network/api";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import { allCategories } from "@/utils/recursive";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicGroupSelectorField from "@/components/form-items/BasicGroupSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import PriceRange from "@/components/form-items/PriceRange.vue";
import { sortAscending } from "@/utils/sortAscending";
import { editorToolbar } from "@/utils/editorToolbar";
import { Message } from 'element-ui';

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewProductBundles",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ElTableDraggable,
    ImageUploader,
    BasicInputField,
    BasicSelectorField,
    BasicSwitchField,
    PriceRange,
    BasicGroupSelectorField,
    BasicTooltipHeading
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  productBundleName: string | undefined = "";
  dirtyTimer: any = null
  dirtyMavonTimer: any = null
  dialogVisible = false;
  dialogMessage = "";
  disabled = false;
  showThumbnail = true;
  thumbnailImages: Array<any> = [{ imageUrl: "", order: 0 }];
  galleryImages: Array<any> = [{ imageUrl: "", order: 0 }];
  showBanner = true;
  product: ProductBundleAdminViewModel = {
    id: "",
    code: "",
    name: "",
    blurb: "",
    description: "",
    isFeatured: false,
    isNew: false,
    isDisabled: true,
    slug: "",
    thumbnailImages: [],
    galleryImages: [],
    relatedProductBundles: [],
    productTags: [],
    categories: [],
  };
  products: Array<ProductAdminListViewModel> = [];
  productTags: Array<ProductTagViewModel> = [];
  categories: Array<CategoryAdminListViewModel> = [];
  temporaryOnSaleValue = false;
  loading = false
  allCategoriesArray: Array<CategoryAdminListViewModel> = [];
  allProductBundleTagsArray: Array<ProductTagViewModel> = [];
  allProductsArray: Array<ProductAdminListViewModel> = [];

  metaKeywordsArray: Array<string> = [];
  productCategoryArray: Array<string> = [];
  bundleProductsArray: Array<string> = [];
  productBundleTagsArray: Array<string> = [];
  relatedProductArray: Array<string> = [];

  confirmText = "Ok";
  cancelVisible = true;
  metaTagInputVisible = false;
  metaTagInputValue = "";
  categoryInputValue = "";
  fromPath = ''

  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: '',
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: ''
    }
  ];

  newProductBundle:
    | ProductBundleAdminCreateModel
    | ProductBundleAdminUpdateModel = {
    name: "",
    code: "",
    blurb: "",
    description: "",
    isFeatured: false,
    isNew: false,
    isDisabled: true,
    slug: "",
    thumbnailImages: [],
    galleryImages: [],
    relatedProducts: [],
    productTags: [],
    categories: [],
  };
  mavenToolbar = editorToolbar;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    thumbnailUploader0: any;
    saveMetaTagInput: any;
  };
  messageInstance: any = Message
  activeTab: any = "info";

  mounted() {
    this.$refs.observer;
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then((res) => {
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses()
      }, 1000);  
    })

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "info";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      } else {
        this.activeTab = "info";
      }
    });

    this.$watch("thumbnailImages", () => {
      console.log("thumbnail watch", this.thumbnailImages);
    });
    this.$watch("galleryImages", () => {
      console.log("banner watch", this.galleryImages);
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("categories", () => {
      if (this.productCategoryArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("bundleProducts", () => {
      if (this.bundleProductsArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("productTags", () => {
      if (this.productBundleTagsArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("relatedProducts", () => {
      if (this.relatedProductArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    let self = this;
    extend("thumbnail", {
      validate(value) {
        let uploader = self.$refs.thumbnailUploader0 as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("gallery", {
      validate(value) {
        let validImages = 0;
        let allRefs = self.$refs;
        for (let item in allRefs) {
          if (item.match(/(^galleryUploader\d+)/g)) {
            let image = self.$refs[item] as ImageUploader;

            if (image && image.image && image.image.id) {
              validImages += 1;
            } else if (image && image._selectedFile) {
              validImages += 1;
            }
          }
        }

        return {
          valid: validImages > 0,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  handleArrayReorder(data:any) {
    this.galleryImages = [...data];
    this.newProductBundle.galleryImages = [...data];
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    if(this.id) {
      this.$router.replace({
        name: "EditProductBundle",
        params: { id: this.id },
        query: { tab: this.activeTab },
      });
    } else {
      this.$router.replace({
        name: "NewProductBundle",
        query: { tab: this.activeTab },
      });
    }
    
  }

  handleSubmit () {
    this.messageInstance.closeAll()
    let errorMessages = [] as Array<string>
      this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
        if(isValid) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.submitForm()
        } else {
          for(let error in errors) {
            if(errors[error].length > 0) {
              errorMessages.push(error)
            }
          }
          let finalMessage = `The following fields are required:<br>`
          errorMessages.forEach((message, index:any) => {
            if (index < 5) {
              finalMessage += `<br>- ${message}`
            }
          })
          if(errorMessages.length > 5) {
            let remaining = errorMessages.length - 5
            finalMessage += `<br><br>and ${remaining} others...`
          }
          this.$message({showClose: true, type: 'error', duration: 0, message: finalMessage, dangerouslyUseHTMLString: true});
        }
      });
    }

  uploadImages(refValue: RegExp): Promise<any> {
    let imagePromises: Array<any> = [];
    let allRefs = this.$refs;
    console.log("refs from upload", allRefs);
    let regex = refValue;
    for (let item in allRefs) {
      if (item.match(regex)) {
        let imagePromise = this.$refs[item] as ImageUploader;
        if (imagePromise) {
          imagePromises.push(imagePromise.uploadImage());
        }
      }
    }

    return Promise.all(imagePromises);
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll('.dirty'); 
      
    if(mavonDirty.length) {
      for(let item of mavonDirty) {
        item.classList.remove('dirty')
      }
    }   
  }

  addThumbnailImage() {
    let allRefs = this.$refs;
    console.log("refs before", allRefs);
    if (this.thumbnailImages.length === 0) {
      this.thumbnailImages!.push({
        imageUrl: "",
        order: 0,
      });
    }
  }

  addGalleryImage() {
    let empty = 0;
    let allRefs = this.$refs;
    console.log("refs before", allRefs);
    for (let item in allRefs) {
      if (item.match(/(^galleryUploader\d+)/g)) {
        let image = this.$refs[item] as ImageUploader;

        if (image) {
          if (image && image.image === null) {
            if (
              (image && !image.hasOwnProperty("_selectedFile")) ||
              (image && image._selectedFile === null)
            ) {
              //if(image._selectedFile) {
              empty += 1;
              console.log("selected file", item, image._selectedFile);
              //}
            }
          } else {
            console.log("selected image", item, image.image);
          }
        }

        // if(image && image.image !== null) {
        //   console.log('selected image',item, image.image);
        // }

        // if((image && !image.hasOwnProperty('_selectedFile')) || (image && image._selectedFile === null)) {
        //   //if(image._selectedFile) {
        //     empty += 1
        //     console.log('selected file',item, image._selectedFile);
        //   //}
        // }
      }
    }

    if (this.galleryImages.length < 6 && empty === 0) {
      this.galleryImages!.push({
        imageUrl: "",
        order: this.galleryImages.length - 1,
      });
    }
    console.log(this.galleryImages, "new", this.newProductBundle.galleryImages);
    console.log("refs after", allRefs);
  }

  get sortedCategories() {
    return sortAscending(this.allCategoriesArray);
  }

  get sortedProducts() {
    return sortAscending(this.allProductsArray, true , 'code');
  }

  get sortedMetaTags() {
    return sortAscending(this.metaKeywordsArray, false);
  }

  get sortedProductTags() {
    return sortAscending(this.allProductBundleTagsArray);
  }

  handleClose(tag: any) {
    this.metaKeywordsArray.splice(this.metaKeywordsArray.indexOf(tag), 1);
  }

  showInput() {
    this.metaTagInputVisible = true;
    this.$nextTick(() => {
      let saveInput = this.$refs.saveMetaTagInput as any;
      saveInput.$refs.input.focus();
    });
  }

  confirmDelete(index: any) {
    console.log(index);
  }

  handleMetaTagInputConfirm() {
    let inputValue = this.metaTagInputValue;
    if (inputValue && !this.metaKeywordsArray.includes(inputValue)) {
      this.metaKeywordsArray.push(inputValue);
    }
    this.metaTagInputVisible = false;
    this.metaTagInputValue = "";
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    //debugger
    let index = Number(area.slice(3));
    console.log("index", index);

    if (area[0] == "t") {
      this.thumbnailImages.splice(index, 1);
      this.newProductBundle.thumbnailImages!.splice(index, 1);
    } else if (area[0] == "g") {
      this.galleryImages.splice(index, 1);
      this.newProductBundle.galleryImages!.splice(index, 1);
    }

    console.log("galleryImages", this.galleryImages);
    console.log(
      "newProductBundle.galleryImages",
      this.newProductBundle.galleryImages
    );

    if (this.thumbnailImages.length === 0) {
      this.thumbnailImages = [{ imageUrl: "", order: 0 }];
    }

    if (this.galleryImages.length === 0) {
      this.galleryImages = [{ imageUrl: "", order: 0 }];
    }
  }

  toggleUploadThumbnail(file: any) {
    if (file && !this.thumbnailImages.length) {
      this.showThumbnail = !this.showThumbnail;
      this.thumbnailImages.push(file);
    }
  }

  handleUploadChange(file: any) {
    if (file) {
      this.galleryImages.push(file);
    }
    console.log("gallery", this.galleryImages);
  }

  beforeImageUpload = async (file: any) => {
    const isType = file.type === ("image/jpeg" || "image/png");
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;
  };

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'ProductList') {
        this.$router.back()
      } else {
        this.$router.push({ name: "ProductList"});
      }
    } else {
      this.submitForm();
    }
  }

  beforeUnmount() {
    this.messageInstance.closeAll()
    clearTimeout(this.dirtyTimer);
    clearTimeout(this.dirtyMavonTimer);
  }

  //-------- Price Range Start

  saveNewPriceRange(range:any) {
    this.loading = true
    AdminProductBundlePrices.adminProductBundlePricesPost(range)
    .then((res) => {
      if (res.data.succeeded) {
        console.log("price post success", res);
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        
    });
  }

  updatePriceRange(range:any) {
    this.loading = true
    AdminProductBundlePrices.adminProductBundlePricesIdPut(range.id, range)
    .then((res) => {
      if (res.data.succeeded) {
        console.log("price put success", res);
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        
    });
  }

  deletePriceRange(id:string) {
    this.loading = true
    AdminProductBundlePrices.adminProductBundlePricesIdDelete(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message.info('Item deleted.')
      this.loadPriceRanges();
      }
      
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
          
    });
  }

  loadPriceRanges() {
    this.loading = true
    AdminProductBundlePrices.adminProductBundlePricesGet(1, 99999999, this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.priceRanges = res.data.resultData!.items
          console.log('get price', this.priceRanges);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  //-------- Price Range End

  submitForm() {
    this.loading = true
    this.uploadImages(/(^thumbnailUploader\d+)/g).then((results) => {
      let thumb = results;
      this.newProductBundle!.thumbnailImages! = thumb;

      this.uploadImages(/(^galleryUploader\d+)/g).then((results) => {
        let gallery = results;
        this.newProductBundle!.galleryImages! = gallery;

        if(this.newProductBundle!.galleryImages!.length) {
          this.newProductBundle!.galleryImages?.forEach((item:any, index:any) => {
              item.order = index;
            }
          )
        }

        if (!this.newProductBundle.slug) {
          this.newProductBundle.slug = this.newProductBundle
            .name!.trim()
            .split(" ")
            .join("-")
            .toLowerCase();
        }
        if (this.metaKeywordsArray.length > 0) {
          this.newProductBundle.seoKeywords = this.metaKeywordsArray.join(", ");
        }
        if (this.productCategoryArray.length > 0) {
          this.newProductBundle.categories = [];
          this.productCategoryArray.map((id, index) => {
            this.newProductBundle.categories!.push({
              productCategoryId: id,
              //Check with Liam on how to determine if isPrimaryCategory
              isPrimaryCategory: index > 0 ? false : true,
            });
          });
        }
        if (this.bundleProductsArray.length > 0) {
          this.newProductBundle.products = [];
          this.bundleProductsArray.map((id, index) => {
            this.newProductBundle.products!.push({
              productId: id,
            });
          });
        }
        if (this.productBundleTagsArray.length > 0) {
          this.newProductBundle.productTags = [];
          console.log("prod tags empty", this.newProductBundle.productTags);
          console.log("prod tags full?", this.productBundleTagsArray);

          this.productBundleTagsArray.map((id) => {
            this.newProductBundle.productTags!.push({
              productTagId: id,
            });
          });
        }
        if (this.relatedProductArray.length > 0) {
          this.newProductBundle.relatedProducts = [];
          this.relatedProductArray.map((id) => {
            this.newProductBundle.relatedProducts!.push({
              relatedProductId: id,
            });
          });
        }
        console.log("new product all - after changes", this.newProductBundle);

        if (this.id) {
          AdminProductBundles.adminProductBundlesIdPut(
            this.product.id,
            this.newProductBundle
          )
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.loadInitialData()
                .then(() => {
                  this.clearDirtyClasses();
                })
              }
              
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                return e.friendlyMessage;
              });
              
            });
        } else {
          AdminProductBundles.adminProductBundlesPost(this.newProductBundle)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success("Saved!");
                this.dialogVisible = false;
                this.id = res.data.resultData!.id
                this.$router.replace({ name: "EditProductBundle", params: {id: this.id} });
                this.loadInitialData()
                .then(() => {
                  this.clearDirtyClasses();
                })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                return e.friendlyMessage;
              });             
            });
        }
      });
    });
  }

  async loadCategories(refresh = false) {
    if(refresh) {
      this.loading = true;
    }
    await AdminCategories.adminCategoriesGet()
      .then((res) => {
        this.categories = res.data
          .resultData as Array<CategoryAdminListViewModel>;
        this.allCategoriesArray = [];
        allCategories(this.categories, this.allCategoriesArray, true, true);
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");         
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadProductTags(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminProductTags.adminProductTagsGet()
      .then((res) => {
        this.productTags = res.data.resultData as Array<ProductTagViewModel>;
        this.allProductBundleTagsArray = [];
        this.productTags.map((tag) => {
          this.allProductBundleTagsArray.push(tag as ProductTagViewModel);
        });
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");         
        }
        console.log("all tags", this.allProductBundleTagsArray);
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadAllProducts(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminProducts.adminProductsGet(1, 99999999)
      .then((res) => {
        this.products = res.data.resultData!.items;
        this.allProductsArray = [];
        this.products.map((product) => {
          this.allProductsArray.push(product);
        });
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");         
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadInitialData(): Promise<any> {
    this.loading = true
    window.scrollTo({ top: 0 });
    await this.loadCategories()

    await this.loadProductTags()

    await this.loadAllProducts() 

    if (this.id) {
      window.scrollTo({ top: 0 });
      this.loadPriceRanges();

      await AdminProductBundles.adminProductBundlesIdGet(this.id).then(
        (res) => {
          this.product = res.data.resultData as ProductBundleAdminViewModel;
          console.log("product data", this.product);

          this.productBundleName = this.product.name;

          // TODO @Mon fix this
          // @ts-ignore: The map kinda works
          this.newProductBundle = (({ id, ...rest }) => rest)(this.product);

          if (this.newProductBundle.seoKeywords) {
            this.metaKeywordsArray =
              this.newProductBundle.seoKeywords.split(", ");
          }
          if (this.newProductBundle.categories) {
            this.productCategoryArray = [];
            this.newProductBundle.categories.map((category) => {
              this.productCategoryArray.push(
                category.productCategoryId as string
              );
            });
          }
          if (this.newProductBundle.products) {
            this.bundleProductsArray = [];
            this.newProductBundle.products.map((category) => {
              this.bundleProductsArray.push(category.productId as string);
            });
          }
          if (this.newProductBundle.productTags) {
            this.productBundleTagsArray = [];
            this.newProductBundle.productTags.map((tag) => {
              this.productBundleTagsArray.push(tag.productTagId as string);
            });
          }
          if (this.newProductBundle.relatedProducts) {
            this.relatedProductArray = [];
            this.newProductBundle.relatedProducts.map((product: any) => {
              this.relatedProductArray.push(product.relatedProductId as string);
            });
          }
          if (this.newProductBundle.thumbnailImages!.length > 0) {
            this.thumbnailImages = [];
            this.newProductBundle.thumbnailImages!.map((image) => {
              this.thumbnailImages.push(image);
            });
          }
          if (this.newProductBundle.galleryImages!.length > 0) {
            this.galleryImages = [];
            this.newProductBundle.galleryImages!.map((image) => {
              this.galleryImages.push(image);
            });
          }
        }
      )
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
    }
    window.scrollTo({ top: 0 });
    this.loading = false
  }
}
