




































































































































































































import {
  AdjustmentAdminCreateModel,
  AdjustmentAdminListViewModelPaginatedList,
  AdjustmentAdminUpdateModel,
  AdjustmentAdminViewModel,
  AdjustmentTypeAdminCreateModel,
  AdjustmentTypeAdminListViewModel,
  AdjustmentTypeAdminUpdateModel,
  AdjustmentTypeAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import FileUploader from "@/components/form-items/FileUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import Pagination from "@/components/Pagination.vue";
import ElTableDraggable from "@/components/ElTableDraggable.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminAdjustments, AdminAdjustmentTypes } from "@/network/api";
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewAdjustmentType",
  components: {
    DialogBox,
    ElTableDraggable,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    FileUploader,
    Pagination,
    SendToPage, ItemsPerPage,
    BasicTooltipHeading
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  adjustmentType: AdjustmentTypeAdminListViewModel = {
    id: "",
    name: "",
    description: "",
  };
  newAdjustmentType:
    | AdjustmentTypeAdminCreateModel
    | AdjustmentTypeAdminUpdateModel = {
    name: "",
    description: "",
  };
  fileList: Array<any> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  adjustments: AdjustmentAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  adjustmentSearch: string | undefined = "";
  debounce: any = null;
  disable = false
  adjustmentPageNumber = 1;
  adjustmentItemPerPage = 5;
  adjustmentItems: Array<AdjustmentAdminViewModel> = [];
  newAdjustment: AdjustmentAdminCreateModel | AdjustmentAdminUpdateModel = {
    name: "",
    adjustmentTypeId: this.id,
    description: "",
  };
  selectedId = "";
  originalName = "";
  originalDescription = "";
  dirtyTimer: any = null;
  loading = false;
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    let toQuery = {
      adjustmentSearch: this.adjustmentSearch,
      adjustmentPageNumber: this.adjustmentPageNumber,
      adjustmentItemPerPage: this.adjustmentItemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch("adjustmentSearch", () => {
    //   this.adjustmentPageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadAdjustments()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400);
    // });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.adjustmentPageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handleSendToPage(id:string, selectedPage:number, determinePage:number) {
    this.loading = true
    let patchInfo: any = {}
    let itemId = 0
    let newPageItems = [] as Array<any>   

    AdminAdjustments.adminAdjustmentsGet( determinePage, this.adjustmentItemPerPage, this.id )
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!
            .items as Array<any>;
          if(selectedPage < this.adjustmentPageNumber) {
            itemId = (selectedPage > 1) ? newPageItems.length - 1 : 0;
          }
          
          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false
          
          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
        }
      })
      .catch((error) => {
        this.loading = false
error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async handleReorder(data: any, page: number = this.adjustmentPageNumber) {
    this.loading = true;
    await AdminAdjustments.adminAdjustmentsIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.adjustmentPageNumber = Number(page);
          this.loading = false;
          this.loadAdjustments();
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  handlePageNumberClick(page: any) {
    this.adjustmentPageNumber = page;
    this.loadAdjustments();
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");

    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if (isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (
      this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>"
    ) {
      if(this.fromPath === 'AdjustmentTypes') {
        this.$router.back()
      } else {
        this.$router.push({ name: "AdjustmentTypes"});
      }
    } else if (this.confirmText === "Yes") {
      this.deleteRow();
    } else {
      this.submitForm();
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }

  editField(data: any) {
    if (
      this.selectedId === data.id &&
      data.name !== "" &&
      data.description !== ""
    ) {
      this.disable = false
      this.saveAttribute(data);
    } else {
      this.selectedId = data.id;
      this.originalName = data.name;
      this.originalDescription = data.description;
      this.disable = true
    }
  }

  addRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;

    if (this.adjustmentItems.length)
      this.adjustmentItems.map((item) => {
        if (item.name === "" && item.description !== "") {
          emptyName += 1;
        }
      });

    if (emptyName === 0) {
      this.adjustmentItems.push({
        id: tempId,
        adjustmentTypeId: this.id,
        description: "",
        name: "",
      });
      console.log("items", this.adjustmentItems);
      this.originalName = "";
      this.originalDescription = "";
      this.selectedId = tempId;
      this.disable = true
    }
  }

  saveAttribute(data: any) {
    let regex = /^(temp).*/g;
    this.loading = true;
    if (!data.id.match(regex)) {
      this.newAdjustment = {
        adjustmentTypeId: this.id,
        description: data.description,
        name: data.name,
      } as AdjustmentAdminUpdateModel;

      AdminAdjustments.adminAdjustmentsIdPut(
        this.selectedId,
        this.newAdjustment
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("adj Put success", res);
            this.$message.success("Saved!");
            this.loadAdjustments();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    } else {
      this.newAdjustment = {
        adjustmentTypeId: this.id,
        description: data.description,
        name: data.name,
      };
      AdminAdjustments.adminAdjustmentsPost(this.newAdjustment)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("adj Post success", res);
            this.$message.success("Saved!");
            this.loadAdjustments();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.selectedId = "";
    this.disable = false
  }

  confirmDelete(data: any) {
    this.dialogMessage =
      "<span><stong>Are you sure you want to delete this adjustment?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if (this.selectedId === data.id && !this.selectedId.match(regex)) {
      data.name = this.originalName;
      data.description = this.originalDescription;
      this.selectedId = "";
      this.disable = false
    } else {
      console.log("in delete", data);

      this.selectedId = data.id;
      this.dialogVisible = true;
    }
  }

  deleteRow() {
    let regex = /^(temp).*/g;
    let index = this.adjustmentItems.length - 1;
    if (this.selectedId.match(regex)) {
      this.adjustmentItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.adjustmentItems.splice(index, 1);
    } else {
      this.loading = true;
      AdminAdjustments.adminAdjustmentsIdDelete(this.selectedId)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.info("Item deleted.");
            this.loadAdjustments();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.selectedId = "";
    this.disable = false
    this.originalName = "";
    this.originalDescription = "";
    this.dialogVisible = false;
  }

  submitForm() {
    this.loading = true;
    if (this.adjustmentType.id) {
      console.log("before save", this.newAdjustmentType);

      AdminAdjustmentTypes.adminAdjustmentTypesIdPut(
        this.adjustmentType.id,
        this.newAdjustmentType
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    } else {
      AdminAdjustmentTypes.adminAdjustmentTypesPost(this.newAdjustmentType)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.id = res.data.resultData!.id;
            this.$router.replace({name: 'EditAdjustmentType', params: {id: this.id}})
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
  }

  async loadAdjustments() {
    this.loading = true;

    await AdminAdjustments.adminAdjustmentsGet(
        this.adjustmentPageNumber,
        this.adjustmentItemPerPage,
        this.id,
        this.adjustmentSearch
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.adjustments = res.data
              .resultData as AdjustmentAdminListViewModelPaginatedList;
            this.adjustmentItems = this.adjustments.items;
            this.newAdjustment = {
              adjustmentTypeId: this.id,
              description: "",
              name: "",
            };
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
  }

  async loadInitialData() {
    if (this.id) {
      await this.loadAdjustments()
      this.loading = true;

      
      await AdminAdjustmentTypes.adminAdjustmentTypesIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.adjustmentType = res.data
              .resultData as AdjustmentTypeAdminViewModel;
            this.name = this.adjustmentType.name;
            this.newAdjustmentType = (({ id, ...rest }) => rest)(
              this.adjustmentType
            ) as any;
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false;
            this.dialogVisible = false;
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      this.loading = false;
    }
  }

  beforeUnmount() {
    clearTimeout(this.debounce); 
  }
}
