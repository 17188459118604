var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.documents.length)?_c('div',{staticClass:"app-container attachments-tab-container app-container",staticStyle:{"padding-top":"0"}},[_c('BasicTooltipHeading',{attrs:{"headingStyle":'margin-bottom:20px;',"heading":'Customer Attachments'}}),_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('ElTableDraggable',{attrs:{"disable":true}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.documents,"row-key":"id","fit":"","stripe":"","border":"","resizable":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"width":200,"prop":"documentUrl","label":"File"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[_c('el-image',{attrs:{"fit":'contain',"src":(_vm.uploadPath + "/" + (row.documentUrl))},on:{"error":_vm.setFallbackImage}})],1)]}}],null,false,2690791460)}),_c('el-table-column',{attrs:{"prop":"documentUrl","label":"File Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleAttachmentName(row.documentUrl)))])]}}],null,false,3349177749)}),_c('el-table-column',{attrs:{"width":"320px","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('a',{staticStyle:{"margin-right":"10px"},attrs:{"href":(_vm.uploadPath + "/" + (row.documentUrl)),"download":""}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Download")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"info"},on:{"click":function($event){return _vm.copyPath(row)}}},[_vm._v("Copy Path")]),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete($index)}}},[_vm._v("Delete")])]}}],null,false,432721824)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }