













































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { OrderInvoiceViewModel } from "@/api-client";
import { PublicOrders } from "@/network/api";
import { calculatePrice } from "@/utils/calculatePrice";

@Component({
    name: "ViewInvoice"
})
export default class extends Vue {
    id: string = "";
    loading: boolean = false;
    invoice:OrderInvoiceViewModel = {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        orderId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        startDateTime: "2022-08-01T11:57:28.136Z",
        endDateTime: "2022-08-01T11:57:28.136Z",
        orderInvoiceTypeId: "Invoice",
        referenceNumber: "",
        currency: {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            name: "",
            code: "",
            symbol: "",
            pricingMultiplier: 0,
            decimalPlaces: 0
        },
        convertedBalance: 0,
        costs: [
            {
                convertedTotalAmount: 0,
                quantity: 0,
                title: "",
                reason: "",
                dateTimeStamp: "2022-08-01T11:57:28.136Z"
            }
        ],
        payments: [
            {
                convertedTotalAmount: 0,
                paymentProviderId: "EFT",
                paymentDate: "2022-08-01T11:57:28.136Z"
            }
        ],
        dateTimeStamp: '',
        orderReferenceNumber: '',
        voidedDate: '',
        country: {
            id: '',
            name: '',
            isDefault: false,
            code: '',
            deliveryTypes: [],
            allowBankTransfers: false,
            currency: {
                id: '',
                name: '',
                code: '',
                symbol: '',
                pricingMultiplier: 1,
                decimalPlaces: 2
            },
            bankTransferDetails: ""
        },
        invoiceNumber: 1,
        deliveryAddress: {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            area: "",
            country: "",
            postalCode: "",
            additionalNotes: "",
            ponumber: ""
        },
        billingAddress: {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            area: "",
            country: "",
            postalCode: "",
            additionalNotes: "",
            ponumber: ""
        }
    };
    balanceTotals = {
        invoice: 0,
        payment: 0
    };

    created() {
        this.id = this.$route.params.id ? this.$route.params.id : "";
        this.loadInitialData();
    }

    loadInitialData() {
        if (this.id) {
            this.loading = true;
            PublicOrders.ordersGetOrderInvoiceDataOrderInvoiceIdGet(this.id)
                .then((res) => {
                    if (res.data.succeeded) {
                        this.invoice = res.data.resultData as any
                    }
                    this.loading = false;
                })
                .catch((error) => {
                console.log(error)
                this.loading = false
                let errors = error.response.data.errors;
                if(errors) {
                    errors.forEach((error: any) => {
                        this.$message({
                            showClose: true,
                            type: "error",
                            duration: 0,
                            message: error.friendlyMessage,
                        });
                        return error.friendlyMessage;
                    });
                }
            });
        }
        this.loading = false;
    }

    getAddressLineRender(addressLine: any, key: any) {
        key = key.toLowerCase();
        const blacklistKeys = ["id", "companyname", "additionalnotes"];
        if (!addressLine || addressLine == "" || blacklistKeys.indexOf(key) > -1) {
            return;
        }

        var render = addressLine;
        if (key == "ponumber") {
            render = `PO Number: ${addressLine}`;
        }
        return render;
    }

    getMarkdown(markdown: string) {
        var markdownArray = markdown.split("\n");
        markdownArray = this.getMarkdownBold(markdownArray)
        markdownArray = this.getMarkdownItals(markdownArray)
        return "<p>" + markdownArray.join("</p><p>") + "</p>";
    }

    getMarkdownBold(markdownArray: string[]) {
        for (let i = 0; i < markdownArray.length; i++) {
            if(markdownArray[i].indexOf("**") < 0) {
                continue;
            }
            const arraySplit = markdownArray[i].split("**");
            if(arraySplit[0] == "") {
                arraySplit.shift();
            }
            markdownArray[i] = "<b>" + arraySplit.join(arraySplit.length < 3 ? "</b>" : "</b><b>") + (arraySplit.length < 3 ? "" : "</b>");
        }
        return markdownArray;
    }

    getMarkdownItals(markdownArray: string[]) {
        for (let i = 0; i < markdownArray.length; i++) {
            if(markdownArray[i].indexOf("*") < 0) {
                continue;
            }
            const arraySplit = markdownArray[i].split("*");
            if(arraySplit[arraySplit.length-1] == "") {
                arraySplit.pop();
            }
            markdownArray[i] = arraySplit.join("<i>") + "</i>";
        }
        return markdownArray;
    }

    totalInvoice(costs: any) {
        var total = this.fullTotal(costs);
        this.balanceTotals.invoice = total;
        return total;
    }

    totalPayment(costs: any) {
        var total = this.fullTotal(costs);
        this.balanceTotals.payment = total;
        return total;
    }

    fullTotal(costs: any) {
        var total = 0;
        for (let i = 0; i < costs.length; i++) {
            total += costs[i].convertedTotalAmount
        }
        return total;
    }

    displayPrice(price: number) {
        if (price || price == 0) {
            var rounded = calculatePrice(price, 1, 2);
            return rounded.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "Error";
    }

    currencyDisplay(currency: any) {
        return `${currency.code} ${currency.symbol}`;
    }

    invoiceDate(dateString: string) {
        let date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '-');
    }

    printInvoice() {
        window.print();
    }
}
