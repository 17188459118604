var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container sizes-container"},[_c('h1',{attrs:{"id":"title"}},[_vm._v("Size Mapping")]),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.sizes.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"flex-end"},attrs:{"span":10}},[_c('button',{staticClass:"apply-btn",attrs:{"disabled":_vm.loading || _vm.mappingUpdateModel().length < 1},on:{"click":_vm.applyMapping}},[_vm._v("Apply Mapping")])])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.sizeItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"min-width":"150","prop":"sizeInconsistency","label":"Size Inconsistency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.sizeInconsistency.name)+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"name"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"style-popper"}},[_c('div',[_c('ul',{staticClass:"filter-style"},_vm._l((_vm.styles),function(style){return _c('li',{key:style.id,class:{
                  'selected-filter': _vm.isSizeFiltered(style.id) > -1
                },on:{"click":function($event){return _vm.handleStyleFilter(style.id)}}},[_vm._v(" "+_vm._s(style.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":function($event){return _vm.handleStyleFilter()}}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-style': _vm.selectedStyle },staticStyle:{"cursor":"pointer","width":"90px"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Style Name"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"availableSizes","label":"Available Sizes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
                var $index = ref.$index;
return [_c('el-select',{attrs:{"size":'small',"filterable":"","clearable":"","value-key":"id","placeholder":"Select a size","value":_vm.sizeMapping[$index].newSizeId},on:{"change":function($event){return _vm.updateMappingObject($index, $event)}}},_vm._l((row.availableSizes),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id,"size":'small'}})}),1)]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.sizes.totalCount,"totalPages":_vm.sizes.totalPages,"pageSize":_vm.sizes.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }