






























































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminArtAssetCategories, AdminArtAssets } from '@/network/api'
import {
  ArtAssetsAdminListViewModel,
  ArtAssetsAdminListViewModelPaginatedList,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import { sortAscending } from '@/utils/sortAscending'
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    reset: {default: false}
  }
})

@Component({
  name: 'ArtAssetsList',
  components: { ElTableDraggable, Pagination, DialogBox, SendToPage, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  },
})
export default class extends AppProps {
  artAsset: ArtAssetsAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  artAssetItems: Array<ArtAssetsAdminListViewModel> = [];
  search: string | undefined = this.$route.params.search || '';
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 5;
  tab = this.$route.params.tab || 'assets';
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this art asset?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedImageId = '';
  debounce: any = null;
  showAllText: Array<string> = [];
  categories: Array<any> = [];
  allArtAssetCategories: Array<any> = [];
  categoryFilter: Array<any> = [];
  loading = false
  

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      categoryFilter: this.categoryFilter
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData()
    this.getCategories()

    // this.$watch('search', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400)
    // })

    this.$watch('reset', () => {
        this.clearAllFilters()
    })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }

  get sortedCategories() {
    return sortAscending(this.allArtAssetCategories)
  }

  handleCategory(selected: string) {
    if(this.categoryFilter.includes(selected)) {  
      this.categoryFilter = this.categoryFilter.filter(category => {
        return category !== selected
      })
    } else {
      if(typeof this.categoryFilter == 'string') {
        const arr = [this.categoryFilter, selected];
        this.categoryFilter = arr;
      } else {
        this.categoryFilter.push(selected);
      }
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num)
  }

  clearCategoryFilter() {
    this.pageNumber = 1
    this.categoryFilter = [];
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = '';
    this.clearCategoryFilter()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(`${this.uploadPath}/${path.imageUrl}`);
    this.$message.success("Path copied.");
  }

  handleSendToPage(id: string, selectedPage: number, determinePage: number) {
    this.loading = true;
    let patchInfo: any = {};
    let itemId = 0;
    let newPageItems = [] as Array<any>;

    AdminArtAssets.adminArtAssetsGet(determinePage, this.itemPerPage, this.search || undefined, this.categoryFilter)
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!.items as Array<any>;

          if (selectedPage < this.pageNumber) {
            itemId = selectedPage > 1 ? newPageItems.length - 1 : 0;
          }

          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false;

          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminArtAssets.adminArtAssetsIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true
    await AdminArtAssets.adminArtAssetsGet(this.pageNumber, this.itemPerPage, this.search || undefined, this.categoryFilter)
      .then((res) => {
        if(res.data.succeeded) {
          this.artAsset = res.data
          .resultData as ArtAssetsAdminListViewModelPaginatedList
        this.artAssetItems = this.artAsset
          .items as Array<ArtAssetsAdminListViewModel>
          console.log('artAsset items',this.artAssetItems);
        }     
      })
      .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
      })
        this.loading = false
  }

  getCategories() {
    this.loading = true
    AdminArtAssetCategories.adminArtAssetCategoriesGet(1, 9999999)
    .then((res) => {
      if(res.data.resultData) {
        this.categories = res.data.resultData.items
        this.categories!.map(category => {
          this.allArtAssetCategories.push(category);
        })
        console.log('categories', this.categories);       
      }
        this.loading = false
    })
    .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              ); 
        })
  }

  confirmDelete(id: string) {
    this.dialogVisible = true
    this.selectedImageId = id
  }

  deleteItem() {
    if (this.selectedImageId) {
      this.loading = true
      AdminArtAssets.adminArtAssetsIdDelete(this.selectedImageId)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
            this.loading = false
        })
        .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              ); 
        })
    }
    this.dialogVisible = false
    this.selectedImageId = ''
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
