










import { Component, Vue } from "vue-property-decorator";
import OrdersSearch from '@/components/order-enquiry-search/OrdersSearch.vue'
import EnquiriesSearch from '@/components/order-enquiry-search/EnquiriesSearch.vue'
import StoresSearch from '@/components/order-enquiry-search/StoresSearch.vue'
import store from "@/store";
import { AdminUsers } from "@/network/api";
import { UserSelectModel } from "@/api-client";


@Component({
  name: "OrderEnquirySearch",
  components: {
    OrdersSearch,
    EnquiriesSearch,
    StoresSearch
  }
})
export default class extends Vue {
  searchTerm = ''
  orderManagers: Array<UserSelectModel> = []

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  // get showOrderSearchOnly() {
  //   if(this.roles && this.roles.length) {
  //     if(this.roles.includes("Admin")
  //     || this.roles.includes("Operations")) {
  //       return true;
  //     }
  //   }
  //   return false
  // }

  created() {
    if(this.$route.query.search) {
      this.searchTerm = this.$route.query.search as string
    }

    this.$watch("$route.query.search", () => {
        this.searchTerm = this.$route.query.search as string;
    });
    this.loadInitialData()
  }

  loadInitialData() {
    AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
    .then((res) => {
      if (res.data.succeeded) {
        this.orderManagers = res.data.resultData!
          .items as Array<UserSelectModel>;
      }
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    }); 
  }
}
