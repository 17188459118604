



















































import {
  AdjustmentAdminListViewModel, AdminSizeGroupAttributeSizeValuesIdDeleteRequest,
  CountryAdminCreateModel,
  CountryAdminUpdateModel, ImportDutyType, OrderItemAdminViewModel,
  SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData
} from "@/api-client";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import { AdminAdjustments, AdminSizeGroupAttributeSizeValues, AdminSizes } from "@/network/api";
import { ValidationObserver } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";

type formattedOverrides = {
  adjustmentName: string;
  adjustmentId: string;
  overrideValue: string;
}

const AppProps = Vue.extend({
  props: {
    sizeGroupAttributeValueId: { type: String },
    sizeGroupId: { type: String },
    sizeGroupAttributeName: { type: String }
  }
});

@Component({
  name: "SizeGroupAttributeOverride",
  components: {
    ValidationObserver,
    BasicInputField,
    BasicSelectorField
  }
})
export default class extends AppProps {
  selectedProduct: OrderItemAdminViewModel | any = {};
  loading = false;
  attributes = [{ name: 'Slim Fit', value: '' }, { name: 'Classic Fit', value: '' }, { name: 'Slightly Fit', value: '' }];
  attribute: CountryAdminCreateModel | CountryAdminUpdateModel = {
    name: "Test Name",
    currencyId: "",
    isDefault: false,
    importDutyTypeId: ImportDutyType.Dap,
    code: "",
    countryGroupId: "",
    geolocationCodes: [],
    allowBankTransfers: false,
    bankTransferDetails: ""
  };
  showMessage = false;


  sizeGroupAttributeValue?: SizeGroupAttributeSizeValueAdminViewModelApplicationResultResultData = {
    id: '',
    value: '',
    sizeGroupAttributeId: '',
    sizeId: '',
    overrideValues: []
  }

  overrideValues?: Array<formattedOverrides> = [];

  adjustmentTypes?: AdjustmentAdminListViewModel[];
  availableAdjustmentsTypes?: AdjustmentAdminListViewModel[];

  adjustments?: any[];

  disable = false;
  newAttribute: { adjustmentId: string, value: string } | null = null;

  async created() {
    this.loading = true;
    await this.initData()
    this.loading = false;
  }

  async initData() {
    this.showMessage = false;
    this.overrideValues = [];
    const { data: { resultData: attribute } } = await AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesIdGet(this.sizeGroupAttributeValueId)
    this.sizeGroupAttributeValue = attribute!

    // get adjustment types for size group attribute
    const { data: { resultData: size } } = await AdminSizes.adminSizesIdGet(this.sizeGroupAttributeValue!.sizeId)
    const adjId = size!.sizeGroupAttributeValueOverrideAdjustmentTypeId;

    if(adjId) {
      const { data: { resultData: adjustments } } = await AdminAdjustments.adminAdjustmentsGet(1, 5, adjId!);
      this.adjustments = adjustments?.items;

      this.adjustmentTypes = adjustments?.items;
      this.filerAdjustmentTypes();

      this.overrideValues = this.sizeGroupAttributeValue!.overrideValues.map(override => {
        const adjName = this.adjustmentTypes?.find(type => type.id === override.adjustmentId)?.name

        if (!adjName) {
          this.$message({showClose: true, type: 'error', dangerouslyUseHTMLString: true, duration: 0, message: `One or more of your overrides is no longer valid.</br>Please click 'Save' to remove invalid overrides.`})
        }

        return ({
          adjustmentId: override.adjustmentId,
          overrideValue: override.value,
          adjustmentName: adjName as string
        })
      }
      )

      this.adjustmentTypes?.forEach((type) => {
        const match = this.overrideValues?.find(override => type.id === override.adjustmentId);
        if(!match) {
          this.overrideValues?.push({
            adjustmentId: type.id,
            overrideValue: '',
            adjustmentName: type.name
          })
        }
      })
    }

    this.showMessage = true;
  }

  filerAdjustmentTypes(adjustmentTypes: AdjustmentAdminListViewModel[] | null = null) {
    this.availableAdjustmentsTypes = adjustmentTypes ?? this.adjustmentTypes;
  }

  async saveOverrides() {
    const overridesToSend = this.overrideValues?.map((override) => {
      if(override.adjustmentName && override.overrideValue) {
        return ({
          adjustmentId: override.adjustmentId,
          value: override.overrideValue
        })
      }
    }).filter(Boolean)

    const toSend = {
      ...this.sizeGroupAttributeValue,
      overrideValues: overridesToSend
    } as AdminSizeGroupAttributeSizeValuesIdDeleteRequest;
    await AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesIdPut(this.sizeGroupAttributeValue!.id, toSend)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message.success('Saved!')
      }
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    });
    this.initData()
  }

  // addRow() {
  //   this.newAttribute = { adjustmentId: "", value: "" };
  // }

  // async addAttributeOverride() {
  //   const toSend = {
  //     ...this.sizeGroupAttributeValue,
  //     overrideValues: [...this.sizeGroupAttributeValue!.overrideValues, this.newAttribute!]
  //   } as AdminSizeGroupAttributeSizeValuesIdDeleteRequest;
  //   await AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesIdPut(this.sizeGroupAttributeValue!.id, toSend);
  //   this.initData()
  // }

//   async removeAttributeOverride(adjustment) {
//     debugger
//     const item = this.sizeGroupAttributeValue!.overrideValues.find(i => i.adjustmentId === adjustment.adjustmentId)
//     const index = this.sizeGroupAttributeValue!.overrideValues.indexOf(item!, 0);
//     if (index > -1) {
//       this.sizeGroupAttributeValue!.overrideValues.splice(index, 1);
//     }
// debugger
//     const toSend = {
//       ...this.sizeGroupAttributeValue,
//     } as AdminSizeGroupAttributeSizeValuesIdDeleteRequest;
//     await AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesIdPut(this.sizeGroupAttributeValue!.id, toSend);
//     this.initData()
//   }
}
