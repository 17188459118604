























































import { AdminDashboard, AdminUsers } from "@/network/api";
import {
  UserAdminListViewModel,
} from "@/api-client";
import { UserModule } from '@/store/modules/user'
import { Component, Vue } from 'vue-property-decorator'
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import store from "@/store";

@Component({
  name: 'Dashboard',
  components: { BasicSelectorField },
})
export default class extends Vue {
  loading: boolean = false;
  dashboardComponents: any = null;
  userItems: Array<UserAdminListViewModel> = [];
  selectedDashboardUser: string = "";

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get thisUserId() {
    return store.getters['user_new/userId']
  }

  get hasPermission() {
    if(this.roles.includes("Admin")
      || this.roles.includes("Operations")
      || this.roles.includes("Sales")
      || this.roles.includes("Sales Admin")) {
        return true
      }
  return false
  }

  get salesRoleOnly() {
    const requiredRole = "Sales";
    const excludedRoles = ["Admin", "Operations", "Marketing & Design", "Sales Admin"];

    const hasRequiredRole = this.roles.includes(requiredRole);
    const hasExcludedRoles = excludedRoles.some(role => this.roles.includes(role));

    return hasRequiredRole && !hasExcludedRoles;
  }

  created() {
    if(this.hasPermission) {

      if(!this.salesRoleOnly) {
        this.loadInitialData();
      }


    if(this.salesRoleOnly) {
      this.selectedDashboardUser = this.thisUserId
    }

    if(this.selectedDashboardUser) {
      this.loadDashboard(this.selectedDashboardUser);
    }
    else {
      this.loadDashboard("");
    }

    this.$watch('selectedDashboardUser', () => {
      if(this.selectedDashboardUser) {
        this.loadDashboard(this.selectedDashboardUser);
      }
      else {
        this.loadDashboard("");
      }
    });
      }
  }

  async loadDashboard(userID = "") {
    await AdminDashboard.adminDashboardGet(userID ? true : false, userID).then((res) => {
      if(res.data.succeeded) {
        this.dashboardComponents = res.data.resultData;
      }
      this.loading = false;
    })
    .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true;
    if(this.roles.includes('Sales') || this.roles.includes('Sales Admin') || this.roles.includes('Operations') || this.roles.includes('Admin')) {
      await AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.userItems = res.data.resultData!
            .items as Array<UserAdminListViewModel>;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }

    // if(this.roles.includes("Admin")) {
    //   await AdminUsers.adminUsersGet(1, 999999, undefined, undefined, ["Admin"])
    //   .then((res) => {
    //     if(res.data.succeeded) {
    //       this.userItems = res.data.resultData!.items as Array<UserAdminListViewModel>;
    //     }
    //   })
    //   .catch((error) => {
    //     this.loading = false;
    //     error.response.data.errors.map((e: any) => {
    //       this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
    //       return e.friendlyMessage;
    //     });
    //   });
    // }

    this.loadDashboard(this.selectedDashboardUser);

    this.loading = false;
  }

  // get name() {
  //   return UserModule.name
  // }

  // get roles() {
  //   return UserModule.roles
  // }
}
