













































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminOrderPayments, AdminOrders } from "@/network/api";
import {
  OrderPaymentAdminListViewModel,
  OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData,
  PaymentProvider,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import { extend, configure } from "vee-validate";
import { formatDate } from "@/utils/formatDate"
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    clearReasons: {
      type: Boolean,
      default: false
    }
  },
});

@Component({
  name: "PaymentsList",

  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    BasicInputField,
    ImageUploader,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "danger",
        true: "success",
        null: "primary"
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  pageNumber = 1;
  itemPerPage = 5;
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this payment?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  debounce: any = null;
  loading = false;
  selection: Array<any> = [];
  selectedItem: OrderPaymentAdminListViewModel = {
    id: "",
    totalAmount: 0,
    convertedTotalAmount: 0,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    paymentProviderId: PaymentProvider.Stripe,
    paymentProviderPaymentId: "",
    paymentProviderResponse: "",
    proofOfPaymentUrl: "",
    isSuccessful: false,
    lastModifiedDate: "",
    paymentDate: "",
    internalReason: '',
    externalReason: '',
    orderCustomerContactId: null,
    orderCustomerContactName: null
  };
  selectedProviderFilter = "";
  orderPayments: OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData =
    {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 1,
    };
  hasProvider: any = undefined;
  convertedOrderBalance = 0;
  internalReason = ''
  externalReason = ''

  get hasStripePaymentType() {
    return this.orderPayments.items.find(item => item.paymentProviderId === 'Stripe') ? true : false;
  }

  get canEditPayments() {
    let roles =  store.getters["user_new/role"] as any;

    if(roles.includes('Admin') || roles.includes('Operations')) {
      return true;
    }

    return false
  }

  get disableSendPayment() {
    const disabledStatuses = ['Cancelled', 'OnHold', 'Lost', 'AwaitingResponse', 'AwaitingAdditionalPayment']

    if(disabledStatuses.includes(this.order.orderStatusId)) {
      return true
    }
    return false
  }

  created() {
    if (this.order) {
      this.loadInitialData();
    }

    this.$watch("order", () => {
      this.loadInitialData();
    });

    this.$watch("$route", () => {
      if (this.$route.query.tab === "payments") {
        this.loadInitialData();
      }
    });

    this.$watch("clearReasons", () => {
      if(this.clearReasons) {
        this.internalReason = this.externalReason = ''
        this.$emit('resetClear')
      }
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handleDate(date:any) {
    return formatDate(date);
  }

  handleProvider(selected: string) {
    if(selected !== this.selectedProviderFilter) {
      this.hasProvider = this.selectedProviderFilter = selected;
    } else {
      this.hasProvider = undefined;
      this.selectedProviderFilter = "";
    }
    this.loadInitialData();
  }

  copyLinkPath() {
    let link = `${process.env.VUE_APP_ROOT_WEB}/order-payments/${this.order.id}`;
    navigator.clipboard.writeText(`${link}`)
    this.$message.success('Path copied.')
  }

  clearProviderFilter() {
    this.selectedProviderFilter = "";
    this.hasProvider = undefined;
    this.loadInitialData();
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(
      `${this.uploadPath}/${path.proofOfPaymentUrl}`
    );
    this.$message.success("Path copied.");
  }

  editPayment(row: OrderPaymentAdminListViewModel) {
    this.$emit("edit", row.id);
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    AdminOrderPayments.adminOrderPaymentsIdDelete(this.selectedItem.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.pageNumber = 1;
          this.$message.info("Item deleted.");
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
      this.loading = false;
      this.dialogVisible = false;

      this.selectedItem = {
        id: "",
        totalAmount: 0,
        convertedTotalAmount: 0,
        currency: {
          id: "",
          name: "",
          code: "",
          symbol: "",
          pricingMultiplier: 0,
          decimalPlaces: 0,
        },
        paymentProviderId: PaymentProvider.Stripe,
        paymentProviderPaymentId: "",
        paymentProviderResponse: "",
        proofOfPaymentUrl: "",
        isSuccessful: false,
        lastModifiedDate: "",
        paymentDate: "",
        internalReason: '',
        externalReason: ''
      };
  }

  sendPaymentEmail() {
    let isDirty = document.querySelectorAll(".dirty");
    if (isDirty.length > 0) {
      this.$message({showClose: true, type: 'error', duration: 0, message: 'Please save your changes before sending a payment request.'})
    } else {
      if((this.order.orderStatusId == 'BankTransferPending') || (this.order.orderStatusId == 'ResponseReceived')) {
        this.$emit('attemptPaymentEmail', {internal: this.internalReason, external: this.externalReason})
      } else {
        AdminOrders.adminOrdersSendOutstandingPaymentEmailOrderIdPost(this.order.id, this.internalReason, this.externalReason)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.success('Email sent successfully.');
            this.$emit('reload')
            this.internalReason = this.externalReason = ''
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      }
    }
  }

  async loadInitialData() {
    if (this.order.id) {
    this.loading = true;

      await AdminOrderPayments.adminOrderPaymentsGet(
        this.pageNumber,
        this.itemPerPage,
        this.order.id,
        this.hasProvider
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.orderPayments = res.data
              .resultData as OrderPaymentAdminListViewModelPaginatedListApplicationResultResultData;
            // this.cancelEdit();
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      await AdminOrders.adminOrdersGetConvertedOrderBalanceIdGet(this.order.id)
        .then((res) => {
          if (res.data.succeeded) {
            let oldBalance = this.convertedOrderBalance
            this.convertedOrderBalance = res.data.resultData as number;

            if(oldBalance != this.convertedOrderBalance) {
              this.$emit('reloadBalance', this.convertedOrderBalance)
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
          this.loading = false;

  }
}
