










































































































































































































































































































































































































































































































































































































import {
  ColourGroupAdminListViewModel,
  ConstructionType,
  FabricAdminListViewModel,
  FabricChannelAdminViewModel,
  LayerAdminCreateModel,
  LayerAdminUpdateModel,
  LayerAdminViewModel,
  LayerFabricAdminCreateModel,
  LayerFabricAdminListViewModel,
  LayerFabricAdminListViewModelPaginatedList,
  LayerFabricAdminUpdateModel,
  PriceAdminViewModel,
  ProductAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import Pagination from "@/components/Pagination.vue";
import PriceRange from "@/components/form-items/PriceRange.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import { handleQueries } from "@/utils/handleQueries";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import {
  AdminColourGroups,
  AdminFabricChannels,
  AdminLayerFabrics,
  AdminLayerPrices,
  AdminLayers,
} from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: { default: "" },
  },
});
@Component({
  name: "NewLayer",
  components: {
    PriceRange,
    Pagination,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    ItemsPerPage,
    BasicTooltipHeading,
    BasicSelectorField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  frontImage: Array<any> = [];
  backImage: Array<any> = [];
  showBanner: boolean = true;
  layer: LayerAdminViewModel = {
    id: "",
    name: "",
    code: "",
    isDisabled: true,
    frontMaskUrl: "",
    backMaskUrl: "",
    hasProductBuilderOptionsGenerationLogs: false,
    fabricChannelId: null,
    fabricChannelInternalName: null,
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newLayer: LayerAdminCreateModel | LayerAdminUpdateModel = {
    name: "",
    code: "",
    isDisabled: true,
    frontMaskUrl: "",
    backMaskUrl: "",
    fabricChannelId: null,
  };
  layerFabricsPageNumber = 1;
  layerFabricsItemPerPage = 5;
  disable = false;
  dirtyTimer: any = null;
  layerFabrics: LayerFabricAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  layerFabricItems: Array<LayerFabricAdminListViewModel> = [];
  newLayerFabric: LayerFabricAdminCreateModel | LayerFabricAdminUpdateModel = {
    layerId: "",
    fabricId: "",
    colourGroupIds: [],
    constructionTypeId: ConstructionType.CutAndSew,
    price: 0,
    customPatternPrice: 0,
    productsIds: [],
  };
  selectedId = "";
  originalFabricId = "";
  originalColourArray: Array<any> = [];
  originalProductsArray: Array<any> = [];
  originalConstructionTypeId = "";
  originalPrice = 0;
  originalCustomPatternPrice = 0;
  layerFabricOptions: Array<FabricAdminListViewModel> = [];
  layerColourOptions: Array<FabricAdminListViewModel> = [];
  colourGroups: Array<ColourGroupAdminListViewModel> = [];
  currentFabricId = "";
  selectedColours: Array<string> = [];
  selectedColoursList: Array<Array<string>> = [];
  toggleColourOptionValue = false;
  toggleFabricOptionValue = false;
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: "",
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: "",
    },
  ];
  loading = false;
  constructionTypes = [
    {
      id: ConstructionType.CutAndSew,
      name: "Cut and Sew",
    },
    {
      id: ConstructionType.Sublimation,
      name: "Sublimation",
    },
  ];
  layerProducts: Array<ProductAdminListViewModel> = [];
  fromPath = "";
  fabricChannels: Array<FabricChannelAdminViewModel> = [];

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    let toQuery = {
      layerFabricsPageNumber: this.layerFabricsPageNumber,
      layerFabricsItemPerPage: this.layerFabricsItemPerPage,
    };
    this.setQueryWatch(toQuery);
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("front", {
      validate(value) {
        let uploader = self.$refs.frontUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("back", {
      validate(value) {
        let uploader = self.$refs.backUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val);
      });
    }
  }

  validateField(field: any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if (provider) {
      return provider!.validate();
    }
  }

  handleUseEmpty(path: any, target: any) {
    this.newLayer[target] = path;
  }

  handlePageNumberClick(page: any) {
    this.layerFabricsPageNumber = page;
    this.loadLayerFabrics();
  }

  toggleFabricOption(visible: boolean) {
    this.toggleFabricOptionValue = visible;
  }

  toggleColourOption(visible: boolean) {
    this.toggleColourOptionValue = visible;
  }

  uploadImages(): Promise<any> {
    let frontUploader = this.$refs.frontUploader as ImageUploader;
    let backUploader = this.$refs.backUploader as ImageUploader;

    let frontPromise;
    let backPromise;

    if (frontUploader) {
      frontPromise = frontUploader!.uploadImage();
    }
    if (backUploader) {
      backPromise = backUploader!.uploadImage();
    }
    return Promise.all([frontPromise, backPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // debugger
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;

    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   debugger
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   debugger
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // debugger
    // return result;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if (isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }
  removeImage(area: string) {
    if (area == "front") {
      this.newLayer.frontMaskUrl = "";
    } else if (area == "back") {
      this.newLayer.backMaskUrl = "";
    }
  }

  handleConfirm() {
    if (
      this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>"
    ) {
      if (this.fromPath === "Layers") {
        this.$router.back();
      } else {
        this.$router.push({ name: "Layers" });
      }
    } else if (this.confirmText === "Yes") {
      this.deleteRow();
    } else {
      this.submitForm();
    }
  }

  fetchColours(row: any) {
    this.selectedColours = [];
    console.log("fetch row", row);

    if (row.colourGroups) {
      row.colourGroups = [];
    }

    if (row.fabricId) {
      this.currentFabricId = row.fabricId;
    } else {
      this.currentFabricId = row;
    }

    if (this.currentFabricId) {
      this.loading = true;
      AdminLayerFabrics.adminLayerFabricsGetColourGroupsForFabricGet(
        this.currentFabricId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get cols for layers", res);
            this.layerColourOptions = res.data
              .resultData as Array<FabricAdminListViewModel>;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
  }

  editField(row: any, index: any) {
    console.log(row);

    if (
      this.selectedId === row.id &&
      row.fabricId !== "" &&
      row.colourGroups.length
    ) {
      this.disable = false;
      this.saveLayerFabric(row);
    } else {
      this.loadOptions(row.id);
      this.fetchColours(row.fabricId);
      this.originalFabricId = row.fabricId;
      this.originalConstructionTypeId = row.constructionTypeId;
      this.originalPrice = row.price;
      this.originalCustomPatternPrice = row.customPatternPrice;
      this.selectedId = row.id;
      this.originalColourArray = [...row.colourGroups];
      this.originalProductsArray = [...row.productsIds];
      this.disable = true;
    }
  }

  addRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadOptions();

    if (this.layerFabricItems.length)
      this.layerFabricItems.map((item) => {
        if (item.fabricId === "" || !item.colourGroups.length) {
          emptyName += 1;
        }
      });

    if (emptyName === 0) {
      this.layerFabricItems.push({
        id: tempId,
        layerId: this.layer.id,
        fabricId: "",
        fabricName: "",
        layerName: "",
        layerCode: "",
        colourGroups: [],
        constructionTypeId: ConstructionType.CutAndSew,
        price: 0,
        customPatternPrice: 0,
        productsIds: [],
      });
      console.log("items", this.layerFabricItems);
      this.originalFabricId = "";
      this.originalConstructionTypeId = "";
      this.originalPrice = 0;
      this.originalCustomPatternPrice = 0;
      this.originalColourArray = [];
      this.originalProductsArray = [];
      this.selectedColours = [];
      this.selectedId = tempId;
      this.disable = true;
    }
  }

  saveLayerFabric(data: any) {
    if (data.fabricId !== "" && data.colourGroups.length > 0) {
      this.loading = true;
      let regex = /^(temp).*/g;
      if (!data.id.match(regex)) {
        let colourIds: Array<string> = [];
        data.colourGroups.map((item: any) => {
          colourIds.push(item.id);
        });
        this.newLayerFabric = {
          fabricId: data.fabricId,
          layerId: this.id,
          colourGroupIds: [...colourIds],
          constructionTypeId: data.constructionTypeId,
          price: data.price,
          customPatternPrice: data.customPatternPrice,
          productsIds: [...data.productsIds],
        };

        console.log("before put", this.newLayerFabric);
        AdminLayerFabrics.adminLayerFabricsIdPut(data.id, this.newLayerFabric)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Put success", res);
              this.$message.success("Saved!");
              this.loadLayerFabrics();
              this.loadOptions();
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.dialogVisible = false;
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
          });
      } else {
        let colourIds: Array<string> = [];
        data.colourGroups.map((item: any) => {
          colourIds.push(item.id);
        });
        this.newLayerFabric = {
          fabricId: data.fabricId,
          layerId: this.id,
          colourGroupIds: [...colourIds],
          constructionTypeId: data.constructionTypeId,
          price: data.price,
          customPatternPrice: data.customPatternPrice,
          productsIds: [...data.productsIds],
        };

        console.log("before post", this.newLayerFabric);
        AdminLayerFabrics.adminLayerFabricsPost(this.newLayerFabric)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Post success", res);
              this.loading = false;
              this.$message.success("Saved!");
              this.loadLayerFabrics();
              this.loadOptions();
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
            this.dialogVisible = false;
          });
      }
      this.loading = false;
      this.disable = false;
      this.selectedId = "";
    }
  }

  confirmDelete(data: any) {
    this.dialogMessage =
      "<span><stong>Are you sure you want to delete this fabric colour?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if (this.selectedId === data.id && !this.selectedId.match(regex)) {
      data.fabricId = this.originalFabricId;
      data.constructionTypeId = this.originalConstructionTypeId;
      data.price = this.originalPrice;
      data.customPatternPrice = this.originalCustomPatternPrice;
      data.colourGroups = [...this.originalColourArray];
      data.productsIds = [...this.originalProductsArray];
      this.selectedId = "";
      this.disable = false;
    } else {
      console.log("in delete", data);

      this.selectedId = data.id;
      this.dialogVisible = true;
    }
    this.loadOptions();
  }

  deleteRow() {
    let regex = /^(temp).*/g;
    let index = this.layerFabricItems.length - 1;
    if (this.selectedId.match(regex)) {
      this.layerFabricItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.layerFabricItems.splice(index, 1);
    } else {
      this.loading = true;
      AdminLayerFabrics.adminLayerFabricsIdDelete(this.selectedId)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.info("Item deleted.");

            this.loadLayerFabrics();
            this.loadOptions();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
    this.selectedId = "";
    this.originalFabricId = "";
    this.originalConstructionTypeId = "";
    this.originalPrice = 0;
    this.originalCustomPatternPrice = 0;
    this.originalColourArray = [];
    this.originalProductsArray = [];
    this.dialogVisible = false;
    this.loading = false;
    this.disable = false;
    this.currentFabricId = "";
  }

  //-------- Price Range Start

  saveNewPriceRange(range: any) {
    this.loading = true;
    AdminLayerPrices.adminLayerPricesPost(range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price post success", res);

          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
      });
  }

  updatePriceRange(range: any) {
    this.loading = true;
    AdminLayerPrices.adminLayerPricesIdPut(range.id, range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price put success", res);
          this.$message.success("Saved!");

          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        this.loadPriceRanges();
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  deletePriceRange(id: string) {
    this.loading = true;
    AdminLayerPrices.adminLayerPricesIdDelete(id)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message.info("Item deleted.");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  loadPriceRanges() {
    if (this.id) {
      this.loading = true;
      AdminLayerPrices.adminLayerPricesGet(1, 99999999, this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.priceRanges = res.data.resultData!.items;
            console.log("get price", this.priceRanges);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  //-------- Price Range End

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newLayer.frontMaskUrl = results[0].imageUrl;
      }

      if (results[1].imageUrl) {
        this.newLayer.backMaskUrl = results[1].imageUrl;
      }

      if (this.layer.id) {
        console.log("before save", this.newLayer);

        AdminLayers.adminLayersIdPut(this.layer.id, this.newLayer)
          .then((res) => {
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
          });
      } else {
        AdminLayers.adminLayersPost(this.newLayer)
          .then((res) => {
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.id = res.data.resultData!.id;
              this.$router.replace({
                name: "EditLayer",
                params: { id: this.id },
              });
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
          });
      }
    });
  }

  loadOptions(layerFabricId: string | undefined = undefined) {
    if (this.id) {
      this.loading = true;
      AdminLayerFabrics.adminLayerFabricsGetFabricsForLayerGet(
        this.id,
        layerFabricId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get fabs for layers", layerFabricId, res);
            this.layerFabricOptions = res.data
              .resultData as Array<FabricAdminListViewModel>;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
  }

  async loadLayerFabrics() {
    this.loading = true;
    if (this.id) {
      await AdminLayerFabrics.adminLayerFabricsGet(
        this.layerFabricsPageNumber,
        this.layerFabricsItemPerPage,
        this.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("fab layers get", res);

            this.layerFabrics = res.data
              .resultData as LayerFabricAdminListViewModelPaginatedList;
            this.layerFabricItems = this.layerFabrics.items;

            this.selectedColoursList = [];
            if (this.layerFabricItems.length) {
              this.layerFabricItems.map((item: any) => {
                this.selectedColoursList.push(item.colourGroups);
              });
              console.log("col list", this.selectedColoursList);
            }

            this.newLayerFabric = {
              fabricId: "",
              colourGroupIds: [],
              layerId: this.id,
              constructionTypeId: ConstructionType.CutAndSew,
              price: 0,
              customPatternPrice: 0,
              productsIds: [],
            };
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  async loadFabricChannels() {
    // this.loading = true;
    await AdminFabricChannels.adminFabricChannelsGet(1, 9999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.fabricChannels = res.data.resultData
            ?.items as Array<FabricChannelAdminViewModel>;
          this.loading = false;
        }
      })
      .catch((error) => {
        this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    await this.loadFabricChannels();

    if (this.id) {
      this.loadPriceRanges();
      this.currentFabricId = "";
      this.loadOptions();
      this.loading = true;

      await AdminColourGroups.adminColourGroupsGet(1, 9999999)
        .then((res) => {
          if (res.data.succeeded) {
            this.colourGroups = res.data.resultData!.items;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });

      await this.loadLayerFabrics();
      this.loading = true;

      await AdminLayers.adminLayersIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.layer = res.data.resultData as LayerAdminViewModel;
            this.name = this.layer.name;
            this.newLayer = (({ id, ...rest }) => rest)(this.layer) as any;
            console.log("new layer", this.newLayer);

            if (this.newLayer.frontMaskUrl) {
              this.frontImage = [
                {
                  imageUrl: this.newLayer.frontMaskUrl,
                },
              ];
            }
            if (this.newLayer.backMaskUrl) {
              this.backImage = [
                {
                  imageUrl: this.newLayer.backMaskUrl,
                },
              ];
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });

      await AdminLayerFabrics.adminLayerFabricsGetProductsForLayerGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.layerProducts = res.data
              .resultData as Array<ProductAdminListViewModel>;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });

      this.loading = false;
    }
    this.loading = false;
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
