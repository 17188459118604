






















































































































































import {
  ColourGroupAdminListViewModel,
  CustomizationGroupAdminListViewModel,
  DesignAdminCreateModel,
  DesignAdminListViewModel,
  DesignAdminUpdateModel,
  DesignAdminViewModel,
  LayerAdminListViewModel,
  PriceAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from "@/utils/sortAscending";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
  validate,
} from "vee-validate";
import {
  AdminColourGroups,
  AdminCustomizationGroups,
  AdminDesignPrices,
  AdminDesigns,
  AdminLayers,
} from "@/network/api";
import PriceRange from "@/components/form-items/PriceRange.vue";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewDesign",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    PriceRange,
    BasicTooltipHeading
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  showBanner: boolean = true;
  design: DesignAdminViewModel = {
    id: "",
    name: "",
    code: "",
    imageUrl: "",
    isDisabled: true,
    customizationGroupIds: [],
    layerIds: [],
    hasProductBuilderOptionsGenerationLogs: false
  };
  designs: Array<DesignAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  colours: Array<ColourGroupAdminListViewModel> = [];
  layers: Array<LayerAdminListViewModel> = [];
  customizations: Array<CustomizationGroupAdminListViewModel> = [];
  newDesign: DesignAdminCreateModel | DesignAdminUpdateModel = {
    name: "",
    code: "",
    imageUrl: "",
    isDisabled: true,
    customizationGroupIds: [],
    layerIds: [],
  };
  selectedRowId = "";
  originalApplicationName = "";
  originalAdditionalCost = "";
  loading = false;
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: "",
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: "",
    },
  ];
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  validateField(field: any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if (provider) {
      return provider!.validate();
    }
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("preview", {
      validate(value) {
        let uploader = self.$refs.previewUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("layers", () => {
      if (this.newDesign.layerIds.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("customizations", () => {
      if (this.newDesign.customizationGroupIds.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedLayers() {
    return sortAscending(this.layers, false);
  }

  get sortedCustomizations() {
    return sortAscending(this.customizations, false);
  }

  handleUseEmpty(path: any, target: any) {
    this.newDesign[target] = path;
  }

  uploadImages(): Promise<any> {
    let previewUploader = this.$refs.previewUploader as ImageUploader;
    let previewPromise;

    if (previewUploader) {
      previewPromise = previewUploader!.uploadImage();
    }
    return Promise.all([previewPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // debugger
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;

    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   debugger
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   debugger
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // debugger
    // return result;
  };

  editField(data: any) {
    if (this.selectedRowId === data.id) {
      //this.saveChildTag(data);
    } else {
      //this.originalAdditionalCost = ?
      this.originalApplicationName = data.name;
      this.selectedRowId = data.id;
    }
  }

  confirmDelete(data: any) {
    if (
      this.selectedRowId === data.id &&
      this.originalApplicationName !== "" &&
      this.originalAdditionalCost !== ""
    ) {
      data.name = this.originalApplicationName;
      //? = this.originalAdditionalCost;
      this.selectedRowId = "";
    } else {
      this.selectedRowId = data.id;
      this.dialogVisible = true;
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  beforeDelete() {
    this.dialogMessage =
      "<span>Are you sure you want to delete this application method?</span>";
    this.confirmText = "Yes";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "preview") {
      this.newDesign.imageUrl = "";
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.loading = true;
      if(this.fromPath === 'Designs') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Designs"});
      }
    } else {
      this.submitForm();
    }
  }

  //-------- Price Range Start

  saveNewPriceRange(range: any) {
    this.loading = true;
    AdminDesignPrices.adminDesignPricesPost(range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price post success", res);
          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
      });
  }

  updatePriceRange(range: any) {
    this.loading = true;
    AdminDesignPrices.adminDesignPricesIdPut(range.id, range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price put success", res);
          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
      });
  }

  deletePriceRange(id: string) {
    this.loading = true;
    AdminDesignPrices.adminDesignPricesIdDelete(id)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message.info("Item deleted.");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  loadPriceRanges() {
    if (this.id) {
      this.loading = true;
      AdminDesignPrices.adminDesignPricesGet(1, 999999999, this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.priceRanges = res.data.resultData!.items;
            console.log("get price", this.priceRanges);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
  }

  //-------- Price Range End

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newDesign.imageUrl = results[0].imageUrl;
      }

      if (this.design.id) {
        console.log("before save", this.newDesign);

        AdminDesigns.adminDesignsIdPut(this.design.id, this.newDesign)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("success?", res);
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
          });
      } else {
        AdminDesigns.adminDesignsPost(this.newDesign)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.id = res.data.resultData!.id
              this.$router.replace({ name: "EditDesign", params: {id: this.id} });
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
          });
      }
    });
  }

  async loadCustomizations(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminCustomizationGroups.adminCustomizationGroupsGet(1, 9999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.customizations = res.data.resultData!
            .items as Array<CustomizationGroupAdminListViewModel>;
          if (refresh) {
            this.loading = false;
            this.$message.success("Section Refreshed.");
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadLayers(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminLayers.adminLayersGet(1, 9999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.layers = res.data.resultData!
            .items as Array<LayerAdminListViewModel>;
          console.log("layers", this.layers);
          if (refresh) {
            this.loading = false;
            this.$message.success("Section Refreshed.");
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadInitialData() {
    this.loading = true;
    await this.loadLayers();

    await AdminColourGroups.adminColourGroupsGet(1, 9999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.colours = res.data.resultData!
            .items as Array<ColourGroupAdminListViewModel>;
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });

    await this.loadCustomizations();

    if (this.id) {
      this.loadPriceRanges();
      await AdminDesigns.adminDesignsIdGet(this.id)
        .then((res) => {
          this.design = res.data.resultData as DesignAdminViewModel;
          this.name = this.design.name;
          this.newDesign = (({ id, ...rest }) => rest)(this.design) as any;
          console.log("new design", this.newDesign);

          if (this.newDesign.imageUrl) {
            this.previewImage = [
              {
                imageUrl: this.newDesign.imageUrl,
              },
            ];
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
    this.loading = false;
  }
}
