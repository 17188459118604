var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container general-imagery-container"},[_c('h1',[_vm._v(" General Imagery ")]),_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{name: 'NewGeneralImage'}}},[_c('button',{staticClass:"new-image",attrs:{"disabled":_vm.loading}},[_vm._v("New General Image")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.generalImage.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {_vm.itemPerPage = value; _vm.loadInitialData()},"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","disabled":_vm.loading,"size":"mini","placeholder":"Search name","clearable":""},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ElTableDraggable',{attrs:{"disable":true}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.generalImageryItems,"row-key":"id","fit":"","stripe":"","border":"","resizable":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"width":"250","prop":"imageUrl","label":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"max-height":"150px"},attrs:{"fit":'contain',"alt":row.name,"src":row.imageUrl && (_vm.uploadPath + "/" + (row.imageUrl))}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"360"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v("Clear Filters")])]}},{key:"default",fn:function(scope){return [_c('a',{attrs:{"href":(_vm.uploadPath + "/" + (scope.row.imageUrl))}},[_c('el-button',{staticStyle:{"margin":"0 15px 0 0"},attrs:{"plain":"","size":"mini","type":"info"}},[_vm._v("Download")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"info"},on:{"click":function($event){return _vm.copyPath(scope.row)}}},[_vm._v("Copy Path")]),_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditGeneralImage',
              params: {
                id: scope.row.id,
              }
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(scope.row.id)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.generalImage.totalCount,"totalPages":_vm.generalImage.totalPages,"pageSize":_vm.generalImage.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }