



















































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  OrderAdminListViewModel,
  OrderStatus,
  OrderAdminListViewModelPaginatedList,
  OrderStatusListViewModel,
  FactoryAdminListViewModel,
  OrderSortColumn,
  SortDirection,
  UserSelectModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import DateRangeFilter from "@/components/form-items/DateRangeFilter.vue";
import { AdminFactories, AdminOrders, AdminUsers } from "@/network/api";
import { formatDate } from "@/utils/formatDate"
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import store from "@/store";
import { handleQueries } from "@/utils/handleQueries"

interface SortByType {
  column: OrderSortColumn | undefined;
  direction: SortDirection | undefined;
}

@Component({
  name: "Orders",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    DateRangeFilter,
    BasicSelectorField
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Cart: "brand",
        Received: "success",
        AwaitingPayment: "danger",
      };
      return statusMap[status];
    },
    noFailFilter: (nofail: string) => {
      const noFailMap: { [key: string]: string } = {
        true: "danger"
      };
      return noFailMap[nofail];
    },
    truthyFilter: (exw: string) => {
      const exWorksMap: { [key: string]: string } = {
        false: "danger",
        true: 'success',
        null: ''
      };
      return exWorksMap[exw];
    },
    paymentFilter: (payment: number) => {
      const paymentMap: { [key: string]: string } = {
        even: "success",
        over: "danger",
        under: "warning",
      };
      let paymentStatus = "";
      if (payment > 0) {
        paymentStatus = "over";
      } else if (payment < 0) {
        paymentStatus = "under";
      } else {
        paymentStatus = "even";
      }
      return paymentMap[paymentStatus];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})



export default class extends Vue {
  orders: OrderAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  orderItems: Array<OrderAdminListViewModel> = [];
  search: string | undefined = this.$route.params.search || "";
  orderManagers: Array<UserSelectModel> = []
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  selectedItem: OrderAdminListViewModel = {
    id: "",
    referenceNumber: "",
    orderStatusId: OrderStatus.Cart,
    customerEmail: "",
    orderPlacementDate: "",
    expectedDeliveryDate: "",
    updatedDeliveryDate: "",
    convertedOrderTotal: 0,
    factories: [],
    exWorksApproved: false,
    exWorksDate: "",
    groupedUK: false,
    groupedUSA: false,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    convertedPaymentOutstanding: 0,
    isQuote: false,
    isStoreOrder: false,
    marketingOptIn: false,
    storeId: null,
    storeName: null,
    disableAutoSendFactoryEmails: false
  };
  debounce: any = null;
  status: Array<OrderStatus> = [];
  selectedFactory: string = '';
  selectedOrderManager: string = '';
  selectedSalesManager: string = '';
  orderStatus?: undefined | OrderStatus;
  expectedDelivery = {
    startDate: undefined,
    endDate: undefined,
  };
  orderPlacement = {
    startDate: undefined,
    endDate: undefined,
  };
  showAllText: Array<string> = [];
  loading = false;
  selection: Array<any> = [];
  orderStatuses: Array<OrderStatusListViewModel> = [];
  factories: Array<FactoryAdminListViewModel> = [];
  successCount = 0;
  failCount = 0;
  isEXWApproved = ''
  isStoreOrder = ''
  dispatchReportFactory: Array<FactoryAdminListViewModel> = [];
  sortBy: SortByType = {
    column: undefined,
    direction: undefined
  }
  exwColumn: OrderSortColumn = OrderSortColumn.ExwDate
  isQuote: boolean | null = null
  calculateOrderTotals = 'false';
  includePaymentId = 'false';

  created() {
    if (this.$route.query.isQuote) {
      this.isQuote = this.$route.query.isQuote == 'true' ? true : null;
    }

    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      status: this.status,
      selectedFactory: this.selectedFactory,
      ['orderPlacement.startDate']: this.orderPlacement.startDate,
      ['orderPlacement.endDate']: this.orderPlacement.endDate,
      ['expectedDelivery.startDate']: this.expectedDelivery.startDate,
      ['expectedDelivery.endDate']: this.expectedDelivery.endDate,
      isEXWApproved: this.isEXWApproved,
      isStoreOrder: this.isStoreOrder,
      ['sortBy.column']: this.sortBy.column,
      ['sortBy.direction']: this.sortBy.direction,
      isQuote: this.isQuote,
      calculateOrderTotals: this.calculateOrderTotals,
      includePaymentId: this.includePaymentId,
      selectedOrderManager: this.selectedOrderManager,
      selectedSalesManager: this.selectedSalesManager
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();
  }

  sendQuoteReminder(id: string) {
    AdminOrders.adminOrdersSendQuoteReminderOrderIdPost(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message({showClose: true, type: 'success', message: 'Checkout reminder sent!'})
      }
    })
    .catch((error) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: 'Error sending checkout reminder.'})
        error.response.data.errors.map((e: any) => {
        this.$message.error(e.friendlyMessage);
        return e.friendlyMessage;
      });
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        let object = null as any

        if(field.includes('.')) {
          object = field.split('.')
        }

        if(object?.length == 2) {
          this[object[0]][object[1]] = queries[field];
        } else {
          this[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    })
  }

  handleSortBy() {
    const sortOptions = {
      [SortDirection.Ascending]: SortDirection.Descending,
      [SortDirection.Descending]: undefined
    }

    if(this.sortBy.column == undefined) {
        this.sortBy = {
          column: OrderSortColumn.ExwDate,
          direction: SortDirection.Ascending
        }
    } else {
      this.sortBy.direction = sortOptions[this.sortBy.direction as SortDirection] as any;

      if(this.sortBy.direction == undefined) {
        this.sortBy.column=  undefined;
      }
    }

    this.pageNumber = 1
    this.loadInitialData()
  }

  sortEXWDate() {
    if(this.sortBy.direction == SortDirection.Ascending) {
      return 'el-icon-sort-up'
    } else if(this.sortBy.direction == SortDirection.Descending) {
      return 'el-icon-sort-down'
    }
    return 'el-icon-sort'
  }

  disableCheckbox(status: string) {
    return (
      status !== "AwaitingAdditionalPayment" && status !== "AwaitingResponse"
    );
  }

  async handleTriggerReminder() {
    await this.sendReminder()
      .then((res: any) => {
        if (this.successCount === res) {
          this.$message({
            showClose: true,
            type: "success",
            duration: 0,
            message: `${this.successCount} reminder/s sent successfully.`,
          });
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            duration: 0,
            message: `${this.successCount} reminder/s sent successfully. ${this.failCount} reminder/s failed.`,
          });
        }

        this.successCount = 0;
        this.failCount = 0;
      })
      .catch((error) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: `${this.successCount} reminder/s sent successfully. ${this.failCount} reminder/s failed.`,
        });
        this.successCount = 0;
        this.failCount = 0;
      });
  }

  async isFactoryDispatchReport() {
    await AdminOrders.adminOrdersGet(1, 1, false, false, ["PreparingToDispatch"], undefined, undefined, undefined, undefined, undefined, undefined, undefined, true)
    .then(async (res) => {
      if(res.status == 200) {
        return res.data.resultData!.items.length > 0;
      }
    })
    .catch((e) => {
      this.$message({
        showClose: true,
        type: "error",
        duration: 0,
        message: e.friendlyMessage,
      });
    });
    return false;
  }

  async factoryDispatchReport(factorySelect) {
    const factoryIds: string[] = [];
    factorySelect.forEach((factoryid) => {
      factoryIds.push(factoryid)
    });
    await AdminFactories.adminFactoriesGetFactoryOrdersReportGet(factoryIds ? factoryIds : undefined, {
      responseType: 'arraybuffer',
    })
    .then(async (res) => {
        if(res.status == 200) {
            await this.saveXSL(res.data)
        }
    })
    .catch((e) => {
      this.$message({
        showClose: true,
        type: "error",
        duration: 0,
        message: e.friendlyMessage,
      });
    });
  }

  async saveXSL(data:any) {
    var assetBlob = new Blob([data], {type:"application/xlsx"});
    var assetUrl = URL.createObjectURL(assetBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = assetUrl;
    downloadLink.download = `Factory Dispatch Report - ${new Date().toISOString().replaceAll("T"," ").replaceAll("Z","").replaceAll(":","--")}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  async sendReminder() {
    return new Promise(async (resolve, reject) => {
      if (this.selection.length > 0) {
        let total = 0;
        this.selection.forEach(async (id: string) => {
          await AdminOrders.adminOrdersSendResponeReminderIdPost(id)
            .then((res) => {
              if (res.data.succeeded) {
                total += 1;
                this.successCount += 1;
                if (total === this.selection.length) {
                  resolve(total);
                }
              }
            })
            .catch((error) => {
              total += 1;
              this.failCount += 1;
              error.response.data.errors.map((e: any) => {
                this.$message({
                  showClose: true,
                  type: "error",
                  duration: 0,
                  message: e.friendlyMessage,
                });
                // return e.friendlyMessage;
              });
                reject(error);
            });
        });
      } else {
        reject()
      }
    });
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handleStatusName(id: string) {
    if (this.orderStatuses.length) {
      let match = this.orderStatuses.find((order) => id == order.id);
      if (match) {
        return match.name;
      }
    }
    return id;
  }

  handleFactories(factories:Array<string>) {
    let removeDuplicates:Array<string> = []
    factories.forEach((factory) => {
      if (!removeDuplicates.includes(factory)) {
          removeDuplicates.push(factory)
        }
      }
    )

    return removeDuplicates.join(', ')
  }

  clearOrderStatusFilter() {
    this.status = [];
    this.pageNumber = 1;
    this.loadInitialData();
  }

  clearFactoryFilter() {
    this.selectedFactory = '';
    this.pageNumber = 1;
    this.loadInitialData();
  }

  clearOrderManagerFilter() {
    this.selectedOrderManager = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearSalesManagerFilter() {
    this.selectedSalesManager = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearEXWApprovedFilter() {
    this.isEXWApproved = '';
    this.pageNumber = 1;
    this.loadInitialData();
  }

  clearIsStoreOrderFilter() {
    this.isStoreOrder = '';
    this.pageNumber = 1;
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = "";
    this.isQuote = null;
    this.expectedDelivery = {
      startDate: undefined,
      endDate: undefined,
    };
    this.orderPlacement = {
      startDate: undefined,
      endDate: undefined,
    };
    this.selectedFactory = '';
    this.selectedOrderManager = '';
    this.selectedSalesManager = '';
    this.isEXWApproved = '';
    this.isStoreOrder = '';
    this.sortBy = {
      column: undefined,
      direction: undefined
    }
    this.clearOrderStatusFilter();
  }

  get areAllSelected() {
    let unselected = 0;
    this.orderItems.forEach((item) => {
      if (
        !this.selection.includes(item.id) &&
        !this.disableCheckbox(item.orderStatusId)
      ) {
        unselected += 1;
      }
    });

    return unselected === 0 ? true : false;
  }

  handleSelectAll() {
    if (!this.areAllSelected) {
      this.orderItems.forEach((order) => {
        if (!this.selection.includes(order.id)) {
          if (!this.disableCheckbox(order.orderStatusId)) {
            this.selection.push(order.id);
          }
        }
      });
    } else {
      this.orderItems.forEach((order) => {
        this.selection = this.selection.filter((item) => item !== order.id);
      });
    }

    console.log(this.selection);
  }

  handleSelect(id: any) {
    if (this.selection.includes(id)) {
      this.selection = this.selection.filter((item) => item !== id);
    } else {
      this.selection.push(id);
    }

    //TODO handle what happens here when call available from liam
    console.log(this.selection);
  }

  handleName(name: string) {
    if (name) {
      const parsedName = name.toLowerCase().split(" ").join("-");
      return parsedName;
    }
    return "";
  }

  handleStatus(selected: OrderStatus) {
    if(this.status.includes(selected)) {
      this.status = this.status.filter(item => {
        return item !== selected
      })
    } else {
      if(typeof this.status == 'string') {
        const arr = [this.status, selected];
        this.status = arr;
      } else {
        this.status.push(selected);
      }
    }

    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleFactory(selected: string) {
    if(this.selectedFactory !== selected) {
      this.selectedFactory = selected;
    } else {
      this.selectedFactory = ''
    }
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleOrderManager(selected:string) {
    if(this.selectedOrderManager !== selected) {
      this.selectedOrderManager = selected;
    } else {
      this.selectedOrderManager = ''
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleSalesManager(selected:string) {
    if(this.selectedSalesManager !== selected) {
      this.selectedSalesManager = selected;
    } else {
      this.selectedSalesManager = ''
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleEXWApproved(selected: string) {
    if(this.isEXWApproved !== selected) {
      this.isEXWApproved = selected;
    } else {
      this.isEXWApproved = ''
    }
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleIsStoreOrder(selected: string) {
    if(this.isStoreOrder !== selected) {
      this.isStoreOrder = selected;
    } else {
      this.isStoreOrder = ''
    }
    this.pageNumber = 1;
    this.loadInitialData();
  }

  async loadInitialData() {
    await AdminOrders.adminOrdersGet(
      this.pageNumber,
      this.itemPerPage,
      this.calculateOrderTotals === 'true' ? true : false,
      this.includePaymentId === 'true' ? true : false,
      this.status,
      this.search || undefined,
      this.orderPlacement.startDate,
      this.orderPlacement.endDate,
      this.expectedDelivery.startDate,
      this.expectedDelivery.endDate,
      undefined,
      this.selectedFactory || undefined,
      this.isEXWApproved === 'Approved' ? true : this.isEXWApproved === 'Not Approved' ? false : undefined,
      this.sortBy.column,
      this.sortBy.direction,
      this.isQuote || undefined,
      this.isStoreOrder === 'Yes' ? true : this.isStoreOrder === 'No' ? false : undefined, this.selectedOrderManager, this.selectedSalesManager
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.orders = res.data
            .resultData as OrderAdminListViewModelPaginatedList;
          this.orderItems = this.orders.items as Array<OrderAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

    await AdminOrders.adminOrdersGetOrderStatusesGet()
      .then((res) => {
        if (res.data.succeeded) {
          this.orderStatuses = res.data
            .resultData as Array<OrderStatusListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

      await AdminFactories.adminFactoriesGetFactoryNamesGet(1, 99999)
      .then((res) => {
        if (res.data.succeeded) {
          this.factories = res.data
            .resultData?.items as Array<FactoryAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

      await AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.orderManagers = res.data.resultData!
            .items as Array<UserSelectModel>;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

    this.loading = false;
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
