





































import {
  OrderInvoiceAdminCreateModel,
  OrderInvoiceType,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminOrderInvoices } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "NewInvoice",
  components: {
    BasicSelectorField,
    BasicDateField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class extends AppProps {
  pageNumber: string = this.$route.params.pageNumber;
  itemPerPage: string = this.$route.params.itemPerPage;
  search: string = this.$route.params.search;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  popImage: Array<any> = [];
  showBanner: boolean = true;
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newInvoice: OrderInvoiceAdminCreateModel = {
    startDateTime: "",
    endDateTime: "",
    orderInvoiceTypeId: OrderInvoiceType.Invoice,
    orderId: "",
  };
  orderInvoiceTypes = [
    {
      id: OrderInvoiceType.Invoice,
      name: "Invoice",
    },
    {
      id: OrderInvoiceType.Proforma,
      name: "Proforma",
    },
  ];
  loading = false;
  orderItems: Array<any> = [];
  dirtyTimer: any = null;

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.$watch("$route", () => {
      if (this.$route.query.tab === "invoices") {
        this.$emit("showList");
      }
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    this.$emit("list");
  }

  handleConfirm() {
    this.submitForm();
  }

  submitForm() {
    this.loading = true;
    this.newInvoice.orderId = this.order.id

    if(this.newInvoice.startDateTime == '') {
      this.newInvoice.startDateTime = null
    }

    if(this.newInvoice.endDateTime == '') {
      this.newInvoice.endDateTime = null
    }
    
    AdminOrderInvoices.adminOrderInvoicesPost(this.newInvoice as OrderInvoiceAdminCreateModel)
      .then((res) => {
        console.log("success?", res);
        if (res.data.succeeded) {
          this.$message.success("Saved!");
          this.loading = false;
          this.dialogVisible = false;
          this.$emit("list");
        }
        this.loading = false;
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.loading = false;
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
    });
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
