
















import { Component, Vue } from 'vue-property-decorator'
import ArtAssetsList from './ArtAssetsList.vue'
import ArtAssetsCategoryList from './ArtAssetsCategoryList.vue'

@Component({
  name: 'ArtAssets',
  components: { ArtAssetsList, ArtAssetsCategoryList },
})
export default class extends Vue {
  activeTab: any = "assets";

  created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "assets"
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "assets"
    }
    });
  }

  handleTabClick(tab:any) {
    this.activeTab = tab.name; //TODO add correct id below
    this.$router.replace({ name: "ArtAssets", query: { tab: this.activeTab } });
  }
}
