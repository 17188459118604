




























































































































import { Component, Vue } from "vue-property-decorator";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries";
import {
  CampaignAdminListViewModel,
  CampaignAdminListViewModelPaginatedList,
} from "@/api-client";
import { AdminCampaigns } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";

@Component({
  name: "Campaigns",
  components: { Pagination, DialogBox, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  campaigns: CampaignAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  campaignItems: Array<CampaignAdminListViewModel> = [];
  searchName: string = "";
  searchUrl: string = "";
  pageNumber = 1;
  itemPerPage = 10;
  debounce: any = null;
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this campaign?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  selectedItem: CampaignAdminListViewModel = {
    id: "",
    dateTimeStamp: "",
    name: "",
    slug: "",
    startDate: "",
    endDate: "",
    customizedBannerItems: true,
    customizedClients: true,
    enquiriesCount: 0
  };
  loading = false;

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get marketingRoleOnly() {
    const requiredRole = "Marketing & Design";
    const excludedRoles = ["Admin", "Operations", "Sales", "Sales Admin"];

    const hasRequiredRole = this.roles.includes(requiredRole);
    const hasExcludedRoles = excludedRoles.some(role => this.roles.includes(role));

    return hasRequiredRole && !hasExcludedRoles;
  }

  created() {
    let toQuery = {
      searchName: this.searchName,
      searchUrl: this.searchUrl,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
    };
    this.setQueryWatch(toQuery);

    this.loadInitialData();

    // this.$watch("search", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val);
      });
    }
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handleSearch(id: "searchName" | "searchUrl") {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById(id)!.focus();
    });
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    if (this.selectedItem.id) {
      this.loading = true;
      AdminCampaigns.adminCampaignsIdDelete(
        this.selectedItem.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.pageNumber = 1;
            this.loading = false;
            this.$message.info("Item deleted.");
            this.loadInitialData();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;

          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage);
            return e.friendlyMessage;
          });
        });
    }
    this.dialogVisible = false;
    this.selectedItem = {
      id: "",
      dateTimeStamp: "",
      name: "",
      slug: "",
      startDate: "",
      endDate: "",
      customizedBannerItems: true,
      customizedClients: true,
      enquiriesCount: 0
    };
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  clearAllFilters() {
    this.searchName = "";
    this.searchUrl = "";
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  async loadInitialData() {
    this.loading = true;
    await AdminCampaigns.adminCampaignsGet(
      this.pageNumber,
      this.itemPerPage,
      this.searchUrl || undefined,
      this.searchName || undefined
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.campaigns = res.data
            .resultData as CampaignAdminListViewModelPaginatedList;
          this.campaignItems = this.campaigns
            .items as Array<CampaignAdminListViewModel>;
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
