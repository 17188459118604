























































































import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue"

const AppProps = Vue.extend({
  props: {
    extras: { default: null },
    row: { default: null },
    invalidSizing: { default: false },
    product: { type: Object, default: null },
    isOrderPack: { default: false },
  },
});

@Component({
  name: "NamesAndNumbers",
  components: {
    DialogBox
  },
})
export default class extends AppProps {
  groupedExtras: Array<any> = [];
  loading = false;
  selectedExtraInfo: any = null;
  extraInfoDialogVisible = false;

  created() {
    if (this.extras) {
      this.groupedExtras = this.groupBy(this.extras, "row");
    }
  }

  //Source: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  groupBy(data: any, key: any) {
    return data.reduce(function (storage: any, item: any) {
      // get the first instance of the key by which we're grouping
      let group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, []); // [] is the initial value of the storage
  }

  pasteToRow(extra:any, group:any, index:any) {
    let content = ''
    let extraId = extra.extraId
    let row = extra.row
    window.navigator.clipboard.readText()
      .then((cliptext) => {
        if (typeof content === 'string') {
          content = cliptext
          const contentArray = content.split('\n')
          let length = 0

          this.groupedExtras.forEach((group:any, index:number) => {
            group.forEach((item:any) => {
              if((item.row >= row) && (item.extraId == extraId)) {
                if(contentArray[length]) {
                  item.value = contentArray[length]
                  length += 1;
                }
              }
            })
          })
        } else {
          this.$message({showClose: true, type: 'error', duration: 0, message:'Only text can be pasted as a name / number value.'})
        }
      })
      .catch((error) => {
        console.log(error)
      }); 
  }

  limitLengthAndUpdate(extra:any) {
    if(extra.customizationTextMaximumLength) {
      if(extra.value.length > extra.customizationTextMaximumLength) {        
        extra.value = extra.value.substring(0, extra.customizationTextMaximumLength);
      }
    }
    this.$emit("update");
  }

  showExtraDetails(extra:any) {
    this.selectedExtraInfo = this.getExtraDetails(extra.extraId)
    this.extraInfoDialogVisible = true;
  }

  getExtraDetails(id:string) {
    if(this.product) {
      return this.product.customizedProduct.extras.find((productExtra: any) => productExtra.id === id);
    }
    return null;
  }

  goBack() {
    this.$emit("back");
  }

  beforeUnmount() {
    this.groupedExtras = [];
  }
}
