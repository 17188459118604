/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sizing': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<defs><style>.cls-1{fill:none;}</style></defs><rect class="cls-1" width="128" height="128"/><path d="M109.93,36,127.71,21.1c-.21-.25-.33-.42-.46-.56-6-6.75-16.2-7.27-23.46-1.19C90.31,30.66,78.85,40,65.38,51.29l13,10.91C89.54,52.81,98.74,45.42,109.93,36Z"/><path d="M39.81,73.09a2.65,2.65,0,0,1-3,.57c-6-2-11.83-1.39-16.92,2.43A144.89,144.89,0,0,0,6.08,87.69C.51,93.06-1,99.84,1.43,107.17c4.33,12.9,19.83,17,30.44,8.23,3.61-3,7.23-6,10.79-9.06,5.49-4.72,7.84-10.69,6.75-17.89a2.25,2.25,0,0,1,.9-2.42c4.4-3.68,6.79-5.38,11.19-9.07L48.57,66.06C45,69.07,43.39,70.08,39.81,73.09ZM36.58,97.17c-4,3.68-8.18,7.19-12.48,10.52A8.11,8.11,0,0,1,13.75,95.24c3.92-3.54,8-6.85,12.11-10.2a7.09,7.09,0,0,1,4.86-1.64,8,8,0,0,1,7.59,5.06C39.6,91.71,39.14,94.84,36.58,97.17Z"/><path d="M63.38,53.29l-5.56-4.68,0,0c-2.79-2.35-5.4-4.6-8.07-6.78a1.78,1.78,0,0,1-.73-1.91c1.17-6.8-.57-12.74-5.72-17.4-4.22-3.82-8.61-7.46-13.06-11A17.23,17.23,0,0,0,19.32,7.93,19,19,0,0,0,1.71,19.84,18.74,18.74,0,0,0,6.43,40.75c4,3.62,8.17,7.08,12.4,10.44,5.62,4.44,11.91,5.36,18.65,3a1.55,1.55,0,0,1,1.77.35C41.13,56.13,43,57.71,45,59.36h0l5.56,4.7L63.5,75l6,5.08C81,89.71,92.45,99.41,104,109a16.29,16.29,0,0,0,23.16-2.11c-10.49-8.84-33-27.74-50.78-42.72Zm-29.95-9.1a8,8,0,0,1-8.06-1.55c-3.75-3.1-7.49-6.2-11.17-9.38A8.11,8.11,0,0,1,11.73,24,8,8,0,0,1,17,19.12a8.17,8.17,0,0,1,2.5-.35,6.69,6.69,0,0,1,.78,0,6,6,0,0,1,.74.1,7.38,7.38,0,0,1,1.41.41,9.63,9.63,0,0,1,2.53,1.55c3.61,3,7.23,6,10.79,9.06C40.86,34.35,39.64,42,33.43,44.19ZM67.27,64.54A3.29,3.29,0,1,1,64,61.23,3.25,3.25,0,0,1,67.27,64.54Z"/>'
  }
})






