






































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  EnquiryAdminListViewModelPaginatedList,
  EnquiryType,
  EnquiryStatus,
  EnquiryAdminListViewModel,
  CampaignAdminListViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import DateRangeFilter from "@/components/form-items/DateRangeFilter.vue";
import { AdminCampaigns, AdminEnquiries } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import { handleQueries } from "@/utils/handleQueries"
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    orderManagers: {
      type: Array,
      default: () => []
    }
  }
})

@Component({
  name: "EnquiriesSearch",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    DateRangeFilter,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        true: "success",
        false: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  enquiries: EnquiryAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  enquiryItems: Array<EnquiryAdminListViewModel> = [];
  enquiryPlacement = {
    startDate: undefined,
    endDate: undefined,
  };
  dateReceived = {
    startDate: undefined,
    endDate: undefined,
  };
  search: string | undefined =  "";
  enquiryPageNumber =  1;
  enquiryItemPerPage =  5;
  debounce: any = null;
  loading = false;
  selectedEnquiryTypes: Array<EnquiryType> = []
  selectedEnquiryStatuses: Array<EnquiryStatus> = []
  calculateQuoteTotals = 'false';
  enquiryStatuses: Array<{ name: string, id: EnquiryStatus }> = [ 
    { id: EnquiryStatus.New, name: 'New' },
    { id: EnquiryStatus.Read, name: 'Read' },
    { id: EnquiryStatus.AttentionRequired, name: 'Attention Required' },
    { id: EnquiryStatus.Resolved, name: 'Resolved' },
    { id: EnquiryStatus.OnHold, name: 'On Hold' },
    { id: EnquiryStatus.AwaitingPq, name: 'Awaiting PQ' },
    { id: EnquiryStatus.Quoting, name: 'Quoting' },
    { id: EnquiryStatus.AwaitingOrder, name: 'Awaiting Order' },
    { id: EnquiryStatus.Lost, name: 'Lost' },
    { id: EnquiryStatus.LostOrderedViaAnotherEnquiry, name: 'Lost - Ordered via Another Enquiry' }
  ]
  enquiryTypes: Array<{ name: string, id: EnquiryType }> = [
    { id: EnquiryType.NotYetAssigned, name: 'Not Yet Assigned' },
    { id: EnquiryType.Sales, name: 'Sales' },
    { id: EnquiryType.CustomerService, name: 'Customer Service' },
    { id: EnquiryType.Store, name: 'Store' },
    { id: EnquiryType.Other, name: 'Other' }
  ]
  showAllText: Array<string> = [];
  storeId = '';
  enquirySelectedOrderManager: string = '';
  enquirySelectedSalesManager: string = '';
  campaigns: CampaignAdminListViewModel[] = []
  selectedCampaign: string = '';

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get canViewCampaigns() {
    return this.roles.includes('Sales Admin') || this.roles.includes('Marketing & Design') || this.roles.includes('Admin')
  }


  created() {
    if(this.searchTerm) {
      this.search = this.searchTerm;
    }

    let toQuery = {
      enquiryPageNumber: this.enquiryPageNumber,
      enquiryItemPerPage: this.enquiryItemPerPage,
      selectedEnquiryStatuses: this.selectedEnquiryStatuses,
      selectedEnquiryTypes: this.selectedEnquiryTypes,
      calculateQuoteTotals: this.calculateQuoteTotals, 
      ['enquiryPlacement.startDate']: this.enquiryPlacement.startDate,
      ['enquiryPlacement.endDate']: this.enquiryPlacement.endDate,
      storeId: this.storeId,
      ['dateReceived.startDate']: this.dateReceived.startDate,
      ['dateReceived.endDate']: this.dateReceived.endDate,
      enquirySelectedOrderManager: this.enquirySelectedOrderManager,
      enquirySelectedSalesManager: this.enquirySelectedSalesManager,
      selectedCampaign: this.selectedCampaign
    }
    this.setQueryWatch(toQuery)

    this.loadCampaigns()
    this.loadInitialData();

    this.$watch("searchTerm", () => {
      this.search = this.searchTerm;
      this.clearAllFilters()
    });

    // this.$watch("search", () => {
    //   this.enquiryPageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("search")!.focus();
    //     });
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        let object = null as any

        if(field.includes('.')) {
          object = field.split('.')
        }

        if(object?.length == 2) {
          this[object[0]][object[1]] = queries[field];
        } else {
          this[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num)
  }

  handleHideResolved() {
    if(this.selectedEnquiryStatuses.length > 0) {
      if(this.selectedEnquiryStatuses.includes('Resolved')) {
        this.selectedEnquiryStatuses = this.selectedEnquiryStatuses.filter(status => status !== EnquiryStatus.Resolved);
      }
    } else {
      this.selectedEnquiryStatuses = ['New', 'Read', 'AttentionRequired', 'OnHold', 'AwaitingPQ', 'Quoting', 'AwaitingOrder', 'Lost', 'LostOrderedViaAnotherEnquiry'];
    }
    this.enquiryPageNumber = 1;
    this.loadInitialData()
  }

  handleCustType(type:string) {
    if(type == 'GroupOrSocialClub') {
      return 'Group / Social Club'
    }
    if(type == 'TeamOrSportsClub') {
      return 'Team / Sports Club'
    }
    if(type == 'TradeOrWholesale') {
      return 'Trade / Wholesale'
    }
    return type
  }

  handlePageNumberClick(page: any) {
    this.enquiryPageNumber = page;
    this.loadInitialData();
  }

  handledate(date: any) {
    if (date) {
      return formatDate(date);
    }
    return "";
  }

  handleStatusName(id: string, statusType: 'status' | 'type') {
      let match = statusType === 'status' ? this.enquiryStatuses.find((enquiry) => id == enquiry.id) : this.enquiryTypes.find((enquiry) => id == enquiry.id);
      if (match) {
        return match.name;
      }
    return id;
  }

  clearCampaignFilter() {
    this.selectedCampaign = "";
    this.enquiryPageNumber = 1
    this.loadInitialData();
  }

  clearStatusFilter() {
    this.selectedEnquiryStatuses = [];
    //this.$router.replace({ name: "Enquiries" });
    this.enquiryPageNumber = 1;
    this.loadInitialData();
  }

  clearTypeFilter() {
    this.selectedEnquiryTypes = [];
    //this.$router.replace({ name: "Enquiries" });
    this.enquiryPageNumber = 1;
    this.loadInitialData();
  }

  clearOrderManagerFilter() {
    this.enquirySelectedOrderManager = '';
    this.enquiryPageNumber = 1
    this.loadInitialData();
  }

  clearSalesManagerFilter() {
    this.enquirySelectedSalesManager = '';
    this.enquiryPageNumber = 1
    this.loadInitialData();
  }

  clearAllFilters() {
    this.selectedEnquiryTypes = []
    this.enquiryPlacement = {
      startDate: undefined,
      endDate: undefined,
    };
    this.dateReceived = {
      startDate: undefined,
      endDate: undefined,
    };
    this.enquirySelectedOrderManager = '';
    this.enquirySelectedSalesManager = '';
    this.storeId = "";
    this.selectedCampaign = ""
    this.clearStatusFilter();
  }

  handleCampaignFilter(selected:string) {
    if(this.selectedCampaign !== selected) {
      this.selectedCampaign = selected;
    } else {
      this.selectedCampaign = ''
    }
    this.enquiryPageNumber = 1
    this.loadInitialData();
  }

  handleEnquiryStatus(selected: EnquiryStatus) {
    if(!this.selectedEnquiryStatuses.includes(selected)) {
      if(typeof this.selectedEnquiryStatuses == 'string') {
        const arr = [this.selectedEnquiryStatuses, selected];
        this.selectedEnquiryStatuses = arr;
      } else {
        this.selectedEnquiryStatuses.push(selected);
      }
    } else {
      this.selectedEnquiryStatuses = this.selectedEnquiryStatuses.filter((item) => item !== selected);
    }
  this.enquiryPageNumber = 1;
    this.loadInitialData();
  }

  handleEnquiryType(selected: EnquiryType) {
    if(!this.selectedEnquiryTypes.includes(selected)) {
      if(typeof this.selectedEnquiryTypes == 'string') {
        const arr = [this.selectedEnquiryTypes, selected];
        this.selectedEnquiryTypes = arr;
      } else {
        this.selectedEnquiryTypes.push(selected);
      }
    } else {
      this.selectedEnquiryTypes = this.selectedEnquiryTypes.filter((item) => item !== selected);
    }
    this.enquiryPageNumber = 1;
    this.loadInitialData();
  }

  handleOrderManager(selected:string) {
    if(this.enquirySelectedOrderManager !== selected) {
      this.enquirySelectedOrderManager = selected;
    } else {
      this.enquirySelectedOrderManager = ''
    }
    this.enquiryPageNumber = 1
    this.loadInitialData();
  }

  handleSalesManager(selected:string) {
    if(this.enquirySelectedSalesManager !== selected) {
      this.enquirySelectedSalesManager = selected;
    } else {
      this.enquirySelectedSalesManager = ''
    }
    this.enquiryPageNumber = 1
    this.loadInitialData();
  }

  async loadCampaigns() {
    if(this.canViewCampaigns) {
    await AdminCampaigns.adminCampaignsGet(
      1,
      99999,
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.campaigns = res.data
            .resultData?.items as CampaignAdminListViewModel[];
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
    }
  }

  async loadInitialData() {
    this.loading = true;
    await AdminEnquiries.adminEnquiriesGet(this.enquiryPageNumber, this.enquiryItemPerPage, this.calculateQuoteTotals === 'true' ? true : false, this.selectedEnquiryStatuses, this.selectedEnquiryTypes, this.search || undefined, this.enquiryPlacement.startDate, this.enquiryPlacement.endDate, this.storeId || undefined, this.enquirySelectedOrderManager, this.enquirySelectedSalesManager, this.dateReceived.startDate, this.dateReceived.endDate, this.selectedCampaign || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.enquiries = res.data
            .resultData as EnquiryAdminListViewModelPaginatedList;
          this.enquiryItems = this.enquiries.items as Array<EnquiryAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

    this.loading = false;
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
