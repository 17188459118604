

















































































































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import DialogBox from "@/components/DialogBox.vue";
import { formatDate } from "@/utils/formatDate";
import {
  AdminOrdersFactoryIdGetRequest,
  AvailableOrderStatusViewModel,
  DeliveryType,
  OrderFactoryListViewModel,
  OrderStatus,
  OrderStatusListViewModel,
} from "@/api-client";
import { AdminOrdersFactory } from "@/network/api";
import { Message } from 'element-ui';
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import AddressFields from "@/components/orders/billing-delivery/AddressFields.vue";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "EditOrder",
  components: {
    ValidationProvider,
    ValidationObserver,
    DialogBox,
    BasicInputField,
    BasicDateField,
    AddressFields
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  order: OrderFactoryListViewModel = {
      id: "",
      orderStatusId: OrderStatus.Cart,
      referenceNumber: "",
      exWorksDate: "",
      exWorksConfirmedByFactoryDate: "",
      exWorksNotes: "",
      exWorksApproved: false,
    };
  orderClone: OrderFactoryListViewModel = {
      id: "",
      orderStatusId: OrderStatus.Cart,
      referenceNumber: "",
      exWorksDate: "",
      exWorksConfirmedByFactoryDate: "",
      exWorksNotes: "",
      exWorksApproved: false,
    };
  clearDirty: any = null;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  messageInstance: any = Message
  orderStatuses: Array<OrderStatusListViewModel> = [];
  reloadOrderPack = false
  clearReasons = false
  fromPath = ''
  voucherId: string | null = null

  created() {
    this.loadInitialData().then(() => {
      this.clearDirty = setTimeout(() => {
        this.clearDirtyClasses();
      }, 500);
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get readOnly() {
    if(this.orderClone.id) {
      let statuses = ['Cancelled', 'OnHold', 'Lost'] // previously had 'Cart', 'CartValidated', 'PreparingQuote', 'QuoteProvided', 'AmendQuote' as well
      if(statuses.includes(this.order.orderStatusId)) {
        return true
      }
      return false
    }
    return true
  }

  handleDate(date:any) {
    return formatDate(date)
  }
  
  handleStatusName(id: string) {
    if (this.orderStatuses.length) {
      let match = this.orderStatuses.find((order) => id == order.id);
      if (match) {
        return match.name;
      }
    }
    return id;
  }

  updateFactoryOrderFields() {
    this.submitForm(false);
  }

  handleSubmit (saveManager = false, scroll = false) {
    this.messageInstance.closeAll()
    let errorMessages = [] as Array<string>
    
    this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
      if(isValid) {
        this.submitForm(scroll)
      } else {
        for(let error in errors) {
          if(errors[error].length > 0) {
            errorMessages.push(error)
          }
        }
        let finalMessage = `The following fields are required:<br>`
        errorMessages.forEach((message, index:any) => {
          if (index < 5) {
            finalMessage += `<br>- ${message}`
          }
        })
        if(errorMessages.length > 5) {
          let remaining = errorMessages.length - 5
          finalMessage += `<br><br>and ${remaining} others...`
        }
        this.$message({showClose: true, type: 'error', duration: 0, message: finalMessage, dangerouslyUseHTMLString: true});
      }
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    this.confirmText = "Yes";
    if (isDirty.length > 0) {
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(['OrderEnquirySearch', 'Orders','TaskOverview'].includes(this.fromPath)) {
        this.$router.back()
      } else {
        this.$router.push({ name: "Orders"});
      }
    } else {
      this.submitForm();
    }
  }

  async submitForm(scroll = false) {
    let newOrder = {
      id: this.orderClone.id,
      orderStatusId: this.orderClone.orderStatusId,
      referenceNumber: this.orderClone.referenceNumber,
      exWorksDate: this.orderClone.exWorksDate,
      exWorksConfirmedByFactoryDate: this.orderClone.exWorksConfirmedByFactoryDate,
      exWorksNotes: this.orderClone.exWorksNotes,
      exWorksApproved: this.orderClone.exWorksApproved
    } as AdminOrdersFactoryIdGetRequest;
    console.log("order before save", newOrder);

    if (this.orderClone.id) {
      this.loading = true;
      console.log("before save", this.order);
      await AdminOrdersFactory.adminOrdersFactoryIdPut(this.orderClone.id, newOrder)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.loadInitialData().then(() => {
              this.clearDirty = setTimeout(() => {
              this.clearDirtyClasses(), 0})
              this.reloadOrderPack = true
            });
            if(scroll) {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.loading = false;
    this.dialogVisible = false;
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true;
      await AdminOrdersFactory.adminOrdersFactoryIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.order = res.data.resultData as OrderFactoryListViewModel;
            this.orderClone = { ...this.order };
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      await AdminOrdersFactory.adminOrdersFactoryGetOrderStatusesFactoryGet()
        .then((res) => {
          if (res.data.succeeded) {
            this.orderStatuses = res.data
              .resultData as Array<OrderStatusListViewModel>;
          }
        })
        .catch((error) => {
          //this.dialogVisible = false;
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }

    this.loading = false;
  }

  beforeUnmount() {
    this.messageInstance.closeAll()
    clearTimeout(this.clearDirty);
  }
}
