/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'custom-store': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M0,33.09V31.34a1.78,1.78,0,0,0,.21-.44C1.65,22.94,4.35,0,6.89,0,45,0,121.93,0,122.25,0c.79-.09,4,21.64,5.44,29.43a11.13,11.13,0,0,1-.36,6.47c-2,4.94-5.68,7.75-11,8.09a11.6,11.6,0,0,1-11.42-6.2,38,38,0,0,1-2.22-6.12H93.79a12.66,12.66,0,0,1-3.92,8.92,12.16,12.16,0,0,1-9.21,3.46c-4.93-.24-11.24-3.75-12-12.33H59.41A12.66,12.66,0,0,1,48.13,44a11.8,11.8,0,0,1-8.08-2,12.71,12.71,0,0,1-5.73-10.32H25.19a37.24,37.24,0,0,1-.92,3.84A11.86,11.86,0,0,1,13.69,44,11.72,11.72,0,0,1,1.89,38,28.08,28.08,0,0,1,0,33.09Z"/><path d="M121.45,128H6.67V52.37q13.73,3.06,23-7.5a22.14,22.14,0,0,0,34.41,0c4.51,5.27,10.19,8.22,17.19,8.22s12.63-2.9,17.2-8.26A22.36,22.36,0,0,0,108.77,52a21.88,21.88,0,0,0,12.68.3ZM77.2,110.48h30.1V64.62H77.2ZM57.28,64.61H20.84v19H57.28Z"/>'
  }
})

