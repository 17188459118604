/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'currency': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M64,0a64,64,0,1,0,64,64A64,64,0,0,0,64,0ZM94.82,102.87c-5,4.21-10.11,6.26-15.57,6.26a23.29,23.29,0,0,1-6.2-.87c-1.35-.37-2.72-.76-4-1.14-3.23-.92-6.57-1.87-9.87-2.6a24.06,24.06,0,0,0-5.15-.58,18.49,18.49,0,0,0-9.53,2.57,26.15,26.15,0,0,1-2.37,1.18c-.38.17-.78.35-1.19.55l-1.37.65-.87-1.24-5.45-7.79-.88-1.24,1.09-1.06c1-1,1.95-1.87,2.9-2.76A76.79,76.79,0,0,0,42,89.08c5.23-6.07,6.42-12.22,3.62-18.82a3,3,0,0,0-.32-.62,2.76,2.76,0,0,0-.28,0H45c-.72,0-1.5,0-2.44,0H38.74c-1.05,0-1.91,0-2.7,0h-.28a3.74,3.74,0,0,1-2.9-1.07,3.85,3.85,0,0,1-.84-3,23.9,23.9,0,0,0,0-2.42c0-.44,0-.88,0-1.35V60h7.87l0-.11c-.83-1.81-1.62-3.52-2.43-5.22a23.64,23.64,0,0,1-1.85-13.74A25.07,25.07,0,0,1,41.37,28.3C47,22,54.5,18.87,64.42,18.87l1.94,0A38,38,0,0,1,79.3,21.6c7,2.91,11.48,9,13.33,18a42.34,42.34,0,0,1,.7,6.26,4,4,0,0,1-1,2.56,2.29,2.29,0,0,1-1.61.82c-1.87.07-3.72.08-5.13.08H79.81l-.25-1.48c-.14-.78-.25-1.52-.36-2.24-.24-1.49-.45-2.89-.77-4.24-1.2-5.06-4.06-8-8.73-9.11a26.87,26.87,0,0,0-6-.75,14.14,14.14,0,0,0-9.12,3,13,13,0,0,0-3.11,16.16l.17.33c1.35,2.6,2.73,5.27,3.88,8,.35.85.55,1,1.54,1h.14c2.11,0,4.41-.06,7.25-.06h11v9.7H59.87c1.92,9.65-1.66,16.87-6.75,22.7a25.93,25.93,0,0,1,6.35-.79,30.81,30.81,0,0,1,7.35.94c2.58.65,5.19,1.33,7.71,2l2,.54a13.6,13.6,0,0,0,3.35.46,9.75,9.75,0,0,0,5.8-1.88c.45-.33.9-.6,1.42-.91l.81-.49,1.52-.94.94,1.52,3.77,6.09,1,1.63.81,1.31Z"/>'
  }
})








