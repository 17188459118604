




import { Component, Vue } from "vue-property-decorator";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";

const AppProps = Vue.extend({
  props: {
    path: { default: '' },
    width: { default: '100%' },
    height: { default: '100%' },
    isFront: { default: false },
  }
})

@Component({
  name: "PreviewImage",
})
export default class extends AppProps {
    svgData = ''

    async created(){
        let data = await globalAxios
            .get(this.path)
            .then((x: any) => x.data)
        data = data.replace('style="max-width: 680px; width: 100%; height: 100%;"', `style="width: ${this.width}; height: ${this.height};"`)
        data = data.replace('style="cursor: grab;"', '')
        data = data.replace('hidden=""', '')
        data = data.replaceAll('id="', `id="${this.uid}`)
        data = data.replaceAll('url(#', `url(#${this.uid}`)
        
        this.svgData = data;        
    }

}
