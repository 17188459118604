












































































































































































import { Component, Vue } from "vue-property-decorator";
import {  AdminOrderInvoices } from "@/network/api";
import {
  OrderInvoiceAdminListViewModel,
  OrderInvoiceType,
  OrderInvoiceAdminListViewModelPaginatedList,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import { extend, configure } from "vee-validate";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  name: "InvoicesList",

  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    BasicInputField,
    ImageUploader,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Invoice: "",
        Proforma: "info",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  pageNumber = 1;
  itemPerPage = 5;
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this invoice?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  debounce: any = null;
  loading = false;
  selectedItem: OrderInvoiceAdminListViewModel = {
    id: "",
    startDateTime: "",
    endDateTime: "",
    orderInvoiceTypeId: OrderInvoiceType.Invoice,
    referenceNumber: "",
    dateTimeStamp: '',
    voidedDate: '',
    orderReferenceNumber: '',
    invoiceNumber: 1
  };

  invoiceTypeFilter: OrderInvoiceType | undefined = undefined;
  selectedInvoiceType = "";

  orderInvoicesPages: OrderInvoiceAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  orderInvoices: Array<OrderInvoiceAdminListViewModel> = [];

  // get showLastRequested() {
  //   if (!this.order) {
  //     return false;
  //   }
  //   return (this.order.orderStatusId !== 'AwaitingAdditionalPayment' && this.order.orderStatusId !== 'AwaitingResponse') || !this.order.responseRequestedDate
  // }

  get roles() {
    return store.getters["user_new/role"] as any;
  }


  created() {
    if (this.order) {
      this.loadInitialData();
    }

    this.$watch("order", () => {
      this.loadInitialData();
    });

    this.$watch("$route", () => {
      if (this.$route.query.tab === "invoices") {
        this.$emit("showList");
        this.loadInitialData();
      }
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handleDate(date: any) {
    if (date) {
      return formatDate(date);
    }
    return "";
  }

  handleInvoiceTypeFilter(selected: string) {
    if (selected === "Invoice" && this.selectedInvoiceType !== "Invoice") {
      this.invoiceTypeFilter = "Invoice";
      this.selectedInvoiceType = "Invoice";
    } else if (selected === "Proforma" && this.selectedInvoiceType !== "Proforma") {
      this.invoiceTypeFilter = "Proforma";
      this.selectedInvoiceType = "Proforma";
    } else {
      this.invoiceTypeFilter = undefined;
      this.selectedInvoiceType = "";
    }
    this.loadInitialData();
  }

  clearInvoiceTypeFilter() {
    this.selectedInvoiceType = "";
    this.invoiceTypeFilter = undefined;
    this.loadInitialData();
  }

  // copyLinkPath() {
  //   let link = `${process.env.VUE_APP_ROOT_WEB}/order-payments/${this.order.id}`;
  //   navigator.clipboard.writeText(`${link}`);
  //   this.$message.success("Path copied.");
  // }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  editInvoice() {
    this.$emit("edit");
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  confirmDelete(item: any, type: string = 'delete') {
    this.selectedItem = item;

    if(type === 'void') {
      this.dialogMessage = "<span><stong>Are you sure you want to void this invoice?</strong></span>";
    } else {
      this.dialogMessage = "<span><stong>Are you sure you want to delete this invoice?</strong></span>";
    }

    this.dialogVisible = true;
  }

  deleteOrVoidItem() {
    debugger
    if(this.dialogMessage.includes('void')) {
      this.voidItem()
    } else {
      this.deleteItem()
    }
  }

  voidItem() {
    AdminOrderInvoices.adminOrderInvoicesVoidOrderInvoiceIdPut(this.selectedItem.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.pageNumber = 1;
          this.$message.info("Item voided.");
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

    this.loading = false;
    this.dialogVisible = false;
    this.selectedItem = {
      id: "",
      startDateTime: "",
      endDateTime: "",
      orderInvoiceTypeId: OrderInvoiceType.Invoice,
      referenceNumber: "",
      dateTimeStamp: '',
      voidedDate: '',
      orderReferenceNumber: '',
      invoiceNumber: 1
    };
  }

  deleteItem() {
    AdminOrderInvoices.adminOrderInvoicesIdDelete(this.selectedItem.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.pageNumber = 1;
          this.$message.info("Item deleted.");
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

    this.loading = false;
    this.dialogVisible = false;
    this.selectedItem = {
      id: "",
      startDateTime: "",
      endDateTime: "",
      orderInvoiceTypeId: OrderInvoiceType.Invoice,
      referenceNumber: "",
      dateTimeStamp: '',
      voidedDate: '',
      orderReferenceNumber: '',
      invoiceNumber: 1
    };
  }

  async loadInitialData() {
    if (this.order?.id) {
      this.loading = true;

      await AdminOrderInvoices.adminOrderInvoicesGet(
        this.pageNumber,
        this.itemPerPage,
        this.order.id,
        this.invoiceTypeFilter
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.orderInvoicesPages = res.data
              .resultData as OrderInvoiceAdminListViewModelPaginatedList;
            this.orderInvoices = this.orderInvoicesPages.items;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }

    this.loading = false;
  }
}
