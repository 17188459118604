var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.enquiryId)?_c('div',{staticClass:"quotes-container"},[_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":14}},[_c('button',{staticClass:"new-order",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.newQuote({ id: '' })}}},[_vm._v(" New Quote ")])]),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"uniqueMinItem":5,"disabled":_vm.loading,"totalCount":_vm.quotes.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.quoteItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"90","prop":"referenceNumber","label":"Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.referenceNumber))])]}}],null,false,2663857100)}),_c('el-table-column',{attrs:{"min-width":"150","prop":"customerEmail","label":"Customer Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(row.customerEmail))])]}}],null,false,702649248)}),_c('el-table-column',{attrs:{"min-width":"180","prop":"amendmentFromCustomer","label":"Customer Note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.amendmentFromCustomer))])]}}],null,false,1776726375)}),_c('el-table-column',{attrs:{"min-width":"200","prop":"quoteMessage","label":"Message to Customer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.quoteMessage))])]}}],null,false,430279733)}),_c('el-table-column',{attrs:{"min-width":"150","prop":"quoteExpiryDate","label":"Quote Expiry Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.quoteExpiryDate ? _vm.handleDate(row.quoteExpiryDate) : ""))])]}}],null,false,364804090)}),_c('el-table-column',{attrs:{"min-width":"145"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderStatuses),function(orderStatus){return _c('li',{key:orderStatus.id,class:{'selected-filter': _vm.status.includes(orderStatus.id)},on:{"click":function($event){return _vm.handleStatus(orderStatus.id)}}},[_vm._v(_vm._s(orderStatus.name))])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearOrderStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.status.length > 0 },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Order Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.orderStatusId))+" ")])]}}],null,false,1073746234)}),_c('DateRangeFilter',{attrs:{"width":120,"heading":'Order Placed',"name":'orderPlacementDate',"isSelected":_vm.orderPlacement.startDate !== undefined},on:{"updateDate":function (x) {
          (_vm.orderPlacement = x), _vm.loadInitialData();
        }}}),_c('DateRangeFilter',{attrs:{"width":170,"heading":'Target Delivery Date',"name":'expectedDeliveryDate',"isSelected":_vm.expectedDelivery.startDate !== undefined},on:{"updateDate":function (x) {
          (_vm.expectedDelivery = x), _vm.loadInitialData();
        }}}),_c('el-table-column',{attrs:{"width":"160","prop":"convertedRecalculatedOrderTotal","label":"Quoted Order Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(((row.currency.code) + " " + (row.currency.symbol) + (row.convertedRecalculatedOrderTotal.toFixed(2)))))])]}}],null,false,3433403088)}),_c('el-table-column',{attrs:{"min-width":"100","prop":"convertedOrderTotal","label":"Order Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(((row.currency.code) + " " + (row.currency.symbol) + (row.convertedOrderTotal))))])]}}],null,false,1732186167)}),_c('el-table-column',{attrs:{"min-width":"150","prop":"isQuote","label":"Checkout Reminder","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return (row.isQuote && ['Cart', 'CartValidated'].includes(row.orderStatusId))?[_c('el-button',{attrs:{"plain":"","size":"mini","type":"success"},on:{"click":function($event){return _vm.sendQuoteReminder(row.id)}}},[_vm._v("Send")])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"width":"200px","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])]),_c('div',{staticClass:"clear-all-container",on:{"click":function($event){_vm.selection = []}}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Selected")])])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [((row.orderStatusId === 'PreparingQuote') || (row.orderStatusId === 'AmendQuote'))?_c('span',{staticClass:"anchor",on:{"click":function($event){return _vm.linkToQuote(row)}}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"info"}},[_vm._v("Manage")])],1):_vm._e(),((row.orderStatusId === 'QuoteProvided'))?_c('span',{staticClass:"anchor",on:{"click":function($event){return _vm.copyLinkPath(row)}}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"info"}},[_vm._v("Copy Link")])],1):_vm._e(),_c('router-link',{staticClass:"anchor",attrs:{"to":{
            name: 'EditOrder',
            params: {
              slug: _vm.handleName(row.referenceNumber),
              id: row.id
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("View")])],1)]}}],null,false,2742884656)})],1),_c('Pagination',{attrs:{"totalItems":_vm.quotes.totalCount,"totalPages":_vm.quotes.totalPages,"pageSize":_vm.quotes.pageSize,"currentPage":_vm.pageNumber,"disableToTop":true},on:{"change":_vm.handlePageNumberClick}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }