/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard-2': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<rect x="70" y="44" width="58" height="84" rx="4"/><rect x="70" width="58" height="31" rx="4"/><rect y="82" width="58" height="46" rx="4"/><rect width="58" height="69" rx="4"/>'
  }
})



