





































































































































import { Component, Vue } from "vue-property-decorator";
import {
  AdminOrderAuditLogs
} from "@/network/api";
import {
  OrderAuditLogViewModelPaginatedList,
  OrderAuditLogViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import { configure } from "vee-validate";
import { formatDate } from "@/utils/formatDate";

const AppProps = Vue.extend({
  props: {
    orderId: {
      type: String,
      default: "",
    },
    refresh: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  name: "HistoryTab",

  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    BasicInputField,
    ImageUploader,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "danger",
        true: "success",
        null: "primary",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  pageNumber = 1;
  itemPerPage = 5;
  // dialogVisible = false;
  // dialogMessage =
  //   "<span><stong>Are you sure you want to delete this payment?</strong></span>";
  // confirmText = "Yes";
  // cancelVisible = true;
  debounce: any = null;
  loading = false;
  selectedItem: OrderAuditLogViewModel = {
    id: "",
    dateTimeStamp: "",
    orderId: "",
    field: "",
    oldValue: "",
    newValue: "",
    aspNetUserId: "",
    aspNetUserDisplayName: "",
  };
  selectedProviderFilter = "";
  auditLogs: OrderAuditLogViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  fields: Array<string> = [];
  selectedFields: Array<string> = [];

  created() {
    if (this.orderId) {
      this.loadInitialData();
    }

    this.$watch("orderId", () => {
      this.loadInitialData();
    });

    this.$watch("refresh", () => {
      if (!this.refresh && this.orderId) {
        this.loadInitialData();
      }
    });

    this.$watch("$route", () => {
      if (this.$route.query.tab === "history") {
        this.loadInitialData();
      }
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  appendLeadingZeroes(n:any){
      if(n <= 9){
        return "0" + n;
      }
      return n
    }

  handleDate(date: any) {
    let hour = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();
    
    return `${formatDate(date)} - ${this.appendLeadingZeroes(hour)}:${this.appendLeadingZeroes(minutes)}`;
  }

  handleValue(value:any) {
    const validDate = Date.parse(value);

    if (!isNaN(validDate)) {
      return formatDate(value);
    }

    return value
  }

  handleFields(selected: string) {
    if (!this.selectedFields.includes(selected)) {
      this.selectedFields.push(selected);
    } else {
      this.selectedFields = this.selectedFields.filter(
        (item) => item !== selected
      );
    }

    this.loadInitialData();
  }

  clearFieldsFilter() {
    this.selectedFields = [];
    this.loadInitialData();
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  async loadInitialData() {
    if (this.orderId) {
      this.loading = true;

      await AdminOrderAuditLogs.adminOrderAuditLogsGetAuditLogsOrderIdGet(
        this.pageNumber,
        this.itemPerPage,
        this.orderId,
        this.selectedFields
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.auditLogs = res.data
              .resultData as OrderAuditLogViewModelPaginatedList;
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      await AdminOrderAuditLogs.adminOrderAuditLogsGetAuditLogFieldsOrderIdGet(
        this.orderId
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.fields = res.data.resultData as Array<string>;
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.loading = false;
  }
}
