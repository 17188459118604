


















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "DialogBox",
  props: {
    dialogVisible: {default: false},
    cancelVisible: {default: false},
    message: {default: ""},
    title: {default: null},
    confirmText: {default: "Ok"},
    width: { default: "30%" },
  }
})
export default class extends Vue {

}
