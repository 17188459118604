






















import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

const AppProps = Vue.extend({
  props: {
    model: { default: "" },
    name: { default: "" },
    mode: { default: "eager" },
    totalCount: {default: 1},
    uniqueMinItem: {default: 10},
    disabled: {default: false}
  },
})
@Component({
  name: "ItemsPerPage",
  components: { ValidationProvider, ValidationObserver },
})
export default class extends AppProps {
  array: Array<any> = [ "5", "10", "20", "50", "100", "200", "400"]

  created() {
    if(this.uniqueMinItem < 10 && !this.array.includes(this.uniqueMinItem.toString())) {
      this.array.unshift(this.uniqueMinItem)
    }
    this.$watch('model', () => {
      //let value = this.model as any;
      // if (value === 'Maximum') {
      //   value = Number(this.totalCount);
      // }
      this.$emit('reset')
      this.$emit('handleChange', this.model);    
    })
  }
}
