







































































































































































































































































































































































import {
  BlogCategoryAdminListViewModel,
  CampaignAdminViewModel,
  CampaignAdminCreateModel,
  CampaignAdminUpdateModel,
  CampaignBannerItemAdminListViewModelPaginatedList,
  CampaignClientAdminListViewModelPaginatedList,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { formatSafeSlug } from "@/utils/index";
import { editorToolbar } from "@/utils/editorToolbar";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import CampaignBanners from "@/views/site-content/campaigns/CampaignBanners.vue";
import CampaignClientLogos from "@/views/site-content/campaigns/CampaignClientLogos.vue";

const AppProps = Vue.extend({
  props: {
    id: { default: "" },
  },
});

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminCampaigns } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";
import {
  EnquiryStatus,
  EnquiryType
} from "@/api-client";

@Component({
  name: "NewCampaign",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicDateField,
    BasicTooltipHeading,
    CampaignBanners,
    CampaignClientLogos,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  dirtyTimer: any = null;
  dirtyMavonTimer: any = null;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  headerImage: Array<any> = [];
  authorImage: Array<any> = [];
  showBanner: boolean = true;
  activeTab: "details" | "enquiries" = "details";
  campaign: CampaignAdminViewModel = {
    id: "",
    dateTimeStamp: "",
    name: "",
    slug: "",
    startDate: "",
    endDate: "",
    customizedBannerItems: true,
    customizedClients: true,
    customerBannerMessage: "",
    quoteTitle: "",
    bannerImageUrl: "",
    enquiries: [],
  };
  campaignBanners: CampaignBannerItemAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  bannerPageNumber = 1;
  bannerPageSize = 5;
  campaignClients: CampaignClientAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  clientPageNumber = 1;
  clientPageSize = 5;
  selectedDates: string[] = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  categories: Array<BlogCategoryAdminListViewModel> = [];
  newCampaign: CampaignAdminCreateModel | CampaignAdminUpdateModel = {
    name: "",
    slug: "",
    startDate: "",
    endDate: "",
    customerBannerMessage: "",
    quoteTitle: "",
    bannerImageUrl: "",
  };
  metaKeywordsArray: Array<string> = [];
  tagInputVisible: boolean = false;
  tagInputValue: string = "";
  mavenToolbar = editorToolbar;
  loading = false;
  calculateQuoteTotals = 'false';
  enquiryStatuses: Array<{ name: string, id: EnquiryStatus }> = [ 
    { id: EnquiryStatus.New, name: 'New' },
    { id: EnquiryStatus.Read, name: 'Read' },
    { id: EnquiryStatus.AttentionRequired, name: 'Attention Required' },
    { id: EnquiryStatus.Resolved, name: 'Resolved' },
    { id: EnquiryStatus.OnHold, name: 'On Hold' },
    { id: EnquiryStatus.AwaitingPq, name: 'Awaiting PQ' },
    { id: EnquiryStatus.Quoting, name: 'Quoting' },
    { id: EnquiryStatus.AwaitingOrder, name: 'Awaiting Order' },
    { id: EnquiryStatus.Lost, name: 'Lost' },
    { id: EnquiryStatus.LostOrderedViaAnotherEnquiry, name: 'Lost - Ordered via Another Enquiry' }
  ]
  enquiryTypes: Array<{ name: string, id: EnquiryType }> = [
    { id: EnquiryType.NotYetAssigned, name: 'Not Yet Assigned' },
    { id: EnquiryType.Sales, name: 'Sales' },
    { id: EnquiryType.CustomerService, name: 'Customer Service' },
    { id: EnquiryType.Store, name: 'Store' },
    { id: EnquiryType.Other, name: 'Other' }
  ]
  showAllText: Array<string> = []
  selectedEnquiryStatuses: Array<EnquiryStatus> = []
  fromPath = "";
  pickerOptions = {
    firstDayOfWeek: 1,
    shortcuts: [
      {
        text: "Last week",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last month",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 3 months",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 6 months",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 120);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last year",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
          picker.$emit("pick", [start, end]);
        },
      },
    ],
  };

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get marketingRoleOnly() {
    const requiredRole = "Marketing & Design";
    const excludedRoles = ["Admin", "Operations", "Sales", "Sales Admin"];

    const hasRequiredRole = this.roles.includes(requiredRole);
    const hasExcludedRoles = excludedRoles.some(role => this.roles.includes(role));

    return hasRequiredRole && !hasExcludedRoles;
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab as "details" | "enquiries";
    } else {
      this.activeTab = "details";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab as "details" | "enquiries";
      } else {
        this.activeTab = "details";
      }
    });

    this.loadInitialData().then((res) => {
      clearTimeout(this.dirtyMavonTimer);
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses();
      }, 1000);
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("dates", {
      validate(value) {
        console.log(value);
        return {
          valid: value?.[0] && value?.[1],
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    let self = this;
    extend("banner", {
      validate(value) {
        let uploader = self.$refs.bannerUploader as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num)
  }

  validateField(field: any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if (provider) {
      return provider!.validate();
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll(".dirty");

    if (mavonDirty.length) {
      for (let item of mavonDirty) {
        item.classList.remove("dirty");
      }
    }
  }

  clearStatusFilter() {
    this.selectedEnquiryStatuses = [];
    this.loadInitialData();
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    this.$router.replace({
      name: "EditCampaign",
      params: { id: this.id },
      query: { tab: this.activeTab },
    });
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  handleDateRanges(value: any) {
    let dates = {
      startDate: "" as string,
      endDate: "" as string,
    };
    if (value) {
      let d1 = new Date(value[0]);
      let d2 = new Date(value[1]);

      //if(d1.getTime() == d2.getTime()) {
      d2.setDate(d2.getDate() + 1);
      //}

      dates = {
        startDate: new Date(d1).toISOString(),
        endDate: new Date(d2).toISOString(),
      };
    }
    this.newCampaign.startDate = dates.startDate;
    this.newCampaign.endDate = dates.endDate;

    this.selectedDates = [this.newCampaign.startDate, this.newCampaign.endDate];
  }

  handleStatusName(id: string, statusType: 'status' | 'type') {
      let match = statusType === 'status' ? this.enquiryStatuses.find((enquiry) => id == enquiry.id) : this.enquiryTypes.find((enquiry) => id == enquiry.id);
      if (match) {
        return match.name;
      }
    return id;
  }

  copyLinkPath(row: any) {
    let link = `${process.env.VUE_APP_ROOT_WEB}/pages/${this.newCampaign.slug}`;
    navigator.clipboard.writeText(`${link}`);
    this.$message.success("Path copied.");
  }

  uploadImages(): Promise<any> {
    let bannerUploader = this.$refs.bannerUploader as ImageUploader;

    let bannerPromise;

    if (bannerUploader) {
      bannerPromise = bannerUploader!.uploadImage();
    }

    return Promise.all([bannerPromise]);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "banner") {
      this.newCampaign.bannerImageUrl = "";
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if (this.fromPath === "Campaigns") {
        this.$router.back();
      } else {
        this.$router.push({ name: "Campaigns" });
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newCampaign.bannerImageUrl = results[0].imageUrl;
      }

      if (!this.newCampaign.slug) {
        this.newCampaign.slug = formatSafeSlug(this.newCampaign.name);
      } else {
        this.newCampaign.slug = formatSafeSlug(this.newCampaign.slug);
      }

      if (this.id) {
        console.log("before save", this.newCampaign);

        AdminCampaigns.adminCampaignsIdPut(
          this.id,
          this.newCampaign as CampaignAdminUpdateModel
        )
          .then((res) => {
            if (res.data.succeeded) {
              console.log("success?", res);
              this.dialogVisible = false;
              this.$message.success("Saved!");
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage);
              return e.friendlyMessage;
            });
          });
      } else {
        AdminCampaigns.adminCampaignsPost(
          this.newCampaign as CampaignAdminCreateModel
        )
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.dialogVisible = false;
              this.$message.success("Saved!");
              if (res.data.resultData) {
                this.id = res.data.resultData!.id;
                this.$router.push({
                  name: "EditCampaign",
                  params: { id: this.id },
                });
                this.loadInitialData()
              }
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage);
              return e.friendlyMessage;
            });
          });
      }
    });
  }

  loadCampaignBanners() {
    AdminCampaigns.adminCampaignsBannerItemsGet(
      this.bannerPageNumber,
      this.bannerPageSize,
      this.id
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.campaignBanners = res.data
            .resultData as CampaignBannerItemAdminListViewModelPaginatedList;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage);
          return e.friendlyMessage;
        });
      });
  }

  loadCampaignClients() {
    AdminCampaigns.adminCampaignsClientsGet(
      this.clientPageNumber,
      this.clientPageSize,
      this.id
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.campaignClients = res.data
            .resultData as CampaignClientAdminListViewModelPaginatedList;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage);
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData(): Promise<any> {
    this.loading = true;
    if (this.id) {
      await AdminCampaigns.adminCampaignsIdGet(this.id, this.calculateQuoteTotals === 'true' ? true : false)
        .then((res) => {
          this.campaign = res.data.resultData as CampaignAdminViewModel;
          this.name = this.campaign.name;
          this.newCampaign = (({ id, ...rest }) => rest)(this.campaign) as any;

          if (this.newCampaign.startDate && this.newCampaign.endDate) {
            this.selectedDates = [
              this.newCampaign.startDate,
              this.newCampaign.endDate,
            ];
          }

          this.loadCampaignBanners();
          this.loadCampaignClients();

          console.log("new campaign", this.newCampaign);
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage);
            return e.friendlyMessage;
          });
        });
    }
    this.loading = false;
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
    clearTimeout(this.dirtyMavonTimer);
  }
}
