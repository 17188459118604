








































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminStores } from "@/network/api";
import {
  StoreAdminListViewModel,
  StoreAdminListViewModelPaginatedList,
  StoreListViewModelPaginatedList,
  StoreStatus,
  StoreStatusViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { formatDate } from "@/utils/formatDate"
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    orderManagers: {
      type: Array,
      default: () => []
    }
  }
})

@Component({
  name: "StoresSearch",
  components: {
    ElTableDraggable,
    Pagination,
    ItemsPerPage,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  customStores: StoreListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  customStoreItems: Array<StoreAdminListViewModel> = [];
  pageNumber = 1;
  itemPerPage = 5;
  search: string | undefined = "";
  statusFilter: string = "";
  onlyActiveStores: boolean | null = null
  loading = false;
  debounce: any = null;
  storeStatuses: Array<StoreStatusViewModel> = [];
  selectedOrderManager: string = '';
  selectedSalesManager: string = '';
  hasCheckedOut: boolean | undefined = undefined;

  created() {
    if(this.searchTerm) {
      this.search = this.searchTerm;
    }
    if (this.$route.query.hasCheckedOut) {
      this.hasCheckedOut = this.$route.query.hasCheckedOut == 'true' ? true : undefined;
    }

    let toQuery = {
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      statusFilter: this.statusFilter,
      onlyActiveStores: this.onlyActiveStores,
      selectedOrderManager: this.selectedOrderManager,
      selectedSalesManager: this.selectedSalesManager
    };
    this.setQueryWatch(toQuery);

    this.loadInitialData();

    this.$watch("searchTerm", () => {
      this.search = this.searchTerm;
      this.clearAllFilters()
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val);
      });
    }
  }

  handleDate(date:any) {
    return formatDate(date)
  }


  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  clearstatusFilter() {
    this.pageNumber = 1;
    this.statusFilter = "";
    this.loadInitialData();
  }

  clearOrderManagerFilter() {
    this.selectedOrderManager = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearSalesManagerFilter() {
    this.selectedSalesManager = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearAllFilters() {
    this.onlyActiveStores = null;
    // this.search = "";
    this.selectedOrderManager = '';
    this.selectedSalesManager = '';
    this.clearstatusFilter();
  }

  handleStatus(selected: string) {
    if(this.statusFilter !== selected) {
      this.statusFilter = selected;
    } else {
      this.statusFilter = ''
    }
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleOrderManager(selected:string) {
    if(this.selectedOrderManager !== selected) {
      this.selectedOrderManager = selected;
    } else {
      this.selectedOrderManager = ''
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleSalesManager(selected:string) {
    if(this.selectedSalesManager !== selected) {
      this.selectedSalesManager = selected;
    } else {
      this.selectedSalesManager = ''
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  toggleCheckedOutFilter(flag:string) {
    if(flag == 'true') {
      this.hasCheckedOut = true;
    } else {
      this.hasCheckedOut = undefined;
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  async loadStoreStatuses() {
    await AdminStores.adminStoresGetStoreStatusesGet()
      .then((res) => {
        if (res.data.succeeded) {
          this.storeStatuses = res.data.resultData as Array<StoreStatusViewModel>;
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true;
    await AdminStores.adminStoresGet(
      this.pageNumber,
      this.itemPerPage,
      undefined,
      (this.statusFilter as StoreStatus) || undefined,
      this.search || undefined,
      this.onlyActiveStores || undefined,
      this.selectedOrderManager,
      this.selectedSalesManager,
      this.hasCheckedOut
    )
      .then(async (res) => {
        if (res.data.succeeded) {
          this.customStores = res.data
            .resultData as StoreAdminListViewModelPaginatedList;
          this.customStoreItems = this.customStores.items as Array<StoreAdminListViewModel>;         
        }
        
        await this.loadStoreStatuses()

        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
