












































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  OrderAdminListViewModel,
  OrderStatus,
  OrderAdminListViewModelPaginatedList,
  OrderStatusListViewModel,
  FactoryAdminListViewModel,
  SortDirection,
  OrderSortColumn,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import DateRangeFilter from "@/components/form-items/DateRangeFilter.vue";
import { AdminFactories, AdminOrders, AdminUsers } from "@/network/api";
import { formatDate } from "@/utils/formatDate"
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    searchTerm: {
      type: String,
      default: '',
    },
    orderManagers: {
      type: Array,
      default: () => []
    }
  }
})

interface SortByType {
  column: OrderSortColumn | undefined;
  direction: SortDirection | undefined;
}

@Component({
  name: "OrdersSearch",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    DateRangeFilter,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Cart: "brand",
        Received: "success",
        AwaitingPayment: "danger",
      };
      return statusMap[status];
    },
    noFailFilter: (nofail: string) => {
      const noFailMap: { [key: string]: string } = {
        true: "danger"
      };
      return noFailMap[nofail];
    },
    truthyFilter: (exw: string) => {
      const exWorksMap: { [key: string]: string } = {
        false: "danger",
        true: 'success',
        null: ''
      };
      return exWorksMap[exw];
    },
    paymentFilter: (payment: number) => {
      const paymentMap: { [key: string]: string } = {
        even: "success",
        over: "danger",
        under: "warning",
      };
      let paymentStatus = "";
      if (payment > 0) {
        paymentStatus = "over";
      } else if (payment < 0) {
        paymentStatus = "under";
      } else {
        paymentStatus = "even";
      }
      return paymentMap[paymentStatus];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  orders: OrderAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  orderItems: Array<OrderAdminListViewModel> = [];
  search: string | undefined = "";
  ordersPageNumber = 1;
  ordersItemPerPage = 5;
  selectedItem: OrderAdminListViewModel = {
    id: "",
    referenceNumber: "",
    orderStatusId: OrderStatus.Cart,
    customerEmail: "",
    orderPlacementDate: "",
    updatedDeliveryDate: "",
    expectedDeliveryDate: "",
    convertedOrderTotal: 0,
    factories: [],
    exWorksApproved: false,
    exWorksDate: "",
    groupedUK: false,
    groupedUSA: false,
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 1,
      decimalPlaces: 2,
    },
    convertedPaymentOutstanding: 0,
    isQuote: false,
    isStoreOrder: false,
    marketingOptIn: false,
    storeId: null,
    storeName: null,
    disableAutoSendFactoryEmails: false
  };
  debounce: any = null;
  status: Array<OrderStatus> = [];
  selectedFactory: string = '';
  ordersSelectedOrderManager: string = '';
  ordersSelectedSalesManager: string = '';
  expectedDelivery = {
    startDate: undefined,
    endDate: undefined,
  };
  orderPlacement = {
    startDate: undefined,
    endDate: undefined,
  };
  showAllText: Array<string> = [];
  loading = false;
  selection: Array<any> = [];
  orderStatuses: Array<OrderStatusListViewModel> = [];
  factories: Array<FactoryAdminListViewModel> = [];
  successCount = 0;
  failCount = 0;
  isEXWApproved = ''
  isStoreOrder = ''
  sortBy: SortByType = {
    column: undefined,
    direction: undefined
  }
  exwColumn: OrderSortColumn = OrderSortColumn.ExwDate
  isQuote: boolean | null = null
  calculateOrderTotals = 'false';
  includePaymentId = 'false';

  created() {
    if (this.$route.query.isQuote) {
      this.isQuote = this.$route.query.isQuote == 'true' ? true : null;
    }

    if(this.searchTerm) {
      this.search = this.searchTerm;
    }

    let toQuery = {
      ordersPageNumber: this.ordersPageNumber,
      ordersItemPerPage: this.ordersItemPerPage,
      status: this.status,
      selectedFactory: this.selectedFactory,
      ['orderPlacement.startDate']: this.orderPlacement.startDate,
      ['orderPlacement.endDate']: this.orderPlacement.endDate,
      ['expectedDelivery.startDate']: this.expectedDelivery.startDate,
      ['expectedDelivery.endDate']: this.expectedDelivery.endDate,
      isEXWApproved: this.isEXWApproved,
      isStoreOrder: this.isStoreOrder,
      ['sortBy.column']: this.sortBy.column,
      ['sortBy.direction']: this.sortBy.direction,
      isQuote: this.isQuote,
      calculateOrderTotals: this.calculateOrderTotals,
      includePaymentId: this.includePaymentId,
      ordersSelectedOrderManager: this.ordersSelectedOrderManager,
      ordersSelectedSalesManager: this.ordersSelectedSalesManager
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    this.$watch("searchTerm", () => {
      this.search = this.searchTerm;
      this.clearAllFilters()
    });
  }

  sendQuoteReminder(id: string) {
    AdminOrders.adminOrdersSendQuoteReminderOrderIdPost(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message({showClose: true, type: 'success', message: 'Checkout reminder sent!'})
      }
    })
    .catch((error) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: 'Error sending checkout reminder.'})
        error.response.data.errors.map((e: any) => {
        this.$message.error(e.friendlyMessage);
        return e.friendlyMessage;
      });
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        let object = null as any

        if(field.includes('.')) {
          object = field.split('.')
        }

        if(object?.length == 2) {
          this[object[0]][object[1]] = queries[field];
        } else {
          this[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSortBy() {
    const sortOptions = {
      [SortDirection.Ascending]: SortDirection.Descending,
      [SortDirection.Descending]: undefined
    }

    if(this.sortBy.column == undefined) {
        this.sortBy = {
          column: OrderSortColumn.ExwDate,
          direction: SortDirection.Ascending
        }
    } else {
      this.sortBy.direction = sortOptions[this.sortBy.direction as SortDirection] as any;

      if(this.sortBy.direction == undefined) {
        this.sortBy.column=  undefined;
      }
    }
    
    this.ordersPageNumber = 1
    this.loadInitialData()
  }

  sortEXWDate() {
    if(this.sortBy.direction == SortDirection.Ascending) {
      return 'el-icon-sort-up'
    } else if(this.sortBy.direction == SortDirection.Descending) {
      return 'el-icon-sort-down'
    }
    return 'el-icon-sort'
  }

  handleordersPageNumberClick(page: any) {
    this.ordersPageNumber = page;
    this.loadInitialData();
  }

  handleStatusName(id: string) {
    if (this.orderStatuses.length) {
      let match = this.orderStatuses.find((order) => id == order.id);
      if (match) {
        return match.name;
      }
    }
    return id;
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handleFactories(factories:Array<string>) {
    let removeDuplicates:Array<string> = []
    factories.forEach((factory) => {
      if (!removeDuplicates.includes(factory)) {
          removeDuplicates.push(factory)
        }
      }
    )

    return removeDuplicates.join(', ')
  }

  clearOrderStatusFilter() {
    this.status = [];
    // this.$router.replace({ name: "Orders" });
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  clearFactoryFilter() {
    this.selectedFactory = '';
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  clearOrderManagerFilter() {
    this.ordersSelectedOrderManager = '';
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  clearSalesManagerFilter() {
    this.ordersSelectedSalesManager = '';
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  clearEXWApprovedFilter() {
    this.isEXWApproved = '';
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  clearIsStoreOrderFilter() {
    this.isStoreOrder = '';
    this.ordersPageNumber = 1;
    this.loadInitialData();
  }

  clearAllFilters() {
    // this.search = "";
    this.isQuote = null;
    this.expectedDelivery = {
      startDate: undefined,
      endDate: undefined,
    };
    this.orderPlacement = {
      startDate: undefined,
      endDate: undefined,
    };
    this.selectedFactory = '';
    this.ordersSelectedOrderManager = '';
    this.ordersSelectedSalesManager = '';
    this.isEXWApproved = '';
    this.isStoreOrder = '';
    this.sortBy = {
      column: undefined,
      direction: undefined
    }
    this.clearOrderStatusFilter();
  }

  handleName(name: string) {
    if (name) {
      const parsedName = name.toLowerCase().split(" ").join("-");
      return parsedName;
    }
    return "";
  }

  handleStatus(selected: OrderStatus) {
    if(this.status.includes(selected)) {  
      this.status = this.status.filter(item => {
        return item !== selected
      })
    } else {
      if(typeof this.status == 'string') {
        const arr = [this.status, selected];
        this.status = arr;
      } else {
        this.status.push(selected);
      }
    }
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  handleFactory(selected: string) {
    if(this.selectedFactory !== selected) {
      this.selectedFactory = selected;
    } else {
      this.selectedFactory = ''
    }
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  handleOrderManager(selected:string) {
    if(this.ordersSelectedOrderManager !== selected) {
      this.ordersSelectedOrderManager = selected;
    } else {
      this.ordersSelectedOrderManager = ''
    }
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  handleSalesManager(selected:string) {
    if(this.ordersSelectedSalesManager !== selected) {
      this.ordersSelectedSalesManager = selected;
    } else {
      this.ordersSelectedSalesManager = ''
    }
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  handleEXWApproved(selected: string) {
    if(this.isEXWApproved !== selected) {
      this.isEXWApproved = selected;
    } else {
      this.isEXWApproved = ''
    }
    this.ordersPageNumber = 1
    this.loadInitialData();
  }

  handleIsStoreOrder(selected: string) {
    if(this.isStoreOrder !== selected) {
      this.isStoreOrder = selected;
    } else {
      this.isStoreOrder = ''
    }
    this.ordersPageNumber = 1;
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;
    await AdminOrders.adminOrdersGet(
      this.ordersPageNumber,
      this.ordersItemPerPage,
      this.calculateOrderTotals === 'true' ? true : false,
      this.includePaymentId === 'true' ? true : false,
      this.status,
      this.search || undefined,
      this.orderPlacement.startDate,
      this.orderPlacement.endDate,
      this.expectedDelivery.startDate,
      this.expectedDelivery.endDate,
      undefined,
      this.selectedFactory || undefined,
      this.isEXWApproved === 'Approved' ? true : this.isEXWApproved === 'Not Approved' ? false : undefined,
      this.sortBy.column,
      this.sortBy.direction,
      this.isQuote || undefined,
      this.isStoreOrder === 'Yes' ? true : this.isStoreOrder === 'No' ? false : undefined, this.ordersSelectedOrderManager, this.ordersSelectedSalesManager
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.orders = res.data
            .resultData as OrderAdminListViewModelPaginatedList;
          this.orderItems = this.orders.items as Array<OrderAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

    await AdminOrders.adminOrdersGetOrderStatusesGet()
      .then((res) => {
        if (res.data.succeeded) {
          this.orderStatuses = res.data
            .resultData as Array<OrderStatusListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

      await AdminFactories.adminFactoriesGetFactoryNamesGet(1, 99999)
      .then((res) => {
        if (res.data.succeeded) {
          this.factories = res.data
            .resultData?.items as Array<FactoryAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

    this.loading = false;
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
