

















































































































































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import DialogBox from "@/components/DialogBox.vue"
import { PriceAdminCreateModel, PriceAdminUpdateModel } from "@/api-client";

const AppProps = Vue.extend({
  props: {
      id: {
        type: String,
        default: "",
      },
      existingPriceRanges: {
        default: null
      }
    },
})

@Component({
  name: "PriceRange",
  components: { ValidationProvider, ValidationObserver, BasicInputNumberField, DialogBox }
})
export default class extends AppProps {
  selectedId = '';
  originalMin = null;
  originalMax = null;
  originalPrice = 0;
  toggleOptionValue = false;
  confirmText: string = "Yes";
  cancelVisible: boolean = true;
  dialogVisible: boolean = false;
  dialogMessage: string = "<span><stong>Are you sure you want to delete this price range?</strong></span>";
  disabled: boolean = false;
  newPriceRange: PriceAdminCreateModel | PriceAdminUpdateModel = {
    minimumQuantity: null,
    maximumQuantity: null,
    price: 0,
    linkedEntityId: this.id
  }
  priceRangeItems = [
    {
      id: '',
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: this.id
    },
  ];

  created() {
    this.$watch('existingPriceRanges', () => {
      if(this.existingPriceRanges) {
        this.priceRangeItems = [...this.existingPriceRanges]
      }
    })
  }

  handleChange(e:any) {
    if (e < 0) {
      e = null
    }
  }

  handleConfirm() {
    if(this.confirmText === 'Yes') {
      this.deleteRow();
    }
  }

  editField(data: any) {
    if ((this.selectedId === data.id)) {
      this.savePriceRange(data);
    } else {      
      this.originalMin = data.minimumQuantity
      this.originalMax = data.maximumQuantity
      this.selectedId = data.id
      this.originalPrice = data.price
    }
  }

  savePriceRange(data: any) {
      let regex = /^(temp).*/g;
      if (data.id.match(regex)) {
        this.newPriceRange = {
          minimumQuantity: data.minimumQuantity,
          maximumQuantity: data.maximumQuantity,
          price: data.price,
          linkedEntityId: this.id
        } as PriceAdminCreateModel;

        this.$emit('post', this.newPriceRange)
      } else {
        this.newPriceRange = {
          id: data.id,
          minimumQuantity: data.minimumQuantity,
          maximumQuantity: data.maximumQuantity,
          price: data.price,
          linkedEntityId: this.id
        } as PriceAdminUpdateModel;
        this.$emit('put', this.newPriceRange)
      }
      this.selectedId = "";
  }

  addRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyRange = 0;

    if(this.priceRangeItems.length)
      this.priceRangeItems.map(item => {
        if(!item.minimumQuantity && !item.maximumQuantity && item.price === 0) {
          emptyRange += 1;
        }
      })

      if(emptyRange === 0) {
        this.priceRangeItems.push({
          id: tempId,
          minimumQuantity: null,
          maximumQuantity: null,
          price: 0,
          linkedEntityId: this.id
      });
      console.log('items', this.priceRangeItems);     
      this.originalMin = null;
      this.originalMax = null;
      this.originalPrice = 0;
      this.selectedId = tempId;
    }
  }

  confirmDelete(data: any) {
    let regex = /^(temp).*/g;
    if ((this.selectedId === data.id) && !this.selectedId.match(regex)) {
      data.minimumQuantity = this.originalMin;
      data.maximumQuantity = this.originalMax;
      data.price = this.originalPrice;
      this.selectedId = "";
    } else {
      console.log('in delete', data);
      
      this.selectedId = data.id;
      this.dialogVisible = true;
    }
  }

  deleteRow() {
    let regex = /^(temp).*/g;
    let index = this.priceRangeItems.length -1;
    if (this.selectedId.match(regex)) {
      this.priceRangeItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.priceRangeItems.splice(index, 1);
    } else {
      this.$emit('delete', this.selectedId)
    }
    this.selectedId = "";
    this.originalMin = null;
    this.originalMax = null;
    this.originalPrice = 0;
    this.dialogVisible = false;
  }
}
