




















































































































import { Component, Vue } from "vue-property-decorator";
import { AdminCategories } from "@/network/api";
import { CategoryAdminListViewModel } from "@/api-client";
import NestedElTableDraggable from "@/components/NestedElTableDraggable.vue";
import DialogBox from "@/components/DialogBox.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: "Categories",
  components: { NestedElTableDraggable, DialogBox },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  searchVal = ''
  selectedId: string = "";
  categories: Array<CategoryAdminListViewModel> = [];
  search: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string =
    "<span><stong>Are you sure you want to delete this category?</strong></span>";
  confirmText: string = "Yes";
  cancelVisible: boolean = true;
  refresh = 0;
  loading = false;

  created() {
    if(this.$route.query.search) {
      this.searchVal = this.$route.query.search as string
    }

    let toQuery = {
      search: this.search,
    }
    this.setQueryWatch(toQuery)
    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch(e:any) {
    if(e) {
      this.searchVal = e.target.value
      this.search = e.target.value
    } else {
      this.searchVal = ''
      this.search = ''
    }
  }

  clearAllFilters() {
    this.search = '';
    this.searchVal = ''
    this.loadInitialData()
  }

  handleName(name: string) {
    let parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  get sortBySearchedCategories() {
    let matchingParents: Array<any> = [];

    const recursive = (array: Array<any>, mainParent: any) => {
      array.map((parent) => {
        if (this.search &&
          (parent.name.toLowerCase().includes(this.search.toLowerCase()) &&
          !matchingParents.includes(mainParent.name.toLowerCase()))
        ) {
          matchingParents.push(mainParent.name.toLowerCase());
        }

        if (parent.childCategories.length > 0) {
          parent.childCategories.map((tag: any) => {
            if (this.search && 
              (tag.name.toLowerCase().includes(this.search.toLowerCase()) &&
              !matchingParents.includes(mainParent.name.toLowerCase()))
            ) {
              matchingParents.push(mainParent.name.toLowerCase());
            }
            if (tag.childCategories.length > 0) {
              recursive(tag.childCategories, mainParent);
            }
          });
        }
      });
    };

    this.categories.map((item) => {
      if (item.childCategories.length > 0) {
        recursive(this.categories, item);
      }
    });

    return this.categories.filter(
      (data) =>
        !this.search ||
        data.name!.toLowerCase().includes(this.search.toLowerCase()) ||
        matchingParents.includes(data.name.toLowerCase())
    );
  }

  handleOpenRows(level: any) {
    let rows = document.querySelectorAll(`.el-table__row--level-${level + 1}`);

    if (rows.length > 1) {
      rows.forEach((element: any) => {
        if (element.style.display !== "none") {
          element.style.display = "none";
        }
      });
      this.handleOpenRows(level + 1);
    }
  }

  onMove(evt: any, originEvt: any, { dragged, related }: any) {
    // if we want to limit what levels can be dragged we can do it here

    // checking dragged.children.length == 0 means we are on a leaf node on the 🌳

    // if (dragged.level !== 2) {
    //     return false;
    // }

    this.handleOpenRows(dragged.level);
  

    if (
      dragged.parent === related.parent &&
      dragged.id !== related.id
    ) {

      console.log("dragged", dragged);
      console.log("related", related);

      return true;
    } 

    
    return false;
  }

  handleReorder(data: any) {
    this.loading = true
    AdminCategories.adminCategoriesIdPatch(
      data.itemId,
      data.previousItemId,
      data.nextItemId
    )
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.loadInitialData();
          //this.refresh += 1
        }
          this.loading = false
      })
      .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  async loadInitialData() {
    this.loading = true
    await AdminCategories.adminCategoriesGet()
      .then((res) => {
        if(res.data.succeeded) {
          this.categories = res.data
          .resultData as Array<CategoryAdminListViewModel>;
        }
          this.loading = false
      })
      .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  confirmDelete(id: string) {
    this.dialogVisible = true;
    this.selectedId = id;
  }

  deleteCategory() {
    if (this.selectedId) {
      this.loading = true
      AdminCategories.adminCategoriesIdDelete(this.selectedId)
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.info("Item deleted.");
          this.loadInitialData();
          }
            this.loading = false
        })
        .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          
        });
    }
    this.dialogVisible = false;
    this.selectedId = "";
  }

  // beforeUnmount() {
  //   clearTimeout(this.debounce);
  // }
}
