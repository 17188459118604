import { render, staticRenderFns } from "./OrderPackApproval.vue?vue&type=template&id=3bb0950a&scoped=true&"
import script from "./OrderPackApproval.vue?vue&type=script&lang=ts&"
export * from "./OrderPackApproval.vue?vue&type=script&lang=ts&"
import style0 from "./OrderPackApproval.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./OrderPackApproval.vue?vue&type=style&index=1&id=3bb0950a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bb0950a",
  null
  
)

export default component.exports