











































































































































































































































































































































































































































import {
  CategoryAdminListViewModel,
  ProductAdminCreateModel,
  ProductAdminUpdateModel,
  ProductAdminViewModel,
  ProductTagViewModel,
  ProductAdminListViewModel,
  StyleGroupAdminListViewModel,
  StyleGroupAdminViewModel,
  PriceAdminViewModel,
  DeliveryType,
  ProductProductionSpeedAdminViewModel,
} from "@/api-client";
import {
  AdminCategories,
  AdminProductionSpeeds,
  AdminProductPrices,
  AdminProductProductionSpeeds,
  AdminProducts,
  AdminProductTags,
  AdminStyleGroups,
} from "@/network/api";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import { allCategories } from "@/utils/recursive";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicGroupSelectorField from "@/components/form-items/BasicGroupSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import PriceRange from "@/components/form-items/PriceRange.vue";
import { sortAscending } from "@/utils/sortAscending";
import { editorToolbar } from "@/utils/editorToolbar";
import { Message } from 'element-ui';

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewProducts",
  components: {
    PriceRange,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ElTableDraggable,
    ImageUploader,
    BasicInputField,
    BasicSelectorField,
    BasicSwitchField,
    BasicGroupSelectorField,
    BasicTooltipHeading,
    BasicInputNumberField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  dirtyMavonTimer: any = null
  productName: string | undefined = "";
  dialogVisible = false;
  videoDialog = false;
  dialogMessage = "";
  disabled = false;
  showThumbnail = true;
  thumbnailImages: Array<any> = [{ imageUrl: "", order: 0 }];
  galleryImages: Array<any> = [{ imageUrl: "", order: 0 }];
  showBanner = true;
  product: ProductAdminViewModel = {
    id: "",
    name: "",
    blurb: "",
    description: "",
    sizeGuidelines: "",
    isFeatured: false,
    isNew: false,
    code: "",
    isDisabled: true,
    slug: "",
    thumbnailImages: [],
    galleryImages: [],
    relatedProducts: [],
    productTags: [],
    categories: [],
    styleGroups: [],
    defaultQuantity: 1,
    minimumQuantity: 1,
    hasProductBuilderOptionsGenerationLogs: false
  };
  products: Array<ProductAdminListViewModel> = [];
  productTags: Array<ProductTagViewModel> = [];
  productStyles: Array<StyleGroupAdminViewModel> = [];
  categories: Array<CategoryAdminListViewModel> = [];
  temporaryOnSaleValue = false;

  allCategoriesArray: Array<CategoryAdminListViewModel> = [];
  allProductTagsArray: Array<ProductTagViewModel> = [];
  allProductsArray: Array<ProductAdminListViewModel> = [];
  allProductStylesArray: Array<StyleGroupAdminListViewModel> = [];

  metaKeywordsArray: Array<string> = [];
  productCategoryArray: Array<string> = [];
  productTagsArray: Array<string> = [];
  relatedProductArray: Array<string> = [];
  productStylesArray: Array<string> = [];
  loading = false
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: '',
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: ''
    }
  ];

  standardDeliveryOptions: Array<any> = []
  expressDeliveryOptions: Array<any> = []
  standardProductProductionSpeed: ProductProductionSpeedAdminViewModel = {
    id: '',
    productId: '',
    productionSpeedId: ''
  }
  expressProductProductionSpeed: ProductProductionSpeedAdminViewModel = {
    id: '',
    productId: '',
    productionSpeedId: ''
  }

  confirmText = "Ok";
  cancelVisible = true;
  metaTagInputVisible = false;
  metaTagInputValue = "";
  categoryInputValue = "";

  newProduct: ProductAdminCreateModel | ProductAdminUpdateModel = {
    name: "",
    blurb: "",
    description: "",
    sizeGuidelines: "",
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    isFeatured: false,
    isNew: false,
    code: "",
    isDisabled: true,
    slug: "",
    thumbnailImages: [],
    galleryImages: [],
    relatedProducts: [],
    productTags: [],
    categories: [],
    styleGroups: [],
    defaultQuantity: 1,
    minimumQuantity: 1,
  };
  mavenToolbar = editorToolbar;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    thumbnailUploader0: any;
    saveMetaTagInput: any;
  };
  messageInstance: any = Message
  activeTab: any = "info";
  fromPath = ''
  // hasDirty = false

  mounted() {
    this.$refs.observer;

    // this.$watch('newProduct', () => {
    //   let dirty = document.querySelectorAll(".dirty");
    //   debugger
    //   if(dirty.length) {
    //     this.hasDirty = true
    //   } else {
    //     this.hasDirty = false
    //   }
    // },
    // {deep: true})
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then((res) => {
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses();
      }, 1000);
    });    
    
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "info";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      } else {
        this.activeTab = "info";
      }
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("textLength", {
      validate(value) {
        return {
          required: true,
          valid: (value > 0) || (value == null)
        };
      },
      computesRequired: true,
      message: "The {_field_} field needs to be greater than 0.",
    });

    extend("categories", () => {
      if (this.productCategoryArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("productTags", () => {
      if (this.productTagsArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("relatedProducts", () => {
      if (this.relatedProductArray.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });
    

    // extend('productStyles', () => {
    //   if (this.productStylesArray.length > 0) {
    //     return true
    //   }
    //   return 'The {_field_} field is required.'
    // })

    let self = this;
    extend("thumbnail", {
      validate(value) {
        let uploader = self.$refs.thumbnailUploader0 as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("gallery", {
      validate(value) {
        let validImages = 0;
        let allRefs = self.$refs;
        for (let item in allRefs) {
          if (item.match(/(^galleryUploader\d+)/g)) {
            let image = self.$refs[item] as ImageUploader;

            if (image && image.image && image.image.id) {
              validImages += 1;
            } else if (image && image._selectedFile) {
              validImages += 1;
            }
          }
        }

        return {
          valid: validImages > 0,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  handleArrayReorder(data:any) {
    this.galleryImages = [...data];
    this.newProduct.galleryImages = [...data];
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    if(this.id) {
      this.$router.replace({
        name: "EditProduct",
        params: { id: this.id },
        query: { tab: this.activeTab },
      });
    } else {
      this.$router.replace({
        name: "NewProduct",
        query: { tab: this.activeTab },
      });
    }
    
  }

  handleSubmit () {
    this.messageInstance.closeAll()

    let allRefs = this.$refs;
    let regex = /(^galleryUploader\d+)/g;
    let invalidVideoUrls = 0;

    for (let item in allRefs) {
      if (item.match(regex)) {
        let imagePromise = this.$refs[item] as ImageUploader;
        if (imagePromise) {
          if((imagePromise as any).videoUrl && !(imagePromise as any).videoUrl.includes('player.vimeo.com')) {
            invalidVideoUrls += 1;
          }
        }
      }
    }


    let errorMessages = [] as Array<string>
      this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
        if(isValid && (invalidVideoUrls == 0)) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.submitForm()
        } else {
          if(errors.length) {
            for(let error in errors) {
              if(errors[error].length > 0) {
                errorMessages.push(error)
              }
            }
            let finalMessage = `The following fields are required:<br>`
            errorMessages.forEach((message, index:any) => {
              if (index < 5) {
                finalMessage += `<br>- ${message}`
              }
            })
            if(errorMessages.length > 5) {
              let remaining = errorMessages.length - 5
              finalMessage += `<br><br>and ${remaining} others...`
            }
            this.$message({showClose: true, type: 'error', duration: 0, message: finalMessage, dangerouslyUseHTMLString: true});
          }

          if(invalidVideoUrls > 0) {
            this.$message({showClose: true, type: 'error', duration: 0, message: 'One or more of the gallery image video URLs is not valid.', dangerouslyUseHTMLString: true});
          }
        }
      });
    }

  uploadImages(refValue: RegExp): Promise<any> {
    let imagePromises: Array<any> = [];
    let allRefs = this.$refs;
    let regex = refValue;

    for (let item in allRefs) {
      if (item.match(regex)) {
        let imagePromise = this.$refs[item] as ImageUploader;
        if (imagePromise) {
          if(!imagePromise.videoUrl) {
            imagePromises.push(imagePromise.uploadImage());
          } else {
            imagePromises.push({ imageUrl: imagePromise.videoUrl });
          }
        }
      }
    }

    return Promise.all(imagePromises);
  }

  addThumbnailImage() {
    let allRefs = this.$refs;
    if (this.thumbnailImages.length === 0) {
      this.thumbnailImages!.push({
        imageUrl: "",
        order: 0,
      });
    }
  }

  addGalleryImage() {
    let empty = 0;
    let allRefs = this.$refs;
    for (let item in allRefs) {
      if (item.match(/(^galleryUploader\d+)/g)) {
        let image = this.$refs[item] as ImageUploader;

        if (image) {
          if (image && image.image === null) {
            if (
              (image && !image.hasOwnProperty("_selectedFile")) ||
              (image && image._selectedFile === null)
            ) {
              empty += 1;
            }
          } else {
            console.log("selected image", item, image.image);
          }
        }
      }
    }

    if (this.galleryImages.length < 6 && empty === 0) {
      this.galleryImages!.push({
        imageUrl: "",
        order: this.galleryImages.length - 1,
      });
    }
  }

  get sortedCategories() {
    return sortAscending(this.allCategoriesArray);
  }

  get sortedProducts() {
    return sortAscending(this.allProductsArray, true , 'code');
  }

  get sortedMetaTags() {
    return sortAscending(this.metaKeywordsArray, false);
  }

  get sortedProductTags() {
    return sortAscending(this.allProductTagsArray);
  }

  get sortedProductStyles() {
    return sortAscending(this.allProductStylesArray, true , 'code');
  }

  handleClose(tag: any) {
    this.metaKeywordsArray.splice(this.metaKeywordsArray.indexOf(tag), 1);
  }

  showInput() {
    this.metaTagInputVisible = true;
    this.$nextTick(() => {
      let saveInput = this.$refs.saveMetaTagInput as any;
      saveInput.$refs.input.focus();
    });
  }

  handleCategoryChange(value: string) {
    let inputValue = value;
    if (value) {
      this.productCategoryArray.push(inputValue);
    }
    this.categoryInputValue = "";
  }

  handleMetaTagInputConfirm() {
    let inputValue = this.metaTagInputValue;
    if (inputValue && !this.metaKeywordsArray.includes(inputValue)) {
      this.metaKeywordsArray.push(inputValue);
    }
    this.metaTagInputVisible = false;
    this.metaTagInputValue = "";
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    //let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  beforeUnmount() {
    clearTimeout(this.dirtyTimer);
    clearTimeout(this.dirtyMavonTimer);
    this.messageInstance.closeAll()
  }

  removeImage(area: string) {
    //debugger
    let index = Number(area.slice(3));

    if (area[0] == "t") {
      this.thumbnailImages.splice(index, 1);
      this.newProduct.thumbnailImages!.splice(index, 1);
    } else if (area[0] == "g") {
      this.galleryImages.splice(index, 1);
      this.newProduct.galleryImages!.splice(index, 1);
    }

    if (this.thumbnailImages.length === 0) {
      this.thumbnailImages = [{ imageUrl: "", order: 0 }];
    }

    if (this.galleryImages.length === 0) {
      this.galleryImages = [{ imageUrl: "", order: 0 }];
    }
  }

  toggleUploadThumbnail(file: any) {
    if (file && !this.thumbnailImages.length) {
      this.showThumbnail = !this.showThumbnail;
      this.thumbnailImages.push(file);
    }
  }

  handleUploadChange(file: any) {
    if (file) {
      this.galleryImages.push(file);
    }
  }

  beforeImageUpload = async (file: any) => {
    const isType = file.type === ("image/jpeg" || "image/png");
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;
  };

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'ProductList') {
        this.$router.back()
      } else {
        this.$router.push({ name: "ProductList"});
      }
    } else {
      this.submitForm();
    }
  }

  //-------- Price Range Start

  saveNewPriceRange(range:any) {
    this.loading = true
    AdminProductPrices.adminProductPricesPost(range)
    .then((res) => {
      if (res.data.succeeded) {
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        
    });
  }

  updatePriceRange(range:any) {
    this.loading = true
    AdminProductPrices.adminProductPricesIdPut(range.id, range)
    .then((res) => {
      if (res.data.succeeded) {
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        
    });
  }

  deletePriceRange(id:string) {
    this.loading = true
    AdminProductPrices.adminProductPricesIdDelete(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message.info('Item deleted.')
      this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
          
    });
  }

  loadPriceRanges() {
    this.loading = true
    AdminProductPrices.adminProductPricesGet(1, 99999999, this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.priceRanges = res.data.resultData!.items
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  //-------- Price Range End

  saveDeliveryMethod = (type:any) =>
    new Promise((resolve, reject) => {    
    this.loading = true
    if(type.id) {
      let productProductionSpeed = {
        id: type.id,
        productId: this.id,
        productionSpeedId: type.productionSpeedId
      }
      AdminProductProductionSpeeds.adminProductProductionSpeedsIdPut(type.id, productProductionSpeed)
      .then((res) => {
        if (res.data.succeeded) {
          resolve(res);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            reject(error)
            return e.friendlyMessage
          }
        );
        
      });
    } else if(type.productionSpeedId) {
        let productProductionSpeed = {
          productId: this.id,
          productionSpeedId: type.productionSpeedId
        }
        AdminProductProductionSpeeds.adminProductProductionSpeedsPost(productProductionSpeed)
        .then((res) => {
          if (res.data.succeeded) {
            resolve(res);
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              reject(error)
              return e.friendlyMessage
            }
          );
          
        });
    } else {
      resolve(true);
    }})

  submitForm() {
    this.loading = true
    this.uploadImages(/(^thumbnailUploader\d+)/g).then((results) => {
      let thumb = results;
      this.newProduct!.thumbnailImages! = thumb;

      this.uploadImages(/(^galleryUploader\d+)/g).then((results) => {
        let gallery = results;
        this.newProduct!.galleryImages! = gallery;

        if(this.newProduct!.galleryImages!.length) {
          this.newProduct!.galleryImages?.forEach((item:any, index:any) => {
              item.order = index;
            }
          )
        }

        if (!this.newProduct.slug) {
          this.newProduct.slug = this.newProduct
            .name!.trim()
            .split(" ")
            .join("-")
            .toLowerCase();
        }
        if (this.metaKeywordsArray.length > 0) {
          this.newProduct.seoKeywords = this.metaKeywordsArray.join(", ");
        }
        if (this.productCategoryArray.length > 0) {
          this.newProduct.categories = [];
          this.productCategoryArray.map((id, index) => {
            this.newProduct.categories!.push({
              productCategoryId: id,
              //Check with Liam on how to determine if isPrimaryCategory
              isPrimaryCategory: index > 0 ? false : true,
            });
          });
        }
        if (this.productTagsArray.length > 0) {
          this.newProduct.productTags = [];
          this.productTagsArray.forEach((id) => {
            this.newProduct.productTags!.push({
              productTagId: id,
            });
          });
        }
        if (this.productStylesArray.length > 0) {
          this.newProduct.styleGroups = [];
          this.productStylesArray.map((id: any) => {
            this.newProduct.styleGroups!.push({
              productStyleGroupId: id,
            });
          });
        }
        if (this.relatedProductArray.length > 0) {
          this.newProduct.relatedProducts = [];
          this.relatedProductArray.map((id) => {
            this.newProduct.relatedProducts!.push({
              relatedProductId: id,
            });
          });
        }
        
        if (this.id) {
          this.saveDeliveryMethod(this.standardProductProductionSpeed)
          .then((res) => {
            if (res) {
              this.saveDeliveryMethod(this.expressProductProductionSpeed)
              .then((res) => {
                if (res) {
                  AdminProducts.adminProductsIdPut(this.product.id, this.newProduct)
                  .then((res) => {
                    if(res.data.succeeded) {
                    this.$message.success("Saved!");
                    this.dialogVisible = false;
                    this.loadInitialData()
                    .then(() => {
                      this.clearDirtyClasses();
                    })
                    }
                    this.loading = false
                  })
                  .catch((error) => {
                    this.loading = false
                    this.dialogVisible = false;
                    error.response.data.errors.map((e: any) => {
                      this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                      return e.friendlyMessage;
                    });
                    
                  });
                }
              })
            }
          })          
        } else {
          AdminProducts.adminProductsPost(this.newProduct)
            .then((res) => {
              if (res.data.succeeded) {
                this.id = res.data.resultData!.id;
                this.saveDeliveryMethod(this.standardProductProductionSpeed)
                .then((res) => {
                  if (res) {
                    this.saveDeliveryMethod(this.expressProductProductionSpeed)
                    .then((res) => {
                      if (res) {
                        this.$message.success("Saved!");
                        this.$router.replace({ name: "EditProduct", params: {id: this.id} });
                        this.loadInitialData()
                        .then(() => {
                          this.clearDirtyClasses();
                        })
                        this.dialogVisible = false;
                      }
                    })
                  }
                })
                
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                return e.friendlyMessage;
              });
              
            });
        }
      });
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        // this.hasDirty = false
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll(".dirty");

    if (mavonDirty.length) {
      for (let item of mavonDirty) {
        item.classList.remove("dirty");
      }
      // this.hasDirty = false
    }
  }

  removeExpressDelivery() {
    this.loading = true
    AdminProductProductionSpeeds.adminProductProductionSpeedsIdDelete(this.expressProductProductionSpeed.id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message.info('Item deleted.');
        this.expressProductProductionSpeed = {
          id: '',
          productId: '',
          productionSpeedId: ''
        }
      }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  async loadCategories(refresh = false) {
    if(refresh) {
      this.loading = true;
    }
    await AdminCategories.adminCategoriesGet()
      .then((res) => {
        this.categories = res.data
          .resultData as Array<CategoryAdminListViewModel>;
        this.allCategoriesArray = [];
        allCategories(this.categories, this.allCategoriesArray, true, true);
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");         
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadProductTags(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminProductTags.adminProductTagsGet()
      .then((res) => {
        this.productTags = res.data.resultData as Array<ProductTagViewModel>;
        this.allProductTagsArray = [];
        this.productTags.map((tag) => {
          this.allProductTagsArray.push(tag as ProductTagViewModel);
        });
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");         
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadStyles(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminStyleGroups.adminStyleGroupsGet(1, 99999999).then((res) => {
      if(res.data.succeeded) {
        this.productStyles = res.data.resultData!
        .items as Array<StyleGroupAdminViewModel>;
      this.allProductStylesArray = [];
      this.productStyles.map((style) => {
        this.allProductStylesArray.push(style);
      });
      if(refresh) {
        this.loading = false
        this.$message.success("Section Refreshed.");         
      }
      }
    });
  }

  async loadAllProducts(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminProducts.adminProductsGet(1, 99999999)
      .then((res) => {
        this.products = res.data.resultData!.items;
        this.allProductsArray = [];
        this.products.map((product) => {
          this.allProductsArray.push(product);
        });
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");         
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadInitialData(): Promise<any> {
    this.loading = true
    window.scrollTo({ top: 0 });
    await this.loadCategories()

    await this.loadProductTags()    

    await this.loadStyles()

    await this.loadAllProducts()    

    await AdminProductionSpeeds.adminProductionSpeedsGet(1, 999999, '', DeliveryType.Standard)
    .then((res) => {
      if(res.data.succeeded) {
        this.standardDeliveryOptions = res.data.resultData!.items;
      }
    })
    .catch((error) => {
      this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });

    await AdminProductionSpeeds.adminProductionSpeedsGet(1, 999999, '', DeliveryType.Express)
    .then((res) => {
      if(res.data.succeeded) {
        this.expressDeliveryOptions = res.data.resultData!.items;
      }
    })
    .catch((error) => {
      this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });

    if (this.id) {
      window.scrollTo({ top: 0 });
      this.loadPriceRanges();

      await AdminProducts.adminProductsIdGet(this.id).then((res) => {
        this.product = res.data.resultData as ProductAdminViewModel;

        this.productName = this.product.name;

        // TODO @Mon fix this
        // @ts-ignore: The map kinda works
        this.newProduct = (({ id, ...rest }) => rest)(this.product);

        if (this.newProduct.seoKeywords) {
          this.metaKeywordsArray = this.newProduct.seoKeywords.split(", ");
        }
        if (this.newProduct.categories) {
          this.productCategoryArray = [];
          this.newProduct.categories.map((category) => {
            this.productCategoryArray.push(
              category.productCategoryId as string
            );
          });
        }
        if (this.newProduct.productTags) {
          this.productTagsArray = [];
          this.newProduct.productTags.forEach((tag) => {
            this.productTagsArray.push(tag.productTagId as string);
          });
        }
        if (this.newProduct.styleGroups) {
          this.productStylesArray = [];
          this.newProduct.styleGroups.map((style) => {
            this.productStylesArray.push(style.productStyleGroupId as string);
          });
        }
        if (this.newProduct.relatedProducts) {
          this.relatedProductArray = [];
          this.newProduct.relatedProducts.map((product) => {
            this.relatedProductArray.push(product.relatedProductId as string);
          });
        }
        if (this.newProduct.thumbnailImages!.length > 0) {
          this.thumbnailImages = [];
          this.newProduct.thumbnailImages!.map((image) => {
            this.thumbnailImages.push(image);
          });
        }
        if (this.newProduct.galleryImages!.length > 0) {
          this.galleryImages = [];
          this.newProduct.galleryImages!.map((image) => {
            this.galleryImages.push(image);
          });
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });

      await AdminProductProductionSpeeds.adminProductProductionSpeedsGet(1, 99999, this.id, DeliveryType.Standard)
      .then((res) => {
        if(res.data.succeeded && (res.data.resultData!.items.length > 0)) {
          this.standardProductProductionSpeed = res.data.resultData!.items[0]
          
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });

      await AdminProductProductionSpeeds.adminProductProductionSpeedsGet(1, 99999, this.id, DeliveryType.Express)
      .then((res) => {
        if(res.data.succeeded && (res.data.resultData!.items.length > 0)) {
          this.expressProductProductionSpeed = res.data.resultData!.items[0]
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
    }
    window.scrollTo({ top: 0 });
    this.loading = false
    
  }
}
