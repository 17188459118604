import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import UniqueId from 'vue-unique-id';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// @ts-ignore
import locale from 'element-ui/lib/locale/lang/en'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import { UserModule } from "./store/modules/user_new";
import { AuthModule } from "./store/modules/auth";
import * as VueGoogleMaps from 'vue2-google-maps'

import { ConfigureAxios } from "@/network/auth-interceptor";

import VueMermaidString from 'vue-mermaid-string'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

ConfigureAxios();
store.registerModule("user_new", UserModule);
store.registerModule("auth", AuthModule);

Vue.use(ElementUI, { locale })
Vue.use(UniqueId);
Vue.use(mavonEditor)
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})
Vue.use(VueMermaidString)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_PLACES_KEY,
    libraries: "places"
  }
})

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "-05:00"; // To consider daylight savings time, use "America/New_York" instead

// Create a global filter in Vue for date formatting
Vue.filter('formatDate', (value, format = 'DD MMM YYYY, HH:mm') => {
  if (!value) return '';
  return dayjs(value).tz(tz).format(format);
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
