
















































































































import {
  AvailableOrderStatusViewModel,
  OrderStatus,
  UserAdminListViewModel,
} from "@/api-client";
import OrderTaskList from "@/components/orders/activity-tasks/OrderTaskList.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import { Component, Vue } from "vue-property-decorator";
import { AdminOrders, AdminUsers } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import store from "@/store";


const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    orderStatuses: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  name: "OrderTasks",
  components: { BasicSelectorField, OrderTaskList, BasicInputField, BasicTooltipHeading },
})
export default class extends AppProps {
  openList = false;
  openMessages = false;
  admins: Array<UserAdminListViewModel> = []
  taskId = ''
  showLostReason = false
  showCancellationReason = false
  resetSection = false

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  created() {
    this.loadInitialData()

    if (this.$route.query.taskId) {
      this.openList = true
    }

    this.$watch('order.orderStatusId', () => {
      if(this.order.orderStatusId === 'Lost') {
        this.showLostReason = true
      } else {
        this.showLostReason = false
      }

      if(this.order.orderStatusId === 'Cancelled') {
        this.showCancellationReason = true
      } else {
        this.showCancellationReason = false
      }
    })
  }

  get disableApprovalChase() {
    return (this.order.orderStatusId !== 'AwaitingAdditionalPayment' && this.order.orderStatusId !== 'AwaitingResponse')
  }

  get canEditManagers() {
    let roles =  store.getters["user_new/role"] as any;

    if(roles.includes('Admin') || roles.includes('Operations')) {
      return true;
    }
    return false
  }

   get isSelectorDisabled(){
    return this.loading || !this.canEditManagers;
  }

  determineStyle(index: number, length:number) {
    if(length == 1) {
      return 'padding:0 25px;'
    } else if(index == 0) {
      return 'padding:0 5px 0 25px;'
    } else if(index == (length - 1)) {
      return 'padding:0 25px 0 5px;'
    } else {
      return 'padding:0 5px;'
    }
  }

  approvalChase() {
    let isDirty = document.querySelectorAll(".dirty");

    if(isDirty.length > 0) {
      this.$message({showClose: true, type: 'error', duration: 0, message: 'Please save your changes before proceeding with this action.'})
    } else {
      AdminOrders.adminOrdersSendResponeReminderIdPost(this.order.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message.success('Reminder sent!');
          this.$emit('reload')
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }
  }

  handleDate(date: any) {
    if(date) {
      return formatDate(date);
    }
    return ''
  }

  setTaskId() {
    if(this.taskId) {
      this.$router.push({
        path: this.$route.fullPath,
        query: { taskId: this.taskId },
      });
    }
  }

  clearTaskIdQuery() {
    if(this.$route.query.taskId) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: undefined
        }
      })
    }
    this.taskId = ''
    this.resetSection = true
  }


  changeStatus(status: AvailableOrderStatusViewModel | null) {
    if(status?.id == OrderStatus.Lost) {
      if(!this.order.lostReason) {
        this.showLostReason = true
        this.setAddressDefaults(status.id)
        this.$message({showClose: true, type: 'error', duration: 0, message: "Please enter a lost reason and before changing the status to 'Lost'. You will need to click 'Lost' again after entering your reason."})
        setTimeout(() => {
          document.getElementById("lostReason")!.focus();
        }, 400);
      } else {
        this.$emit('changeStatus', status.id)
      }
    } else if(status?.id == OrderStatus.Cancelled) {
      if(!this.order.cancellationReason) {
        this.showCancellationReason = true
        this.setAddressDefaults(status.id)
        this.$message({showClose: true, type: 'error', duration: 0, message: "Please enter a cancellation reason and before changing the status to 'Cancelled'. You will need to click 'Cancelled' again after entering your reason."})
        setTimeout(() => {
          document.getElementById("cancelledReason")!.focus();
        }, 400);
      } else {
        this.$emit('changeStatus', status.id)
      }
    } else if(status) {
      this.$emit('changeStatus', status.id)
    }
  }

  setAddressDefaults(status: OrderStatus | null) {
    if (status && ['Lost', 'Cancelled'].includes(status) && this.order.primaryDeliveryAddress) {
      // Set default address fields automatically if order marked as lost/ cancelled
      const defaults = ['addressLine1', 'area', 'country', 'postalCode']
      defaults.forEach((def) => {
        if(!this.order.primaryDeliveryAddress![def] || ['TBC - Lost', 'TBC - Cancelled'].includes(this.order.primaryDeliveryAddress![def])) {
          this.order.primaryDeliveryAddress![def] = `TBC - ${status}`
        }
      })
      this.$emit("updateAddress", this.order.primaryDeliveryAddress)
    }
  }

  async loadInitialData() {
    if(this.roles.includes('Operations') || this.roles.includes('Admin') || this.roles.includes('Sales') || this.roles.includes('Sales Admin')) {
      await AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.admins = res.data.resultData!
            .items as Array<UserAdminListViewModel>;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      }); 
    }
  }
}
