

























































































































import {
  OrderCostAdminCreateModel,
  OrderCostAdminListViewModel,
  OrderCostAdminUpdateModel,
  OrderCostType,
  OrderItemAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminOrderCosts } from "@/network/api";
import { thousandSeparator, calculatePrice } from "@/utils/calculatePrice";	

const AppProps = Vue.extend({
  props: {
    costId: {
      type: String,
      default: "",
    },
    order: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "NewCost",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputNumberField,
    BasicInputField,
  },
})
export default class extends AppProps {
  pageNumber: string = this.$route.params.pageNumber;
  itemPerPage: string = this.$route.params.itemPerPage;
  search: string = this.$route.params.search;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  popImage: Array<any> = [];
  showBanner: boolean = true;
  cost: OrderCostAdminListViewModel = {
    id: "",
    orderId: "",
    totalAmount: 0,
    convertedTotalAmount: 0,
    quantity: 0,
    orderItemId: null,
    orderCostTypeId: OrderCostType.Adhoc,
    reason: "",
    lastModifiedDate: "",
    internalReason: '',
    orderItemName: ''
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newCost: OrderCostAdminCreateModel | OrderCostAdminUpdateModel = {
    totalAmount: 0,
    quantity: 0,
    reason: "",
    internalReason: '',
    orderItemId: null
  };
  loading = false;
  orderItems: Array<any> = [];
  dirtyTimer: any = null;
  unconvertedAmount: number = 0;

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get convertedPerTotal() {
    return calculatePrice(this.unconvertedAmount, this.order.currency.pricingMultiplier, this.order.currency.decimalPlaces)
  }

  get convertedTotal() {
    return calculatePrice(this.newCost.totalAmount, this.order.currency.pricingMultiplier, this.order.currency.decimalPlaces)
  }

  get actualQuantity() {
    if(this.newCost.orderItemId && this.orderItems.length) {
      const item = this.orderItems.find((item) => item.id === this.newCost.orderItemId).quantity;
      if(item) {
        return item;
      }
    }
    return this.newCost.quantity;
  }

  created() {
    this.loadInitialData();

    this.$watch("$route", () => {
      if (this.$route.query.tab === "costs") {
        this.$emit("showList");
      }
    });

    this.$watch("actualQuantity", () => {
      if (this.actualQuantity > 0) {
        this.newCost.totalAmount = this.unconvertedAmount * this.actualQuantity;
      } else {
        this.newCost.totalAmount = this.unconvertedAmount;
      }
    });

    this.$watch("unconvertedAmount", () => {
      if (this.actualQuantity > 0) {
        this.newCost.totalAmount = this.unconvertedAmount * this.actualQuantity;
      } else {
        this.newCost.totalAmount = this.unconvertedAmount;
      }
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    this.$emit("list");
    // let isDirty = document.querySelectorAll(".dirty");
    // this.confirmText = "Yes";
    // debugger

    // if (isDirty.length > 0) {
    //   this.dialogMessage =
    //     "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    //   this.dialogVisible = true;
    // } else {
    // this.handleConfirm();
    // }
  }

  // beforeSave() {
  //   this.dialogMessage =
  //     "<span>Are you sure you want to save all changes?</span>";
  //   this.confirmText = "Save";
  //   this.dialogVisible = true;
  // }

  handleConfirm() {
    // if (this.confirmText === "Yes") {
    //   this.$emit('list');
    // } else {
    this.submitForm();
    // }
  }

  handleDeselectProduct() {
    if(!this.newCost.orderItemId) {
      this.newCost.quantity = 0;
    }
  }

  submitForm() {
    this.loading = true;

    if(this.actualQuantity > 0) {
      this.newCost.totalAmount = this.unconvertedAmount * this.actualQuantity;
    } else {
      this.newCost.totalAmount = this.unconvertedAmount;
    }

    if (this.costId) {
      AdminOrderCosts.adminOrderCostsIdPut(this.costId, this.newCost)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.loading = false;
            this.dialogVisible = false;
            this.$emit("list");
          }
          this.loading = false;
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false;
          this.dialogVisible = false;
        });
    } else {
      AdminOrderCosts.adminOrderCostsPost({
        orderId: this.order.id,
        ...this.newCost,
      } as OrderCostAdminCreateModel)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.loading = false;
            this.dialogVisible = false;
            this.$emit("list");
          }
          this.loading = false;
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false;
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
    // });
  }

  async loadInitialData() {
    this.loading = true;

    if (this.costId) {
      await AdminOrderCosts.adminOrderCostsIdGet(this.costId)
        .then((res) => {
          if (res.data.succeeded) {
            this.cost = res.data.resultData as OrderCostAdminListViewModel;
            this.newCost = (({ id, orderItemName, ...rest }) => rest)(this.cost) as any;
            if(this.actualQuantity > 0) {
              this.unconvertedAmount = this.newCost.totalAmount / this.actualQuantity;
            } else {
              this.unconvertedAmount = this.newCost.totalAmount;
            }
          }

          // this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });

          this.dialogVisible = false;
        });
    }

    if (this.order) {
      this.orderItems = [];
      this.order.orderItems.forEach((item: OrderItemAdminViewModel, index:number) => {
        this.orderItems.push({
          id: item.customizedProduct.id,
          name: item.customizedProduct.name,
          extraLabel: ` - ${index} of ${this.orderItems.length}`,
          quantity: item.customizedProduct.quantity
        });
      });
    }
    this.loading = false;
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
