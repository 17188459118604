
















































































import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminProductionSpeedAdjustments } from "@/network/api";
import { AdminProductionSpeedAdjustmentsGetRequest, ProductionSpeedAdjustmentViewModelApplicationResultResultData } from "@/api-client";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewProductionSpeedAdjustment",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
    BasicInputNumberField,
    BasicDateField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  productionSpeedAdjustment: AdminProductionSpeedAdjustmentsGetRequest | ProductionSpeedAdjustmentViewModelApplicationResultResultData = {
    name: "",
    description: null,
    startDate: "",
    endDate: "",
    deliveryDelay: 0,
    productionDelay: 0
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;

  newProductionSpeedAdjustment: AdminProductionSpeedAdjustmentsGetRequest | ProductionSpeedAdjustmentViewModelApplicationResultResultData = {
    name: "",
    description: null,
    startDate: "",
    endDate: "",
    deliveryDelay: 0,
    productionDelay: 0
  };
  loading = false
  dirtyTimer: any = null
  fromPath = ''

  created() {
    this.loadInitialData()
    .then(() => {
      this.clearDirtyClasses();
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'ProductionSpeedAdjustment') {
        this.$router.back()
      } else {
        this.$router.push({ name: "ProductionSpeedAdjustment"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    if ((this.productionSpeedAdjustment as ProductionSpeedAdjustmentViewModelApplicationResultResultData).id) {
      this.loading = true
      console.log("before save", this.newProductionSpeedAdjustment);

      AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsIdPut((this.productionSpeedAdjustment as ProductionSpeedAdjustmentViewModelApplicationResultResultData).id, this.newProductionSpeedAdjustment)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false
          this.dialogVisible = false;
        });
    } else {
      AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsPost(this.newProductionSpeedAdjustment)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.$router.push({ name: "ProductionSpeedAdjustment" });
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false
          this.dialogVisible = false;
        });
    }
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true
      await AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsIdGet(this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.productionSpeedAdjustment = res.data.resultData as ProductionSpeedAdjustmentViewModelApplicationResultResultData;
          this.name = this.productionSpeedAdjustment.name;
          this.newProductionSpeedAdjustment = (({ id, ...rest }) => rest)(this.productionSpeedAdjustment as ProductionSpeedAdjustmentViewModelApplicationResultResultData); 
        }

        this.loading = false            
      })
      .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false
          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
