
































































































import {
  BannerMessageAdminViewModel,
  CountryViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminBannerMessages, PublicCountries } from "@/network/api";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import { formatDate } from "@/utils/formatDate"

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewBannerMessage",
  components: {
    BasicInputNumberField,
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
    BasicSwitchField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  bannerMessage: BannerMessageAdminViewModel = {
    id: "",
    countryId: "",
    startDate: "",
    endDate: "",
    url: null,
    message: "",
    showOnQuotes: true
  }
  bannerMessages: Array<BannerMessageAdminViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  allCountries: Array<CountryViewModel> = [];
  loading = false
  newBannerMessage: BannerMessageAdminViewModel = {
    id: '',
    countryId: "",
    startDate: "",
    endDate: "",
    url: null,
    message: "",
    showOnQuotes: true
  };
  fromPath = ''
  selectedDates:any = null
  pickerOptions= {
    firstDayOfWeek: 1,
    shortcuts: [{
      text: 'Last week',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last month',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last 3 months',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: 'Last 6 months',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 120);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: 'Last year',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit('pick', [start, end]);
      }
    },
    ]
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then(() => {
      this.clearDirtyClasses();
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined, 0].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handleDateRanges(value:any) {  
    let dates = {
      startDate: "" as string,
      endDate: "" as string
    };   
    if(value) {
      let d1 = new Date(value[0])
      let d2 = new Date(value[1])

      //if(d1.getTime() == d2.getTime()) {
        d2.setDate(d2.getDate() + 1);   
      //}

      dates = {
        startDate: new Date(d1).toISOString(),
        endDate: new Date(d2).toISOString()
      }
    }
    this.newBannerMessage.startDate = this.selectedDates.startDate = dates.startDate;
    this.newBannerMessage.endDate = this.selectedDates.endDate = dates.endDate;

    this.selectedDates = [this.newBannerMessage.startDate, this.newBannerMessage.endDate];
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'BannerMessages') {
        this.$router.back()
      } else {
        this.$router.push({ name: "BannerMessages"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true

      if (this.bannerMessage.id) {
        console.log("before save", this.newBannerMessage);
        AdminBannerMessages.adminBannerMessagesIdPut(this.bannerMessage.id, this.newBannerMessage)
          .then((res) => {
            if(res.data.succeeded) {
              console.log("success?", res);
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            }
            this.loading = false            
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false
            this.dialogVisible = false;
          });
      } else {
        AdminBannerMessages.adminBannerMessagesPost(this.newBannerMessage)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.id = res.data.resultData!.id;
              this.$router.replace({ name: "EditBannerMessage", params: {id: this.id} });
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            }
            this.loading = false
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false
            this.dialogVisible = false;
          });
      }
    }

    async loadCountries() {
    this.loading = true;
    await PublicCountries.countriesGet().then((res) => {
      if (res.data.succeeded) {
        this.allCountries = res.data.resultData as Array<CountryViewModel>;
      }
      this.loading = false;
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: e.friendlyMessage,
        });
        return e.friendlyMessage;
      });
      this.loading = false;
      this.dialogVisible = false;
    });
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true
      await AdminBannerMessages.adminBannerMessagesIdGet(this.id).then((res) => {
        if(res.data.succeeded) {
        this.bannerMessage = res.data.resultData as BannerMessageAdminViewModel;
        this.newBannerMessage = (({ id, ...rest }) => rest)(this.bannerMessage) as any;

        if(this.newBannerMessage.startDate && this.newBannerMessage.endDate) {
          this.selectedDates = [this.newBannerMessage.startDate, this.newBannerMessage.endDate]
        }
        
        console.log("new banner message", this.newBannerMessage);
        }
      })
      .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false
            this.dialogVisible = false;
          });
      this.loading = false
    }

    this.loadCountries()
  }
}
