






import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'App'
})
export default class extends Vue {
  //TODO empyt mask url: 7a44dbce0f78407ba837ea335cf706e2db8b5d12/empty_mask.png
  // mounted() {
  //   window.removeEventListener('storage', (e) => this.syncLocalStorage(e));
  //   window.addEventListener('storage', (e) => this.syncLocalStorage(e));    
  // }

  // syncLocalStorage(e:any):any {
  //   localStorage.setItem(e.key, e.newValue)
  // }
}
