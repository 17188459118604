







import { Component, Vue } from "vue-property-decorator";
import OrderTable from "@/components/orders/OrderTable.vue";
import FactoryOrderTable from "@/components/orders/FactoryOrderTable.vue";
import store from "@/store";

@Component({
  name: "Orders",
  components: {
    OrderTable,
    FactoryOrderTable
  },
})

export default class extends Vue {
  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get isFactoryOrders () {
    return this.roles.includes('Factory Worker');
  }
}
