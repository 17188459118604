import { AuthenticationResult } from "@/api-client";
import { Authentication } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import store, { RootState} from "..";
import router from "@/router";

// interface TokenResponse {}

export enum SocialProviders {
  Google,
  Facebook,
  Apple,
}

export enum MutationTypes {
  SetToken = "SET_Auth_Token",
}

export type AuthState = {
  token: AuthenticationResult | null;
  isLoggedIn: boolean;
};

export const state: AuthState = {
  token: null, // use getter stright out of localstore or cookies
  isLoggedIn: getTokenState() != null,
};

type Getters = {
  token(state: AuthState, rootState: RootState): AuthenticationResult | null;
  isLoggedIn(state: AuthState): boolean;
};

function getTokenState(_state?: AuthState): AuthenticationResult | null {
  if (_state && _state!.token != null) {
    return _state!.token;
  }
  const vuex = localStorage.getItem("vuex");
  const store = JSON.parse(vuex!);
  if (store == null) {
    return null;
  }
  if (store["auth"] == null) {
    return null;
  }
  const tkn = store["auth"]["token"];
  if (tkn != null) {
    if (_state) {
      _state!.token = tkn;
    }
    return tkn;
  }
  return null;
}

export const getters: GetterTree<AuthState, RootState> & Getters = {
  token(state) {
    return getTokenState(state);
  },
  isLoggedIn(state) {
    // if (state.isLoggedIn )
    // var test = getTokenState(state) == null ? false : true;
    // console.log(`User Logged in : ${test}`)
    return state.isLoggedIn; // state.isLoggedIn;
  },
};

type Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any): void;
};

export const mutations: MutationTree<AuthState> & Mutations = {
  [MutationTypes.SetToken](state: AuthState, token: any) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["auth"]) {
      store["auth"] = {};
    }
    store["auth"]["token"] = token;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.token = { ...token };
    if (token) {
      state.isLoggedIn = true;
    } else {
      state.isLoggedIn = false;
    }
  },
};

let _refreshingToken: Promise<void> | null = null;

export const AuthModule: Module<AuthState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    login: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.identityAuthenticationLoginPost({
          email: params.email!,
          password: params.password!,
        })
          .then(async (res) => {
            let token = res.data.resultData
            context.commit(MutationTypes.SetToken, token);
            // debugger
            await store.dispatch("user_new/getUserInfo", { token: token?.encodedToken });
            resolve();
          })
          .catch((e) => {
            console.error("LOGIN FAILED", e);
            reject(e);
          });
      });
    },
    logout: async (context, params) => {
      return new Promise<void>((resolve, reject) => {
        context.commit(MutationTypes.SetToken, null);
        store.dispatch("user_new/clearProfile").then(() => {
          
          let path = window.location.pathname
          router.push({name: "Login", query: { redirect: path }});
          resolve()
        });
      });
    },
    refresh: (context, params) => {
      return (_refreshingToken ??= new Promise<void>((resolve, reject) => {
        const refreshToken = (state.token! as any)["refreshToken"];    
        Authentication.identityAuthenticationRefreshTokenPost({
          refreshToken: refreshToken,
        })
          .then(async (res) => {
            // debugger
            if(res.data.succeeded) {
              context.commit(MutationTypes.SetToken, res.data.resultData);
            // await context.dispatch("user/getProfile", null, { root: true });

            resolve();
            }
            
          })
          .catch((e) => {
            // store.dispatch("auth/logout")
            console.error("LOGIN FAILED", e);
            reject(e);
          });
      }).finally(() => (_refreshingToken = null)));
    },
    // socialAuth: (context, params) => {
    //   switch (params.provider as SocialProviders) {
    //     case SocialProviders.Google:
    //       SocialAuth.apiPublicSocialAuthGooglePost({ Token: params.token })
    //         .then(async (res) => {
    //           context.commit(MutationTypes.SetToken, res.data);
    //           await context.dispatch("user/getProfile", null, { root: true });
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //         });

    //       break;
    //     case SocialProviders.Facebook:
    //       break;
    //     case SocialProviders.Apple:
    //       break;
    //   }
    // },
    register: (context, params) => {
      return new Promise<void>((resolve, reject) => {
        Authentication.identityAuthenticationRegisterPost({
          displayName: params.displayName,
          email: params.email!,
          password: params.password!,
          googleRecaptchaResponse: params.googleRecaptchaResponse
        })
          .then((res) => {
            console.log(res);
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  namespaced: true,
};
