var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"art-asset-list-container"},[_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{name: 'NewArtAsset'}}},[_c('button',{staticClass:"new-asset",attrs:{"disabled":_vm.loading}},[_vm._v("New Art Asset")])])],1),_c('el-col',{staticStyle:{"margin-top":"15px"},attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"uniqueMinItem":5,"disabled":_vm.loading,"totalCount":_vm.artAsset.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
          _vm.itemPerPage = value;
          _vm.loadInitialData();
        },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search name","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ElTableDraggable',{attrs:{"handle":'.handle'},on:{"reorder":_vm.handleReorder}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.artAssetItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"230","prop":"imageUrl","label":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-image',{attrs:{"fit":'contain',"alt":row.metaData ? row.metaData : '',"src":row.imageUrl && (_vm.uploadPath + "/" + (row.imageUrl))}})]}}])}),_c('el-table-column',{attrs:{"min-width":"120","prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"120"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.sortedCategories),function(category){return _c('li',{key:category.id,class:{'selected-filter': _vm.categoryFilter.includes(category.id)},on:{"click":function($event){return _vm.handleCategory(category.id)}}},[_vm._v(_vm._s(category.name))])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearCategoryFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{'filtered-category': _vm.categoryFilter.length > 0},staticStyle:{"width":"90px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v("Categories"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{attrs:{"id":row.id}},[_vm._v(_vm._s(_vm.showAllText.includes(row.id) ? row.categoryNames.join(", ") : _vm.truncateString(row.categoryNames.join(", "), 150)))]),((row.categoryNames.join(', ').length > 150) && !_vm.showAllText.includes(row.id))?_c('span',{staticClass:"elipsis",on:{"click":function($event){return _vm.showAllText.push(row.id)}}},[_vm._v("...")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Reorder","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('SendToPage',{attrs:{"id":row.id,"pageNumber":_vm.pageNumber,"totalPages":_vm.artAsset.totalPages},on:{"send":_vm.handleSendToPage}})]}}])}),_c('el-table-column',{attrs:{"width":"260","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v("Clear Filters")])]}},{key:"default",fn:function(scope){return [_c('a',{attrs:{"href":(_vm.uploadPath + "/" + (scope.row.imageUrl))}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"info"}},[_vm._v("Download")])],1),_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditArtAsset',
              params: {
                id: scope.row.id,
              }
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v(" Edit ")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(scope.row.id)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.artAsset.totalCount,"totalPages":_vm.artAsset.totalPages,"pageSize":_vm.artAsset.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }