var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.orderId)?_c('div',{staticClass:"history-tab-container app-container",staticStyle:{"padding-top":"0"}},[_c('h2',{staticClass:"margin:0"},[_vm._v("Order History")]),_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.auditLogs.totalCount,"label":'Items per page',"model":_vm.itemPerPage,"uniqueMinItem":5},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1)],1),_c('ElTableDraggable',{attrs:{"disable":true}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.auditLogs.items,"row-key":"id","fit":"","stripe":"","border":"","resizable":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"width":"170","prop":"dateTimeStamp","label":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleDate(row.dateTimeStamp)))])]}}],null,false,1232999082)}),_c('el-table-column',{attrs:{"min-width":"170","prop":"aspNetUserDisplayName","label":"User"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.aspNetUserDisplayName))])]}}],null,false,1503261470)}),_c('el-table-column',{attrs:{"min-width":"170"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.fields),function(field){return _c('li',{key:field,class:{
                    'selected-filter': _vm.selectedFields.includes(field),
                  },on:{"click":function($event){return _vm.handleFields(field)}}},[_vm._v(" "+_vm._s(field)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearFieldsFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedFields.length },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Field"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.field)+" ")])]}}],null,false,1891057548)}),_c('el-table-column',{attrs:{"min-width":"170","prop":"oldValue","label":"Old Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleValue(row.oldValue)))])]}}],null,false,797919944)}),_c('el-table-column',{attrs:{"min-width":"170","prop":"newValue","label":"New Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleValue(row.newValue)))])]}}],null,false,3016202835)})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.auditLogs.totalCount,"totalPages":_vm.auditLogs.totalPages,"pageSize":_vm.auditLogs.pageSize,"currentPage":_vm.pageNumber,"disableToTop":true},on:{"change":_vm.handlePageNumberClick}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }