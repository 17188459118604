

























































































































































































import {
  ColourAdminListViewModel,
  FabricAdminCreateModel,
  FabricAdminUpdateModel,
  FabricAdminViewModel,
  FabricColourAdminCreateModel,
  FabricColourAdminListViewModel,
  FabricColourAdminListViewModelPaginatedList,
  FabricColourAdminUpdateModel,
} from "@/api-client"
import { Component, Vue } from "vue-property-decorator"
import DialogBox from "@/components/DialogBox.vue"
import ImageUploader from "@/components/form-items/ImageUploader.vue"
import FileUploader from "@/components/form-items/FileUploader.vue"
import BasicInputField from "@/components/form-items/BasicInputField.vue"
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue"
import Pagination from '@/components/Pagination.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminFabricColours, AdminFabrics } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewFabric",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    FileUploader,
    Pagination,
    ItemsPerPage,
    BasicTooltipHeading
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  showBanner: boolean = true;
  fabric: FabricAdminViewModel = {
    id: "",
    name: "",
    code: "",
  };
  fabrics: Array<FabricAdminViewModel> = [];
  newFabric: FabricAdminCreateModel | FabricAdminUpdateModel = {
    name: '',
    code: ''
  };
  fileList: Array<any> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  fabricColours: FabricColourAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  fabricColourPageNumber = 1;
  fabricColourItemPerPage = 5;
  fabricColourItems: Array<FabricColourAdminListViewModel> = [];
  newFabricColour: FabricColourAdminCreateModel | FabricColourAdminUpdateModel = {
    fabricId: '',
    colourId: '',
    referenceCode: ''
  };
  selectedId = '';
  originalId = '';
  originalRef = '';
  fabricColourOptions: Array<ColourAdminListViewModel> = [];
  toggleOptionValue = false;
  loading = false
  fromPath = ''
  disable = false;

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    let toQuery = {
      fabricColourPageNumber: this.fabricColourPageNumber,
      fabricColourItemPerPage: this.fabricColourItemPerPage
    }
    this.setQueryWatch(toQuery)
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  handlefabricColourPageNumberClick(page: any) {
    this.fabricColourPageNumber = page
    this.loadFabricColours();
  }

  toggleOption(visible: boolean) {
    console.log('option', visible);
    
    this.toggleOptionValue = visible;
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if(isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>") {
        if(this.fromPath === 'Fabrics') {
          this.$router.back()
        } else {
          this.$router.push({ name: "Fabrics"});
        }
    } else if(this.confirmText === 'Yes') {
      this.deleteRow();
    } else {
      this.submitForm();
    }
  }

  editField(data: any) {
    if ((this.selectedId === data.id)
    && data.colourId !== ''
    && data.referenceCode !== '') {
      this.disable = false;
      this.saveFabricColour(data);
    } else {
      this.loadOptions(data.id);
      this.originalId = data.colourId;
      this.selectedId = data.id;
      this.originalRef = data.referenceCode;
      this.disable = true;
    }
  }

  addRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadOptions();

    if(this.fabricColourItems.length)
      this.fabricColourItems.map(item => {
        if(item.colourName === '' || item.referenceCode === '') {
          emptyName += 1;
        }
      })

      if(emptyName === 0) {
        this.fabricColourItems.push({
        id: tempId,
        fabricId: this.fabric.id,
        fabricName: this.fabric.name,
        colourId: '',
        colourName: '',
        referenceCode: ''
      });
      console.log('items', this.fabricColourItems);     
      this.originalId = '';
      this.originalRef = '';
      this.selectedId = tempId;
      this.disable = true;
    }
  }

  saveFabricColour(data: any) {
    if (data.colourId !== '' && data.referenceCode !== '') {
      this.loading = true
      let regex = /^(temp).*/g;
      if (!data.id.match(regex)) {
        this.newFabricColour = {
          fabricId: this.id,
          colourId: data.colourId,
          referenceCode: data.referenceCode
        };

        AdminFabricColours.adminFabricColoursIdPut(data.id, this.newFabricColour)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Put success", res);
              this.$message.success('Saved!')
              this.loadFabricColours();
              this.loadOptions();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
          });
      } else {
        this.newFabricColour = {
          fabricId: this.id,
          colourId: data.colourId,
          referenceCode: data.referenceCode
        };
        AdminFabricColours.adminFabricColoursPost(this.newFabricColour)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Post success", res);
              this.$message.success('Saved!')
              this.loadFabricColours();
              this.loadOptions();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      }
      this.selectedId = "";
      this.disable = false;
    }
  }

  confirmDelete(data: any) {
    this.dialogMessage = "<span><stong>Are you sure you want to delete this fabric colour?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if ((this.selectedId === data.id) && !this.selectedId.match(regex)) {
      data.colourId = this.originalId;
      data.referenceCode = this.originalRef;
      this.selectedId = "";
      this.disable = false;
    } else {
      console.log('in delete', data);
      
      this.selectedId = data.id;
      this.dialogVisible = true;
    }
    this.loadOptions()
  }

  deleteRow() {
    let regex = /^(temp).*/g;
    let index = this.fabricColourItems.length -1;
    
    if (this.selectedId.match(regex)) {
      this.fabricColourItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.fabricColourItems.splice(index, 1);
    } else {
      this.loading = true
      AdminFabricColours.adminFabricColoursIdDelete(this.selectedId)
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.info('Item deleted.')
          this.loadFabricColours();
          this.loadOptions();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
        });
    }
    this.selectedId = "";
    this.disable = false;
    this.originalId = "";
    this.originalRef = ""
    this.dialogVisible = false;
  }

  submitForm() {
    this.loading = true
    if (this.fabric.id) {
      console.log("before save", this.newFabric);

      AdminFabrics.adminFabricsIdPut(this.fabric.id, this.newFabric)
        .then((res) => {
          if(res.data.succeeded) {
            console.log("success?", res);
          this.$message.success('Saved!')
          this.dialogVisible = false;
          this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        });
    } else {
      AdminFabrics.adminFabricsPost(this.newFabric)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success('Saved!')
            this.dialogVisible = false;
            this.id = res.data.resultData!.id;
            this.$router.replace({ name: "EditFabric", params: {id: this.id} });
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
          }
          this.loading = false
        })
        .catch((error) => {
          this.dialogVisible = false;
          this.loading = false
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );              
        });
    }
  }

  loadOptions(fabricColourId: string | undefined = undefined) {
    this.loading = true
    AdminFabricColours.adminFabricColoursGetColoursForFabricGet(this.id, fabricColourId)
      .then((res) => {
        if (res.data.succeeded) {
          console.log('get fabs for cols', res);
          this.fabricColourOptions = res.data.resultData as Array<ColourAdminListViewModel>
        }
        this.loading = false
      })
      .catch((error) => {
          this.dialogVisible = false;
          this.loading = false
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );              
        });
  }

  async loadFabricColours() {
    this.loading = true
    await AdminFabricColours.adminFabricColoursGet(this.fabricColourPageNumber, this.fabricColourItemPerPage, this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.fabricColours = res.data.resultData as FabricColourAdminListViewModelPaginatedList;
          this.fabricColourItems = this.fabricColours.items;
          this.newFabricColour = {
            fabricId: this.id,
            colourId: '',
            referenceCode: ''
          }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false; 
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
                
      })
  }

  async loadInitialData() {
    if (this.id) {
      
      this.loadOptions();

      this.loadFabricColours()
      this.loading = true

      await AdminFabrics.adminFabricsIdGet(this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.fabric = res.data.resultData as FabricAdminViewModel;
        this.name = this.fabric.name;
        this.newFabric = (({ id, ...rest }) => rest)(this.fabric) as any;
        console.log("new fabric", this.newFabric);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
        this.dialogVisible = false;      
      })
    }
    this.loading = false
  }
}
