/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'users': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M64,69C30.69,69,3.3,94.86,0,128H128C124.7,94.86,97.31,69,64,69Z"/><ellipse cx="64" cy="31.5" rx="31.66" ry="31.5"/>'
  }
})







