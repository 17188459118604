





































































import { Component, Vue } from 'vue-property-decorator'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";


const AppProps = Vue.extend({
  props: {
    documents: {
      default: [],
      type: Array,
    }
  },
});

@Component({
  name: 'AttachmentsTab',

  components: { ElTableDraggable, Pagination, DialogBox, ItemsPerPage, BasicInputField, ImageUploader, BasicTooltipHeading },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends AppProps {
  selectedItemIndex:any = null
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this attachment?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedImageId = ''
  loading = false
  selection: Array<any> = []

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  setFallbackImage({target}) {
    if(target) {
      target.src = '/img/fallback-file-image.png'
    }
  }

  confirmDelete(index:any) {
    this.selectedItemIndex = index;
    console.log(this.selectedItemIndex);
    
    this.dialogVisible = true;
  }

  deleteItem() {
    if(this.selectedItemIndex >= 0) {
      this.documents.splice(this.selectedItemIndex, 1)
    }
    console.log(this.documents);
    
    this.dialogVisible = false
    this.selectedItemIndex = null
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(`${this.uploadPath}/${path.documentUrl}`)
    this.$message.success('Path copied.')
  }

  // download(doc:OrderDocumentViewModel) {
  //   window.open(`${this.uploadPath}/${doc.documentUrl}`);
  // }

  // downloadAll() {

  //   // handle request resupply
  // }

  handleAttachmentName(attachment:any) {
    let arr = attachment.split('/');
    return arr[arr.length - 1];
  }
}
