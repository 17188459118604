import { render, staticRenderFns } from "./OrderTaskList.vue?vue&type=template&id=fb9a26c4&scoped=true&"
import script from "./OrderTaskList.vue?vue&type=script&lang=ts&"
export * from "./OrderTaskList.vue?vue&type=script&lang=ts&"
import style0 from "./OrderTaskList.vue?vue&type=style&index=0&id=fb9a26c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb9a26c4",
  null
  
)

export default component.exports