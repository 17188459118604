





































import { Component, Vue } from "vue-property-decorator";

const AppProps = Vue.extend({
  props: {
    totalItems: {default: 0},
    pageSize: {default: 10},
    currentPage: {default: 1},
    totalPages: {type: Number},
    showScrollToTop: {default: true},
    disableToTop: {default: false},
  },
})
@Component({
  name: "Pagination",
})
export default class extends AppProps {
  goToPage = 1;

  pageChange(page: number) {
    if(page != this.currentPage) {
      this.$emit('change', page)  
      if(!this.disableToTop) {
        this.scrollToTop()
      }
    }   
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
