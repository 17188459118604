export function formatDate(date:any) {
    function appendLeadingZeroes(n:any){
      if(n <= 9){
        return "0" + n;
      }
      return n
    }

    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateToFormat = new Date(date)
    const formattedDate = appendLeadingZeroes(dateToFormat.getDate()) + " " + months[dateToFormat.getMonth()] + " " + dateToFormat.getFullYear()
    return formattedDate;
  }