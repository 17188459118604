






































































import {
  ExtraAdminListViewModel,
  ExtraGroupAdminCreateModel,
  ExtraGroupAdminListViewModel,
  ExtraGroupAdminUpdateModel,
  ExtraGroupAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from "@/utils/sortAscending";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminExtraGroups, AdminExtras } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewExtrasGroup",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  extraGroup: ExtraGroupAdminViewModel = {
    id: "",
    name: "",
    code: "",
    extraIds: [],
    isDisabled: true,
    isSystemManaged: false,
  };
  extras: Array<ExtraAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  newExtraGroup: ExtraGroupAdminCreateModel | ExtraGroupAdminUpdateModel = {
    name: "",
    code: "",
    extraIds: [],
    isDisabled: true,
  };
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("extras", () => {
      if (this.newExtraGroup.extraIds.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedExtras() {
    return sortAscending(this.extras, true, "code");
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Extras') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Extras"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true;
    if (this.extraGroup.id) {
      console.log("before save", this.newExtraGroup);

      AdminExtraGroups.adminExtraGroupsIdPut(
        this.extraGroup.id,
        this.newExtraGroup
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        }); 
        });
    } else {
      AdminExtraGroups.adminExtraGroupsPost(this.newExtraGroup)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.$router.push({ name: "Extras" });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        }); 
        });
    }
  }

  async loadExtras(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminExtras.adminExtrasGet(1, 99999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.extras = res.data.resultData!
            .items as Array<ExtraAdminListViewModel>;
          if (refresh) {
            this.loading = false;
            this.$message.success("Section Refreshed.");
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });       
      });
  }

  async loadInitialData() {
    this.loading = true;

    await this.loadExtras()
    
    if (this.id) {
      await AdminExtraGroups.adminExtraGroupsIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.extraGroup = res.data
              .resultData as ExtraGroupAdminListViewModel;
            this.name = this.extraGroup.name;
            this.newExtraGroup = (({ id, ...rest }) => rest)(
              this.extraGroup
            ) as any;
            console.log("new extra", this.newExtraGroup);
          }
        })
        .catch((error) => {
          this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        }); 
        });
    }
    this.loading = false;
  }
}
