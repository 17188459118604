// import { Profile } from "@/network/api";
import { Store as VuexStore, Module, GetterTree, MutationTree } from "vuex";
import store, { RootState } from "..";
import { getUserInfo } from '@/api/users'
import { AdminUsers } from "@/network/api";
import router from '@/router'

interface Profile {
  name: string;
}

export type UserState = {
  profile: string | null;
  userId: string;
  role: Array<string>;
  displayName: string;
};

export enum MutationTypes {
  SetUserProfile = "SET_UserProfile",
  SetUserId = "SET_UserId",
  SetRole = "SET_Role",
  SetDisplayName = "SET_DisplayName"
}

export const state: UserState = {
  profile: null,
  role: [],
  userId: '',
  displayName: '',
};

type Getters = {
  profile(state: UserState): string | null;
  userId(state: UserState): string;
  role(state: UserState): Array<string>;
  displayName(state: UserState): string;
};

export const getters: GetterTree<UserState, RootState> & Getters = {
  profile(state) {
    if (state.profile != null) {
      return state.profile;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user_new"] == null) {
      return null;
    }
    const profile = store["user_new"]["profile"];
    if (profile != null) {
      state.profile = profile;
      return profile;
    }

    return null;
  },
  userId(state) {
    if (state.userId != '') {
      return state.userId;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user_new"] == null) {
      return null;
    }
    const userId = store["user_new"]["userId"];
    if (userId != '') {
      state.userId = userId;
      return userId;
    }

    return '';
  },
  role(state) {
    if (state.role && state.role.length) {
      return state.role;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user_new"] == null) {
      return null;
    }
    const role = store["user_new"]["role"];
    if (role && role.length) {
      state.role = role;
      return role;
    }

    return [];
  },
  displayName(state) {
    if (state.displayName != '') {
      return state.displayName;
    }
    const vuex = localStorage.getItem("vuex");
    const store = JSON.parse(vuex!);
    if (store == null) {
      return null;
    }
    if (store["user_new"] == null) {
      return null;
    }
    const displayName = store["user_new"]["displayName"];
    if (displayName != '') {
      state.displayName = displayName;
      return displayName;
    }

    return '';
  },
};

type Mutations = {
  [MutationTypes.SetUserProfile](state: UserState, userProfile: any): void;
  [MutationTypes.SetUserId](state: UserState, userId: any): void;
  [MutationTypes.SetRole](state: UserState, role: any): void;
  [MutationTypes.SetDisplayName](state: UserState, role: any): void;
};

export const mutations: MutationTree<UserState> & Mutations = {
  [MutationTypes.SetUserProfile](state, profile) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user_new"]) {
      store["user_new"] = {};
    }
    store["user_new"]["profile"] = profile;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.profile = profile;
  },
  [MutationTypes.SetUserId](state, userId) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user_new"]) {
      store["user_new"] = {};
    }
    store["user_new"]["userId"] = userId;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.userId = userId;
  },
  [MutationTypes.SetRole](state, role) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user_new"]) {
      store["user_new"] = {};
    }
    store["user_new"]["role"] = role;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.role = role;
  },
  [MutationTypes.SetDisplayName](state, displayName) {
    const vuex = localStorage.getItem("vuex");
    let store = JSON.parse(vuex!);
    if (!store) {
      store = {};
    }
    if (!store["user_new"]) {
      store["user_new"] = {};
    }
    store["user_new"]["displayName"] = displayName;
    localStorage.setItem("vuex", JSON.stringify({ ...store }));
    state.displayName = displayName;
  }
};

async function getDisplayName(context:any, id:string) {
  await AdminUsers.adminUsersIdGet(id)
  .then(res => {
    debugger
    if(res.data.succeeded) {
      context.commit(MutationTypes.SetDisplayName, res.data.resultData?.displayName);
    }
  })
}

export const UserModule: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions: {
    getUserInfo: async (context, params) => {
      // let token = store.getters["auth/token"]
      // debugger
      const data = await getUserInfo( params.token )

      if (!data) {
        throw Error('Verification failed, please Login again.')
      }

      let route = router.currentRoute

      if(!((route.path === '/login') && route.query.redirect.includes('/orderpack/approval/'))) {
        if (!data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].length <= 0) {
          throw Error('GetUserInfo: roles must be a non-null array!')
        }
      }

      let id = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
      let role = data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      let name = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']

      if(typeof role == 'string') {
        role = [role]
      }
      
      context.commit(MutationTypes.SetUserId, id);
      context.commit(MutationTypes.SetRole, role);
      context.commit(MutationTypes.SetDisplayName, name);
    },
    clearProfile: (context, params) => {
      context.commit(MutationTypes.SetUserProfile, null);
      context.commit(MutationTypes.SetUserId, '');
      context.commit(MutationTypes.SetRole, []);
      context.commit(MutationTypes.SetDisplayName, '');
    },
  },
  namespaced: true,
};
