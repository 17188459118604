



























































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminMedias, AdminOrderItemAssets } from '@/network/api'
import {
  OrderItemAssetAdditionalFileViewModel,
  OrderItemAssetAdminListViewModel,
  OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData,
  OrderItemAssetAdminViewModel,
  OrderItemAssetStatus,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import globalAxios from "axios";
import { formatDate } from "@/utils/formatDate"

import {
  extend,
  configure,
} from "vee-validate";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;


const AppProps = Vue.extend({
  props: {
    itemId: {
      type: String,
      default: '',
    },
    order: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: 'AssetsTab',

  components: { ElTableDraggable, Pagination, DialogBox, ItemsPerPage, BasicInputField, ImageUploader, BasicTooltipHeading },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'danger',
        true: 'success'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends AppProps {
  search: string | undefined = this.$route.params.search || '';
  selectedImageId = ''
  pageNumber = 1;
  itemPerPage = 5;
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this asset?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  debounce: any = null;
  loading = false
  assets: OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  allAssets: Array<OrderItemAssetAdminListViewModel> = []
  orderItemAssetStatusOptions: Array<any> = [
    {
      name: 'Not Checked',
      id: 'NotChecked'
    },
    {
      name: 'Required',
      id: 'Required'
    },
    {
      name: 'Requested',
      id: 'Requested'
    },
    {
      name: 'Ordered',
      id: 'Ordered'
    },
    {
      name: 'Ready',
      id: 'Ready'
    },
    {
      name: 'Customer Approved As Is',
      id: 'CustomerApprovedAsIs'
    },
    {
      name: 'Customer Redraw Requested',
      id: 'CustomerRedrawRequested'
    },
    {
      name: 'Customer File Provided (Not Checked)',
      id: 'CustomerFileProvidedNotChecked'
    },
    {
      name: 'Customer File Provided (Checked)',
      id: 'CustomerFileProvidedChecked'
    }

  ]
  selection: Array<any> = []
  currentRow:OrderItemAssetAdminListViewModel | OrderItemAssetAdditionalFileViewModel = {
    id:	'',
    orderItemId:	'',
    orderItemAssetStatusId:	OrderItemAssetStatus.NotChecked,
    notes:	'',
    customizationId:	'',
    customizationName:	'',
    fileUrl:	'',
    additionalFiles: []
  }
  imageAwaitingUpload = false
  showAllText: Array<string> = [];
  bgIsDark = false
  currentStatus: number = STATUS_INITIAL;
  uploadedAssets: any = [];
  uploadError: any;
  fileCount:any;

  get isInitial() {
    return this.currentStatus === STATUS_INITIAL;
  }
  get isSaving() {
    return this.currentStatus === STATUS_SAVING;
  }
  get isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  get isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  created() {
    if(this.itemId) {
      this.loadInitialData().then(() => {
        this.styleAdditionalRows()
      })
    }

    this.$watch('itemId', () => {
      this.loadInitialData()
      this.selection = []
    })

    this.$watch('search', () => {
      console.log(this.search);
      
      this.pageNumber = 1
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadInitialData()
        .then(() => {
          document.getElementById("search")!.focus();
        })
      }, 400)
    })

    let self = this;
    extend("asset", {
      validate(value) {
        let uploader = self.$refs.assetUploader as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get isDisabled() {
    let required = 0
    if(this.allAssets.length && this.order) {
      this.allAssets.forEach((asset:OrderItemAssetAdminListViewModel) => {
        console.log(asset.orderItemAssetStatusId, this.order.orderStatusId);
        if((asset.orderItemAssetStatusId === 'Required') && (this.order.orderStatusId === 'SignOffCustomerFiles')) {
          required += 1
        }
      })
    }

    if(required > 0) {
      return false
    }
    return true
  }

  handleDate(date:any) {
    return formatDate(date);
  }

  async filesChange(fileList: any, asset: OrderItemAssetAdminListViewModel) {
    let assetToSave = {...asset}
    let files = [...fileList]
    if (!files.length) return;

    await Promise.all(files.map(async (file: any) => {
      const formData = new FormData();
      formData.append("file", file);
      let path = await this.save(formData) as any;

      if(path) {
        this.uploadedAssets.push({fileUrl: path})
      }
    }))
    .then(async () => {
      assetToSave.additionalFiles = [...assetToSave.additionalFiles, ...this.uploadedAssets]
      await this.saveAsset(assetToSave)
    })
  }

  async save(formData: any) {
    return new Promise<void>((resolve, reject) => {
    // upload data to the server
    this.currentStatus = STATUS_SAVING;

    this.upload(formData)
      .then((x: any) => {
        this.currentStatus = STATUS_INITIAL;
        resolve(x)
      })
      .catch((err: any) => {
        reject(err)
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
    })
  }

  async upload(formData: any) {
    const url = `${this.uploadPath}`;
    return globalAxios
      .post(url, formData)
      .then((x: any) => x.data)
      .then((img: any) => img.relativePath);
  }

  truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num)
  }

  handleAssetName(attachment:any) {
    let arr = attachment.split('/');
    return arr[arr.length - 1];
  }

  handleStatusName(id: string) {
    if (this.orderItemAssetStatusOptions.length) {
      let match = this.orderItemAssetStatusOptions.find((order) => id == order.id);
      if (match) {
        return match.name;
      }
    }
    return id;
  }

  setFallbackImage({target}) {
    if(target) {
      target.src = '/img/fallback-file-image.png'
    }
  }

  copyLinkPath() {
    let link = `${process.env.VUE_APP_ROOT_WEB}/resupply-assets/${this.order.id}`;
    navigator.clipboard.writeText(`${link}`)
    this.$message.success('Path copied.')
  }

  styleAdditionalRows() {
    let additionalRows = document.querySelectorAll(`.el-table__row--level-1`) as any;
    if(additionalRows.length) {
      additionalRows.forEach((row:any) => {
        row.children[0].style.backgroundColor = 'rgb(230, 230, 230)';
      })
    }
  }

  saveEditDisabled(row:any) {
    if((this.currentRow.id == row.id) && !this.imageAwaitingUpload)
    if(!this.currentRow.fileUrl) {
      return true
    }
    return false
  }

  get areAllSelected() {
    let unselected = 0;
    this.assets.items.forEach(item => {
      if(!this.selection.includes(item.id)) {
        unselected += 1
      }   
    });

    return unselected === 0 ? true : false
    
  }

  imageCheck (field:any) {
    const assetField = this.$refs[field] as any;

    // Validate the field
    if(assetField) {
      this.imageAwaitingUpload = true
    }     
  }

  isParent(row:any) {
    if(row) {
      let child = Object.prototype.hasOwnProperty.call(row, "additionalFiles");
      return child;
    }
    return true
  }

  toggleExpand(row:any) {
    let refs = this.$refs as any;
    let table = refs.table
    table.toggleRowExpansion(row, true)
  }

  // addAdditionalRow(row:OrderItemAssetAdminViewModel) {
  //   let tempId = "temp" + Math.floor(Math.random() * 99999);
  //   let newRow = {
  //     id: tempId,
  //     fileUrl: ''
  //   } as OrderItemAssetAdditionalFileViewModel
  //   let empty = [] as Array<any>
  //   if(row.additionalFiles.length) {
  //     row.additionalFiles.find((item:any) => {
  //       if(item.fileUrl === '') {
  //         empty.push(item)
  //       }
  //     })
  //   }

  //   if(!empty.length) {
  //     new Promise((resolve, reject):void => {
  //       row.additionalFiles.push(newRow)
  //       resolve(true)
  //     }).then(() => {
  //       this.toggleExpand(row)
  //       this.styleAdditionalRows()
  //     })

  //     // row.additionalFiles.push({ ...newRow })
  //     // this.toggleExpand(newRow)
  //     this.editSaveRow(newRow)
      
  //   }

    
  //   // this.toggleExpand(row)
  // }

  uploadImages(): Promise<any> {
    let assetUploader = this.$refs.assetUploader as ImageUploader;

    let assetPromise;

    if (assetUploader) {
      assetPromise = assetUploader!.uploadImage();
    }

    return Promise.all([assetPromise]);
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(`${this.uploadPath}/${path.fileUrl}`)
    this.$message.success('Path copied.')
  }

  removeImage(area: string) {
    if (area == "asset") {
      this.currentRow.fileUrl = "";
    }
  }

  handleSelectAll() {
    if(!this.areAllSelected) {
      this.assets.items.forEach(order => {
        if(!this.selection.includes(order.id)) {
          this.selection.push(order.id);
        }   
      });  
    } else {
      this.assets.items.forEach(order => {
        this.selection = this.selection.filter(item => item !== order.id) 
      });
    }
    
    console.log(this.selection);
  }

  handleSelect(id: any) {
    if(this.selection.includes(id)) {
      this.selection = this.selection.filter(item => item !== id)
    } else {
      this.selection.push(id)
    }
  }

  editSaveRow(row: OrderItemAssetAdminListViewModel | OrderItemAssetAdditionalFileViewModel) {
    if(this.currentRow.id == row.id) {
      this.loading = true;

      this.uploadImages().then((results) => {
        if (results[0].imageUrl) {
          this.currentRow.fileUrl = results[0].imageUrl;
        }

        this.prepareAsset(this.currentRow as OrderItemAssetAdminListViewModel, 'save');
      })
      this.loading = false
    } else {
      this.currentRow = {...row};
      this.imageAwaitingUpload = false
    }
  }

  requestResupply() {
    if (this.order.orderStatusId === 'SignOffCustomerFiles') {
      let isDirty = document.querySelectorAll(".dirty");
      debugger
      if (isDirty.length > 0) {
        this.$message({showClose: true, type: 'error', duration: 0, message: 'Please save your changes before sending an asset resupply request.'})
      } else {
        this.loading = true
        AdminOrderItemAssets.adminOrderItemAssetsSendOrderItemAssetResupplyEmailIdPost(this.order.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.success('Asset resupply email sent!');
            this.$emit('reload')
            this.loadInitialData()
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      }
    }
  }

  bulkSelectionStatus(status: OrderItemAssetStatus) {
    let currentAsset:any = {}
    this.selection.forEach((id:string, index) => {
      currentAsset = this.allAssets.find(item => item.id === id)
      currentAsset.orderItemAssetStatusId = OrderItemAssetStatus[status]

      this.saveAsset(currentAsset, undefined, index)
    })
  }

  prepareAsset(asset: OrderItemAssetAdminListViewModel, type: 'save' | 'delete' = 'save') {
    if(asset.id) {
      this.loading = true
      let assetToSave = {...asset};
      
      // Weird stuff to configure additional files within an asset to save properly
      if(!this.isParent(asset)) {
        this.assets.items.forEach((parent:OrderItemAssetAdminListViewModel) => {
          if(parent.additionalFiles.length) {
            parent.additionalFiles.forEach((file:any, index:any) => {
              if(file.id === asset.id) {

                if(type === 'save') {
                  file.fileUrl = asset.fileUrl

                  let regex = /^(temp).*/g;
                  if(regex.test(file.id)) {
                    delete file.id
                  }
                  assetToSave = parent
                  this.saveAsset(assetToSave)

                }

                if(type === 'delete') {
                  parent.additionalFiles.splice(index, 1)
                  assetToSave = parent
                  this.saveAsset(assetToSave, 'delete')
                }           
              }
            })
          }
        })
      } else {
        this.saveAsset(assetToSave)
      }
      this.loading = false
    }
  }


  async saveAsset(asset:OrderItemAssetAdminListViewModel, type: undefined | 'delete' = undefined, index: number | null = null) {
    this.loading = true
    await AdminOrderItemAssets.adminOrderItemAssetsIdPut(asset.id, asset)
      .then((res) => {
        if(res.data.succeeded) {
          console.log("success?", res);
          if(type === 'delete') {
            this.$message.info("Item deleted.") 
          } else {
            if(index == null || (index !== null && index == 0)) {
              this.$message.success("Saved!");
            }
          }
          this.imageAwaitingUpload = false
          this.loadInitialData()
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }

  cancelEdit() {
    let regex = /^(temp).*/g;
    let regexMatch = regex.test(this.currentRow.id)
    if(regexMatch && !this.isParent(this.currentRow) && !this.currentRow.fileUrl) {
        this.assets.items.forEach((asset:OrderItemAssetAdminListViewModel) => {
          if(asset.additionalFiles.length) {
            asset.additionalFiles.forEach((file:OrderItemAssetAdditionalFileViewModel, index:any) => {
              if(file.id === this.currentRow.id) {
                file.fileUrl = this.currentRow.fileUrl
                asset.additionalFiles.splice(index, 1)
              }
            })
          }
        })
      }

        this.currentRow = {
          id:	'',
          orderItemId:	'',
          orderItemAssetStatusId:	OrderItemAssetStatus.NotChecked,
          notes:	'',
          customizationId:	'',
          customizationName:	'',
          fileUrl:	'',
          additionalFiles: [],
          isFromLibrary: false
        } as OrderItemAssetAdminListViewModel
        this.imageAwaitingUpload = false
  }

  async loadInitialData() {
    this.uploadedAssets = []
    if(this.itemId) {
    this.loading = true

      await AdminOrderItemAssets.adminOrderItemAssetsGet(this.pageNumber, this.itemPerPage, this.itemId)
      .then((res) => {
        if (res.data.succeeded) {
          this.assets = res.data.resultData as OrderItemAssetAdminListViewModelPaginatedListApplicationResultResultData;
          this.cancelEdit();
          setTimeout(() => {
            this.styleAdditionalRows()
          }, 500);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

      await AdminOrderItemAssets.adminOrderItemAssetsGet(1, 999999999, this.itemId)
      .then((res) => {
        if (res.data.succeeded) {
          this.allAssets = res.data.resultData!.items;
          this.cancelEdit();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }
  }
}
