var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"blog-post-list-container"},[_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{name: 'NewBlogPost'}}},[_c('button',{staticClass:"new-post",attrs:{"disabled":_vm.loading}},[_vm._v("New Blog Post")])])],1),_c('el-col',{staticStyle:{"margin-top":"15px"},attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.blogPost.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
          _vm.itemPerPage = value;
          _vm.loadInitialData();
        },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"searchSlug","size":"mini","placeholder":"Search url","clearable":""},on:{"clear":function($event){return _vm.handleSearch('searchSlug')}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch('searchSlug')}},model:{value:(_vm.slugSearch),callback:function ($$v) {_vm.slugSearch=$$v},expression:"slugSearch"}}),_c('el-input',{attrs:{"id":"searchName","size":"mini","placeholder":"Search name","clearable":"","disabled":_vm.loading},on:{"clear":function($event){return _vm.handleSearch('searchName')}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch('searchName')}},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1)])],1),_c('ElTableDraggable',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.blogPostItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"prop":"publishedDate","label":"Published Date","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleDate(row.publishedDate)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"250","prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.sortedCategories),function(category){return _c('li',{key:category.id,class:{'selected-filter': _vm.selectedBlogCategories.includes(category.id)},on:{"click":function($event){return _vm.handleCategory(category.id)}}},[_vm._v(_vm._s(category.name))])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearCategoryFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{'filtered-category': _vm.selectedBlogCategories.length > 0},staticStyle:{"width":"90px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v("Categories"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',{attrs:{"id":row.id}},[_vm._v(_vm._s(_vm.showAllText.includes(row.id) ? row.categories.join(", ") : _vm.truncateString(row.categories.join(", "), 90)))]),((row.categories.join(', ').length > 90) && !_vm.showAllText.includes(row.id))?_c('span',{staticClass:"elipsis",on:{"click":function($event){return _vm.showAllText.push(row.id)}}},[_vm._v("...")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"250","prop":"slug","label":"Url"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.slug))])]}}])}),_c('el-table-column',{attrs:{"width":130},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},[_c('li',{class:{'selected-filter': _vm.selectedStatusFilter === 'Published'},on:{"click":function($event){return _vm.handleStatus('Published')}}},[_vm._v("Published")]),_c('li',{class:{'selected-filter': _vm.selectedStatusFilter === 'Not Published'},on:{"click":function($event){return _vm.handleStatus('Not Published')}}},[_vm._v("Not Published")])])]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{'filtered-category': _vm.selectedStatusFilter},staticStyle:{"width":"60px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v("Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(_vm.isPublished(row.publishedDate))}},[_vm._v(" "+_vm._s(_vm.isPublished(row.publishedDate) ? "Published" : "Not Published")+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"170"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v("Clear Filters")])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditBlogPost',
              params: {
                id: row.id,
              }
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v(" Edit ")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row.id)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.blogPost.totalCount,"totalPages":_vm.blogPost.totalPages,"pageSize":_vm.blogPost.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }