












































import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import { email } from "vee-validate/dist/rules";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminGeneralSettings, GeneralSettings } from "@/network/api";

@Component({
  name: "GeneralSettings",
  components: { DialogBox, ValidationProvider, ValidationObserver, BasicInputField },
})
export default class extends Vue {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  keys: Array<any> = [
    { key: 'tel', value: '' },
    { key: 'email', value: '' },
    { key: 'newsletterTitle', value: '' },
    { key: 'newsletterSubtitle', value: '' },
    { key: 'newsletterTnCCopy', value: '' },
    { key: 'newsletterMailingId', value: '' },
    // { key: 'EftBank', value: '' },
    // { key: 'EftAccountNumber', value: '' },
    // { key: 'EftBranch', value: '' },
  ]

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();
    
    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("email", {
      ...email,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    this.submitForm();
  }

  submitForm() {
    this.loading = true
    const keyValues: any = {}
    this.keys.forEach((item:any, index: number) => {
      keyValues[item.key] = item.value || null
    })
    AdminGeneralSettings.adminGeneralSettingsSetGeneralSettingsPost(keyValues)
      .then((res) => {
        console.log("success?", res);
        if (res) {
          this.loading = false
          this.$message.success('Saved!')
          this.dialogVisible = false
          this.loadInitialData()
        }
      })
      .catch((error) => {
        this.loading = false
        if(error.response) {
          error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          )
        }
        else {
          console.error(error)
        }
        this.dialogVisible = false
      });
  }

  loadInitialData() {
    this.loading = true
    const keyParams: Array<string> = this.keys.map((item:any) => { return item.key })
    AdminGeneralSettings.adminGeneralSettingsGetGeneralSettingsGet(keyParams)
      .then((res) => {
        if (res.data.succeeded) {
          Object.entries(res.data.resultData as Object).forEach((val) => {
            const keyIndex = this.keys.findIndex((item, index) => item.key === val[0])
            if(keyIndex > -1) {
              this.keys[keyIndex].value = val[1]
            }
          })
          console.log('let set', res.data);   
          this.loading = false      
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }
}
