
























































































































































































































import {
  CarouselItemAdminCreateModel,
  CarouselItemAdminUpdateModel,
  CarouselItemAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import ColourSuggestions from "@/components/misc/ColourSuggestions.vue";
import { editorToolbar } from "@/utils/editorToolbar";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminCarouselItems } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: { default: "" },
  },
});
@Component({
  name: "NewSlide",
  components: {
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    ColourSuggestions,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  id: string = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  pattern: Array<any> = [];
  slide: CarouselItemAdminViewModel = {
    id: "",
    title: "",
    titleFontColour: "",
    content: "",
    contentFontColour: "",
    buttonUrl: "",
    buttonText: "",
    buttonFontColour: "",
    buttonBackgroundColour: "",
    backgroundColour: "",
    backgroundImageUrl: "",
    featuredImageUrl: "",
    section: "",
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newSlide: CarouselItemAdminCreateModel | CarouselItemAdminUpdateModel = {
    title: "",
    titleFontColour: "#00233B",
    content: "",
    contentFontColour: "#00233B",
    buttonUrl: "",
    buttonText: "",
    buttonFontColour: "#FFFFFF",
    buttonBackgroundColour: "#00233B",
    backgroundColour: "#00233B",
    backgroundImageUrl: "",
    featuredImageUrl: "",
    section: "",
  };
  sectionOptions: Array<any> = [
    { name: "Carousel 1", id: "Carousel 1" },
    { name: "Carousel 2", id: "Carousel 2" },
  ];
  loading = false;
  dirtyTimer: any = null;
  fromPath = "";
  mavenToolbar = editorToolbar;
  dirtyMavonTimer: any = null

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then((res) => {
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses();
      }, 1000);
    }); 

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("link", (value) => {
      if (!this.newSlide.buttonText) {
        return true;
      } else {
        return value.length;
      }
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField(field: any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if (provider) {
      return provider!.validate();
    }
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll(".dirty");

    if (mavonDirty.length) {
      for (let item of mavonDirty) {
        item.classList.remove("dirty");
      }
      // this.hasDirty = false
    }
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(path);
    this.$message.success("Value copied.");
  }

  uploadImages(): Promise<any> {
    let slideFeaturedUploader = this.$refs
      .slideFeaturedUploader as ImageUploader;
    let slideBackgroundUploader = this.$refs
      .slideBackgroundUploader as ImageUploader;

    let featuredPromise;
    let backgroundPromise;

    if (slideFeaturedUploader) {
      featuredPromise = slideFeaturedUploader.uploadImage();
    }

    if (slideBackgroundUploader) {
      backgroundPromise = slideBackgroundUploader.uploadImage();
    }

    return Promise.all([backgroundPromise, featuredPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "backgroundImage") {
      this.newSlide.backgroundImageUrl = null;
    } else if (area == "featuredImage") {
      this.newSlide.featuredImageUrl = null;
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if (this.fromPath === "HomePage") {
        this.$router.back();
      } else {
        this.$router.push({ name: "HomePage" });
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      console.log("result", results);

      if (results[0]?.imageUrl) {
        this.newSlide.backgroundImageUrl = results[0].imageUrl;
      }

      if (results[1]?.imageUrl) {
        this.newSlide.featuredImageUrl = results[1].imageUrl;
      }

      this.handleColours("remove");

      if (!this.newSlide.buttonText) {
        this.newSlide.buttonUrl = "";
      }

      if (this.slide.id) {
        console.log("before save", this.newSlide);

        AdminCarouselItems.adminCarouselItemsIdPut(this.slide.id, this.newSlide)
          .then((res) => {
            console.log("success?", res);
            this.loading = false;
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            });
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.$message.error(e.friendlyMessage);
              return e.friendlyMessage;
            });
            this.dialogVisible = false;
          });
      } else {
        AdminCarouselItems.adminCarouselItemsPost(
          this.newSlide as CarouselItemAdminCreateModel
        )
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.loading = false;
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.$router.push({ name: "HomePage" });
            }
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.$message.error(e.friendlyMessage);
              return e.friendlyMessage;
            });
            this.dialogVisible = false;
          });
      }
    });
  }

  async handleColours(type: "add" | "remove") {
    const colourVariables = [
      "titleFontColour",
      "contentFontColour",
      "buttonFontColour",
      "buttonBackgroundColour",
      "backgroundColour",
    ];

    colourVariables.forEach((colour) => {
      if (this.newSlide[colour]) {
        this.newSlide[colour] =
          type == "add"
            ? `#${this.newSlide[colour]}`
            : this.newSlide[colour].slice(1);
      }
    });
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true;
      await AdminCarouselItems.adminCarouselItemsIdGet(this.id)
        .then(async (res) => {
          if (res.data.succeeded) {
            this.slide = res.data.resultData as CarouselItemAdminViewModel;
            this.newSlide = (({ id, ...rest }) => rest)(this.slide) as any;
            console.log("new slide", this.newSlide);

            await this.handleColours("add");

            if (this.newSlide.backgroundImageUrl) {
              this.pattern = [
                {
                  imageUrl: this.newSlide.backgroundImageUrl,
                },
              ];
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage);
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
