
































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import OrderPack from "@/views/orders/OrderPack.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

const AppProps = Vue.extend({
  props: {
    id: {
      type: String,
      default: "",
    },
  },
});

@Component({
  name: "OrderPackApproval",
  components: {
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
    OrderPack,
  },
})
export default class extends AppProps {
}
