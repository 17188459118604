





































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminFabricChannels } from "@/network/api";
import {
  FabricChannelAdminListViewModelPaginatedList,
  FabricChannelAdminViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import DialogBox from "@/components/DialogBox.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: "FabricChannels",
  components: { ElTableDraggable, Pagination, DialogBox, SendToPage, ItemsPerPage },
  // filters: {
  //   statusFilter: (status: string) => {
  //     const statusMap: { [key: string]: string } = {
  //       false: "success",
  //       true: "danger",
  //     };
  //     return statusMap[status];
  //   },
  // },
})
export default class extends Vue {
  fabricChannels: FabricChannelAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  toPage: Array<number> = [1];
  fabricChannelItems: Array<FabricChannelAdminViewModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  search: string | undefined = this.$route.params.search || "";
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this fabric channel?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  selectedItem: FabricChannelAdminViewModel = {
    id: "",
    internalName: "",
    externalName: "",
    hasProductBuilderOptionsGenerationLogs: false,
    layerIds: []
  };
  debounce: any = null;
  // selectedStatusFilter = "";
  loading = false

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      // selectedStatusFilter: this.selectedStatusFilter
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch("search", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("search")!.focus();
    //     });
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleSendToPage(id:string, selectedPage:number, determinePage:number) {
    this.loading = true
    let patchInfo: any = {}
    let itemId = 0
    let newPageItems = [] as Array<any>   
    // const status = !this.selectedStatusFilter ? undefined : this.selectedStatusFilter === 'Inactive' ? true : false

    AdminFabricChannels.adminFabricChannelsGet(determinePage, this.itemPerPage, this.search || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!
            .items as Array<any>;

          if(selectedPage < this.pageNumber) {
            itemId = (selectedPage > 1) ? newPageItems.length - 1 : 0;
          }
          
          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false
          
          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {      
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });     
      });
  }

  clearStatusFilter() {
    // this.selectedStatusFilter = "";
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = "";
    this.clearStatusFilter();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  // handleStatus(selected: string) {
  //   if(selected === this.selectedStatusFilter) {
  //     this.selectedStatusFilter = ''
  //   } else {
  //     this.selectedStatusFilter = selected
  //   }
  //   this.pageNumber = 1
  //   this.loadInitialData();
  // }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminFabricChannels.adminFabricChannelsIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);         
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  async loadInitialData(page: number = this.pageNumber) {
    // const status = !this.selectedStatusFilter ? undefined : this.selectedStatusFilter === 'Inactive' ? true : false

    this.loading = true;
    await AdminFabricChannels.adminFabricChannelsGet(
      page,
      this.itemPerPage,
      this.search || undefined,
    )
      .then((res) => {
        if(res.data.succeeded) {
          this.fabricChannels = res.data
          .resultData as FabricChannelAdminListViewModelPaginatedList;
          this.fabricChannelItems = this.fabricChannels.items as Array<FabricChannelAdminViewModel>;
          console.log("fabric channel items", this.fabricChannelItems);
        }        
      })
      .catch((error) => {
        this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
      this.loading = false;
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    if (this.selectedItem.id) {
      this.loading = true;
      AdminFabricChannels.adminFabricChannelsIdDelete(this.selectedItem.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1;
            this.$message.info("Item deleted.");
            this.loadInitialData();
          }
          
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          
        });
    }
    this.dialogVisible = false;
    this.selectedItem = {
      id: "",
      internalName: "",
      externalName: "",
      hasProductBuilderOptionsGenerationLogs: false,
      layerIds: []
    };
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
