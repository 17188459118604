







































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminBlogCategories, AdminBlogs } from '@/network/api'
import {
  BlogCategoryAdminListViewModel,
  BlogCategoryAdminViewModel,
  BlogAdminListViewModel,
  BlogAdminListViewModelPaginatedList,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import { sortAscending } from '@/utils/sortAscending'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { formatDate } from "@/utils/formatDate"
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    reset: {default: false}
  }
})

@Component({
  name: 'BlogPostsList',
  components: { ElTableDraggable, Pagination, DialogBox, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'danger',
        true: 'success'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends AppProps {
  blogPost: BlogAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  blogPostItems: Array<BlogAdminListViewModel> = [];
  blogCategories: Array<BlogCategoryAdminListViewModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  nameSearch: string | undefined = this.$route.params.nameSearch || "";
  slugSearch: string | undefined = this.$route.params.slugSearch || "";
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this blog post?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedId = '';
  debounce: any = null;
  showAllText: Array<string> = [];
  selectedStatusFilter = '';
  selectedFeaturedFilter = '';
  isPublic?: boolean | undefined = true;
  selectedBlogCategories: Array<string> = [];
  loading = false

  created() {
    let toQuery = {
      nameSearch: this.nameSearch,
      slugSearch: this.slugSearch,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      selectedBlogCategories: this.selectedBlogCategories,
    }
    this.setQueryWatch(toQuery)

    const isPublicQuery = this.$route.query.isPublic
    if(isPublicQuery) {
      this.isPublic = isPublicQuery == 'true' ? true : false
      this.selectedStatusFilter = !this.isPublic ? 'Not Published' : 'Published'
    }

    this.$watch('isPublic', (val) => {
        handleQueries('isPublic', val)
    })

    if(!isPublicQuery) {
      this.isPublic = undefined
      this.selectedStatusFilter = ''
    }
    
    this.loadInitialData();
    this.loadCategories();

    // this.$watch('nameSearch', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("searchName")!.focus();
    //     });
    //   }, 400);
    // })

    // this.$watch('slugSearch', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("searchSlug")!.focus();
    //     });
    //   }, 400);
    // })
  
    this.$watch('reset', () => {
      this.clearAllFilters()
    })

    
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch(id:string = 'search') {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById(id)!.focus();
    }) 
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get sortedCategories() {
    return sortAscending(this.blogCategories)
  }

  isPublished(date:any) {
    let todayDate = new Date()
    let publishDate = new Date(date)
    let dateCheck = todayDate.getTime() > publishDate.getTime()
    if (dateCheck) {
      return true
    } else {
      return false
    }
  }

  handleStatus(selected: string) {
    if(selected === 'Published' && this.selectedStatusFilter !== 'Published') {  
      this.isPublic = true;
      this.selectedStatusFilter = 'Published'
    } else if(selected === 'Not Published' && this.selectedStatusFilter !== 'Not Published') {  
      this.isPublic = false;
      this.selectedStatusFilter = 'Not Published'
    } else {
      this.isPublic = undefined;  
      this.selectedStatusFilter = ''   
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleCategory(selected: string) {
    if(this.selectedBlogCategories.includes(selected)) {  
      this.selectedBlogCategories = this.selectedBlogCategories.filter(category => {
        return category !== selected
      })
    } else {
      if(typeof this.selectedBlogCategories == 'string') {
        const arr = [this.selectedBlogCategories, selected];
        this.selectedBlogCategories = arr;
      } else {
        this.selectedBlogCategories.push(selected);
      }
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num)
  }

  clearCategoryFilter() {
    this.pageNumber = 1
    this.selectedBlogCategories = [];
    this.loadInitialData();
  }

  clearStatusFilter() {
    this.pageNumber = 1
    this.selectedStatusFilter = '';
    this.isPublic = undefined;
    this.loadInitialData();
  }

  clearAllFilters() {
    this.slugSearch = '';
    this.nameSearch = ''
    this.selectedBlogCategories = [];
    this.clearStatusFilter()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  async loadInitialData() {
    this.loading = true
    await AdminBlogs.adminBlogsGet(this.pageNumber, this.itemPerPage, this.slugSearch || undefined, this.nameSearch || undefined, this.isPublic, undefined, this.selectedBlogCategories)
      .then((res) => {
        if(res.data.succeeded) {
          this.blogPost = res.data
          .resultData as BlogAdminListViewModelPaginatedList
        this.blogPostItems = this.blogPost
          .items as Array<BlogAdminListViewModel>
          console.log('blogPost items',this.blogPostItems);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
      })
  }

  loadCategories() {
    this.loading = true
    AdminBlogCategories.adminBlogCategoriesGet(1, 999999999)
    .then((res) => {
      if(res.data.succeeded) {
        this.blogCategories = res.data.resultData!.items as Array<BlogCategoryAdminViewModel>;
      console.log('categories', this.blogCategories);
      }
      this.loading = false      
    })
    .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
      })
  }

  confirmDelete(id: string) {
    this.dialogVisible = true
    this.selectedId = id
  }

  deleteItem() {
    if (this.selectedId) {
      this.loading = true
      AdminBlogs.adminBlogsIdDelete(this.selectedId)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        })
    }
    this.dialogVisible = false
    this.selectedId = ''
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
