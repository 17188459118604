









































import { Component, Vue } from "vue-property-decorator";
import { formatDate } from "@/utils/formatDate"

@Component({
  name: "DateRangeFilter",
  props: {
    name: {default: undefined},
    heading: {default: 'Date Range'},
    isSelected: {default: false},
    width: {default: 'auto'}
  }
})
export default class extends Vue {
  selectedDates: any = null
  pickerOptions= {
    firstDayOfWeek: 1,
    shortcuts: [{
      text: 'Last week',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last month',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last 3 months',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: 'Last 6 months',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 120);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: 'Last year',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit('pick', [start, end]);
      }
    },
    ]
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handleDateRanges(value:any) {    
    let dates = {};   
    if(value) {
      let d1 = new Date(value[0])
      let d2 = new Date(value[1])

      //if(d1.getTime() == d2.getTime()) {
        d2.setDate(d2.getDate() + 1);   
      //}

      dates = {
        startDate: new Date(d1).toISOString(),
        endDate: new Date(d2).toISOString()
      }
    } else {
      dates = {
        startDate: undefined,
        endDate: undefined
      };
    }  
    this.$emit('updateDate', dates)
  }
}
