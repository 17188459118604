








































































































































































import {
  BlogCategoryAdminListViewModel,
  BlogAdminCreateModel,
  BlogAdminUpdateModel,
  BlogAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from '@/utils/sortAscending'
import { editorToolbar } from '@/utils/editorToolbar'
import BasicDateField from "@/components/form-items/BasicDateField.vue";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminBlogCategories, AdminBlogs } from "@/network/api";

@Component({
  name: "NewBlogPost",
  components: { BasicSelectorField, BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField, BasicDateField },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  dirtyMavonTimer:any = null
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  headerImage: Array<any> = [];
  authorImage: Array<any> = [];
  showBanner: boolean = true;
  post: BlogAdminViewModel = {
    id: '',
    name: '',
    slug: '',
    isFeatured: true,
    previewImageUrl: '',
    headerImageUrl: '',
    content: '',
    categoryIds: [],
    authorName: '',
    authorImageUrl: '',
    publishedDate: ''
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  categories: Array<BlogCategoryAdminListViewModel> = [];
  newBlogPost: BlogAdminCreateModel | BlogAdminUpdateModel = {
    name: '',
    slug: '',
    isFeatured: true,
    previewImageUrl: '',
    headerImageUrl: '',
    content: '',
    categoryIds: [],
    authorName: '',
    authorImageUrl: '',
    publishedDate: ''
  };
  metaKeywordsArray: Array<string> = [];
  tagInputVisible: boolean = false;
  tagInputValue: string = "";
  mavenToolbar = editorToolbar;
  loading = false
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then((res) => {
      clearTimeout(this.dirtyMavonTimer)
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses()
      }, 1000);  
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend('categories', () => {
      if (this.newBlogPost.categoryIds!.length > 0) {
        return true
      }
      return 'The {_field_} field is required.'
    })

    let self = this;
    extend("preview", {
      validate(value) {
        let uploader = self.$refs.previewUploader as any;
        
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });
    extend("header", {
      validate(value) {
        let uploader = self.$refs.headerUploader as any;
        
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });
    extend("author", {
      validate(value) {
        let uploader = self.$refs.authorUploader as any;
        
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedCategories() {
    return sortAscending(this.categories, false);
  }

  get sortedMetaTags() {
    return sortAscending(this.metaKeywordsArray, false)
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  handleClose(tag: any) {
    this.metaKeywordsArray.splice(this.metaKeywordsArray.indexOf(tag), 1);
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll('.dirty'); 
      
    if(mavonDirty.length) {
      for(let item of mavonDirty) {
        item.classList.remove('dirty')
      }
    }   
  }

  showInput() {
    this.tagInputVisible = true;
    this.$nextTick(() => {
      let saveInput = this.$refs.saveTagInput as any;
      saveInput.$refs.input.focus();
    });
  }

  handleInputConfirm() {
    let inputValue = this.tagInputValue;
    if (inputValue && !this.metaKeywordsArray.includes(inputValue)) {
      this.metaKeywordsArray.push(inputValue);
    }
    this.tagInputVisible = false;
    this.tagInputValue = "";
  }

  uploadImages(): Promise<any> {
    let previewUploader = this.$refs.previewUploader as ImageUploader;
    let headerUploader = this.$refs.headerUploader as ImageUploader;
    let authorUploader = this.$refs.authorUploader as ImageUploader;

    let previewPromise;
    let headerPromise;
    let authorPromise;

    if(previewUploader) {
      previewPromise = previewUploader!.uploadImage()
    }
    if(headerUploader) {
      headerPromise = headerUploader!.uploadImage()
    }
    if(authorUploader) {
      authorPromise = authorUploader!.uploadImage()
    }

    return Promise.all([previewPromise, headerPromise, authorPromise])
  }

  getHeightAndWidthFromDataUrl = (dataURL:any) => new Promise(resolve => {
    const img = new Image()
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width
        })
      }
      img.src = dataURL
  })

  beforeImageUpload = async (file: any) => {
    const isType = (file.type === 'image/jpeg') || (file.type === 'image/png');
    const isLt2M = file.size / 1024 / 1024 < 2;
    
    if (!isType) {
      this.$message.error('Image must be JPG or PNG format.');
    }
    if (!isLt2M) {
      this.$message.error('Image size can not exceed 2MB.');
    }

    return isType && isLt2M;
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string){
    if (area == "preview") {
      this.newBlogPost.previewImageUrl = '';
    } 

    if (area == "header") {
      this.newBlogPost.headerImageUrl = '';
    } 

    if (area == "author") {
      this.newBlogPost.authorImageUrl = '';
    } 
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'BlogPosts') {
        this.$router.back()
      } else {
        this.$router.push({ name: "BlogPosts"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true
    this.uploadImages().then((results) => {
      if(results[0].imageUrl) {
        this.newBlogPost.previewImageUrl = results[0].imageUrl;
      }

      if(results[1].imageUrl) {
        this.newBlogPost.headerImageUrl = results[1].imageUrl;
      }

      if(results[2].imageUrl) {
        this.newBlogPost.authorImageUrl = results[2].imageUrl;
      }

      if (this.metaKeywordsArray.length) {
        this.newBlogPost.seoKeywords = this.metaKeywordsArray.join(", ");
      }

      if (!this.newBlogPost.slug) {
      this.newBlogPost.slug = this.newBlogPost
        .name!.trim()
        .split(' ')
        .join('-')
        .toLowerCase()
    }

        if (this.post.id) {
          console.log('before save', this.newBlogPost);
          
          AdminBlogs.adminBlogsIdPut(this.post.id, this.newBlogPost)
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.dialogVisible = false;
              this.$message.success('Saved!')
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
        } else {
          AdminBlogs.adminBlogsPost(this.newBlogPost as BlogAdminCreateModel)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.dialogVisible = false;
                this.$message.success('Saved!')
                this.$router.push({ name: "BlogPosts" });
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
            });
        }
    });
  }

  async loadInitialData(): Promise<any> {
    this.loading = true
    await AdminBlogCategories.adminBlogCategoriesGet(1, 999999)
    .then((res) => {
      if (res.data.succeeded) {
        this.categories = res.data.resultData!.items as Array<BlogCategoryAdminListViewModel>
      }
    })
    .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
            });

    console.log('id', this.id, this.$route);
    

    if (this.id) {
      await AdminBlogs.adminBlogsIdGet(this.id).then((res) => {
        this.post = res.data.resultData as BlogAdminViewModel;
        this.name = this.post.name;
        this.newBlogPost = (({ id, ...rest }) => rest)(this.post) as any;
        console.log('new post', this.newBlogPost);
        
        if (this.newBlogPost.seoKeywords) {
          this.metaKeywordsArray = this.newBlogPost.seoKeywords.split(", ");
        }

        if (this.newBlogPost.previewImageUrl) {
          this.previewImage = [{
            imageUrl: this.newBlogPost.previewImageUrl
          }];
        }

        if (this.newBlogPost.headerImageUrl) {
          this.headerImage = [{
            imageUrl: this.newBlogPost.headerImageUrl
          }];
        }

        if (this.newBlogPost.authorImageUrl) {
          this.authorImage = [{
            imageUrl: this.newBlogPost.authorImageUrl
          }];
        }
      })
      .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
            });
    }
    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
    clearTimeout(this.dirtyMavonTimer)
  }
}
