










































































import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminProductionSpeeds } from "@/network/api";
import { DeliveryType, ProductionSpeedAdminCreateModel, ProductionSpeedAdminUpdateModel, ProductionSpeedAdminViewModel } from "@/api-client";

type pricePerItemType = 'Absolute' | 'Percentage';

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewProductionSpeed",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicInputNumberField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  productionSpeed: ProductionSpeedAdminViewModel = {
    id: '',
    name: '',
    pricePerOrder: 0,
    pricePerItem: 0,
    productionDays: 0,
    deliveryTypeId: DeliveryType.Standard,
    pricePerItemIsPercent: false
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  pricePerItemType: pricePerItemType = 'Absolute'
  pricePerItemTypes: {id: pricePerItemType, name:string}[] = [
    { 
      id: 'Absolute',
      name: 'Absolute',
    },
    { 
      id: 'Percentage',
      name: 'Percentage',
    },
  ]

  newProductionSpeed: ProductionSpeedAdminCreateModel | ProductionSpeedAdminUpdateModel = {
    name: '',
    pricePerOrder: 0,
    pricePerItem: 0,
    productionDays: 0,
    deliveryTypeId: DeliveryType.Standard,
    pricePerItemIsPercent: false
  };
  loading = false
  dirtyTimer: any = null
  fromPath = ''

  created() {
    this.loadInitialData()
    .then(() => {
      this.clearDirtyClasses();
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'ProductionSpeeds') {
        this.$router.back()
      } else {
        this.$router.push({ name: "ProductionSpeeds"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.newProductionSpeed.pricePerItemIsPercent = this.pricePerItemType === 'Percentage';

    if(this.newProductionSpeed.pricePerItemIsPercent) {
      this.newProductionSpeed.pricePerItem = this.newProductionSpeed.pricePerItem / 100;
    }

    if (this.productionSpeed.id) {
      this.loading = true
      console.log("before save", this.newProductionSpeed);

      AdminProductionSpeeds.adminProductionSpeedsIdPut(this.productionSpeed.id, this.newProductionSpeed)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false
          this.dialogVisible = false;
        });
    } else {
      AdminProductionSpeeds.adminProductionSpeedsPost(this.newProductionSpeed)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.$router.push({ name: "ProductionSpeeds" });
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false
          this.dialogVisible = false;
        });
    }
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true
      await AdminProductionSpeeds.adminProductionSpeedsIdGet(this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.productionSpeed = res.data.resultData as ProductionSpeedAdminViewModel;
        this.name = this.productionSpeed.name;
        this.newProductionSpeed = (({ id, ...rest }) => rest)(this.productionSpeed) as any; 
        }

        this.pricePerItemType = this.newProductionSpeed.pricePerItemIsPercent == true ? 'Percentage' : 'Absolute';

        if(this.newProductionSpeed.pricePerItemIsPercent) {
          this.newProductionSpeed.pricePerItem = this.newProductionSpeed.pricePerItem * 100;
        }

        this.loading = false            
      })
      .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false
          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
