


















































































import {
  ArtAssetCategoryAdminListViewModel,
  ArtAssetsAdminCreateModel,
  ArtAssetsAdminUpdateModel,
  ArtAssetsAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from "@/utils/sortAscending";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminArtAssetCategories, AdminArtAssets } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewAsset",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  tab: string = this.$route.params.tab;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  showBanner: boolean = true;
  asset: ArtAssetsAdminViewModel = {
    id: "",
    name: "",
    imageUrl: "",
    categoryIds: [],
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  categories: Array<ArtAssetCategoryAdminListViewModel> = [];
  newAsset: ArtAssetsAdminCreateModel | ArtAssetsAdminUpdateModel = {
    name: "",
    imageUrl: "",
    categoryIds: [],
  };
  dirtyTimer: any = null
  loading = false
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("preview", {
      validate(value) {
        let uploader = self.$refs.previewUploader as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedCategories() {
    return sortAscending(this.categories, false);
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  uploadImages(): Promise<any> {
    let previewUploader = this.$refs.previewUploader as ImageUploader;

    let previewPromise;

    if (previewUploader) {
      previewPromise = previewUploader!.uploadImage();
    }
    return Promise.all([previewPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // debugger
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;

    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   debugger
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   debugger
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // debugger
    // return result;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";
    console.log("back dirty", isDirty);

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "preview") {
      this.newAsset.imageUrl = "";
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'ArtAssets') {
        this.$router.back()
      } else {
        this.$router.push({ name: "ArtAssets"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newAsset.imageUrl = results[0].imageUrl;
      }

      if (this.asset.id) {
        console.log("before save", this.newAsset);

        AdminArtAssets.adminArtAssetsIdPut(this.asset.id, this.newAsset)
          .then((res) => {
            if(res.data.succeeded) {
              console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            })           
            }
              this.loading = false
            
          })
          .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      } else {
        let createAsset = { ...this.newAsset } as ArtAssetsAdminCreateModel;
        AdminArtAssets.adminArtAssetsPost(createAsset)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.$router.push({ name: "ArtAssets" });
            }
              this.loading = false
          })
          .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      }
    });
  }

  async loadArtAssetCategories(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminArtAssetCategories.adminArtAssetCategoriesGet(1, 999999).then(
      (res) => {
        if (res.data.succeeded) {
          this.categories = res.data.resultData!
            .items as Array<ArtAssetCategoryAdminListViewModel>;
            if (refresh) {
            this.loading = false;
            this.$message.success("Section Refreshed.");
          }
        }
      })
    .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
  }

  async loadInitialData() {
    this.loading = true
    
    await this.loadArtAssetCategories()

    if (this.id) {
      await AdminArtAssets.adminArtAssetsIdGet(this.id).then((res) => {
        this.asset = res.data.resultData as ArtAssetsAdminViewModel;
        this.name = this.asset.name;
        this.newAsset = (({ id, ...rest }) => rest)(this.asset) as any;
        console.log("new asset", this.newAsset);

        if (this.newAsset.imageUrl) {
          this.previewImage = [
            {
              imageUrl: this.newAsset.imageUrl,
            },
          ];
        }
      })
      .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
    }
      this.loading = false
  }
}
