



























































































































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import DialogBox from "@/components/DialogBox.vue";
import GeneralTab from "@/components/enquiries/general/GeneralTab.vue";
import AttachmentsTab from "@/components/enquiries/attachments/AttachmentsTab.vue";
import EnquiryTasks from "@/components/enquiries/activity-tasks/EnquiryTasks.vue";
import ProductsTab from "@/components/enquiries/products/ProductsTab.vue";
import QuotesTab from "@/components/enquiries/quotes/QuotesTab.vue";
import {
  AdminEnquiriesIdDeleteRequest,
  EnquiryAdminViewModel,
  EnquiryCustomerType,
  EnquiryCustomerTypeListViewModel,
  EnquiryStatus,
  EnquiryStatusListViewModel,
  EnquiryType,
} from "@/api-client";
import { AdminEnquiries } from "@/network/api";
import { email } from "vee-validate/dist/rules";
import { Message } from "element-ui";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "EditEnquiry",
  components: {
    ValidationProvider,
    ValidationObserver,
    DialogBox,
    GeneralTab,
    AttachmentsTab,
    EnquiryTasks,
    ProductsTab,
    QuotesTab
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  activeTab: any = "general";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  enquiry: EnquiryAdminViewModel = {
    id: "",
    dateTimeStamp: "",
    customerName: "",
    customerEmail: "",
    customerContactNumber: "",
    leadIn: "",
    businessName: "",
    ponumber: "",
    message: "",
    orderManagerAspNetUserDisplayName: "",
    salesManagerAspNetUserDisplayName: "",
    enquiryStatusId: EnquiryStatus.New,
    salesManagerAspNetUserId: "",
    orderManagerAspNetUserId: "",
    orderManagerAspNetUserEmail: "",
    salesManagerAspNetUserEmail: "",
    enquiryTypeId: EnquiryType.NotYetAssigned,
    referenceNumber: "",
    attachments: [],
    customizedProducts: [],
    countryId: '',
    countryName: '',
    lastModifiedDate: '',
    enquiryCustomerTypeId: EnquiryCustomerType.Individual,
    confirmedDeadline: '',
    qualityScore: 0,
    targetDate: '',
    specialTerms: null,
    internalNotes: '',
    cartId: '',
    cartReferenceNumber: '',
    expectedQuantity: null,
    storeId: '',
    campaignId: '',
    campaignName: ''
  };
  enquiryClone: EnquiryAdminViewModel = {
    id: "",
    dateTimeStamp: "",
    customerName: "",
    customerEmail: "",
    customerContactNumber: "",
    leadIn: "",
    businessName: "",
    ponumber: "",
    message: "",
    orderManagerAspNetUserDisplayName: "",
    salesManagerAspNetUserDisplayName: "",
    enquiryStatusId: EnquiryStatus.New,
    salesManagerAspNetUserId: "",
    orderManagerAspNetUserId: "",
    orderManagerAspNetUserEmail: "",
    salesManagerAspNetUserEmail: "",
    enquiryTypeId: EnquiryType.NotYetAssigned,
    referenceNumber: "",
    attachments: [],
    customizedProducts: [],
    countryId: '',
    countryName: '',
    lastModifiedDate: '',
    enquiryCustomerTypeId: EnquiryCustomerType.Individual,
    confirmedDeadline: '',
    qualityScore: 0,
    targetDate: '',
    specialTerms: null,
    internalNotes: '',
    cartId: '',
    cartReferenceNumber: '',
    expectedQuantity: null,
    storeId: '',
  };
  clearDirty: any = null;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  messageInstance: any = Message;
  enquiryStatuses: Array<EnquiryStatusListViewModel> = []
  enquiryCustomerTypes: Array<EnquiryCustomerTypeListViewModel> = [];
  fromPath = ''

  created() {
    this.getCustomerTypes()
    this.loadInitialData().then(() => {
      this.clearDirty = setTimeout(() => {
        this.clearDirtyClasses();
      }, 500);
    });

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "general";
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      } else {
        this.activeTab = "general";
      }
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("email", {
      ...email,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
    this.$refs.observer;
  }

  handledate(date: any) {
    if (date) {
      return formatDate(date);
    }
    return "";
  }

  handleSubmit() {
    this.messageInstance.closeAll();
    let errorMessages = [] as Array<string>;
    this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
      if (isValid) {
        this.submitForm();
      } else {
        for (let error in errors) {
          if (errors[error].length > 0) {
            errorMessages.push(error);
          }
        }
        let finalMessage = `The following fields are required:<br>`;
        errorMessages.forEach((message, index: any) => {
          if (index < 5) {
            finalMessage += `<br>- ${message}`;
          }
        });
        if (errorMessages.length > 5) {
          let remaining = errorMessages.length - 5;
          finalMessage += `<br><br>and ${remaining} others...`;
        }
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: finalMessage,
          dangerouslyUseHTMLString: true,
        });
      }
    });
  }

  handleTabClick(tab: any) {
    tab.name ? (this.activeTab = tab.name) : (this.activeTab = tab);
    this.$router.replace({
      name: "EditEnquiry",
      params: { id: this.enquiryClone.id },
      query: { tab: this.activeTab },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    this.confirmText = "Yes";
    if (isDirty.length > 0) {
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(['OrderEnquirySearch', 'Enquiries','TaskOverview'].includes(this.fromPath)) {
        this.$router.back()
      } else {
        this.$router.push({ name: "Enquiries"});
      }
    } else {
      this.submitForm();
    }
  }

  async submitForm() {
    let newEnquiry = {
      enquiryStatusId: this.enquiryClone.enquiryStatusId,
      salesManagerAspNetUserId: this.enquiryClone.salesManagerAspNetUserId,
      orderManagerAspNetUserId: this.enquiryClone.orderManagerAspNetUserId,
      enquiryTypeId: this.enquiryClone.enquiryTypeId,
      specialTerms: this.enquiryClone.specialTerms,
      internalNotes: this.enquiryClone.internalNotes,
      targetDate: this.enquiryClone.targetDate,
      qualityScore: this.enquiryClone.qualityScore,
      confirmedDeadline: this.enquiryClone.confirmedDeadline,
      enquiryCustomerTypeId: this.enquiryClone.enquiryCustomerTypeId,
      expectedQuantity: this.enquiryClone.expectedQuantity,
    } as AdminEnquiriesIdDeleteRequest;
    if (this.enquiryClone.id) {
      this.loading = true;
      await AdminEnquiries.adminEnquiriesIdPut(
        this.enquiryClone.id,
        newEnquiry
      ) 
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.loading = false;
    this.dialogVisible = false;
  }

  fetchStatuses(typeId: EnquiryType | null = null, setDefault = false) {
    AdminEnquiries.adminEnquiriesGetEnquiryStatusForTypeGet(1, 999, typeId || this.enquiry.enquiryTypeId)
    .then((res) => {
        if (res.data.succeeded) {
          this.enquiryStatuses = res.data.resultData!
            .items as Array<EnquiryStatusListViewModel>;

          const includesStatus = this.enquiryStatuses.find((status) => status.id === this.enquiryClone.enquiryStatusId)
          
          if(includesStatus == undefined) {
            this.enquiryClone.enquiryStatusId = this.enquiryStatuses[0].id as EnquiryStatus;
          }
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async getCustomerTypes() {
    await AdminEnquiries.adminEnquiriesGetEnquiryCustomerTypesGet(1, 999)
    .then((res) => {
        if (res.data.succeeded) {
          this.enquiryCustomerTypes = res.data.resultData?.items as Array<EnquiryCustomerTypeListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    
    if (this.id) {
      this.loading = true;
        await AdminEnquiries.adminEnquiriesIdGet(this.id)
          .then((res) => {
            if (res.data.succeeded) {
              this.enquiry = res.data.resultData as EnquiryAdminViewModel;
              this.enquiryClone = { ...this.enquiry };
              this.fetchStatuses();
            }
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
          });
        }

    this.loading = false;
  }

  beforeUnmount() {
    this.messageInstance.closeAll();
    clearTimeout(this.clearDirty);
  }
}
