var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container banner-messages-container"},[_c('el-row',{staticStyle:{"flex-wrap":"wrap"},attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"xs":24,"md":10}},[_c('h1',{attrs:{"id":"title"}},[_vm._v("Banner Messages")])]),_c('el-col',{staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"flex-end"},attrs:{"xs":24,"md":14}},[_c('button',{staticClass:"new-banner-message",attrs:{"disabled":_vm.loading},on:{"click":_vm.handleHideInActive}},[_vm._v(" "+_vm._s(_vm.hideInactive ? "Show All Banner Messages" : "Hide Inactive Banner Messages")+" ")])])],1),_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteBannerMessage,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{ name: 'NewBannerMessage' }}},[_c('button',{staticClass:"new-banner-message",attrs:{"disabled":_vm.loading}},[_vm._v(" New Banner Message ")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.bannerMessages.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search message & URL","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ElTableDraggable',{attrs:{"handle":'.handle'},on:{"reorder":_vm.handleReorder}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.bannerMessagesItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"min-width":"200","prop":"message","label":"Message"}}),_c('el-table-column',{attrs:{"min-width":"120","prop":"startDate","label":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.startDate && _vm.handleDate(row.startDate)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"120","prop":"endDate","label":"End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.endDate && _vm.handleDate(row.endDate)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"170","prop":"countryName","label":"Country"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.countries),function(country){return _c('li',{key:country.id,class:{'selected-filter': _vm.countryFilter == country.id},on:{"click":function($event){return _vm.handleCountry(country.id)}}},[_vm._v(_vm._s(country.name))])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearCountryFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{'filtered-country': _vm.countryFilter.length > 0},staticStyle:{"width":"90px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v("Countries"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.countryName))])]}}])}),_c('el-table-column',{attrs:{"width":"90","prop":"url","label":"URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.url)?_c('a',{staticClass:"link",attrs:{"href":row.url,"target":"_blank"}},[_vm._v("Open Link")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"95","prop":"showOnQuotes","label":"On Quotes","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.showOnQuotes)?_c('el-tag',{staticStyle:{"font-size":"14px"},attrs:{"type":_vm._f("showOnQuotesFilter")(row.showOnQuotes)}},[_vm._v(" ✔ ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Reorder","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('SendToPage',{attrs:{"id":row.id,"pageNumber":_vm.pageNumber,"totalPages":_vm.bannerMessages.totalPages},on:{"send":_vm.handleSendToPage}})]}}])}),_c('el-table-column',{attrs:{"width":"170","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var row = ref.row;
return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear Filters ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditBannerMessage',
              params: {
                id: row.id,
              },
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.bannerMessages.totalCount,"totalPages":_vm.bannerMessages.totalPages,"pageSize":_vm.bannerMessages.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }