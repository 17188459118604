


































import { PropType } from "vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ColourSuggestions",
  props: {
    type: { type: String as PropType<"hex" | "rgb">, default: 'hex' }
  }
})
export default class extends Vue {
  copyPath(path: any) {
    navigator.clipboard.writeText(path);
    this.$message.success("Value copied.");
  }
}
