























































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminStores, AdminUsers } from "@/network/api";
import {
  StoreAdminListViewModel,
  StoreAdminListViewModelPaginatedList,
  StoreListViewModelPaginatedList,
  StoreStatus,
  StoreStatusViewModel,
  UserSelectModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries";
import { formatDate } from "@/utils/formatDate"
import store from "@/store";

@Component({
  name: "CustomStores",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  customStores: StoreListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  customStoreItems: Array<StoreAdminListViewModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  searchTitle: string | undefined = this.$route.params.searchTitle || "";
  searchRef: string | undefined = this.$route.params.searchRef || "";
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this online store?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  selectedItem: StoreAdminListViewModel = {
    id: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    storeOwnerName: "",
    storeOwnerEmail: "",
    countryId: '',
    countryName: '',
    currencyId: '',
    currencyName: '',
    referenceNumber: ''
  };
  statusFilter: string = "";
  onlyActiveStores: boolean | null = null
  loading = false;
  debounce: any = null;
  storeStatuses: Array<StoreStatusViewModel> = [];
  orderManagers: Array<UserSelectModel> = []
  selectedOrderManager: string = '';
  selectedSalesManager: string = '';
  hasCheckedOut: boolean | undefined = undefined;

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  created() {
    if (this.$route.query.onlyActiveStores) {
      this.onlyActiveStores = this.$route.query.onlyActiveStores == 'true' ? true : null;
    }
    if (this.$route.query.hasCheckedOut) {
      this.hasCheckedOut = this.$route.query.hasCheckedOut == 'true' ? true : undefined;
    }

    let toQuery = {
      searchTitle: this.searchTitle,
      searchRef: this.searchRef,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      statusFilter: this.statusFilter,
      onlyActiveStores: this.onlyActiveStores,
      selectedOrderManager: this.selectedOrderManager,
      selectedSalesManager: this.selectedSalesManager
    };
    this.setQueryWatch(toQuery);

    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val);
      });
    }
  }

  handleSearch(id:string = 'searchTitle') {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById(id)!.focus();
    });
  }

  handleDate(date:any) {
    return formatDate(date)
  }


  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  clearstatusFilter() {
    this.pageNumber = 1;
    this.statusFilter = "";
    this.loadInitialData();
  }

  clearOrderManagerFilter() {
    this.selectedOrderManager = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearSalesManagerFilter() {
    this.selectedSalesManager = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearAllFilters() {
    this.onlyActiveStores = null;
    this.searchTitle = "";
    this.searchRef = "";
    this.selectedOrderManager = '';
    this.selectedSalesManager = '';
    this.clearstatusFilter();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  handleStatus(selected: string) {
    if(this.statusFilter !== selected) {
      this.statusFilter = selected;
    } else {
      this.statusFilter = ''
    }
    this.pageNumber = 1;
    this.loadInitialData();
  }

  handleOrderManager(selected:string) {
    if(this.selectedOrderManager !== selected) {
      this.selectedOrderManager = selected;
    } else {
      this.selectedOrderManager = ''
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleSalesManager(selected:string) {
    if(this.selectedSalesManager !== selected) {
      this.selectedSalesManager = selected;
    } else {
      this.selectedSalesManager = ''
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  toggleCheckedOutFilter(flag:string) {
    if(flag == 'true') {
      this.hasCheckedOut = true;
    } else {
      this.hasCheckedOut = undefined;
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  toCreateStore() {
    let link = `${process.env.VUE_APP_ROOT_WEB}/profile?section=online-stores&store=new`;
    window.open(link, "_blank");
  }

  async loadStoreStatuses() {
    await AdminStores.adminStoresGetStoreStatusesGet()
      .then((res) => {
        if (res.data.succeeded) {
          this.storeStatuses = res.data.resultData as Array<StoreStatusViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true;
    await AdminStores.adminStoresGet(
      this.pageNumber,
      this.itemPerPage,
      this.searchTitle || undefined,
      (this.statusFilter as StoreStatus) || undefined,
      this.searchRef || undefined,
      this.onlyActiveStores || undefined,
      this.selectedOrderManager,
      this.selectedSalesManager,
      this.hasCheckedOut
    )
      .then(async (res) => {
        if (res.data.succeeded) {
          this.customStores = res.data
            .resultData as StoreAdminListViewModelPaginatedList;
          this.customStoreItems = this.customStores.items as Array<StoreAdminListViewModel>;         
        }
        
        await this.loadStoreStatuses()

        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });

      await AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.orderManagers = res.data.resultData!
            .items as Array<UserSelectModel>;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      }); 
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    if (this.selectedItem.id && this.roles.includes('Admin')) {
      this.loading = true;
      AdminStores.adminStoresIdDelete(this.selectedItem.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.pageNumber = 1;
            this.$message.info("Item deleted.");
            this.loadInitialData();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
    this.dialogVisible = false;
    this.loading = false;
    this.selectedItem = {
      id: "",
      startDate: "",
      endDate: "",
      title: "",
      deliveryFeePerCustomer: 0,
      storeStatusId: StoreStatus.Editing,
      storeOwnerName: "",
      storeOwnerEmail: "",
      countryId: '',
      countryName: '',
      currencyId: '',
      currencyName: '',
      referenceNumber: ''
    };
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
