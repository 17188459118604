
































































































































































import {
  VoucherAdminCreateModel,
  VoucherAdminUpdateModel,
  VoucherAdminViewModel,
  VoucherType,
  VoucherApplyToType,
  ProductAdminListViewModel,
  CountryAdminListViewModel,
  CountryAdminListViewModelPaginatedList
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminVouchers, AdminProducts, AdminCountries } from "@/network/api";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import { formatDate } from "@/utils/formatDate"
import { sortAscending } from "@/utils/sortAscending";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewVouchers",
  components: {
    BasicInputNumberField,
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  voucherName: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  voucher: VoucherAdminViewModel = {
    id: '',
    voucherTypeId: VoucherType.Absolute,
    value: 0,
    redemptionCode: '',
    minimumCartValue: null,
    maximumRedemptions: null,
    maximumValue: null,
    startDate: null,
    endDate: null,
    voucherApplyToTypeId: VoucherApplyToType.TotalCost,
    isProductListInclusion: null,
    productIds: [],
    countryIds: [],
    applyToStandardDelivery: false,
    applyToExpressDelivery: false
  };
  voucherTypes: Array<any> = [
    {
      id: VoucherType.Absolute,
      name: 'Absolute'
    },
    {
      id: VoucherType.Percentage,
      name: 'Percentage'
    },
  ]
  voucherApplyTypes: Array<any> = [
    {
      id: VoucherApplyToType.TotalCost,
      name: 'Total Cost'
    },
    {
      id: VoucherApplyToType.ProductCost,
      name: 'Product Cost'
    },
    {
      id: VoucherApplyToType.DeliveryCost,
      name: 'Delivery Cost'
    }
  ]
  vouchers: Array<VoucherAdminViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false
  newVoucher: VoucherAdminCreateModel | VoucherAdminUpdateModel | VoucherAdminViewModel = {
    voucherTypeId: VoucherType.Absolute,
    value: 0,
    minimumCartValue: null,
    maximumRedemptions: null,
    maximumValue: null,
    startDate: null,
    voucherApplyToTypeId: VoucherApplyToType.TotalCost,
    endDate: null,
    isProductListInclusion: null,
    productIds: [],
    countryIds: [],
    applyToStandardDelivery: false,
    applyToExpressDelivery: false
  };
  allProductsArray: Array<ProductAdminListViewModel> = [];
  allCountries: Array<CountryAdminListViewModel> = [];
  fromPath = ''
  selectedDates:any = null
  pickerOptions= {
    firstDayOfWeek: 1,
    shortcuts: [{
      text: 'Last week',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last month',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last 3 months',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: 'Last 6 months',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 120);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: 'Last year',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit('pick', [start, end]);
      }
    },
    ]
  }
  deliveryCostAll = false

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get sortedProducts() {
    return sortAscending(this.allProductsArray, true , 'code');
  }

  get isDeliveryCostIndeterminate() {
    return !this.deliveryCostAll && (this.newVoucher.applyToStandardDelivery == true || this.newVoucher.applyToExpressDelivery == true);
  }

  created() {
    this.loadInitialData().then(() => {
      this.clearDirtyClasses();
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined, 0].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  updateVoucherType() {
    this.deliveryCostAll = false;
    this.handleCheckAllDeliveryCost();
  }

  copyRedemptionCode() {
    navigator.clipboard.writeText((this.newVoucher as VoucherAdminViewModel).redemptionCode)
    this.$message.success('Redemption code copied.')
  }

  handleDateRanges(value:any) {
    let dates = {
      startDate: null as string | null,
      endDate: null as string | null
    };
    if(value) {
      let d1 = new Date(value[0])
      let d2 = new Date(value[1])

      //if(d1.getTime() == d2.getTime()) {
        d2.setDate(d2.getDate() + 1);
      //}

      dates = {
        startDate: new Date(d1).toISOString(),
        endDate: new Date(d2).toISOString()
      }
    }
    this.newVoucher.startDate = this.selectedDates.startDate = dates.startDate;
    this.newVoucher.endDate = this.selectedDates.endDate = dates.endDate;

    this.selectedDates = [this.newVoucher.startDate, this.newVoucher.endDate];
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  updateProductInteraction(value) {
    this.newVoucher.isProductListInclusion = value !== undefined ? value : null;
    if (this.newVoucher.isProductListInclusion == null) {
      this.newVoucher.productIds = [];
    }
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Vouchers') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Vouchers"});
      }
    } else {
      this.submitForm();
    }
  }

  presubmitCheckDeliveryCost() {
    if (this.newVoucher.voucherApplyToTypeId == 'DeliveryCost' && (!this.isDeliveryCostIndeterminate && !this.deliveryCostAll)) {
      return false;
    }
    return true;
  }

  submitForm() {
    this.loading = true

      const toSend = {...this.newVoucher}
      toSend.minimumCartValue = toSend.minimumCartValue == 0 ? null : toSend.minimumCartValue;
      toSend.maximumRedemptions = toSend.maximumRedemptions == 0 ? null : toSend.maximumRedemptions;
      toSend.maximumValue = toSend.maximumValue == 0 ? null : toSend.maximumValue;

      if(toSend.voucherTypeId === 'Percentage') {
        toSend.value = toSend.value / 100;
      }

      if (this.voucher.id) {
        console.log("before save", this.newVoucher);
        if (!this.presubmitCheckDeliveryCost()) {
          this.loading = false;
          this.$message({showClose: true, type: 'error', duration: 0, message: "Please select at least one type of delivery to apply this voucher to."})
          return null;
        }
        AdminVouchers.adminVouchersIdPut(this.voucher.id, toSend)
          .then((res) => {
            if(res.data.succeeded) {
              console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
            }
            this.loading = false
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false
            this.dialogVisible = false;
          });
      } else {
        AdminVouchers.adminVouchersPost(toSend)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.id = res.data.resultData!.id;
              this.$router.replace({ name: "EditVoucher", params: {id: this.id} });
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            }
            this.loading = false
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false
            this.dialogVisible = false;
          });
      }
    }

  async loadInitialData() {
    this.loading = true
    await this.loadAllProducts()
    await this.loadAllCountries()
    if (this.id) {
      await AdminVouchers.adminVouchersIdGet(this.id).then((res) => {
        if(res.data.succeeded) {
        this.voucher = res.data.resultData as VoucherAdminViewModel;
        this.voucherName = this.voucher.redemptionCode;
        this.newVoucher = (({ id, ...rest }) => rest)(this.voucher) as any;

        if(this.newVoucher.startDate && this.newVoucher.endDate) {
          this.selectedDates = [this.newVoucher.startDate, this.newVoucher.endDate]
        }

        if(this.newVoucher.voucherTypeId === 'Percentage') {
          this.newVoucher.value = this.newVoucher.value * 100;
        }

        console.log("new voucher", this.newVoucher);
        }
      })
      .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false
            this.dialogVisible = false;
          });

      this.handleCheckedDeliveryCost();
    }
    this.loading = false
  }

  async loadAllProducts(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminProducts.adminProductsGet(1, 99999999)
      .then((res) => {
        const products = res.data.resultData!.items;
        this.allProductsArray = [];
        products.map((product) => {
          this.allProductsArray.push(product);
        });
        if(refresh) {
          this.loading = false
          this.$message.success("Section Refreshed.");
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadAllCountries(refresh = false) {
    if(refresh) {
      this.loading = true
    }
    await AdminCountries.adminCountriesGet(1, 99999999)
      .then((res) => {
        if(res.data.succeeded) {
          const countries = res.data.resultData as CountryAdminListViewModelPaginatedList
          this.allCountries = countries.items as Array<CountryAdminListViewModel>
          this.loading = false
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  handleCheckedDeliveryCost() {
    this.deliveryCostAll = this.newVoucher.applyToStandardDelivery == true && this.newVoucher.applyToExpressDelivery == true;
  }

  handleCheckAllDeliveryCost() {
    this.newVoucher.applyToStandardDelivery = this.deliveryCostAll;
    this.newVoucher.applyToExpressDelivery = this.deliveryCostAll;
  }
}
