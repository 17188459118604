

























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import NamesAndNumbers from "@/components/orders/sizing/NamesAndNumbers.vue";
import {
  CustomizedProductSizeOptionsViewModel,
  OrderCartItemQuantityAdjustmentViewModel,
  OrderCartItemQuantityCreateUpdateModel,
  OrderCartItemQuantityExtraCustomizationViewModel,
  OrderCartItemQuantityViewModel,
  OrderItemAdminViewModel,
  OrderPaymentAdminListViewModel,
} from "@/api-client";
import { CustomizedProducts } from "@/network/api";
import BasicInputField from "@/components/form-items/BasicInputField.vue";

const AppProps = Vue.extend({
  props: {
    show: { default: true },
    product: { type: Object, default: null },
    quantity: { default: 25 },
    contacts: { default: null },
    isStoreOrder: { type: Boolean, default: false },
    isOrderPack: { type: Boolean, default: false },
    dialogVisible: { type: Boolean, default: false },
  },
});

@Component({
  name: "SizeNameSelector",
  components: {
    NamesAndNumbers,
    BasicInputField
  },
})
export default class extends AppProps {
  selectedProduct: OrderItemAdminViewModel | any = {};
  quantities: Array<OrderCartItemQuantityCreateUpdateModel> = [
    {
      quantity: 1,
      sizeId: "",
      adjustments: [], //adjustmentId strings
      extraCustomizations: [],
    },
  ];
  quantitiesArray: Array<any> = [
    {
      quantity: 1,
      selectedSizeGroup: {
        id: "",
        displayName: "",
        sizes: [],
        order: 0
      },
      selectedSize: {
        id: "",
        name: "",
        order: 0
      },
      selectedAdjustments: [],
      extraCustomizations: [],
      orderPackNotes: ''
    },
  ];
  productSizeInfo: CustomizedProductSizeOptionsViewModel = {
    sizeGroups: [],
    adjustmentTypes: [],
    extras: [],
  };
  showSizeInfo = false;
  showNamesNumbers = false;
  loading = false;
  selectedRow: any = null;
  selectedRowIndex: any = null;
  newQuantity = 0;
  emptyExtras = 0;
  invalidSizing = false
  showSizeBreakdown = false
  editCustomer = false
  stripePayments: OrderPaymentAdminListViewModel[] = [];
  orderPackQtyMismatch = false;
  dialogOpen = false;

  created() {
    this.selectItem(this.product as any);
    
    this.$watch('$props', () => {
      if(this.$props.dialogVisible && !this.dialogOpen) {
        this.selectItem(this.product as any);        
        this.dialogOpen = true;
        this.quantitiesArray = this.quantitiesArray.sort((a, b) => {
          if (a.selectedSizeGroup.order === b.selectedSizeGroup.order) {
            // Size is only important when size groups are the same
            return a.selectedSize.order - b.selectedSize.order;
          }
          return a.selectedSizeGroup.order > b.selectedSizeGroup.order ? 1 : -1;
        })
      } else if(!this.$props.dialogVisible && this.dialogOpen) {
        this.dialogOpen = false;
      }
    }, { deep: true })
  }

  get totalQuantity() {
    let total = 0;
    this.quantitiesArray.forEach((quantity) => {
      total += Number(quantity.quantity);
    });
    return total;
  }

  get handleMismatch() {
    if(this.totalQuantity > this.product.originalQuantity) {
      const num = this.totalQuantity - this.product.originalQuantity
      return `remove ${num} unit${num > 1 ? 's' : ''}`
    } else if (this.totalQuantity < this.product.originalQuantity) {
      const num = this.product.originalQuantity - this.totalQuantity;
      return `add ${num} unit${num > 1 ? 's' : ''}`
    } else {
      this.orderPackQtyMismatch = false;
      return "";
    }
  }

  getSizeBreakdown(row:any) {
    const selectedSizeAttributes = row.selectedSizeGroup.sizes.find((size:any) => size.sizeId === row.selectedSize.sizeId)?.attributes

    if(selectedSizeAttributes) {
      return selectedSizeAttributes.map((attribute:any) => {
        let overrideValue;
        row.selectedAdjustments.forEach((adjustment:any) => {
          let match = attribute.overrides.find((override:any) => override.adjustmentId === adjustment.adjustmentId)?.value;
          if (match) {
            overrideValue = match;
          }
        })
        
        return ({
          name: attribute.name,
          value: overrideValue || attribute.value
        })
      })
    }
    return null;
  }

  updateSizeDetails() {
    let formattedQuantities = [] as Array<any>;
    this.emptyExtras = 0;

    this.quantitiesArray.forEach((sizeItem:any) => {
      let quantity = {
        orderCustomerContactId: sizeItem.orderCustomerContactId,
        orderCustomerContactName: sizeItem.orderCustomerContactName,
        paymentReference: sizeItem.paymentReference,
        quantity: Number(sizeItem.quantity),
        sizeId: sizeItem.selectedSize.sizeId,
        sizeGroupId: sizeItem.selectedSizeGroup.sizeGroupId,
        sizeName: sizeItem.selectedSize.name,
        sizeGroupDisplayName: sizeItem.selectedSizeGroup.displayName,
        sizeGroupOrder: sizeItem.selectedSizeGroup.order,
        adjustments: [],
        extraCustomizations: [],
        orderPackNotes: sizeItem.orderPackNotes
      } as any;

      sizeItem.selectedAdjustments.forEach((adjustment:any) => {
        quantity.adjustments.push({
          adjustmentId: adjustment.adjustmentId,
          adjustmentName: adjustment.name,
        });
      });

      sizeItem.extraCustomizations.forEach((extra:any) => {
        if( extra.value === '' ) {
          this.emptyExtras += 1;
        }
        quantity.extraCustomizations.push({
          extraId: extra.extraId,
          extraName: extra.extraName,
          value: extra.value,
          row: extra.row,
        });
      });
      formattedQuantities.push(quantity);
    })

    this.product.quantities = [...formattedQuantities];

    if(this.isOrderPack) {
      if(!Object.prototype.hasOwnProperty.call(this.product, "originalQuantity")) {
        this.product.originalQuantity = this.totalQuantity;
      } else {
        this.orderPackQtyMismatch = this.product.originalQuantity !== this.totalQuantity
        this.$emit('qtyMismatch', this.orderPackQtyMismatch)
      }
      // debugger
    }
    this.$emit("setInitial")
  }

  handleQuantityChange(row: any, index: any) {
    if (row.quantity < 1) {
      row.quantity = 1;
    }

    if (this.productSizeInfo.extras.length && row.quantity > 2500) {
      row.quantity = 2500;
    }

    if (this.productSizeInfo.extras.length) {
      let extraQuantity =  row.extraCustomizations.length / this.productSizeInfo.extras.length;

      if (row.quantity > extraQuantity) {
        for (let i = extraQuantity; i < row.quantity; i++) {
          this.productSizeInfo.extras.forEach((extra: any) => {
            row.extraCustomizations.push({
              extraId: extra.id,
              value: "",
              row: i,
              extraName: extra.name,
              customizationTextMaximumLength: extra.customizationTextMaximumLength,
            });
          });
        }
      } else if (row.quantity < extraQuantity) {
        row.extraCustomizations.splice(
          row.quantity * this.productSizeInfo.extras.length
        );
      }
    }

    this.updateSizeDetails();
  }

  selectItem(item: any) {
    this.selectedProduct = item;
    this.getSizingInfo(this.selectedProduct.customizedProduct.id);
    // this.updateSizeDetails()
  }

  showSizes() {
    this.showNamesNumbers = false;
    this.selectedRow = null;
    this.selectedRowIndex = null;
  }

  editNamesNumbers(row: any, index: any) {
    this.showNamesNumbers = true;
    this.selectedRow = { ...row };
    this.selectedRowIndex = index;
  }

  addRow() {
    let defaultAdjustments = [] as Array<any>;
    this.productSizeInfo.adjustmentTypes.forEach((adjustment: any) => {
      defaultAdjustments.push(adjustment.adjustments[0]);
    });

    if (this.productSizeInfo.extras.length) {
      let extras = [] as Array<any>;

      for (let i = 0; i < 1; i++) {
        this.productSizeInfo.extras.forEach((extra: any) => {
          extras.push({
            extraId: extra.id,
            value: "",
            row: i,
            extraName: extra.name,
            customizationTextMaximumLength:
              extra.customizationTextMaximumLength,
          });
        });
      }
      this.quantitiesArray.push({
        quantity: 1,
        orderCustomerContactId: null,
        orderCustomerContactName: null,
        paymentReference: null,
        selectedSizeGroup: this.productSizeInfo.sizeGroups[0],
        selectedSize: this.productSizeInfo.sizeGroups[0].sizes[0],
        selectedAdjustments: defaultAdjustments,
        extraCustomizations: [...extras],
        orderPackNotes: ''
      });
    } else {
      this.quantitiesArray.push({
        quantity: 1,
        orderCustomerContactId: null,
        orderCustomerContactName: null,
        paymentReference: null,
        selectedSizeGroup: this.productSizeInfo.sizeGroups[0],
        selectedSize: this.productSizeInfo.sizeGroups[0].sizes[0],
        selectedAdjustments: defaultAdjustments,
        extraCustomizations: [],
        orderPackNotes: ''
      });
    }

    this.updateSizeDetails();
  }

  getCustomerName(row:any) {
    if(!row.orderCustomerContactId) {
      return 'Unassigned / Extra'
    }

    if((this.contacts as any)?.length && row.orderCustomerContactId) {
      const nameFromContacts = (this.contacts as any).find(contact => contact.id === row.orderCustomerContactId);

      if(nameFromContacts) {
        return `${nameFromContacts.name}${nameFromContacts.referenceNumber ? ' (#' + nameFromContacts.referenceNumber + ')' : ''}`
      } else if(row.orderCustomerContactName) {
        return row.orderCustomerContactName
      }
      return null;
    }
  }

  getStripePaymentId(row: any) {
    if(!row.orderCustomerContactId) {
      return null
    }

    if((this.contacts as any)?.length && row.orderCustomerContactId) {
      const paymentIDFromContacts = (this.contacts as any).find(contact => contact.id === row.orderCustomerContactId);

      if(paymentIDFromContacts) {
        return `${paymentIDFromContacts.paymentReference || ''}`
      } 
      return null;
    }
  }

  unpaidStoreOrder(row: any) {
    if(row.orderCustomerContactId && !row.paymentReference) {
      return true;
    }

    return false;
  }

  removeRow(row: any, index: number) {
    this.quantitiesArray.splice(index, 1);

    if (!this.quantitiesArray.length) {
      this.addRow();
    }

    this.updateSizeDetails();
  }

  getSizingInfo(id: string) {
    CustomizedProducts.customizedProductsGetCustomizedProductSizeOptionsCustomizedProductIdGet(
      id
    ).then((res) => {
      if (res.data.succeeded) {
        this.productSizeInfo = res.data
          .resultData as CustomizedProductSizeOptionsViewModel;
        if (
          Object.prototype.hasOwnProperty.call(
            this.selectedProduct,
            "quantities"
          ) &&
          this.selectedProduct.quantities.length
        ) {
          // debugger
          this.quantitiesArray = [];
          this.selectedProduct.quantities.forEach(
            (group: OrderCartItemQuantityViewModel) => {
              let sizeGroup = this.productSizeInfo.sizeGroups.find(
                (item: any) => {
                  return item.sizeGroupId === group.sizeGroupId;
                }
              );
              let size = sizeGroup?.sizes.find((item: any) => {
                return item.sizeId === group.sizeId;
              });
              let adjustments = [] as Array<any>;
              let extras = [] as Array<any>;

              //find selected adjustments for each adjustment type in each quantity group
              this.productSizeInfo.adjustmentTypes.forEach((type: any) => {
                type.adjustments.forEach((item: any) => {
                  group.adjustments.forEach((groupAdj: any) => {
                    if (item.adjustmentId === groupAdj.adjustmentId) {
                      adjustments.push(item);
                    }
                  });
                });
              });

              if (this.productSizeInfo.extras.length) {
              //go through each extra that can exist on the product
                this.productSizeInfo.extras.forEach((item) => {
                  
                  //go through each row
                  for (let i = 0; i < group.quantity; i++) {
                    //get the extra customization for this row and extra
                    let extra = group.extraCustomizations.find(x => (x.row == i) && (x.extraId == item.id));
                    if (extra)
                    {
                      //if it exists push it with the existing value
                      extras.push({
                        extraId: extra.extraId,
                        value: extra.value,
                        row: extra.row,
                        extraName: extra.extraName,
                        customizationTextMaximumLength: item.customizationTextMaximumLength,
                      });
                    }
                    else {
                      //if it doesn't push a new one with an empty value
                      extras.push({
                        extraId: item.id,
                        value: "",
                        row: i,
                        extraName: item.name,
                        customizationTextMaximumLength: item.customizationTextMaximumLength,
                      });
                    }
                    //if it still doesn't work blame Liam -> it didnt work Liam but it was an easy fix -> I take that back, had to do a bit more fixing :)
                  }
                });
            }

            //order the extras by row
            extras = extras.sort((a: any, b: any) => {
              return a.row - b.row;
            })

              this.quantitiesArray.push({
                orderCustomerContactId: group.orderCustomerContactId,
                orderCustomerContactName: group.orderCustomerContactName,
                paymentReference: group.paymentReference,
                quantity: group.quantity,
                selectedSizeGroup: sizeGroup,
                sizeGroupOrder: sizeGroup?.order || 0,
                selectedSize: size,
                selectedAdjustments: [...adjustments],
                extraCustomizations: [...extras],
                orderPackNotes: group.orderPackNotes
              });
            }
          );
          this.updateSizeDetails();
          // debugger
        } else if (
          this.quantitiesArray[0].selectedSizeGroup &&
          !this.quantitiesArray[0].selectedSizeGroup.sizeGroupId
        ) {
          this.quantitiesArray[0].quantity = this.quantity;
          this.quantitiesArray[0].selectedSizeGroup = this.productSizeInfo
            .sizeGroups[0] as any;
          this.productSizeInfo.adjustmentTypes.forEach((adjustment: any) => {
            this.quantitiesArray[0].selectedAdjustments.push(
              adjustment.adjustments[0]
            );
          });

          if (this.productSizeInfo.extras.length) {
            let extras = [] as Array<any>;

            for (let i = 0; i < this.quantity; i++) {
              this.productSizeInfo.extras.forEach((extra: any) => {
                extras.push({
                  extraId: extra.id,
                  value: "",
                  row: i,
                  extraName: extra.name,
                  customizationTextMaximumLength:
                    extra.customizationTextMaximumLength,
                });
              });
            }

            this.quantitiesArray[0].extraCustomizations = extras;
          }

          this.defaultSizeforSizeGroup(this.quantitiesArray[0]);
        }
      }
    })
    .catch((error) => {
      if (
          Object.prototype.hasOwnProperty.call(
            this.selectedProduct,
            "quantities"
          ) &&
          this.selectedProduct.quantities.length
        ) {
          this.invalidSizing = true
          this.quantitiesArray = [];
          this.selectedProduct.quantities.forEach(
            (group: OrderCartItemQuantityViewModel) => {
              let extras = [] as Array<any>;

              if (group.extraCustomizations.length) {
                group.extraCustomizations.forEach((item: OrderCartItemQuantityExtraCustomizationViewModel) => {
                    extras.push({
                      extraId: item.extraId,
                      value: item.value,
                      row: item.row,
                      extraName: item.extraName,
                    });
                });
            }

            //order the extras by row
            extras = extras.sort((a: any, b: any) => {
              return a.row - b.row;
            })

            this.quantitiesArray.push({
                orderCustomerContactId: group.orderCustomerContactId,
                orderCustomerContactName: group.orderCustomerContactName,
                paymentReference: group.paymentReference,
                quantity: group.quantity,
                selectedSizeGroup: {
                  id: group.sizeGroupId,
                  displayName: group.sizeGroupDisplayName,
                  sizes: [],
                },
                selectedSize: {
                  id: group.sizeId,
                  name: group.sizeName,
                },
                selectedAdjustments: [... group.adjustments],
                extraCustomizations: [...extras],
                orderPackNotes: group.orderPackNotes
              });
            }
          );
        } 

      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    })
  }

  defaultSizeforSizeGroup(row: any) {
    if (row.selectedSizeGroup.sizeGroupId) {
      row.selectedSize = row.selectedSizeGroup.sizes[0];
    }
    this.updateSizeDetails();
  }

  beforeDestroy() {
    this.showSizes()
  }
}
