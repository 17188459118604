var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-container"},[_c('el-row',{staticStyle:{"flex-wrap":"wrap"},attrs:{"type":"flex","justify":"space-between","align":"bottom"}},[_c('el-col',{attrs:{"xs":24,"md":10}},[_c('h1',[_vm._v("Orders")])]),_c('el-col',{staticStyle:{"margin-left":"auto"},attrs:{"span":12,"md":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.orders.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":12,"md":6}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.orderItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"align":"center","width":"50"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":_vm.areAllSelected},on:{"change":_vm.handleSelectAll}})]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [(!_vm.disableCheckbox(row.orderStatusId))?_c('el-checkbox',{attrs:{"value":_vm.selection.includes(row.id)},on:{"change":function($event){return _vm.handleSelect(row.id)}}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"130","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])]),_c('div',{staticClass:"clear-all-container",on:{"click":function($event){_vm.selection = []}}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Selected")])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditFactoryOrder',
            params: {
              slug: _vm.handleName(row.referenceNumber),
              id: row.id,
              pageNumber: _vm.pageNumber,
              itemPerPage: _vm.itemPerPage,
              search: _vm.search,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])}),_c('el-table-column',{attrs:{"width":"90","prop":"referenceNumber","label":"Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.referenceNumber))])]}}])}),_c('el-table-column',{attrs:{"min-width":"145"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderStatuses),function(orderStatus){return _c('li',{key:orderStatus.id,class:{'selected-filter': _vm.status.includes(orderStatus.id)},on:{"click":function($event){return _vm.handleStatus(orderStatus.id)}}},[_vm._v(_vm._s(orderStatus.name))])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearOrderStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.status.length > 0 },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Order Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.orderStatusId))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"110","prop":"exWorksDate","label":"EXW Date"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('p',{class:{'filtered-status': _vm.sortBy.column !== undefined},staticStyle:{"cursor":"pointer"},on:{"click":_vm.handleSortBy}},[_c('i',{class:_vm.sortEXWDate()}),_vm._v(" Required EXW Date")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.exWorksDate ? _vm.handleDate(row.exWorksDate) : ''))])]}}])}),_c('DateRangeFilter',{attrs:{"width":120,"heading":'Confirmed EXW Date',"name":'exWorksConfirmedByFactoryDate',"isSelected":_vm.orderPlacement.startDate !== undefined},on:{"updateDate":function (x) {(_vm.orderPlacement = x), _vm.loadInitialData();}}}),_c('el-table-column',{attrs:{"min-width":"230","prop":"exWorksNotes","label":"Notes"}}),_c('el-table-column',{attrs:{"width":"130","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])]),_c('div',{staticClass:"clear-all-container",on:{"click":function($event){_vm.selection = []}}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Selected")])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditFactoryOrder',
            params: {
              id: row.id,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.orders.totalCount,"totalPages":_vm.orders.totalPages,"pageSize":_vm.orders.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }