





















































import {
  FabricChannelAdminCreateModel,
  FabricChannelAdminUpdateModel,
  FabricChannelAdminViewModel,
  LayerAdminListViewModel,
  LayerAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import { sortAscending } from "@/utils/sortAscending";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminFabricChannels, AdminLayers } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewFabricChannel",
  components: {
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  fabricChannel: FabricChannelAdminViewModel = {
    id: '',
    internalName: "",
    externalName: "",
    hasProductBuilderOptionsGenerationLogs: false,
    layerIds: []
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newFabricChannel: FabricChannelAdminCreateModel | FabricChannelAdminUpdateModel = {
    internalName: "",
    externalName: "",
    layerIds: []
  };
  loading = false
  dirtyTimer: any = null
  fromPath = ''
  layers: Array<LayerAdminViewModel> = [];

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get sortedLayers() {
    return sortAscending(this.layers, false);
  }
  
  created() {
    this.loadInitialData()
    .then(() => {
      this.clearDirtyClasses()
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      // console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        // console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'FabricChannels') {
        this.$router.back()
      } else {
        this.$router.push({ name: "FabricChannels"});
      }
    } else {
      this.submitForm();
    }
  }

  async loadLayers(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminLayers.adminLayersGet(1, 9999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.layers = res.data.resultData!
            .items as Array<LayerAdminViewModel>;
          // console.log("layers", this.layers);
          if (refresh) {
            this.loading = false;
            this.$message.success("Section Refreshed.");
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  submitForm() {
    this.loading = true
    if (this.fabricChannel.id) {
      AdminFabricChannels.adminFabricChannelsIdPut(this.fabricChannel.id, this.newFabricChannel)
        .then((res) => {
          if(res.data.succeeded) {
            this.loading = false
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses()
            })
          }
          
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    } else {
      AdminFabricChannels.adminFabricChannelsPost(this.newFabricChannel)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.loading = false
            this.dialogVisible = false;
            this.$router.push({ name: "FabricChannels" });
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  async loadInitialData() {
    this.loading = true
    await this.loadLayers();

    if (this.id) {
      await AdminFabricChannels.adminFabricChannelsIdGet(this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.fabricChannel = res.data.resultData as FabricChannelAdminViewModel;
          this.name = this.fabricChannel.internalName;
          this.newFabricChannel = (({ id, ...rest }) => rest)(this.fabricChannel) as any;    
          this.loading = false 
        } 
      })
      .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    } 
    this.loading = false 
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
