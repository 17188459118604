


















































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import countries from "@/assets/country-codes/countries.json";

const AppProps = Vue.extend({
  props: {
    placeholder: { default: "" },
    span: { default: 12 },
    label: { default: "" },
    name: { default: "" },
    rules: { default: "" },
    mode: { default: "eager" },
    type: { default: "" },
    size: { default: "" },
    maxlength: { default: null },
    isDisabled: { default: false },
    number: { default: "" },
    code: { default: "" },
  },
});

@Component({
  name: "BasicPhoneNumberField",
  components: { ValidationProvider, ValidationObserver, },
})
export default class extends AppProps {
  countryCodes = countries;
  selectedCode = "+1";
  fullNumber = "";

  created() {
    this.countryCodes = this.countryCodes.sort((a: any, b: any) => {
      return a.dialCode - b.dialCode;
    });
  }

  handleInput(value:any, e:any) {
    let regex = /[^0-9 \-]+/g;
    let matches = [...value.matchAll(regex)]
      if (matches.length) {
        matches.forEach((match:any) => {
          value = value.replace(match[0], "");
        })
      }
    this.$emit('update:number', value)
  }
}
