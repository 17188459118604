var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container seo-list-container"},[_c('h1',{attrs:{"id":"title"}},[_vm._v("SEO Page Settings")]),_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{name: 'NewSEOSettings'}}},[_c('button',{staticClass:"new-button",attrs:{"disabled":_vm.loading}},[_vm._v("New Settings")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.SEOEntryPages.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
                    _vm.itemPerPage = value;
                    _vm.loadInitialData();
                },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"searchSlug","size":"mini","placeholder":"Search url","clearable":""},on:{"clear":function($event){return _vm.handleSearch('searchSlug')}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch('searchSlug')}},model:{value:(_vm.slugSearch),callback:function ($$v) {_vm.slugSearch=$$v},expression:"slugSearch"}})],1)])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.SEOEntries,"row-key":"url","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"prop":"url","label":"Url"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.url))])]}}])}),_c('el-table-column',{attrs:{"prop":"canonicalUrl","label":"Canonical Url"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.canonicalUrl))])]}}])}),_c('el-table-column',{attrs:{"prop":"noIndex","label":"No Index"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('input',{staticStyle:{"display":"block","width":"20px","height":"20px"},attrs:{"type":"checkbox","disabled":""},domProps:{"checked":scope.row.noIndex}})]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"170"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v("Clear Filters")])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
                        name: 'EditSEOSettings',
                        params: {
                            url: row.url,
                        }
                    }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v(" Edit ")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row.url)}}},[_vm._v("Delete")])]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.SEOEntryPages.totalCount,"totalPages":_vm.SEOEntryPages.totalPages,"pageSize":_vm.SEOEntryPages.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }