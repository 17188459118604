















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminFactories, AdminOrderPacks, AdminOrders, PublicOrders } from "@/network/api";
import {
  FactoryAdminListViewModel,
  FactoryAdminListViewModelPaginatedList,
  OrderPackItemViewModel,
} from "@/api-client";
import PreviewImage from "@/components/form-items/PreviewImage.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isFactory: {
      type: Boolean,
      default: false,
    },
    reload: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  components: { PreviewImage, BasicSelectorField },
})
export default class extends AppProps {
  loading: boolean = false;
  id: string = this.$route.params.id ? this.$route.params.id : "";
  orderId = "";
  orderPack: any = null;
  orderInfo: Array<OrderPackItemViewModel> = [];
  orderRef: string = "";
  specialTerms = "";
  placementDate: string = "";
  approvalDate: string = "";
  exWorksDate: string = "";
  // exWorksConfirmedByFactoryDate: string = "";
  customerResponseDate: string = "";
  deliveryTypeId: string = "";
  generalInfo: any = [];
  factories: Array<FactoryAdminListViewModel> = [];
  selectedFactoryId = "";
  notesToCustomer: string = "";
  notesFromCustomer: string = "";
  orderPackDraftNumber = 0;
  customerFiles: Array<string> = [];
  validOrderPack = true;

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get hasPermission() {
    if (
      this.roles &&
      (this.roles.includes("Admin") ||
        this.roles.includes("Sales") ||
        this.roles.includes("Sales Admin") ||
        this.roles.includes("Operations"))
    ) {
      if (this.isCustomer) {
        return false;
      }
      return true;
    }
    return false;
  }

  hasDesignAssets(item: any) {
    let hasCustomPattern = false;

    if (item.customizedProduct.items.length > 0) {
      return true;
    }

    item.customizedProduct.layers.forEach((spec: any) => {
      if (
        this.hasPermission &&
        (this.propCheck(spec.fabric.colour, "itemAsset") ||
          spec.fabric.colour.patternImageUrl ||
          spec.fabric.colour.highQualityPatternImageUrl)
      ) {
        hasCustomPattern = true;
      }
    });
    return hasCustomPattern;
  }

  propCheck(object: object, prop: string) {
    if (object) {
      let hasProp = Object.prototype.hasOwnProperty.call(object, prop);

      if (hasProp && object[prop]) {
        return true;
      }
    }
    return false;
  }

  printOP() {
    document
      .querySelectorAll(".el-table__row:not(.expanded) .el-table__expand-icon")
      .forEach(
        function (e: any) {
          e.click();
        },
        setTimeout(function () {
          window.print();
        }, 200)
      );
  }

  downloadAssets() {
    this.loading = true;
    if(!this.isFactory) {
      AdminOrderPacks.adminOrderPacksGetAssetArchiveForOrderPackIdGet(
      this.id,
      this.selectedFactoryId || undefined,
      {
        responseType: "arraybuffer",
      }
    )
      .then(async (res) => {
        if (res.status == 200) {
          let fileName = `${this.orderPack.referenceNumber}-Order-Pack-${this.orderPackDraftNumber}.zip`;
          await this.saveZip(res.data, fileName);
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error?.response?.data?.errors?.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
    } else {
      AdminOrders.adminOrdersAssetArchiveDownloadTokenGet(
      this.id,
      {
        responseType: "arraybuffer",
      }
    )
      .then(async (res) => {
        if (res.status == 200) {
          let fileName = `${this.orderPack.referenceNumber}-Factory-Order-Pack-${this.orderPackDraftNumber}.zip`;
          await this.saveZip(res.data, fileName);
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error?.response?.data?.errors?.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
    }

  }

  downloadCustomerFiles() {
    this.loading = true;
    AdminOrderPacks.adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(
      this.id,
      {
        responseType: "arraybuffer",
      }
    )
      .then(async (res) => {
        if (res.status == 200) {
          let fileName = `${this.orderPack.referenceNumber}-Order-Pack-${this.orderPackDraftNumber}-Customer-Files.zip`;
          await this.saveZip(res.data, fileName);
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error?.response?.data?.errors?.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  async saveZip(data: any, fileName) {
    var assetBlob = new Blob([data], { type: "application/zip" });
    var assetUrl = URL.createObjectURL(assetBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = assetUrl;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  copyCustomization(idSelect, isSingle) {
    var copyText;

    if (isSingle) {
      copyText = document.getElementById(idSelect)! as HTMLInputElement;
    } else {
      var copyString = "";
      document
        .querySelectorAll(
          ".el-table__body-wrapper [data-extra-index='" +
            idSelect +
            "'] .copy-td-value"
        )
        .forEach((element: any, index) => {
          copyString += `${index == 0 ? "" : "\n"}${element.value}`;
        });
      copyText = document.getElementById(
        "all-text-" + idSelect
      )! as HTMLInputElement;
      copyText.value = copyString;
    }

    navigator.clipboard.writeText(copyText.value).then(
      function () {},
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  saveSvg(svgNumber, fontObj, name) {
    var svgEl = document.getElementById(`asset-${svgNumber}`)!;
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var svgData = svgEl.outerHTML;
    var fontDetail = `<defs><style> @font-face{font-family:'${fontObj.name}';font-style:normal;font-weight:400;font-display:block;src:url(${this.uploadPath}/${fontObj.fontFileUrl})} </style></defs>`;
    svgData =
      svgData.split("<rect")[0] +
      fontDetail +
      svgData.split("<rect")[1].split("</rect>")[1];
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  toggleSizeInfo(size, rowIndex) {
    size.rows[rowIndex].show =
      size.rows[rowIndex].show == undefined ? true : !size.rows[rowIndex].show;
  }

  generalColStyle({ row, column, rowIndex, columnIndex }) {
    const endStyle: any[] = [];
    endStyle.push({ padding: "8px 0" });
    if (columnIndex == 0) {
      endStyle.push({ "font-weight": "bold" });
      endStyle.push({ "text-align": "right" });
    }
    if (
      row.name.toLowerCase() == "delivery type:" &&
      row.val.toLowerCase().indexOf("fail") > 0
    ) {
      endStyle.push({ "background-color": "#ffeded" });
      endStyle.push({ color: "#ff4949" });
    }
    return endStyle;
  }

  isImgTag(fileUrl) {
    return ["jpg", "jpeg", "png", "svg"].some(
      (test) => fileUrl.indexOf(test) > -1
    );
  }

  getSizeTitle(columns, values) {
    var titleString: string = "Note";

    Object.keys(columns).forEach((col) => {
      if (columns[col].toString().toLowerCase() == "size") {
        titleString += " on " + values[col];
        return titleString;
      }
    });

    return titleString;
  }

  sizingColStyle({ row, column, rowIndex, columnIndex }) {
    const endStyle: any[] = [];
    endStyle.push({ padding: "8px 5px" });
    return endStyle;
  }

  openExtraRows(rows) {
    rows.forEach(function (e: any) {
      e.click();
    });
  }

  hasHeader(rowIndex, size) {
    if (
      rowIndex == 0 ||
      size.rows[rowIndex - 1].extraCustomizationRows.length > 0
    ) {
      return true;
    }
    return false;
  }

  created() {
    document.querySelector("body")!.id = "OPLoaded";
    this.loadFactories();
    this.loadOrder();

    this.$watch("selectedFactoryId", () => {
      this.loadOrder();
    });

    this.$watch("reload", () => {
      if (this.reload) {
        this.loadOrder();
        this.$emit("reload");
      }
    });
  }

  updated() {
    this.$nextTick(function () {
      document
        .querySelectorAll(
          ".el-table__row:not(.expanded) .el-table__expand-icon"
        )
        .forEach(function (e: any) {
          e.click();
        });
    });
  }

  async loadFactories() {
    if (!this.isFactory && this.hasPermission) {
      this.loading = true;
      await AdminFactories.adminFactoriesGetFactoriesForOrderPackGet(
        1,
        999999999,
        this.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.factories = res.data.resultData
              ?.items as Array<FactoryAdminListViewModel>;

            // if(this.factories.length === 1) {
            //     this.selectedFactoryId = this.factories[0].id;
            // }
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error?.response?.data?.errors?.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
  }

  formatresponse(results: any) {
    this.orderId = results.orderId;
    this.specialTerms = results.specialTerms;
    this.notesToCustomer = results.notesToCustomer as string;
    this.notesFromCustomer = results.notesFromCustomer as string;
    this.orderPack = results.orderPack;
    this.customerFiles = results.files;
    this.orderRef = this.orderPack.referenceNumber;
    this.orderPackDraftNumber = results.versionNumber;
    this.placementDate = new Date(this.orderPack.orderPlacementDate!)
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    this.deliveryTypeId = results.deliveryTypeId;
    if (results.noFail && (this.hasPermission || this.isFactory)) {
      this.deliveryTypeId = `<span class="general-no-fail">${this.deliveryTypeId} <b><i class="el-alert__icon el-icon-warning is-big"></i><span><u>NOTE:</u> this is a no-fail order. the deadline for this order must be met.</span></b></span>`;
    }
    this.orderInfo = this.orderPack.orderItems;
    this.generalInfo = [];
    this.generalInfo.push(
      // { name: 'Customer code:', val: '123'},
      { name: "Draft Number:", val: results.versionNumber },
      { name: "Order Placed:", val: this.placementDate },
      { name: "Delivery Type:", val: this.deliveryTypeId }
    );
    if (!this.isFactory && results.approvalDate) {
      this.approvalDate = new Date(results.approvalDate!)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");

      this.generalInfo.push({
        name: "Order Approved:",
        val: this.approvalDate,
      });
    }
    if (results.exWorksDate && !this.isCustomer) {
      this.exWorksDate = new Date(results.exWorksDate!)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");

      this.generalInfo.push(
        { name: "EXW Date:", val: this.exWorksDate }
        // { name: 'EXW Date:', val: `${this.exWorksDate}${!results.exWorksApproved ? "<div><b>preparing to dispatch</b></div>" : "<div><b>dispatch</b></div>"}${results.exWorksNotes ? "<div class='note'><small>" + results.exWorksNotes + "</small></div>" : ""}`}
      );
    }
    // if(results.exWorksConfirmedByFactoryDate) {
    //     this.exWorksConfirmedByFactoryDate = new Date(results.!).toLocaleDateString('en-GB', {
    //         day: 'numeric', month: 'short', year: 'numeric'
    //     }).replace(/ /g, '-');

    //     this.generalInfo.push(
    //         { name: 'EXW Confirmed By Factory Date:', val: this.exWorksConfirmedByFactoryDate}
    //     );
    // }
    if (this.selectedFactoryId) {
      let factory = this.factories.find((f) => f.id == this.selectedFactoryId);
      this.generalInfo.push({ name: "Factory:", val: factory?.name });
    }
    if (!this.isFactory && results.customerResponseDate) {
      this.customerResponseDate = new Date(results.customerResponseDate!)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");

      this.generalInfo.push(
        { name: "Customer Response Date:", val: `${this.customerResponseDate}` }
        // { name: 'Customer Response Date:', val: `${this.customerResponseDate}${!results.isCustomerApproval ? "<div><small>note: the customer opted out of the approval process, so this is not a requirement</small></div>" : ""}`}
      );
    } else if(!this.isFactory) {
      this.generalInfo.push(
        { name: "Customer Response Date:", val: "n/a" }
        // { name: 'Customer Response Date:', val: `n/a${!results.isCustomerApproval ? "<div><small>note: the customer opted out of the approval process, so this is not a requirement</small></div>" : ""}`}
      );
    }

    let orderPackStatus = results.orderPackStatusId as string;
    this.validOrderPack = results.invalidationDate ? false : true;

    if(!this.isFactory) {
      if (orderPackStatus === "AwaitingFeedback") {
        orderPackStatus = "Awaiting Feedback";
      } else if (orderPackStatus === "Rejected") {
        orderPackStatus = "Changes Requested";
      }
      this.generalInfo.push({
        name: "Order Pack Status:",
        val: orderPackStatus + `${!this.validOrderPack ? " (Invalid)" : ""}`,
      });

      if (results.invalidationDate) {
        let invalidDate = new Date(results.invalidationDate)
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
          .replace(/ /g, "-");

        this.generalInfo.push({
          name: "Invalidation Date:",
          val: `<strong style="color:crimson;">${invalidDate}</strong>`,
        });
      }
    }

    if (results.invalidationReason) {
      this.generalInfo.push({
        name: "Invalidation Reason:",
        val: `<strong style="color:crimson;">${results.invalidationReason}</strong>`,
      });
    }

    this.$emit("loaded")
  }

  async loadOrder() {
    if (this.id) {
      this.loading = true;

      if (this.isCustomer) {
        await PublicOrders.ordersGetOrderPackOrderPackIdGet(this.id)
          .then((res) => {
            if (res.data.succeeded) {
              this.formatresponse(res.data.resultData);
            }
          })
          .catch((error) => {
            error?.response?.data?.errors?.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
            this.loading = false;
          });
      } else if(this.isFactory){
        AdminOrders.adminOrdersOrderPackFactoryTokenGet(this.id)
        .then(async (res) => {
          if (res.data.succeeded) {
            this.formatresponse(res.data.resultData);
          }
        })
        .catch((error) => {
          debugger;
          this.loading = false;

          let hasProp = Object.prototype.hasOwnProperty.call(error, "message");
          if (
            hasProp &&
            error.message ===
              "Cannot read properties of null (reading 'refreshToken')"
          ) {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message:
                "Please log in to the relevant account to view this page.",
            });
            this.$router.push(`/login?redirect=${this.$route.path}`);
          } else {
            if (error.response.status == 401) {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message:
                  "Please log in to the relevant account to view this page.",
              });
              this.$router.push(`/login?redirect=${this.$route.path}`);
            } else {
              error?.response?.data?.errors?.map((e: any) => {
                this.$message({
                  showClose: true,
                  type: "error",
                  duration: 0,
                  message: e.friendlyMessage,
                });
                return e.friendlyMessage;
              });
            }
          }
        });
      } else {
        await AdminOrderPacks.adminOrderPacksIdGet(
          this.id,
          this.selectedFactoryId || undefined
        )
          .then((res) => {
            if (res.data.succeeded) {
              this.formatresponse(res.data.resultData);
            }
          })
          .catch((error) => {
            error?.response?.data?.errors?.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
            this.loading = false;
          });
      }
    }
    this.loading = false;
  }
}
