











































































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

const AppProps = Vue.extend({
  props: {
    label: { default: "" },
    model: { default: '' },
    placeholder: { default: "" },
    span: { default: 12 },
    multiple: { default: false },
    name: { default: "" },
    rules: { default: "" },
    mode: { default: "eager" },
    // childArray: { default: [] },
    parentArray: { default: [] },
    initialOption: { default: false },
    uniqueLabel: { default: "" },
    customLabel: { default: "" },
    disabled: {default: false},
    valueKey: {default: 'id'},
    clickable: {default: false},
    mainRoute: {default: ''},
    hasGroup: {
      type: Object,
        default() {
            return { show: false, prop: '' }
        }
    }
  },
})

@Component({
  name: "BasicGroupSelectorField",
  components: { ValidationProvider, ValidationObserver },
})
export default class extends AppProps {
  selectedTags:Array<any> = []
  showTags = false

  get labelNoWhitespace() {
    if(this.label) {
      return this.label.replace(/\s/g, "");
    }
    return ''
  }

  created() {
    this.$watch('model', () => {
      if(this.model && this.model.length) {
        let modelArray = [...this.model] as Array<string>
        let selected = [] as Array<any>
        modelArray.map((item:any) => {
          this.parentArray.forEach((child:any) => {
            if(child.childProductTags.length) {
              child.childProductTags.forEach((x:any) => {
                if(x.id === item) {
                  selected.push(x)
                }
              })
            }
          })
        })
        this.selectedTags = selected
      } else {
        this.selectedTags = []
      }    
    })
  }

  openCreate(isGroup:boolean) {
    let routeName = '';
    
    isGroup ? routeName = `New${this.mainRoute}Group` :  routeName = `New${this.mainRoute}`;

    let routeData = this.$router.resolve({name: routeName});
    
    window.open(routeData.href, '_blank');
  }

  handleTagClick(e:any, item:any) {
    let routeName = '';
    let parentId = '';

    this.parentArray.forEach((arr:any) => {
      arr.childProductTags.forEach((tag:any) => {
        if(tag.id === item.id) {
          parentId = arr.id
        }
      })
    })

    routeName = `Edit${this.mainRoute}`;

    let routeData = this.$router.resolve({name: routeName, params: {id: parentId}});

    window.open(routeData.href, '_blank');
  }

  refresh() {
    this.$emit('refresh', true)
  }
}
