























import { Component, Vue } from "vue-property-decorator";
import ProductsList from "./ProductsList.vue";
import ProductBundlesList from "./ProductBundlesList.vue";
import store from "@/store";
import { AdminProducts } from "@/network/api";

@Component({
  name: "ArtAssets",
  components: { ProductsList, ProductBundlesList },
})
export default class extends Vue {
  activeTab: any = "products";
  loading = false

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "products"
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "products"
    }
    });
  }

  handleTabClick(tab:any) {
    this.activeTab = tab.name; //TODO add correct id below
    this.$router.replace({ name: "ProductList", query: { tab: this.activeTab } });
  }

  regenerateProductBuilderOptions(regenAllProds: boolean, ignoreRegenDelay: boolean) {
    this.loading = true
    AdminProducts.adminProductsGenerateOptionsPost(regenAllProds, ignoreRegenDelay)
    .then((res) => {
        if (res.data.succeeded) {
          if(regenAllProds) {
            this.$message({showClose: true, duration: 0, type: 'success', message: 'Force all product options generation initiated.'})
          } else {
            this.$message({showClose: true, duration: 0, type: 'success', message: 'Queued product options generation initiated.'})
          }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.$message({showClose: true, type: 'error', duration: 0, message: 'Error generating product options.'})
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }
}
