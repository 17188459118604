var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container new-home-banner-container"},[_c('DialogBox',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"cancelVisible":_vm.cancelVisible,"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage},on:{"confirm":_vm.handleConfirm,"close":function($event){_vm.dialogVisible = false}}}),_c('h1',[_c('span',[_vm._v(_vm._s(_vm.id ? "Edit" : "New"))]),_vm._v(" Home Page Banner ")]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"model":_vm.newBanner,"label-position":"top"}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('h4',{staticClass:"mavon-label"},[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"blurb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('mavon-editor',{staticClass:"mavon-editor",attrs:{"placeholder":'Enter title',"language":'en',"toolbars":_vm.mavenToolbar},model:{value:(_vm.newBanner.boxTitle),callback:function ($$v) {_vm.$set(_vm.newBanner, "boxTitle", $$v)},expression:"newBanner.boxTitle"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('h4',{staticClass:"mavon-label"},[_vm._v("Text")]),_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"blurb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('mavon-editor',{staticClass:"mavon-editor",attrs:{"placeholder":'Enter text',"language":'en',"toolbars":_vm.mavenToolbar},model:{value:(_vm.newBanner.boxDescription),callback:function ($$v) {_vm.$set(_vm.newBanner, "boxDescription", $$v)},expression:"newBanner.boxDescription"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('BasicSelectorField',{attrs:{"label":'Text Align',"model":_vm.newBanner.boxTextAlignmentClass,"array":_vm.textAlignOptions},on:{"update:model":function($event){return _vm.$set(_vm.newBanner, "boxTextAlignmentClass", $event)}}}),_c('BasicSelectorField',{attrs:{"label":'Placement',"model":_vm.newBanner.boxPositionClass,"array":_vm.placementOptions},on:{"update:model":function($event){return _vm.$set(_vm.newBanner, "boxPositionClass", $event)}}})],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('BasicInputField',{attrs:{"label":'Button Text',"model":_vm.newBanner.buttonText},on:{"update:model":function($event){return _vm.$set(_vm.newBanner, "buttonText", $event)}}}),_c('BasicInputField',{attrs:{"isDisabled":!_vm.newBanner.buttonText,"name":'button link',"rules":"link","placeholder":!_vm.newBanner.buttonText ? 'Add button text to enable' : '',"label":!_vm.newBanner.buttonText && _vm.newBanner.buttonLink ? 'Button Link (Add button text to enable)' : !_vm.newBanner.buttonText ? 'Button Link' : 'Button Link*',"model":_vm.newBanner.buttonLink},on:{"update:model":function($event){return _vm.$set(_vm.newBanner, "buttonLink", $event)}}})],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"image-label"},[_vm._v("Banner Image*")]),_c('span',{staticClass:"dimensions-text"},[_c('p',[_vm._v("Recommended: 1920px x 667px ")])]),_c('ValidationProvider',{ref:"preview",attrs:{"slim":"","name":"banner image","rules":"banner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('ImageUploader',{ref:"bannerUploader",attrs:{"label":"Banner Image*","area":'banner'},on:{"removed":_vm.removeImage,"validate":function($event){return _vm.validateField('preview')}},model:{value:(_vm.newBanner.backgroundImageUrl),callback:function ($$v) {_vm.$set(_vm.newBanner, "backgroundImageUrl", $$v)},expression:"newBanner.backgroundImageUrl"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"image-label"},[_vm._v("Text Colour*")]),_c('span',{staticClass:"dimensions-text",staticStyle:{"visibility":"hidden"}},[_c('p',[_vm._v("See coulour suggestions")])]),_c('el-color-picker',{attrs:{"color-format":'hex',"show-alpha":false},model:{value:(_vm.newBanner.boxTextColour),callback:function ($$v) {_vm.$set(_vm.newBanner, "boxTextColour", $$v)},expression:"newBanner.boxTextColour"}})],1),_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"image-label"},[_vm._v("Background Colour*")]),_c('span',{staticClass:"dimensions-text",staticStyle:{"visibility":"hidden"}},[_c('p',[_vm._v("Default transparent")])]),_c('el-color-picker',{attrs:{"color-format":'hex',"show-alpha":true},model:{value:(_vm.newBanner.boxBackgrounColour),callback:function ($$v) {_vm.$set(_vm.newBanner, "boxBackgrounColour", $$v)},expression:"newBanner.boxBackgrounColour"}})],1)],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('el-col',{attrs:{"span":6}}),_c('el-col',{attrs:{"span":6}},[_c('ColourSuggestions')],1),_c('el-col',{attrs:{"span":6}},[_c('ColourSuggestions',{attrs:{"type":"rgb"}})],1)],1)],1),_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submitForm)}}},[_vm._v("Save Changes")]),_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":_vm.beforeBack}},[_vm._v("Back")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }