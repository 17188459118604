



































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminVouchers } from '@/network/api'
import {
  VoucherAdminListViewModelPaginatedList,
  VoucherAdminViewModel,
  VoucherType,
  VoucherApplyToType,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"
import { formatDate } from "@/utils/formatDate"

@Component({
  name: 'Vouchers',
  components: { Pagination, DialogBox, ItemsPerPage, ElTableDraggable },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    textFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        true: 'success',
        false: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends Vue {
  vouchers: VoucherAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  vouchersItems: Array<VoucherAdminViewModel> = [];
  pageNumber = 1;
  itemPerPage = 10;
  search: string | undefined = '';
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this voucher?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedVoucher: VoucherAdminViewModel = {
    id: '',
    voucherTypeId: VoucherType.Absolute,
    value: 0,
    redemptionCode: '',
    minimumCartValue: null,
    maximumRedemptions: null,
    startDate: null,
    endDate: null,
    voucherApplyToTypeId: VoucherApplyToType.TotalCost,
    productIds: [],
    countryIds: []
  };
  debounce: any = null;
  selectedTypeFilter: VoucherType | '' = ''
  selectedApplicationFilter: VoucherApplyToType | '' = ''
  showAllText: Array<string> = [];
  loading = false
  hideInvalid = false;
  voucherApplyTypes: any = {
    [VoucherApplyToType.TotalCost.toString()]: 'Total Cost',
    [VoucherApplyToType.ProductCost.toString()]: 'Product Cost',
    [VoucherApplyToType.DeliveryCost.toString()]: 'Delivery Cost'
  }

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      selectedTypeFilter: this.selectedTypeFilter || undefined
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  copyRedemptionCode(code:string) {
    navigator.clipboard.writeText(code)
    this.$message.success('Redemption code copied.')
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }

  clearTypeFilter() {
    this.pageNumber = 1
    this.selectedTypeFilter = '';
    this.loadInitialData();
  }

  clearApplicationTypeFilter() {
    this.pageNumber = 1
    this.selectedApplicationFilter = '';
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = '';
    this.hideInvalid = false;
    this.clearTypeFilter();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  handleType(selected: VoucherType) {
    if(selected === this.selectedTypeFilter) {
      this.selectedTypeFilter = ''
    } else {
      this.selectedTypeFilter = selected
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleApplicationType(selected: VoucherApplyToType) {
    if(selected === this.selectedApplicationFilter) {
      this.selectedApplicationFilter = ''
    } else {
      this.selectedApplicationFilter = selected
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true
    await AdminVouchers.adminVouchersGet(this.pageNumber, this.itemPerPage, this.hideInvalid, this.selectedTypeFilter || undefined, this.selectedApplicationFilter || undefined, this.search || undefined)
      .then((res) => {
        if(res.data.succeeded) {
          this.vouchers = res.data
          .resultData as VoucherAdminListViewModelPaginatedList
        this.vouchersItems = this.vouchers
          .items as Array<VoucherAdminViewModel>
          console.log('vouchers items',this.vouchersItems);
        }
        this.loading = false        
      })
      .catch((error) => {
        this.loading = false
              this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
      })
  }

  confirmDelete(voucher: any) {
    this.dialogVisible = true
    this.selectedVoucher = voucher;
  }

  deleteVoucher() {
    if (this.selectedVoucher.id) {
      this.loading = true
      AdminVouchers.adminVouchersIdDelete(this.selectedVoucher.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false          
        })
        .catch((error) => {
          this.loading = false
              this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        })
    }
    this.dialogVisible = false
    this.loading = false
    this.selectedVoucher = {
      id: '',
      voucherTypeId: VoucherType.Absolute,
      value: 0,
      redemptionCode: '',
      minimumCartValue: 0,
      maximumRedemptions: 0,
      startDate: '',
      endDate: '',
      voucherApplyToTypeId: VoucherApplyToType.TotalCost,
      productIds: [],
      countryIds: []
    }
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
