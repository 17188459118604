


























import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import SidebarItem from "./SidebarItem.vue";
import SidebarLogo from "./SidebarLogo.vue";
import variables from "@/styles/_variables.scss";
import store from "@/store";

@Component({
  name: "SideBar",
  components: {
    SidebarItem,
    SidebarLogo,
  },
})
export default class extends Vue {
  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get sidebar() {
    return AppModule.sidebar;
  }

  get routes() {
    return (this.$router as any).options.routes;
  }

  get variables() {
    return variables;
  }

  get activeMenu() {
    const route = this.$route;
    const { meta, path } = route;
    if (meta?.activeMenu) {
      return meta?.activeMenu;
    }
    return path;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }

  get showDashBoard() {
    if(this.roles && this.roles.length) {
      if(this.roles.includes("Admin")
      || this.roles.includes("Operations")
      || this.roles.includes("Sales")
      || this.roles.includes("Sales Admin")) {
        return true;
      }
    }
    return false
  }

  get showTaskOverview() {
    if(this.roles && this.roles.length) {
      if(this.roles.includes("Admin")
      || this.roles.includes("Operations")
      || this.roles.includes("Sales Admin")) {
        return true;
      }
    }
    return false
  }
}
