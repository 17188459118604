var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container new-tag-container"},[_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.handleConfirm,"close":function($event){_vm.dialogVisible = false}}}),_c('h1',[_c('span',[_vm._v(_vm._s(_vm.tagName ? "Edit" : "New"))]),_vm._v(" Product Tag Group "),(_vm.tagName)?_c('span',[_vm._v("- "+_vm._s(_vm.tagName))]):_vm._e()]),_c('br'),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"group name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('el-input',{staticClass:"parent-tag",attrs:{"placeholder":"Enter Group Name"},model:{value:(_vm.newParentProductTag.name),callback:function ($$v) {_vm.$set(_vm.newParentProductTag, "name", $$v)},expression:"newParentProductTag.name"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('el-row',{attrs:{"class-name":"row-buttons"}},[_c('button',{staticClass:"new-tag",attrs:{"disabled":_vm.loading || (_vm.parentProductTag.name === _vm.newParentProductTag.name)},on:{"click":function($event){return _vm.saveParentTag(_vm.newParentProductTag.name, null)}}},[_vm._v(" Save ")]),_c('button',{staticClass:"new-tag",attrs:{"disabled":_vm.loading || (_vm.parentProductTag.name === _vm.newParentProductTag.name)},on:{"click":function($event){_vm.newParentProductTag.name = _vm.parentProductTag.name}}},[_vm._v(" Cancel ")])]),(_vm.id)?_c('el-row',[_c('ElTableDraggable',{attrs:{"handle":'.handle'},on:{"reorder":_vm.handleReorder}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.childProductTags,"row-key":"id","default-expand-all":"","fit":"","border":"","resizable":"","stripe":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label-class-name":"col-label","label":"Tag Names"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"tag name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('el-input',{class:{ 'disabled-field': _vm.selectedRowId !== row.id },attrs:{"disabled":_vm.selectedRowId !== row.id,"size":"small","id":row.id,"value":row.id},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,false,2042079279)}),_c('el-table-column',{attrs:{"width":"80px","class-name":"handle centered"}},[_c('img',{attrs:{"width":"30px","height":"auto","src":require("@/icons/svg/move.svg"),"alt":"Handle"}})]),_c('el-table-column',{attrs:{"width":"180px","label-class-name":"buttons-label","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"disabled":(_vm.selectedRowId !== scope.row.id) && (_vm.selectedRowId !== ''),"plain":"","size":"mini","type":"primary"},on:{"click":function($event){return _vm.editField(scope.row)}}},[_vm._v(_vm._s(_vm.selectedRowId !== scope.row.id ? "Edit" : "Save"))]),_c('el-button',{attrs:{"disabled":(_vm.selectedRowId !== scope.row.id) && (_vm.selectedRowId !== ''),"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(scope.row)}}},[_vm._v(_vm._s(_vm.selectedRowId !== scope.row.id ? "Delete" : "Cancel"))])]}}],null,false,1033580692)})],1)],1),_c('button',{staticClass:"new-tag",attrs:{"disabled":_vm.loading || _vm.disable},on:{"click":_vm.addTagRow}},[_vm._v("New Tag")])],1):_vm._e(),_c('el-row',[_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":_vm.beforeBack}},[_vm._v("Back")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }