








































































































































































































































































































































import {
  CountryViewModel,
  CurrencyViewModel,
  StoreAdminListViewModel,
  StoreAdminUpdateModel,
  StoreAdminViewModel,
  StoreCustomizedProductListViewModel,
  StoreCustomizedProductsIdDeleteRequest,
  StoreStatus,
  StoreStatusViewModel,
  StoreType,
  UserAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminEnquiries, AdminStoreCustomizedProducts, AdminStores, AdminUsers, PublicCountries } from "@/network/api";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import PreviewImage from "@/components/form-items/PreviewImage.vue";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";
import { calculatePrice } from "@/utils/calculatePrice";	


const AppProps = Vue.extend({
  props: {
    id: { default: "" },
  },
});
@Component({
  name: "NewCustomStore",
  components: {
    BasicInputNumberField,
    BasicSelectorField,
    BasicTooltipHeading,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
    PreviewImage
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  dirtyTimer: any = null;
  customStoreTitle: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  customStore: StoreAdminViewModel = {
    id: "",
    ownerAspNetUserId: "",
    orderId: "",
    orderManagerAspNetUserId: "",
    orderManagerAspNetUserName: "",
    salesManagerAspNetUserId: "",
    salesManagerAspNetUserName: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeTypeId: StoreType.Private,
    storeStatusId: StoreStatus.Editing,
    countryId: '',
    countryName: '',
    currency: {
      id: "",
      name: "",
      code: "USD",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 2,
    },
    referenceNumber: ''
  };
  customStores: Array<StoreAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  newCustomStore: StoreAdminUpdateModel = {
    ownerAspNetUserId: "",
    orderManagerAspNetUserId: "",
    orderManagerAspNetUserName: "",
    salesManagerAspNetUserId: "",
    salesManagerAspNetUserName: "",
    startDate: "",
    endDate: "",
    title: "",
    deliveryFeePerCustomer: 0,
    storeStatusId: StoreStatus.Editing,
    countryId: '',
  };
  fromPath = "";
  admins: Array<UserAdminListViewModel> = [];
  usersList: Array<UserAdminListViewModel> = [];
  selectedDates: any = null;
  storeStatuses: Array<StoreStatusViewModel> = []
  customizedProducts: Array<StoreCustomizedProductListViewModel> = []
  allCountries: Array<CountryViewModel> = [];
  showDesigns = true;
  hasEnquiries = false;
  selectedCurrency: CurrencyViewModel = {
    id: "",
    name: "",
    code: "USD",
    symbol: "",
    pricingMultiplier: 0,
    decimalPlaces: 2,
  } 
  pickerOptions = {
    firstDayOfWeek: 1,
    shortcuts: [
      {
        text: "Last week",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last month",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 3 months",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last 6 months",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 120);
          picker.$emit("pick", [start, end]);
        },
      },
      {
        text: "Last year",
        onClick(picker: any) {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
          picker.$emit("pick", [start, end]);
        },
      },
    ],
  };

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get convertedDeliveryFee() {
    return calculatePrice(this.newCustomStore.deliveryFeePerCustomer as number, this.selectedCurrency.pricingMultiplier, this.selectedCurrency.decimalPlaces)
  }

  get publicUrl() {
    return `${process.env.VUE_APP_ROOT_WEB}/online-store/${this.customStore.id}`
  }

  get isOpen() {
    if(this.customStore.id && this.customStore.startDate) {
      const today = new Date().getTime();
      const startDate = new Date(this.customStore.startDate).getTime();
      const closeDate = new Date(this.customStore.endDate).getTime();
      return today >= startDate && today <= closeDate ? true : false;
    }
    return false;
  }


  convertedPrice(price:any) {
    return calculatePrice(price, this.selectedCurrency.pricingMultiplier, this.selectedCurrency.decimalPlaces)
  }

  created() {
    this.loadInitialData().then(() => {
      this.clearDirtyClasses();
    });

    this.$watch('newCustomStore.countryId', () => {
      this.handleCountryChange();
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined, 0].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  toWeb() {
    let link = `${process.env.VUE_APP_ROOT_WEB}/online-store-admin/${this.customStore.id}`;
    window.open(link, "_blank");
  }

  copyPublicUrl() {
    navigator.clipboard.writeText(this.publicUrl)
    this.$message.success('Public store URL copied.')
  }

  handleDateRanges(value: any) {
    let dates = {
      startDate: null as string | null,
      endDate: null as string | null,
    };
    if (value) {
      let d1 = new Date(value[0]);
      let d2 = new Date(value[1]);

      //if(d1.getTime() == d2.getTime()) {
      d2.setDate(d2.getDate() + 1);
      //}

      dates = {
        startDate: new Date(d1).toISOString(),
        endDate: new Date(d2).toISOString(),
      };
    }

    this.newCustomStore.startDate = this.selectedDates.startDate =
      dates.startDate as string;
    this.newCustomStore.endDate = this.selectedDates.endDate =
      dates.endDate as string;

    this.selectedDates = [
      this.newCustomStore.startDate,
      this.newCustomStore.endDate,
    ];
  }

  handleCountryChange() {
    if(['Editing', 'Rejected'].includes(this.customStore.storeStatusId)) {
      const country = this.allCountries.find(country => country.id === this.newCustomStore.countryId);
      if(country) {
        this.selectedCurrency = {...country.currency};
      }
    } else {
      this.selectedCurrency = {...this.customStore.currency};
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if (this.fromPath === "CustomStores") {
        this.$router.back();
      } else {
        this.$router.push({ name: "CustomStores" });
      }
    } else {
      this.submitForm();
    }
  }

  toDesigner(id: string) {
    let link = `${process.env.VUE_APP_ROOT_WEB}/designer?saved-design=${id}&store=${this.customStore.id}&admin=true`;
    window.open(link, "_blank");
  }

  checkForEnquiries() {
    if(this.id) {
      AdminEnquiries.adminEnquiriesGet(1, 1, false, undefined, undefined, undefined, undefined, undefined, this.id)
      .then((res) => {
        if (res.data.succeeded && res.data.resultData?.items.length) {
          this.hasEnquiries = true;
        } else {
          this.hasEnquiries = false;
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }
  }

  async loadUsers() {
    await AdminUsers.adminUsersGetUsersForOrderGet(1, 9999999)
    .then((res) => {
      if (res.data.succeeded) {
        this.usersList = res.data.resultData?.items as Array<UserAdminListViewModel>;
      }
    })
    .catch((error) => {
      this.loading = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    });
  }

  async loadStoreProducts(storeId: string) {
    await AdminStoreCustomizedProducts.adminStoreCustomizedProductsStoreIdGet(1, 999999, storeId)
    .then((res) => {
      if (res.data.succeeded) {
        this.customizedProducts = res.data.resultData?.items as Array<StoreCustomizedProductListViewModel>;
      }
    })
    .catch((error) => {
      this.loading = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    });
  }

  async loadStoreStatuses() {
    if(this.customStore.id) {
      await AdminStores.adminStoresGetAvailableStoreStatusesForStoreStoreIdGet(this.customStore.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.storeStatuses = res.data.resultData as Array<StoreStatusViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
    }
  }

  async saveDesigns() {
    await Promise.all(this.customizedProducts.map(async (item: StoreCustomizedProductListViewModel) => {
      const payload: StoreCustomizedProductsIdDeleteRequest  = {
        expectedQuantity: item.expectedQuantity,
        price: item.price,
        name: item.name
      }

      AdminStoreCustomizedProducts.adminStoreCustomizedProductsIdPut(item.id, payload)
      .then((res) => {
        if (res.data.succeeded) {
          return res;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
        this.loading = false;
        this.dialogVisible = false;
      });
    }))
  }

  async submitForm() {
    this.loading = true;
    if (this.customStore.id) {
      console.log("before save", this.newCustomStore);
      AdminStores.adminStoresIdPut(this.customStore.id, this.newCustomStore)
        .then(async (res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            // this.$message.success("Details Saved!");
            this.dialogVisible = false;
            await this.saveDesigns()
            .then(() => {
              this.$message.success("Saved!");
              this.loadStoreProducts(this.id);
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
          this.loading = false;
          this.dialogVisible = false;
        });
    }
  }

  async loadCountries() {
    this.loading = true;
    await PublicCountries.countriesGet().then((res) => {
      if (res.data.succeeded) {
        this.allCountries = res.data.resultData as Array<CountryViewModel>;
        this.handleCountryChange();
      }
      this.loading = false;
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: e.friendlyMessage,
        });
        return e.friendlyMessage;
      });
      this.loading = false;
      this.dialogVisible = false;
    });
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true;
      await AdminStores.adminStoresIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.customStore = res.data.resultData as StoreAdminViewModel;
            this.customStoreTitle = this.customStore.title;
            this.newCustomStore = (({ id, ...rest }) => rest)(
              this.customStore
            ) as any;

            if (this.newCustomStore.startDate && this.newCustomStore.endDate) {
              this.selectedDates = [
                this.newCustomStore.startDate,
                this.newCustomStore.endDate,
              ];
            }
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
          this.loading = false;
          this.dialogVisible = false;
        });

      if (
        this.roles.includes("Sales") ||
        this.roles.includes("Sales Admin") ||
        this.roles.includes("Operations") ||
        this.roles.includes("Admin")
      ) {
        await AdminUsers.adminUsersGetOrderManagersGet(
          1,
          999999,
          undefined,
          undefined
        )
          .then((res) => {
            if (res.data.succeeded) {
              this.admins = res.data.resultData!
                .items as Array<UserAdminListViewModel>;
            }
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
          });
      }

      await this.loadStoreStatuses();
      await this.loadUsers();
      await this.loadStoreProducts(this.id);
      await this.loadCountries();
      this.checkForEnquiries();
      this.loading = false;
    }
  }
}
