
















import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

@Component({
  name: "BasicTooltipHeading",
  components: { ValidationProvider, ValidationObserver },
  props: {
    heading: { default: "" },
    placement: { default: "right" },
    headingStyle: { default: "" },
    icon: { default: "el-icon-warning" },
    message: { default: "Note: Any updates to this section take effect immediately." },
    isMavon: { default: false }
  },
})
export default class extends Vue {}
