





























































import { Component, Prop, Vue } from 'vue-property-decorator'
import settings from '@/settings'
import store from '@/store';

@Component({
  name: 'SidebarLogo'
})
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean
  private title = settings.title
  search = ''
  debounce:any = null

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  get showDashBoardTasksSearch() {
    if(this.roles && this.roles.length) {
      if(this.roles.includes("Admin")
      || this.roles.includes("Operations")
      || this.roles.includes("Sales")
      || this.roles.includes("Sales Admin")) {
        return true;
      }
    }
    return false
  }

  // get showTaskOverview() {
  //   if(this.roles && this.roles.length) {
  //     if(this.roles.includes("Admin")
  //     || this.roles.includes("Operations")) {
  //       return true;
  //     }
  //   }
  //   return false
  // }

  handleSearch() {
    // clearTimeout(this.debounce);
    // this.debounce = setTimeout(() => {
      if(this.$route?.meta?.title !== 'Order & Enquiry Search') {
        this.$router.push({
          name: 'OrderEnquirySearch',
          query: {
            search: this.search
          }
        })
      } else {
          this.$router.replace({
            name: 'OrderEnquirySearch',
            query: {
              search: this.search
            }
          })
      }
    // }, 400);
  }
}
