


































































































































import {
  BannerItemAdminCreateModel,
  BannerItemAdminUpdateModel,
  BannerItemAdminViewModel
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import ColourSuggestions from "@/components/misc/ColourSuggestions.vue";
import { editorToolbar } from "@/utils/editorToolbar";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminBannerItems } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewBanner",
  components: { BasicSelectorField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField, ColourSuggestions },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  id: string = '';
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  pattern: Array<any> = [];
  banner: BannerItemAdminViewModel = {
    id: '',
    backgroundImageUrl: '',
    boxBackgrounColour: 'rgba(255, 255, 255, 0.7)',
    boxPositionClass: 'middle-left',
    boxTextAlignmentClass: 'start',
    boxTextColour: '#00233B'
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newBanner: BannerItemAdminCreateModel | BannerItemAdminUpdateModel = {
    backgroundImageUrl: '',
    boxBackgrounColour: 'rgba(255, 255, 255, 0.7)',
    boxPositionClass: 'middle-left',
    boxTextAlignmentClass: 'start',
    boxTextColour: '#00233B'
  };
  textAlignOptions: Array<any> = [
    { name: 'Left', id: 'start' },
    { name: 'Center', id: 'center' },
    { name: 'Right', id: 'end' }
  ]
  placementOptions: Array<any> = [
    { name: 'Top Left', id: 'top-left' },
    { name: 'Top Center', id: 'top-center' },
    { name: 'Top Right', id: 'top-right' },
    { name: 'Middle Left', id: 'middle-left' },
    { name: 'Middle Center', id: 'middle-center' },
    { name: 'Middle Right', id: 'middle-right' },
    { name: 'Bottom Left', id: 'bottom-left' },
    { name: 'Bottom Center', id: 'bottom-center' },
    { name: 'Bottom Right', id: 'bottom-right' }
  ]
  loading = false;
  dirtyTimer: any = null
  fromPath = '';
  mavenToolbar = editorToolbar;
  dirtyMavonTimer: any = null

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then((res) => {
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses();
      }, 1000);
    }); 

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("banner", {
      validate(value) {
        let uploader = self.$refs.bannerUploader as any;
        
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });
    
    extend('link', {
      validate(value) {
        if(!self.newBanner.buttonText) {
          return {
            valid:true,
            required: true
          };
      } else {
        return {
          required: true,
          valid: value?.length > 0 ? true : false
        };
      }   
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll(".dirty");

    if (mavonDirty.length) {
      for (let item of mavonDirty) {
        item.classList.remove("dirty");
      }
      // this.hasDirty = false
    }
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(path);
    this.$message.success("Value copied.");
  }

  uploadImages(): Promise<any> {
    let bannerUploader = this.$refs.bannerUploader as ImageUploader;
    let bannerPromise;

    if(bannerUploader) {
      bannerPromise = bannerUploader.uploadImage()
    }

    return Promise.all([bannerPromise])
  }

  getHeightAndWidthFromDataUrl = (dataURL:any) => new Promise(resolve => {
    const img = new Image()
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width
        })
      }
      img.src = dataURL
  })

  beforeImageUpload = async (file: any) => {
    const isType = (file.type === 'image/jpeg') || (file.type === 'image/png');
    const isLt2M = file.size / 1024 / 1024 < 2;
    
    if (!isType) {
      this.$message.error('Image must be JPG or PNG format.');
    }
    if (!isLt2M) {
      this.$message.error('Image size can not exceed 2MB.');
    }

    return isType && isLt2M;
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string){
    if (area == "banner") {
      this.newBanner.backgroundImageUrl = '';
    } 
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'HomePage') {
        this.$router.back()
      } else {
        this.$router.push({ name: "HomePage"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true
    this.uploadImages().then((results) => {
      console.log('result', results);
      
      if(results[0].imageUrl) {
        this.newBanner.backgroundImageUrl = results[0].imageUrl;
      }

      if(this.newBanner.boxTextColour) {
        this.newBanner.boxTextColour = this.newBanner.boxTextColour.slice(1)
      }

      if(!this.newBanner.buttonText) {
        this.newBanner.buttonLink = '';
      }

        if (this.banner.id) {
          console.log('before save', this.newBanner);
          
          AdminBannerItems.adminBannerItemsIdPut(this.banner.id, this.newBanner)
            .then((res) => {
              console.log("success?", res);
              this.loading = false
              this.$message.success('Saved!');
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                this.loading = false
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
            });
        } else {
          AdminBannerItems.adminBannerItemsPost(this.newBanner as BannerItemAdminCreateModel)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.loading = false
                this.$message.success('Saved!');
                this.dialogVisible = false;
                this.$router.push({ name: "HomePage" });
              }
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                this.loading = false
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
            });
        }
    });
  }

  async loadInitialData() {
    if (this.id) {   
      this.loading = true  
      await AdminBannerItems.adminBannerItemsIdGet(this.id).then((res) => {
        if(res.data.succeeded) {
          this.banner = res.data.resultData as BannerItemAdminViewModel;
        this.newBanner = (({ id, ...rest }) => rest)(this.banner) as any;
        console.log('new banner', this.newBanner);
        
        if(this.newBanner.boxTextColour) {
          this.newBanner.boxTextColour = `#${this.newBanner.boxTextColour}`
        }

        if (this.newBanner.backgroundImageUrl) {
          this.pattern = [{
            imageUrl: this.newBanner.backgroundImageUrl
          }];
        }
        this.loading = false
        }      
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
        this.dialogVisible = false;
      });   
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
