











































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicPhoneNumberField from "@/components/form-items/BasicPhoneNumberField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import AddressFields from "@/components/orders/billing-delivery/AddressFields.vue";
import {
  ValidationProvider
} from "vee-validate";
import { DeliveryType } from "@/api-client";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
  }
});

@Component({
  name: "BillingTab",
  components: {  ValidationProvider, BasicInputField, BasicSwitchField, BasicDateField, BasicPhoneNumberField, AddressFields, BasicSelectorField },
})
export default class extends AppProps {
  deliveryTypes = [
    {
      id: DeliveryType.Standard,
      name: 'Standard',
    },
    {
      id: DeliveryType.Express,
      name: 'Express',
    }
  ]
  // courierItems: Array<CourierAdminListViewModel> = [];
  // selectedCourier: CourierAdminListViewModel = {
  //   id: "",
  //   name: "",
  //   trackingUrlFormat: ""
  // }


  created() {
    // this.loadInitialData()
    // this.$watch
    // if(this.order) {
    //   if(this.order.deliveryTypeId == DeliveryType.Express) {
    //     this.deliveryType = true;
    //   } else {
    //     this.deliveryType = false;
    //   }

    //   if(this.order.primaryCustomerContact?.phoneNumber) {
    //     let array = this.order.primaryCustomerContact.phoneNumber.split("-");
    //     if (array.length === 2) {
    //       this.primaryPhoneNumber.code = array[0];
    //       this.primaryPhoneNumber.number = array[1];
    //     }
    //   }

    //   if(this.order.additionalCustomerContacts?.length) {
    //     this.additionalPhoneNumbers = [];
    //     this.order.additionalCustomerContacts.forEach((contact:OrderCustomerContactViewModel) => {
    //       let array = contact.phoneNumber.split("-");
    //       if (array.length === 2) {
    //         this.additionalPhoneNumbers.push(
    //           {
    //             code: array[0],
    //             number: array[1]
    //           }
    //         )
    //       }
    //     });
        
    //   }
    // }

    // this.$watch('selectedCourier', () => {
    //   this.order.courierLink = this.selectedCourier.trackingUrlFormat
    // })

  }

  // get courierRequired() {
  //   return this.order.orderStatusId.toLowerCase() === 'preparingtodispatch'
  // }

  // copyLink() {
  //   let link = ''
  //   if(this.order.courierLink) {
  //     link = this.order.courierLink
  //   } else {
  //     link = `${this.order.courier.trackingUrlFormat.replace('{CourierReference}', this.order.courierReference)}`
  //   }
  //   navigator.clipboard.writeText(link);
  //   this.$message.success("Tracking link copied.");
  // }

  addAddress() {
    this.order.additionalAddresses.push({
      additionalNotes: "",
      addressLine1: "",
      addressLine2: "",
      area: "",
      companyName: "",
      country: "",
      ponumber: "",
      postalCode: ""
    })
  }

  removeAddress(index:any) {
    this.order.additionalAddresses.splice(index, 1);
  }

  // async loadInitialData() {
  //   await AdminCouriers.adminCouriersGet(
  //     1,
  //     99999999,
  //   )
  //     .then((res) => {
  //       if(res.data.succeeded) {
  //         this.courierItems = res.data.resultData!.items as Array<CourierAdminListViewModel>;
  //         console.log("courier items", this.courierItems);
  //       }
        
  //     })
  //     .catch((error) => {
  //         error.response.data.errors.map((e: any) => {
  //         this.$message.error(e.friendlyMessage);
  //         return e.friendlyMessage;
  //       });
  //     });
  // }
}
