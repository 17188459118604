




























































































































































































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import {
  ValidationProvider,
} from "vee-validate";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicPhoneNumberField from "@/components/form-items/BasicPhoneNumberField.vue";
import { PropType } from "vue";
import store from "@/store";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";

const AppProps = Vue.extend({
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
    enquiryCustomerTypes: {
      type: Array,
      default: () => []
    },
    countries: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    contactNumber: {
      type: Object as PropType<{code:string, number:string}>,
      default: () => {}
    }
  }
});

@Component({
  name: "GeneralTab",
  components: {  BasicTooltipHeading, ValidationProvider, BasicInputField, BasicSwitchField, BasicDateField, BasicSelectorField, BasicInputNumberField, BasicPhoneNumberField },
})
export default class extends AppProps {
  qualityScores = [
    { id: 0, name:0 },
    { id: 1, name:1 },
    { id: 2, name:2 },
    { id: 3, name:3 },
    { id: 4, name:4 },
    { id: 5, name:5 }
  ]
  openCart() {
    if(this.enquiry.cartId) {
      let routeData = this.$router.resolve({name: 'EditOrder', params: {id: this.enquiry.cartId}});
      window.open(routeData.href, '_blank');
    }
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  handleTabChange(to:string) {
    this.$emit('query', to)
  }
}

