








































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminExtraGroups, AdminExtras } from '@/network/api'
import {
  ExtraGroupAdminListViewModel,
  ExtraGroupAdminListViewModelPaginatedList,
  ExtraGroupAdminViewModel,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: 'Extras',
  components: { ElTableDraggable, Pagination, DialogBox, SendToPage, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    textFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        true: 'success',
        false: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends Vue {
  extras: ExtraGroupAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  extrasItems: Array<ExtraGroupAdminViewModel> = [];
  pageNumber = 1;
  itemPerPage = 10;
  search: string | undefined = '';
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this extra?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedExtra: ExtraGroupAdminViewModel = {
    id: '',
    name: '',
    code: '',
    isDisabled: false,
    extraIds: [],
    isSystemManaged: false
  };
  debounce: any = null;
  selectedStatusFilter = '';
  selectedTypeFilter = ''
  extraStatus?: boolean | undefined;
  extraType?: boolean | undefined;
  showAllText: Array<string> = [];
  loading = false

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      selectedStatusFilter: this.selectedStatusFilter,
      selectedTypeFilter: this.selectedTypeFilter
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch('search', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400)
    // })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }

  clearStatusFilter() {
    this.pageNumber = 1
    this.selectedStatusFilter = '';
    this.loadInitialData();
  }

  clearTypeFilter() {
    this.pageNumber = 1
    this.selectedTypeFilter = '';
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = '';
    this.selectedStatusFilter = '';
    this.clearTypeFilter();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  handleStatus(selected: string) {
    if(selected === this.selectedStatusFilter) {
      this.selectedStatusFilter = ''
    } else {
      this.selectedStatusFilter = selected
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleType(selected: string) {
    if(selected === this.selectedTypeFilter) {
      this.selectedTypeFilter = ''
    } else {
      this.selectedTypeFilter = selected
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleSendToPage(id:string, selectedPage:number, determinePage:number) {
    this.loading = true
    let patchInfo: any = {}
    let itemId = 0
    let newPageItems = [] as Array<any>  
    const type = !this.selectedTypeFilter ? undefined : this.selectedTypeFilter === 'Extra' ? true : false
    const status = !this.selectedStatusFilter ? undefined : this.selectedStatusFilter === 'Inactive' ? true : false 

    AdminExtraGroups.adminExtraGroupsGet(determinePage, this.itemPerPage, this.search || undefined, status, type)
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!
            .items as Array<any>;

          if(selectedPage < this.pageNumber) {
            itemId = (selectedPage > 1) ? newPageItems.length - 1 : 0;
          }
          
          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false
          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
        }
      })
      .catch((error) => {
        this.dialogVisible = false;
        this.loading = false
error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminExtraGroups.adminExtraGroupsIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);
          this.loading = false;
          this.loadInitialData();
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  async loadInitialData() {
    this.loading = true
    const type = !this.selectedTypeFilter ? undefined : this.selectedTypeFilter === 'Extra' ? true : false
    const status = !this.selectedStatusFilter ? undefined : this.selectedStatusFilter === 'Inactive' ? true : false 
    await AdminExtraGroups.adminExtraGroupsGet(this.pageNumber, this.itemPerPage, this.search || undefined, status, type)
      .then((res) => {
        if(res.data.succeeded) {
          this.extras = res.data
          .resultData as ExtraGroupAdminListViewModelPaginatedList
        this.extrasItems = this.extras
          .items as Array<ExtraGroupAdminListViewModel>
          console.log('extras items',this.extrasItems);
        }
        this.loading = false        
      })
      .catch((error) => {
        this.loading = false
              this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
      })
  }

  confirmDelete(extra: any) {
    this.dialogVisible = true
    this.selectedExtra = extra;
  }

  deleteExtra() {
    if (this.selectedExtra.id && this.selectedExtra.isSystemManaged) {
      this.loading = true
      AdminExtras.adminExtrasIdDelete(this.selectedExtra.extraIds[0])
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false          
        })
        .catch((error) => {
          this.loading = false
              this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        })
    } else if (this.selectedExtra.id) {
      AdminExtraGroups.adminExtraGroupsIdDelete(this.selectedExtra.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false          
        })
        .catch((error) => {
          this.loading = false
              this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        })
    }
    this.dialogVisible = false
    this.loading = false
    this.selectedExtra = {
      id: '',
      name: '',
      code: '',
      isDisabled: false,
      extraIds: [],
      isSystemManaged: false
    }
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
