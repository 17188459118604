function bankersRoundingFunc(num: number, decimalPlaces: number) {
  const d = decimalPlaces || 0;
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);

  return d ? (r / m).toFixed(2) : r.toFixed(2);
}

export function thousandSeparator(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function calculatePrice (price: number, multiplier: number, decimalPlaces = 2) {
  if (price && multiplier) {
    return bankersRoundingFunc(price * multiplier, decimalPlaces);
  } else {
    return (0).toFixed(2);
  }
};