var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stores-search-container"},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('h1',{staticStyle:{"margin":"0","margin-top":"15px"},style:((_vm.customStores.totalCount > 5) ? 'margin-bottom:0;' : 'margin-bottom:20px;')},[_vm._v("Online Store Orders")])])],1),_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('el-col',{attrs:{"span":10}}),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"uniqueMinItem":5,"disabled":_vm.loading,"totalCount":_vm.customStores.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1)],1),_c('el-row',{attrs:{"type":"flex","align":"middle"}},[_c('el-col',{staticStyle:{"margin-bottom":"8px"},attrs:{"span":24}},[_c('el-checkbox',{attrs:{"true-label":"true","false-label":"false"},on:{"change":_vm.toggleCheckedOutFilter},model:{value:(_vm.hasCheckedOut),callback:function ($$v) {_vm.hasCheckedOut=$$v},expression:"hasCheckedOut"}},[_c('span',{staticStyle:{"line-height":"20px","font-weight":"400","font-size":"16px"}},[_vm._v("Show stores with pending checkout")])])],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.customStoreItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"130","align":"center","justify":"center"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditCustomStore',
            params: {
              id: row.id,
              search: _vm.search,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])}),_c('el-table-column',{attrs:{"width":"90","prop":"referenceNumber","label":"Reference"}}),_c('el-table-column',{attrs:{"min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderManagers),function(user){return _c('li',{key:user.id,class:{
                'selected-filter': _vm.selectedOrderManager == user.id,
              },on:{"click":function($event){return _vm.handleOrderManager(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearOrderManagerFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedOrderManager },staticStyle:{"width":"130px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Order Manager"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
              var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.orderManagerAspNetUserName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderManagers),function(user){return _c('li',{key:user.id,class:{
                  'selected-filter': _vm.selectedSalesManager == user.id,
                },on:{"click":function($event){return _vm.handleSalesManager(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearSalesManagerFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedSalesManager },staticStyle:{"width":"130px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Sales Manager"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.salesManagerAspNetUserName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180","prop":"title","label":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"storeOwnerName","label":"Owner Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.storeOwnerName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"160","prop":"storeOwnerEmail","label":"Owner Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.storeOwnerEmail))])]}}])}),_c('el-table-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.storeStatuses),function(status){return _c('li',{key:status.id,class:{
                  'selected-filter': _vm.statusFilter == status.id,
                },on:{"click":function($event){return _vm.handleStatus(status.id)}}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearstatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{
              'filtered-category': _vm.statusFilter,
            },staticStyle:{"width":"90px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',{attrs:{"id":row.id}},[_vm._v(_vm._s(row.storeStatusId === 'AwaitingApproval' ? 'Awaiting Approval' : row.storeStatusId))])]}}])}),_c('el-table-column',{attrs:{"min-width":"120","prop":"startDate","label":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.startDate && _vm.handleDate(row.startDate)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"120","prop":"endDate","label":"End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.endDate && _vm.handleDate(row.endDate)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"200","prop":"deliveryFeePerCustomer","label":"Delivery Fee Per Customer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(("GBP £" + (row.deliveryFeePerCustomer.toFixed(2) || 0))))])]}}])}),_c('el-table-column',{attrs:{"width":"180px","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear Filters ")])]}},{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditCustomStore',
            params: {
              id: row.id,
              search: _vm.search,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.customStores.totalCount,"totalPages":_vm.customStores.totalPages,"pageSize":_vm.customStores.pageSize,"currentPage":_vm.pageNumber,"disableToTop":true},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }