


































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

@Component({
  name: "BasicInputField",
  components: { ValidationProvider, ValidationObserver },
  props: {
    label: { default: "" },
    model: { default: "" },
    placeholder: { default: "" },
    span: { default: 12 },
    name: { default: "" },
    rules: { default: "" },
    mode: { default: "eager" },
    size: { default: "" },
    isDisabled: { default: false },
    handleBlur: { type: Function },
    handleChange: { type: Function },
    precision: {default: 0},
    min: {default: undefined},
    symbol: {default: null}
  },
})
export default class extends Vue {}
