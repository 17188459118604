import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user_new'
import { AuthModule } from "@/store/modules/auth";
import { ElMessageOptions } from 'element-ui/types/message'
import store from './store'

NProgress.configure({ showSpinner: false })

const whiteList = ['Login', 'OrderPackApproval', 'OrderPackFactory']

router.beforeEach(async(to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()
  let token = store.getters['auth/token']
  let role = store.getters['user_new/role']
  // debugger

  // Determine whether the user has logged in
  if (token?.encodedToken) {
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // Check whether the user has obtained his permission roles
      if (!role || typeof role === 'string') {
        // debugger
        try {
          // Get user info, including roles
          await store.dispatch('user_new/getUserInfo', {token: token.encodedToken})
          // Set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (err) {
          // Remove token and redirect to login page
          await store.dispatch('auth/refresh')
          // await store.dispatch('user_new/getUserInfo', {token: token.encodedToken})
          Message.error(err as ElMessageOptions || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      } else {
        next()
      }
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.name as string) !== -1) {
      // In the free login whitelist, go directly
      next()
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = to?.meta?.title
})
