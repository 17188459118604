































































































































































import {
AdjustmentTypeAdminListViewModel,
SizeGroupAdminCreateModel,
SizeGroupAdminUpdateModel,
SizeGroupAdminViewModel,
SizeGroupAttributeAdminCreateModel,
SizeGroupAttributeAdminListViewModelPaginatedList,
SizeGroupAttributeAdminUpdateModel,
SizeGroupAttributeAdminViewModel
} from "@/api-client"
import DialogBox from "@/components/DialogBox.vue"
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import BasicInputField from "@/components/form-items/BasicInputField.vue"
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue"
import FileUploader from "@/components/form-items/FileUploader.vue"
import ImageUploader from "@/components/form-items/ImageUploader.vue"
import Pagination from '@/components/Pagination.vue'
import { Component, Vue } from "vue-property-decorator"

import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue"
import SendToPage from "@/components/form-items/SendToPage.vue"
import { AdminAdjustmentTypes, AdminSizeGroupAttributes, AdminSizeGroups } from "@/network/api"
import { handleQueries } from "@/utils/handleQueries"
import SizeGroupAttributeOverride from "@/views/sizing/size-groups/SizeGroupAttributeOverride.vue"
import {
configure, extend, ValidationObserver, ValidationProvider
} from "vee-validate"

const AppProps = Vue.extend({
  props: {
    id: { default: '' }
  }
})

@Component({
  name: "NewSizeGroup",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    FileUploader,
    Pagination,
    ElTableDraggable,
    SendToPage,
    ItemsPerPage,
    BasicTooltipHeading,
    SizeGroupAttributeOverride,
    BasicSelectorField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible = false;
  dialogMessage = "";
  disabled = false;
  showBanner = true;
  sizeGroup: SizeGroupAdminViewModel = {
    id: "",
    name: "",
    displayName: ""
  };

  newSizeGroup: SizeGroupAdminCreateModel | SizeGroupAdminUpdateModel = {
    name: '',
    displayName: ''
  };

  fileList: Array<any> = [];
  confirmText = "Ok";
  cancelVisible = true;
  attributes: SizeGroupAttributeAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };

  attributeSearch: string | undefined = "";
  attributeItemPerPage= 5;
  attributePageNumber = 1;
  attributeItems: Array<SizeGroupAttributeAdminViewModel> = [];
  newAttribute: SizeGroupAttributeAdminCreateModel | SizeGroupAttributeAdminUpdateModel = {
    name: "",
    sizeGroupId: this.id
  };

  selectedId = '';
  originalName = '';
  dirtyTimer: any = null;
  loading = false
  
  debounce: any = null;
  disable = false
  fromPath = ''

  adjustmentTypes?: AdjustmentTypeAdminListViewModel[] = [];

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    const toQuery = {
      attributeSearch: this.attributeSearch,
      attributePageNumber: this.attributePageNumber,
      attributeItemPerPage: this.attributeItemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch("attributeSearch", () => {
    //   this.attributePageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadSizeGroupAttributes()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400);
    // });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required."
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error"
      }
    });
  }

  setQueryWatch(fields: any) {
    const queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.attributePageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handleSendToPage(id:string, selectedPage:number, determinePage:number) {
    this.loading = true
    let patchInfo: any = {}
    let itemId = 0
    let newPageItems = [] as Array<any>   

    AdminSizeGroupAttributes.adminSizeGroupAttributesGet(determinePage, this.attributeItemPerPage, this.id, this.attributeSearch || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!
            .items as Array<any>;

          if (selectedPage < this.attributePageNumber) {
            itemId = (selectedPage > 1) ? newPageItems.length - 1 : 0;
          }
          
          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id
          };

          this.loading = false
          
          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
        }
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({ showClose: true, type: 'error', duration: 0, message: e.friendlyMessage })
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async handleReorder(data: any, page: number = this.attributePageNumber) {
    this.loading = true;
    await AdminSizeGroupAttributes.adminSizeGroupAttributesIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.attributePageNumber = Number(page);
          this.loading = false;
          this.loadSizeGroupAttributes();
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({ showClose: true, type: 'error', duration: 0, message: e.friendlyMessage })
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  handlePageNumberClick(page: any) {
    this.attributePageNumber = page
    this.loadSizeGroupAttributes();
  }

  beforeBack() {
    const isDirty = document.querySelectorAll(".dirty");
    
    this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if (isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>") {
      if (this.fromPath === 'SizeGroups') {
        this.$router.back()
      } else {
        this.$router.push({ name: "SizeGroups" });
      }
    } else if (this.confirmText === 'Yes') {
      this.deleteRow();
    } else {
      this.submitForm();
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      const dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (const item of dirty) {
          item.classList.remove("dirty");
        }
        const clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }

  editField(data: any) {
    if ((this.selectedId === data.id) &&
    data.name !== '') {
      this.disable = false
      this.saveAttribute(data);
    } else {
      this.selectedId = data.id;
      this.originalName = data.name;
      this.disable = true
    }
  }

  addRow() {
    const tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;

    if (this.attributeItems.length) {
      this.attributeItems.forEach(item => {
        if (item.name === '') {
          emptyName += 1;
        }
      }) 
    }

    if (emptyName === 0) {
      this.attributeItems.push({
        id: tempId,
        sizeGroupId: this.id,
        name: ''
      });
      console.log('items', this.attributeItems);     
      this.originalName = '';
      this.selectedId = tempId;
      this.disable = true
    }
  }

  saveAttribute(data: any) {
    const regex = /^(temp).*/g;
    if (!data.id.match(regex)) {
      this.loading = true
      this.newAttribute = {
        sizeGroupId: this.id,
        name: data.name
      } as SizeGroupAttributeAdminUpdateModel;

      AdminSizeGroupAttributes.adminSizeGroupAttributesIdPut(this.selectedId, this.newAttribute)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("att Put success", res);
            this.$message.success('Saved!')
            this.loadSizeGroupAttributes();
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.dialogVisible = false;
          this.loading = false
        });
    } else {
      this.newAttribute = {
        sizeGroupId: this.id,
        name: data.name
      };
      AdminSizeGroupAttributes.adminSizeGroupAttributesPost(this.newAttribute)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("att Post success", res);
            this.$message.success('Saved!')
            this.loadSizeGroupAttributes();
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.dialogVisible = false;
        });
    }
    this.selectedId = "";
    this.disable = false;
    this.loading = false
  }

  confirmDelete(data: any) {
    this.dialogMessage = "<span><stong>Are you sure you want to delete this attribute?</strong></span>";
    this.confirmText = "Yes";
    const regex = /^(temp).*/g;
    if ((this.selectedId === data.id) && !this.selectedId.match(regex)) {
      data.name = this.originalName;
      this.selectedId = "";
      this.disable = false;
    } else {
      console.log('in delete', data);
      
      this.selectedId = data.id;
      this.dialogVisible = true;
    }
  }

  deleteRow() {
    const regex = /^(temp).*/g;
    let index = this.attributeItems.length - 1;
    if (this.selectedId.match(regex)) {
      this.attributeItems.forEach((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.attributeItems.splice(index, 1);
    } else {
      this.loading = true
      AdminSizeGroupAttributes.adminSizeGroupAttributesIdDelete(this.selectedId)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.info('Item deleted.')
            this.loadSizeGroupAttributes();
          }  
          this.loading = false        
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.dialogVisible = false;
          this.loading = false
        });
    }
    this.selectedId = "";
    this.disable = false;
    this.originalName = ""
    this.dialogVisible = false;
    this.loading = false
  }

  submitForm() {
    if (this.sizeGroup.id) {
      this.loading = true

      AdminSizeGroups.adminSizeGroupsIdPut(this.sizeGroup.id, this.newSizeGroup)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success('Saved!')
            this.dialogVisible = false;
            this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })          
          } 
          this.loading = false     
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.loading = false
          this.dialogVisible = false;
        });
    } else {
      AdminSizeGroups.adminSizeGroupsPost(this.newSizeGroup)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success('Saved!')
            this.dialogVisible = false;
            this.id = res.data.resultData!.id;
            this.$router.replace({ name: 'EditSizeGroup', params: { id: this.id } })
            this.loadInitialData();
            this.clearDirtyClasses();
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.dialogVisible = false;
          this.loading = false
        });
    }
  }

  async loadSizeGroupAttributes() {
    this.loading = true
    await AdminSizeGroupAttributes.adminSizeGroupAttributesGet(this.attributePageNumber, Number(this.attributeItemPerPage), this.id, this.attributeSearch || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.attributes = res.data.resultData as SizeGroupAttributeAdminListViewModelPaginatedList;
          this.attributeItems = this.attributes.items;
          this.newAttribute = {
            sizeGroupId: this.id,
            name: ''
          }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
        );
        this.dialogVisible = false;   
      })
  }

  async loadInitialData() {
    const { data } = await AdminAdjustmentTypes.adminAdjustmentTypesGet(1, 100)
    this.adjustmentTypes = data.resultData?.items;

    if (this.id) {
      await this.loadSizeGroupAttributes()
      this.loading = true

      await AdminSizeGroups.adminSizeGroupsIdGet(this.id).then((res) => {
        this.sizeGroup = res.data.resultData as SizeGroupAdminViewModel;
        this.name = this.sizeGroup.name;
        this.newSizeGroup = (({ id, ...rest }) => rest)(this.sizeGroup) as any;
        console.log("new fabric", this.newSizeGroup);
      })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.dialogVisible = false;      
        })
      this.loading = false
    }
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
