var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-search-container"},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('h1',{staticStyle:{"margin":"0"},style:((_vm.orders.totalCount > 5) ? 'margin-bottom:0;' : 'margin-bottom:20px;')},[_vm._v("Orders")])])],1),_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('el-col',{staticStyle:{"margin-bottom":"8px"},attrs:{"span":10}},[_c('el-checkbox',{attrs:{"true-label":"true","false-label":"false"},on:{"change":_vm.loadInitialData},model:{value:(_vm.calculateOrderTotals),callback:function ($$v) {_vm.calculateOrderTotals=$$v},expression:"calculateOrderTotals"}},[_c('span',{staticStyle:{"line-height":"20px","font-weight":"400","font-size":"16px"}},[_vm._v("Include Order Totals")])]),_c('el-checkbox',{attrs:{"true-label":"true","false-label":"false"},on:{"change":_vm.loadInitialData},model:{value:(_vm.includePaymentId),callback:function ($$v) {_vm.includePaymentId=$$v},expression:"includePaymentId"}},[_c('span',{staticStyle:{"line-height":"20px","font-weight":"400","font-size":"16px"}},[_vm._v("Include StripeID")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"uniqueMinItem":5,"disabled":_vm.loading,"totalCount":_vm.orders.totalCount,"label":'Items per page',"model":_vm.ordersItemPerPage},on:{"update:model":function($event){_vm.ordersItemPerPage=$event},"handleChange":function (value) {
            _vm.ordersItemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.ordersPageNumber = 1}}})],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.orderItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"130","align":"center","justify":"center"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditOrder',
            params: {
              id: row.id,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])}),_c('el-table-column',{attrs:{"width":"90","prop":"referenceNumber","label":"Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.referenceNumber))])]}}])}),_c('el-table-column',{attrs:{"min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderManagers),function(user){return _c('li',{key:user.id,class:{
                  'selected-filter': _vm.ordersSelectedOrderManager == user.id,
                },on:{"click":function($event){return _vm.handleOrderManager(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearOrderManagerFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.ordersSelectedOrderManager },staticStyle:{"width":"130px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Order Manager"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.orderManagerAspNetUserDisplayName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderManagers),function(user){return _c('li',{key:user.id,class:{
                  'selected-filter': _vm.ordersSelectedSalesManager == user.id,
                },on:{"click":function($event){return _vm.handleSalesManager(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearSalesManagerFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.ordersSelectedSalesManager },staticStyle:{"width":"130px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Sales Manager"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.salesManagerAspNetUserDisplayName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"customerName","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.customerName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"deliveryAddressCompanyName","label":"Company Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.deliveryAddressCompanyName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"160","prop":"customerEmail","label":"Customer Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(row.customerEmail))])]}}])}),_c('el-table-column',{attrs:{"min-width":"145"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderStatuses),function(orderStatus){return _c('li',{key:orderStatus.id,class:{'selected-filter': _vm.status.includes(orderStatus.id)},on:{"click":function($event){return _vm.handleStatus(orderStatus.id)}}},[_vm._v(_vm._s(orderStatus.name))])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearOrderStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.status.length > 0 },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Order Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.orderStatusId))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"isStoreOrder","label":"Online Store"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},[_c('li',{class:{
                  'selected-filter': _vm.isStoreOrder === 'Yes',
                },on:{"click":function($event){return _vm.handleIsStoreOrder('Yes')}}},[_vm._v(" Yes ")]),_c('li',{class:{
                  'selected-filter': _vm.isStoreOrder === 'No',
                },on:{"click":function($event){return _vm.handleIsStoreOrder('No')}}},[_vm._v(" No ")])])]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearIsStoreOrderFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.isStoreOrder },staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Online Store"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.isStoreOrder ? row.storeName : '')+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"90"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.factories),function(factory){return _c('li',{key:factory.id,class:{
                  'selected-filter': _vm.selectedFactory == factory.id,
                },on:{"click":function($event){return _vm.handleFactory(factory.id)}}},[_vm._v(" "+_vm._s(factory.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearFactoryFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedFactory },staticStyle:{"width":"90px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Factories"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleFactories(row.factories)))])]}}])}),_c('el-table-column',{attrs:{"width":"120","prop":"disableAutoSendFactoryEmails","label":"Auto Send Factory Emails"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [(!row.disableAutoSendFactoryEmails)?_c('el-tag',{staticStyle:{"font-size":"14px"},attrs:{"type":_vm._f("truthyFilter")(!row.disableAutoSendFactoryEmails)}},[_vm._v(" ✔ ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"70","prop":"noFail","label":"No Fail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [(row.noFail)?_c('el-tag',{staticStyle:{"font-size":"14px"},attrs:{"type":_vm._f("noFailFilter")(row.noFail)}},[_vm._v(" ✔ ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"110","prop":"exWorksDate","label":"EXW Date"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('p',{class:{'filtered-status': _vm.sortBy.column !== undefined},staticStyle:{"cursor":"pointer"},on:{"click":_vm.handleSortBy}},[_c('i',{class:_vm.sortEXWDate()}),_vm._v(" EXW Date")])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.exWorksDate ? _vm.handleDate(row.exWorksDate) : ''))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"exWorksApproved","label":"Approved to Ship"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},[_c('li',{class:{
                  'selected-filter': _vm.isEXWApproved === 'Approved',
                },on:{"click":function($event){return _vm.handleEXWApproved('Approved')}}},[_vm._v(" Approved ")]),_c('li',{class:{
                  'selected-filter': _vm.isEXWApproved === 'Not Approved',
                },on:{"click":function($event){return _vm.handleEXWApproved('Not Approved')}}},[_vm._v(" Not Approved ")])])]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearEXWApprovedFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.isEXWApproved },staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Approved to Ship"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [(row.exWorksApproved !== null)?_c('el-tag',{staticStyle:{"font-size":"14px"},attrs:{"type":_vm._f("truthyFilter")(row.exWorksApproved)}},[_vm._v(" "+_vm._s(row.exWorksApproved ? 'Approved' : 'Not Approved')+" ")]):_vm._e()]}}])}),_c('DateRangeFilter',{attrs:{"width":120,"heading":'Order Placed',"name":'orderPlacementDate',"isSelected":_vm.orderPlacement.startDate !== undefined},on:{"updateDate":function (x) {(_vm.orderPlacement = x), _vm.loadInitialData();}}}),_c('DateRangeFilter',{attrs:{"width":245,"heading":'Est. Delivery Date (at Checkout)',"name":'expectedDeliveryDate',"isSelected":_vm.expectedDelivery.startDate !== undefined},on:{"updateDate":function (x) {
          (_vm.expectedDelivery = x), _vm.loadInitialData();
        }}}),_c('el-table-column',{attrs:{"min-width":"230","prop":"updatedDeliveryDate","label":"Est. Delivery Date (at Sign-Off)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.updatedDeliveryDate ? _vm.handleDate(row.updatedDeliveryDate) : ''))])]}}])}),(_vm.calculateOrderTotals === 'true')?_c('el-table-column',{attrs:{"min-width":"160","prop":"convertedRecalculatedOrderTotal","label":"Quoted Order Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(((row.currency.code) + " " + (row.currency.symbol) + (row.convertedRecalculatedOrderTotal.toFixed(2)))))])]}}],null,false,3433403088)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"140","prop":"convertedOrderTotal","label":"Order Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(((row.currency.code) + " " + (row.currency.symbol) + (row.convertedOrderTotal.toFixed(2)))))])]}}])}),_c('el-table-column',{attrs:{"min-width":"170","class-name":"payment-checkbox","prop":"convertedPaymentOutstanding","label":"Payment Outstanding"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [(row.convertedPaymentOutstanding !== 0)?_c('el-tag',{staticStyle:{"font-size":"14px"},attrs:{"type":_vm._f("paymentFilter")(row.convertedPaymentOutstanding)}},[_vm._v(" "+_vm._s(((row.currency.code) + " " + (row.currency.symbol) + (row.convertedPaymentOutstanding.toFixed(2))))+" ")]):_c('span',[_vm._v(" "+_vm._s(((row.currency.code) + " " + (row.currency.symbol) + (row.convertedPaymentOutstanding.toFixed(2))))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"isQuote","label":"Checkout Reminder","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return (row.isQuote && ['Cart', 'CartValidated'].includes(row.orderStatusId))?[_c('el-button',{attrs:{"plain":"","size":"mini","type":"success"},on:{"click":function($event){return _vm.sendQuoteReminder(row.id)}}},[_vm._v("Send")])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"width":"130","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])])]}},{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditOrder',
            params: {
              slug: _vm.handleName(row.referenceNumber),
              id: row.id,
              pageNumber: _vm.ordersPageNumber,
              itemPerPage: _vm.ordersItemPerPage,
              search: _vm.search,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.orders.totalCount,"totalPages":_vm.orders.totalPages,"pageSize":_vm.orders.pageSize,"currentPage":_vm.ordersPageNumber,"disableToTop":true},on:{"change":_vm.handleordersPageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }