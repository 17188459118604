export function sortAscending(array: Array<any>, byName: boolean = true, uniqueProp: string| null = null) {
  if(byName) {
    let prop = 'name';

    if(uniqueProp) {
      prop = uniqueProp
    }

    return array.sort((a, b) => {
      if(a[prop] < b[prop]){
        return -1
      }
      if(a[prop] > b[prop]){
        return 1
      }
      return 0
    })
  } else {
    return array.sort((a, b) => {
      if(a < b){
        return -1
      }
      if(a > b){
        return 1
      }
      return 0
    })
  }
}