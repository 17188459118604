var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container new-layer-container"},[_c('DialogBox',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"cancelVisible":_vm.cancelVisible,"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage},on:{"confirm":_vm.handleConfirm,"close":function($event){_vm.dialogVisible = false}}}),_c('h1',[_c('span',[_vm._v(_vm._s(_vm.name ? "Edit" : "New"))]),_vm._v(" Layer "),(_vm.name)?_c('span',[_vm._v("- "+_vm._s(_vm.name))]):_vm._e()]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"model":_vm.newLayer,"label-position":"top"}},[_c('el-row',[_c('el-col',{attrs:{"span":2}},[_c('BasicSwitchField',{attrs:{"label":'Active',"activeValue":false,"inactiveValue":true,"model":_vm.newLayer.isDisabled},on:{"update:model":function($event){return _vm.$set(_vm.newLayer, "isDisabled", $event)}}})],1)],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('BasicInputField',{attrs:{"rules":'required',"name":'name',"label":'Name*',"model":_vm.newLayer.name},on:{"update:model":function($event){return _vm.$set(_vm.newLayer, "name", $event)}}}),_c('BasicInputField',{attrs:{"rules":'required',"name":'code',"label":'Code*',"model":_vm.newLayer.code},on:{"update:model":function($event){return _vm.$set(_vm.newLayer, "code", $event)}}})],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{staticClass:"basic-input",attrs:{"label":"Fabric Channel"}},[_c('el-select',{attrs:{"filterable":"","placeholder":" "},model:{value:(_vm.newLayer.fabricChannelId),callback:function ($$v) {_vm.$set(_vm.newLayer, "fabricChannelId", $$v)},expression:"newLayer.fabricChannelId"}},[_c('el-option',{attrs:{"label":"-","value":null}}),_vm._l((_vm.fabricChannels),function(fabricChannel,index){return _c('el-option',{key:("fabric-channel-" + index),attrs:{"label":((fabricChannel.internalName) + " - " + (fabricChannel.externalName)),"value":fabricChannel.id}})})],2)],1)],1)],1),_c('h2',[_vm._v("Images")]),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"image-label"},[_vm._v("Front Design Layer*")]),_c('span',{staticClass:"dimensions-text"},[_c('p',[_vm._v("Recommended: 680px x 800px")])]),_c('ValidationProvider',{ref:"front",attrs:{"slim":"","name":"front design layer","rules":"front"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('ImageUploader',{ref:"frontUploader",attrs:{"label":"Front Design Layer*","area":'front',"emptyMaskCheckbox":true},on:{"removed":_vm.removeImage,"useEmpty":function (x) { return _vm.handleUseEmpty(x, 'frontMaskUrl'); },"validate":function($event){return _vm.validateField('front')}},model:{value:(_vm.newLayer.frontMaskUrl),callback:function ($$v) {_vm.$set(_vm.newLayer, "frontMaskUrl", $$v)},expression:"newLayer.frontMaskUrl"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('el-col',{attrs:{"span":6}},[_c('h4',{staticClass:"image-label"},[_vm._v("Back Design Layer*")]),_c('span',{staticClass:"dimensions-text"},[_c('p',[_vm._v("Recommended: 680px x 800px")])]),_c('ValidationProvider',{ref:"back",attrs:{"slim":"","name":"back design layer","rules":"back"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('ImageUploader',{ref:"backUploader",attrs:{"label":"Back Design Layer*","area":'back',"emptyMaskCheckbox":true},on:{"removed":_vm.removeImage,"useEmpty":function (x) { return _vm.handleUseEmpty(x, 'backMaskUrl'); },"validate":function($event){return _vm.validateField('back')}},model:{value:(_vm.newLayer.backMaskUrl),callback:function ($$v) {_vm.$set(_vm.newLayer, "backMaskUrl", $$v)},expression:"newLayer.backMaskUrl"}}),_c('span',{class:classes},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('BasicTooltipHeading',{staticStyle:{"padding-top":"10px"},attrs:{"headingStyle":_vm.id && 'margin-bottom:20px;',"heading":'Pricing'}}),(_vm.id)?_c('PriceRange',{staticStyle:{"margin-bottom":"30px"},attrs:{"existingPriceRanges":_vm.priceRanges,"id":_vm.id},on:{"put":_vm.updatePriceRange,"post":_vm.saveNewPriceRange,"delete":_vm.deletePriceRange}}):_c('div',{staticStyle:{"margin-bottom":"30px"}},[_c('p',{staticStyle:{"line-height":"1.5em"}},[_vm._v(" Price ranges can only be added to existing layers. ")])]),_c('el-row',{style:(_vm.id && 'margin-bottom:30px'),attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{staticStyle:{"padding-top":"10px"},attrs:{"span":14}},[_c('BasicTooltipHeading',{attrs:{"heading":'Fabric Colours'}})],1),(_vm.id)?_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.disable,"uniqueMinItem":5,"totalCount":_vm.layerFabrics.totalCount,"label":'Items per page',"model":_vm.layerFabricsItemPerPage},on:{"update:model":function($event){_vm.layerFabricsItemPerPage=$event},"handleChange":function (value) {
                _vm.layerFabricsItemPerPage = value;
                _vm.loadLayerFabrics();
              },"reset":function($event){_vm.layerFabricsPageNumber = 1}}})],1):_vm._e()],1),(_vm.id)?_c('el-row',[_c('el-table',{attrs:{"data":_vm.layerFabricItems,"row-key":"id","default-expand-all":"","fit":"","stripe":"","resizable":"","border":"","highlight-current-row":"","cell-class-name":"fabric-cell"}},[_c('el-table-column',{attrs:{"min-width":"280","label-class-name":"col-label","label":"Fabrics"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
              var $index = ref.$index;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"fabrics","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('el-select',{class:{
                    'disabled-field hide-select-suffix':
                      _vm.selectedId !== row.id,
                  },staticStyle:{"width":"250px"},attrs:{"disabled":_vm.selectedId !== row.id,"size":'small',"id":row.fabricId,"placeholder":"Select a fabric","filterable":""},on:{"change":function($event){return _vm.fetchColours(row)},"visible-change":_vm.toggleFabricOption},model:{value:(row.fabricId),callback:function ($$v) {_vm.$set(row, "fabricId", $$v)},expression:"row.fabricId"}},[(
                      _vm.layerFabricItems[$index].fabricId &&
                      _vm.layerFabricItems[$index].fabricName &&
                      !_vm.toggleFabricOptionValue
                    )?_c('el-option',{attrs:{"label":_vm.layerFabricItems[$index].fabricName,"value":_vm.layerFabricItems[$index].fabricId,"size":'small'}}):_vm._e(),_vm._l((_vm.layerFabricOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":((item.code) + " - " + (item.name)),"value":item.id,"size":'small'}})})],2),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"320","label-class-name":"col-label","label":"Products"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"products","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-select',{class:{
                    'disabled-field hide-select-suffix':
                      _vm.selectedId !== row.id,
                  },attrs:{"disabled":_vm.selectedId !== row.id || !_vm.layerProducts.length,"size":'small',"filterable":"","placeholder":"Select a product","multiple":"","reserve-keyword":""},model:{value:(row.productsIds),callback:function ($$v) {_vm.$set(row, "productsIds", $$v)},expression:"row.productsIds"}},_vm._l((_vm.layerProducts),function(item){return _c('el-option',{key:item.id,attrs:{"label":((item.code) + " - " + (item.name)),"value":item.id,"size":'small'}})}),1),_c('span',{class:'validation-inline-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"250","label-class-name":"col-label","label":"Colours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
                  var $index = ref.$index;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"colours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('el-select',{class:{
                    'disabled-field hide-select-suffix':
                      _vm.selectedId !== row.id,
                  },attrs:{"disabled":_vm.selectedId !== row.id || !_vm.layerColourOptions.length,"size":'small',"filterable":"","value-key":"id","placeholder":"Select a colour","multiple":"","reserve-keyword":""},on:{"visible-change":_vm.toggleColourOption},model:{value:(row.colourGroups),callback:function ($$v) {_vm.$set(row, "colourGroups", $$v)},expression:"row.colourGroups"}},[(row.colourGroups.length && !_vm.toggleColourOptionValue)?_c('span',_vm._l((_vm.selectedColoursList[$index]),function(item){return _c('el-option',{key:item.id,attrs:{"label":((item.code) + " - " + (item.name) + (!item.isSystemManaged ? ' - Group' : '')),"value":item,"size":'small'}})}),1):_c('span',_vm._l((_vm.layerColourOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":((item.code) + " - " + (item.name) + (!item.isSystemManaged ? ' - Group' : '')),"value":item,"size":'small'}})}),1)]),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"width":"170","label-class-name":"col-label","label":"Construction Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"construction type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('el-select',{class:{
                    'disabled-field hide-select-suffix':
                      _vm.selectedId !== row.id,
                  },attrs:{"disabled":_vm.selectedId !== row.id,"size":'small',"filterable":"","id":row.constructionTypeId,"placeholder":"Select a construction type"},model:{value:(row.constructionTypeId),callback:function ($$v) {_vm.$set(row, "constructionTypeId", $$v)},expression:"row.constructionTypeId"}},_vm._l((_vm.constructionTypes),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id,"size":'small'}})}),1),_c('span',{class:'validation-inline-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"width":"120","label-class-name":"col-label","label":"Fabric Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticStyle:{"margin":"4px 0 0 0"}},[_vm._v("£")]),_c('el-input-number',{class:{ 'disabled-field': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":"small","value":row.price,"controls":false,"precision":2,"min":0,"type":'number'},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}})],1),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"width":"160","label-class-name":"col-label","label":"Custom Pattern Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticStyle:{"margin":"4px 0 0 0"}},[_vm._v("£")]),_c('el-input-number',{class:{ 'disabled-field': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":"small","value":row.customPatternPrice,"controls":false,"precision":2,"min":0,"type":'number'},model:{value:(row.customPatternPrice),callback:function ($$v) {_vm.$set(row, "customPatternPrice", $$v)},expression:"row.customPatternPrice"}})],1),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"width":"160","label-class-name":"buttons-label","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
                  var $index = ref.$index;
return [_c('el-button',{attrs:{"disabled":_vm.selectedId !== row.id && _vm.selectedId !== '',"plain":"","size":"mini","type":"primary"},on:{"click":function($event){return _vm.editField(row, $index)}}},[_vm._v(_vm._s(_vm.selectedId !== row.id ? "Edit" : "Save"))]),_c('el-button',{attrs:{"disabled":_vm.selectedId !== row.id && _vm.selectedId !== '',"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v(_vm._s(_vm.selectedId !== row.id ? "Delete" : "Cancel"))])]}}],null,true)})],1),_c('Pagination',{attrs:{"disableToTop":true,"totalItems":_vm.layerFabrics.totalCount,"showScrollToTop":_vm.layerFabricsItemPerPage > 5 && _vm.layerFabrics.totalCount > 5,"totalPages":_vm.layerFabrics.totalPages,"pageSize":_vm.layerFabrics.pageSize,"currentPage":_vm.layerFabricsPageNumber},on:{"change":_vm.handlePageNumberClick}}),_c('el-button',{staticClass:"primary-buttons new-tag",attrs:{"disabled":_vm.disable ||
            !_vm.layerFabricOptions.length ||
            (_vm.layerFabricOptions.length === 1 && _vm.selectedId !== '')},on:{"click":_vm.addRow}},[_vm._v("New Layer Fabric")])],1):_c('div',[_c('p',{staticStyle:{"line-height":"1.5em"}},[_vm._v(" Fabric colours can only be added to existing layers. ")])])],1),_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submitForm)}}},[_vm._v("Save Changes")]),_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":_vm.beforeBack}},[_vm._v("Back")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }