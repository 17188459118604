




































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminProductBuilder } from "@/network/api";
import {
  ProductBuilderOptionsGenerationLogViewModel,
  ProductBuilderOptionsGenerationLogViewModelPaginatedList,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries";

@Component({
  name: "Extras",
  components: { ElTableDraggable, Pagination, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Warning: "warning",
        Error: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  generationLogs: ProductBuilderOptionsGenerationLogViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  generationLogItems: Array<ProductBuilderOptionsGenerationLogViewModel> = [];
  pageNumber = 1;
  itemPerPage = 10;
  debounce: any = null;
  loading = false;

  created() {
    let toQuery = {
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
    };
    this.setQueryWatch(toQuery);

    this.loadInitialData();

    // this.$watch('search', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400)
    // })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val);
      });
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById("search")!.focus();
    });
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  async loadInitialData() {
    this.loading = true;

    await AdminProductBuilder.adminProductBuilderGetProductBuilderOptionsGenerationLogsGet(
      this.pageNumber,
      this.itemPerPage
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.generationLogs = res.data
            .resultData as ProductBuilderOptionsGenerationLogViewModelPaginatedList;
          this.generationLogItems = this.generationLogs
            .items as Array<ProductBuilderOptionsGenerationLogViewModel>;
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        // this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage);
          return e.friendlyMessage;
        });
      });
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
