










import { Component, Vue } from "vue-property-decorator";
import InvoicesList from '@/components/orders/invoices/InvoicesList.vue'
import NewInvoice from '@/components/orders/invoices/NewInvoice.vue'
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    balance: {
      type: Number,
      default: 0
    },
    orderCurrency: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
});

@Component({
  name: "InvoicesTab",
  components: {
    InvoicesList,
    NewInvoice,
    BasicTooltipHeading
  }
})
export default class extends AppProps {
  currentScreen: 'list' | 'edit' = 'list'
  invoiceId:string = ''

  get heading() {
    return (this.currentScreen === 'list') ? 'All Invoices' : 'New Invoice'
  }

  reload() {
    this.$emit('reload')
  }


  editInvoice() {
    this.currentScreen = 'edit'
  }

  toList() {
    this.currentScreen = 'list'
  }
}
