







































































































































































































































































import {
  CountryAdminCreateModel,
  CountryAdminUpdateModel,
  CountryAdminViewModel,
  CountryDeliveryTypeAdminCreateModel,
  CountryDeliveryTypeAdminUpdateModel,
  CountryDeliveryTypeAdminViewModel,
  CountryGroupListViewModel,
  CurrencyAdminViewModel,
  CurrencyViewModel,
  DeliveryType,
  ImportDutyType,
  ImportDutyTypeListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from "@/utils/sortAscending";
import { countryAplphaCodes } from "@/views/country-delivery/countryAlphaCodes";
import { editorToolbar } from "@/utils/editorToolbar";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import {
  AdminCountries,
  AdminCountryDeliveryTypes,
  AdminCountryGroups,
  AdminCountryImportDutyTypes,
  AdminCurrencies,
} from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewCountry",
  components: {
    BasicInputNumberField,
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicTooltipHeading
  },
  
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  country: CountryAdminViewModel = {
    id: "",
    name: "",
    currencyId: "",
    isDefault: false,
    importDutyTypeId: ImportDutyType.Dap,
    code: "",
    countryGroupId: "",
    geolocationCodes: [],
    allowBankTransfers: false,
    bankTransferDetails: "",
  };
  countryGroups: Array<CountryGroupListViewModel> = [];
  currencies: Array<CurrencyAdminViewModel> = [];
  importDutyTypes: Array<ImportDutyTypeListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newCountry: CountryAdminCreateModel | CountryAdminUpdateModel = {
    name: "",
    currencyId: "",
    isDefault: false,
    importDutyTypeId: ImportDutyType.Dap,
    code: "",
    countryGroupId: "",
    geolocationCodes: [],
    allowBankTransfers: false,
    bankTransferDetails: "",
  };
  standardDelivery: CountryDeliveryTypeAdminViewModel = {
    id: "",
    countryId: this.id,
    deliveryTypeId: DeliveryType.Standard,
    pricePerItem: 0,
    pricePerOrder: 0,
    deliveryDays: 0,
  };
  newStandardDelivery:
    | CountryDeliveryTypeAdminCreateModel
    | CountryDeliveryTypeAdminUpdateModel = {
    countryId: this.id,
    deliveryTypeId: DeliveryType.Standard,
    pricePerItem: 0,
    pricePerOrder: 0,
    deliveryDays: 0,
  };
  expressDelivery: CountryDeliveryTypeAdminViewModel = {
    id: "",
    countryId: this.id,
    deliveryTypeId: DeliveryType.Express,
    pricePerItem: 0,
    pricePerOrder: 0,
    deliveryDays: 0,
  };
  newExpressDelivery:
    | CountryDeliveryTypeAdminCreateModel
    | CountryDeliveryTypeAdminUpdateModel = {
    countryId: this.id,
    deliveryTypeId: DeliveryType.Express,
    pricePerItem: 0,
    pricePerOrder: 0,
    deliveryDays: 0,
  };
  showExpressDelivery = false;
  showStandardDelivery = false;
  selectedMethod: CountryDeliveryTypeAdminViewModel = {
    id: "",
    countryId: this.id,
    deliveryTypeId: DeliveryType.Standard,
    pricePerItem: 0,
    pricePerOrder: 0,
    deliveryDays: 0,
  };
  dirtyTimer: any = null;
  dirtyMavonTimer: any = null;
  geoCodesEmpty: Array<string> = [];
  geoCodesArray: Array<string> = [];
  countryAlphaArray = countryAplphaCodes;
  loading = false;
  mavenToolbar = editorToolbar;
  fromPath = ''

  get sortedCountryGroups() {
    return sortAscending(this.countryGroups);
  }

  get sortedCurrencies() {
    return sortAscending(this.currencies);
  }

  get sortedImportDutyTypes() {
    return sortAscending(this.importDutyTypes);
  }

  copyExample(eg:string) {
    let options = {
      eg1: '**Title:** *Information*',
      eg2: '**Bank:** *Example Bank*',
      eg3: '**Account Number:** *0000 0000 0000*',
      eg4: '**SWIFT Code:** *XXXXXXXXX*'
    }
    navigator.clipboard.writeText(options[eg]);
    this.$message.success('Example copied.')
  }

  created() {
    this.loadInitialData().then((res) => {
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses();
      }, 1000);
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll(".dirty");

    if (mavonDirty.length) {
      for (let item of mavonDirty) {
        item.classList.remove("dirty");
      }
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
    clearTimeout(this.dirtyMavonTimer);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (
      this.dialogMessage ===
      "<span>Are you sure you want to delete this delivery method?</span>"
    ) {
      this.deleteDeliveryMethod(this.selectedMethod);
    } else if ( this.dialogMessage === "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>") {
      if(this.fromPath === 'Countries') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Countries"});
      }
    } else {
      this.submitForm();
    }
  }

  clearStandardFields() {
    this.showStandardDelivery = false;
    this.newStandardDelivery = {
      countryId: this.id,
      deliveryTypeId: DeliveryType.Standard,
      pricePerItem: 0,
      pricePerOrder: 0,
      deliveryDays: 0,
    };
  }

  clearExpressFields() {
    this.showExpressDelivery = false;
    this.newExpressDelivery = {
      countryId: this.id,
      deliveryTypeId: DeliveryType.Express,
      pricePerItem: 0,
      pricePerOrder: 0,
      deliveryDays: 0,
    };
  }

  confirmDelete(method: any) {
    this.dialogMessage =
      "<span>Are you sure you want to delete this delivery method?</span>";
    this.confirmText = "Yes";
    this.selectedMethod = method;
    this.dialogVisible = true;
  }

  deleteDeliveryMethod(method: any) {
    this.loading = true;
    AdminCountryDeliveryTypes.adminCountryDeliveryTypesIdDelete(method.id)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("delivery deleted", res);
          if (method.deliveryTypeId === 1) {
            this.standardDelivery = {
              id: "",
              countryId: this.id,
              deliveryTypeId: DeliveryType.Standard,
              pricePerItem: 0,
              pricePerOrder: 0,
              deliveryDays: 0,
            };
            this.newStandardDelivery = (({ id, ...rest }) => rest)(
              this.standardDelivery
            ) as any;
            this.showStandardDelivery = false;
          } else if (method.deliveryTypeId === 2) {
            this.expressDelivery = {
              id: "",
              countryId: this.id,
              deliveryTypeId: DeliveryType.Express,
              pricePerItem: 0,
              pricePerOrder: 0,
              deliveryDays: 0,
            };
            this.newExpressDelivery = (({ id, ...rest }) => rest)(
              this.expressDelivery
            ) as any;
            this.showExpressDelivery = false;
          }
          this.$message.info("Item deleted.");
        }
        this.loading = false;
        this.dialogVisible = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
      this.loading = false;
  }

  saveDeliveryMethod = (method: any, newMethod: any, toggleMethod: any) =>
    new Promise((resolve, reject) => {
      if (toggleMethod && method.id) {
        this.loading = true;
        AdminCountryDeliveryTypes.adminCountryDeliveryTypesIdPut(
          method.id,
          newMethod
        )
          .then((res) => {
            if (res.data.succeeded) {
              resolve(res);
              console.log("delivery put", res);
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;

            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              reject(error);
              return e.friendlyMessage;
            });
            this.dialogVisible = false;
          });
      } else {
        if (toggleMethod) {
          this.loading = true;
          AdminCountryDeliveryTypes.adminCountryDeliveryTypesPost(newMethod)
            .then((res) => {
              if (res.data.succeeded) {
                resolve(res);
                console.log("delivery post", res);
              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;

              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                reject(error);
                return e.friendlyMessage;
              });
              this.dialogVisible = false;
            });
        } else {
          this.loading = false;
          resolve(true);
        }
      }
      this.loading = false;
    });

  submitForm() {
    if (this.country.id) {
      this.loading = true;
      console.log("before save", this.newCountry);

      this.saveDeliveryMethod(
        this.standardDelivery,
        this.newStandardDelivery,
        this.showStandardDelivery
      )
        .then((res) => {
          this.saveDeliveryMethod(
            this.expressDelivery,
            this.newExpressDelivery,
            this.showExpressDelivery
          )
            .then((res) => {
              this.loading = true;

              if(!this.newCountry.allowBankTransfers) {
                this.newCountry.bankTransferDetails = null
              }

              AdminCountries.adminCountriesIdPut(
                this.country.id,
                this.newCountry
              )
                .then((res) => {
                  if (res.data.succeeded) {
                    console.log("success?", res);
                    this.$message.success("Saved!");
                    this.dialogVisible = false;
                    this.loadInitialData().then(() => {
                      this.clearDirtyClasses();
                    });
                  }
                  this.loading = false;
                })
                .catch((error) => {
                  this.loading = false;

                  error.response.data.errors.map((e: any) => {
                    this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                    return e.friendlyMessage;
                  });
                  this.dialogVisible = false;
                });
            })
            .catch((error) => {
              this.loading = false;

              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                return e.friendlyMessage;
              });
              this.dialogVisible = false;
            });
        })
        .catch((error) => {
          this.loading = false;

          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    } else {
      this.loading = true;

      if(!this.newCountry.allowBankTransfers) {
                this.newCountry.bankTransferDetails = null
              }

      AdminCountries.adminCountriesPost(this.newCountry)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.id = res.data.resultData!.id;
            this.expressDelivery.countryId = this.id;
            this.newExpressDelivery.countryId = this.id;
            this.standardDelivery.countryId = this.id;
            this.newStandardDelivery.countryId = this.id;
            this.loadInitialData();
            // this.$router.push({ name: "Countries" });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
    this.loading = false;
  }

  async loadInitialData() {
    this.loading = true;
    await AdminCountryGroups.adminCountryGroupsGet(1, 999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.countryGroups = res.data.resultData!
            .items as Array<CountryGroupListViewModel>;
          console.log("country groups", this.countryGroups);
        }
      })
      .catch((error) => {
        this.loading = false;

        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

    await AdminCurrencies.adminCurrenciesGet(1, 999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.currencies = res.data.resultData!
            .items as Array<CurrencyViewModel>;
          console.log("currencies", this.currencies);
        }
      })
      .catch((error) => {
        this.loading = false;

        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

    await AdminCountryImportDutyTypes.adminCountryImportDutyTypesGet(1, 999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.importDutyTypes = res.data.resultData!
            .items as Array<ImportDutyTypeListViewModel>;
          console.log("importDutyTypes", this.importDutyTypes);
        }
      })
      .catch((error) => {
        this.loading = false;

        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    this.loading = false;

    if (this.id) {
      this.loading = true;
      await AdminCountryDeliveryTypes.adminCountryDeliveryTypesGet(
        1,
        999999,
        this.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            let deliveryTypes = res.data.resultData!.items;
            console.log("delivery types", deliveryTypes);
            if (deliveryTypes.length > 0) {
              deliveryTypes.map((type: any) => {
                if (type.deliveryTypeId === "Standard") {
                  this.standardDelivery = type;
                  this.newStandardDelivery = (({ id, ...rest }) => rest)(
                    this.standardDelivery
                  ) as any;
                  this.showStandardDelivery = true;
                } else if (type.deliveryTypeId === "Express") {
                  this.expressDelivery = type;
                  this.newExpressDelivery = (({ id, ...rest }) => rest)(
                    this.expressDelivery
                  ) as any;
                  this.showExpressDelivery = true;
                }
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;

          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      await AdminCountries.adminCountriesIdGet(this.id)
        .then((res) => {
          this.country = res.data.resultData as CountryAdminViewModel;
          this.name = this.country.name;
          this.newCountry = (({ id, ...rest }) => rest)(this.country) as any;
          console.log("new country", this.newCountry);
        })
        .catch((error) => {
          this.loading = false;

          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      this.loading = false;
    }
    this.loading = false;
  }
}
