

































































import {
  CustomizationAdminCreateModel,
  CustomizationAdminUpdateModel,
  CustomizationAdminViewModel,
  CustomizationType,
  CustomizationTypeListViewModel,
  PriceAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from '@/utils/sortAscending'

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminCustomizationPrices, AdminCustomizations, AdminCustomizationTypes } from "@/network/api";
import PriceRange from "@/components/form-items/PriceRange.vue";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewCustomization",
  components: { BasicTooltipHeading, PriceRange, BasicSelectorField, BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  customization: CustomizationAdminViewModel = {
    id: '',
    name: '',
    code: '',
    customizationTypeId: CustomizationType.Text,
    isDisabled: true,
    orderPackNotes: ''
  };
  customizationTypes: Array<CustomizationTypeListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newCustomization: CustomizationAdminCreateModel | CustomizationAdminUpdateModel = {
    name: '',
    code: '',
    customizationTypeId: CustomizationType.Text,
    isDisabled: true,
    orderPackNotes: ''
  };
  loading = false
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: '',
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: ''
    }
  ];
  dirtyTimer: any = null
  fromPath = ''

  get sortedCustomizationType() {
    return sortAscending(this.customizationTypes);
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Customizations') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Customizations"});
      }
    } else {
      this.submitForm();
    }
  }

  //-------- Price Range Start

  saveNewPriceRange(range:any) {
    this.loading = true
    AdminCustomizationPrices.adminCustomizationPricesPost(range)
    .then((res) => {
      if (res.data.succeeded) {
        console.log("price post success", res);
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        this.loading = false
        this.dialogVisible = false;
    });
  }

  updatePriceRange(range:any) {
    this.loading = true
    AdminCustomizationPrices.adminCustomizationPricesIdPut(range.id, range)
    .then((res) => {
      if (res.data.succeeded) {
        console.log("price put success", res);
        this.$message.success('Saved!')
        this.loadPriceRanges();
      }
      this.loading = false
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            this.loadPriceRanges();
            return e.friendlyMessage
          }
        );
        this.loading = false
        this.dialogVisible = false;
    });
  }

  deletePriceRange(id:string) {
    this.loading = true
    AdminCustomizationPrices.adminCustomizationPricesIdDelete(id)
    .then((res) => {
      if(res.data.succeeded) {
        this.$message.info('Item deleted.')
      this.loadPriceRanges();
      }
      this.loading = false      
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
          this.loading = false
          this.dialogVisible = false;
    });
  }

  loadPriceRanges() {
    this.loading = true
    AdminCustomizationPrices.adminCustomizationPricesGet(1, 99999999, this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.priceRanges = res.data.resultData!.items
          console.log('get price', this.priceRanges);
        }
        this.loading = false
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.loading = false
        this.dialogVisible = false;
      });
  }

  //-------- Price Range End

  submitForm() {
        if (this.customization.id) {
          this.loading = true
          console.log('before save', this.newCustomization);
          
          AdminCustomizations.adminCustomizationsIdPut(this.customization.id, this.newCustomization)
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.$message.success('Saved!')
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
              }
              this.loading = false              
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.loading = false
              this.dialogVisible = false;
            });
        } else {
          AdminCustomizations.adminCustomizationsPost(this.newCustomization)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success('Saved!')
                this.dialogVisible = false;
                this.id = res.data.resultData!.id;
                this.$router.replace({ name: "EditCustomization", params: {id: this.id} });
                this.loadInitialData()
                .then(() => {
                  this.clearDirtyClasses()
                })
                //this.$router.push({ name: "Customizations" });
              }
              this.loading = false
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.loading = false
              this.dialogVisible = false;
            });
        }
  }

  async loadInitialData() {
    this.loading = true
    await AdminCustomizationTypes.adminCustomizationTypesGet()
    .then((res) => {
      if (res.data.succeeded) {
        this.customizationTypes = res.data.resultData as Array<CustomizationTypeListViewModel>
        console.log('types', this.customizationTypes);      
      }
    })

    if (this.id) {
      this.loadPriceRanges();
      await AdminCustomizations.adminCustomizationsIdGet(this.id).then((res) => {
        this.customization = res.data.resultData as CustomizationAdminViewModel;
        this.name = this.customization.name;
        this.newCustomization = (({ id, ...rest }) => rest)(this.customization) as any;
        console.log('new customization', this.newCustomization);
      });
    }
    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
