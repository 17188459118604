
















































































































import {
  FactoryAdminCreateModel,
  FactoryAdminUpdateModel,
  FactoryAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicPhoneNumberField from "@/components/form-items/BasicPhoneNumberField.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminFactories } from "@/network/api";
import { email } from "vee-validate/dist/rules";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewFactory",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicInputNumberField,
    BasicPhoneNumberField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  pageNumber: string = this.$route.params.pageNumber;
  itemPerPage: string = this.$route.params.itemPerPage;
  search: string = this.$route.params.search;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  factory: FactoryAdminViewModel = {
    id: "",
    name: "",
    emailAddresses: [],
    code: "",
    contactPhoneNumber: "",
    address: "",
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  phoneNumber = {
    code: "+86",
    number: "",
  };

  newFactory: FactoryAdminCreateModel | FactoryAdminUpdateModel = {
    name: "",
    code: "",
    contactPhoneNumber: "",
    address: "",
    emailAddresses: []
  };
  loading = false;
  dirtyTimer: any = null;
  debouncePhone: any = null;
  // emailAddresses: Array<string> = [];
  inputVisible = false;
  inputValue = "";
  fromPath = ''


  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then(() => {
      this.clearDirtyClasses();
    });

    this.$watch(
      "phoneNumber",
      function handler(newValue) {
        clearTimeout(this.debouncePhone);
        this.debouncePhone = setTimeout(() => {
          this.newFactory.contactPhoneNumber = `${newValue.code}-${newValue.number}`;
        }, 400);
      },
      { deep: true }
    );

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("email", {
      ...email,
      // validate(value) {
      //   let emails = self.$refs.emails as any;
      //   return {
      //     valid: emails._selectedFile || uploader.image || value,
      //   };
      // },
      // computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("phoneNumber", () => {
      if (this.phoneNumber.number) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  handleClose(tag:any) {
    this.factory.emailAddresses.splice(this.factory.emailAddresses.indexOf(tag), 1);
  }

  async validateField(boolRet = true) {
    const provider = this.$refs.emails as any;

    // Validate the field
    if (provider) {
      if (boolRet) {
        let valid = await provider!.validate();
        return valid.valid;
      } else {
        provider!.validate();
      }
    }
  }

  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      (this.$refs as any).saveTagInput.$refs.input.focus();
    });
  }

  async handleInputConfirm() {
    let isValid = await this.validateField(true);
    if (isValid) {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.factory.emailAddresses.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Factories') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Factories"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    if(!this.factory.emailAddresses.length) {
      this.showInput()
      this.validateField(false)
    } else if (this.factory.id) {
      console.log("before save", this.newFactory);
      this.loading = true;
      AdminFactories.adminFactoriesIdPut(this.factory.id, this.newFactory)
        .then((res) => {
          if (res.data.succeeded) {
            this.loading = false;
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            });
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false;
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    } else {
      AdminFactories.adminFactoriesPost(this.newFactory)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.loading = false;
            this.dialogVisible = false;
            this.$router.push({ name: "Factories" });
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false;
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true;
      await AdminFactories.adminFactoriesIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.factory = res.data.resultData as FactoryAdminViewModel;
            this.name = this.factory.name;
            this.newFactory = (({ id, ...rest }) => rest)(this.factory) as any;

            if (this.factory.contactPhoneNumber) {
              let array = this.factory.contactPhoneNumber.split("-");
              if (array.length >= 2) {
                this.phoneNumber.code = array[0];
                array.shift()
                this.phoneNumber.number = array.join('-');
              }
            }

            this.loading = false;
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false;
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
    clearTimeout(this.debouncePhone);
  }
}
