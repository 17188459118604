

































































import { Component, Vue } from "vue-property-decorator";
import SizeNameSelector from "@/components/orders/sizing/SizeNameSelector.vue";
import { OrderCartItemViewModel, OrderPackItemViewModel } from "@/api-client";
import PreviewImage from "@/components/form-items/PreviewImage.vue";
import { PropType } from "vue";
import cloneDeep from "lodash/cloneDeep";

@Component({
  name: "SizingDialog",
  props: {
    dialogVisible: {default: false},
    orderItems: {
      type: Array as PropType<OrderCartItemViewModel[]>,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
  },
  components: {
    SizeNameSelector,
    PreviewImage
  }
})
export default class extends Vue {
  loading = false;
  selectedItemId: string | null = null;
  incorrectQty = false;
  orderItemsCopy: OrderCartItemViewModel[] | null = null;

  get selectedItem() {
    if (this.$props.orderItems && this.selectedItemId) {
      return this.$props.orderItems.find(
        (item: OrderPackItemViewModel) => item.customizedProduct.id === this.selectedItemId
      );
    } else if(!this.selectedItemId && this.$props.orderItems?.length) {
      this.selectedItemId = this.$props.orderItems[0].customizedProduct.id;
      return this.$props.orderItems[0];
    } 
    return null;
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handleQtyCount(mismatch: boolean) {
    this.incorrectQty = mismatch;
  }

  setInitialCopy() {
    if(!this.orderItemsCopy) {
      this.orderItemsCopy = cloneDeep(this.$props.orderItems);
      this.$emit('setInitial', this.$props.orderItems);
    }
  }

  handleCopy(type: 'open' | 'close' | 'cancel') {
    switch(type) {
      case 'open': {
        this.orderItemsCopy = null;
      }

      case 'close': {
        // This seems to call dialog is opened or closed in any way
        this.incorrectQty = false;
        break
      }

      case 'cancel': {
        this.$emit('cancel', this.orderItemsCopy);
        break
      }

      default: {
        break
      }
    }
  }
}
