


























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminOrderPacks } from "@/network/api";
import {
  OrderPackAdminListViewModelPaginatedListApplicationResultResultData,
  OrderPackAdminListViewModel,
  OrderPackStatus,
} from "@/api-client";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";

import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import { extend, configure } from "vee-validate";
import { formatDate } from "@/utils/formatDate";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    reload: {
      type: Boolean,
      default: false,
    },
    originalOrder: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "OrderPacksTab",

  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    BasicInputField,
    ImageUploader,
    BasicTooltipHeading,
    BasicSwitchField
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Generated: "info",
        Voided : "warning",
        AwaitingFeedback: "",
        Rejected: 'danger',
        Approved: 'success',
      };
      return statusMap[status];
    },
    sizesChangedFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        true: "success",
        false : "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  pageNumber = 1;
  itemPerPage = 5;
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this order pack?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  debounce: any = null;
  loading = false;
  selection: Array<any> = [];
  selectedItem: OrderPackAdminListViewModel = {
    id: "",
    orderId: this.order?.id || "",
    customerResponseDate: "",
    notesToCustomer: "",
    notesFromCustomer: "",
    dateTimeStamp: "",
    orderPackStatusId: OrderPackStatus.Generated,
    versionNumber: 1,
    invalidationDate: '',
    invalidationReason: '',
    invalidationAspNetUserId: '',
    invalidationAspNetUserDisplayName: '',
    filesCount: 0,
    sizesChanged: false
  };
  selectedProviderFilter = "";
  orderPacks: OrderPackAdminListViewModelPaginatedListApplicationResultResultData =
    {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 1,
    };
  notesToCustomer = 'Please reply ASAP - delays can lead to a delay in delivery of your originalOrder.'
  showAllText: Array<string> = [];
  showLink = false

  created() {
    if (this.order && this.originalOrder.id) {
      this.loadInitialData();
    }

    this.$watch("originalOrder.id", () => {
      this.loadInitialData();
    });

    this.$watch("reload", () => {
      if(this.reload) {
        this.loadInitialData();
        this.$emit('resetReload')
      }
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  downloadCustomerFiles (row) {
        this.loading = true;
        AdminOrderPacks.adminOrderPacksGetOrderPackUploadedFilesArchiveIdGet(row.id, {
            responseType: 'arraybuffer',
        })
        .then(async (res) => {
            if(res.status == 200) {
                let fileName = `${this.originalOrder.referenceNumber}-Order-Pack-${row.versionNumber}-Customer-Files.zip`
                await this.saveZip(res.data, fileName)
            }
            this.loading = false;
            })
            .catch((error) => {
            this.loading = false;
                error.response.data.errors.map((e: any) => {
                    this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                    return e.friendlyMessage;
                });
            });
    }

    async saveZip(data:any, fileName) {
        var assetBlob = new Blob([data], {type:"application/zip"});
        var assetUrl = URL.createObjectURL(assetBlob);
        var downloadLink = document.createElement("a");
        downloadLink.href = assetUrl;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

  orderPackStatus(status:string) {
    if(status === 'AwaitingFeedback') {
      return 'Awaiting Feedback'
    } else if(status === 'Rejected') {
      return 'Changes Requested'
    }

    return status
  }

  copyLinkPath() {
    let link = `${window.location.origin}/orderpack/approval/${this.originalOrder.orderPackIdAwaitingApproval}`;
    navigator.clipboard.writeText(`${link}`)
    this.$message.success('Path copied.')
  }

  truncateString(str: string, num: number) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num)
  }

  appendLeadingZeroes(n:any){
      if(n <= 9){
        return "0" + n;
      }
      return n
    }

  handleDateTime(date: any) {
    let hour = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();
    
    return `${formatDate(date)} - ${this.appendLeadingZeroes(hour)}:${this.appendLeadingZeroes(minutes)}`;
  }

  handleDate(date: any) {
    if(date) {
      return formatDate(date);
    }
    return ''
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(
      `${this.uploadPath}/${path.proofOfPaymentUrl}`
    );
    this.$message.success("Path copied.");
  }

  viewOrderPack(row: OrderPackAdminListViewModel) {
    let routeData = this.$router.resolve({name: 'Orderpack', params: {id: row.id}});
    window.open(routeData.href, '_blank');  
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    AdminOrderPacks.adminOrderPacksIdDelete(this.selectedItem.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.pageNumber = 1;
          this.$message.info("Item deleted.");
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    this.loading = false;
    this.dialogVisible = false;

    this.selectedItem = {
      id: "",
      orderId: this.order?.id || "",
      customerResponseDate: "",
      notesToCustomer: "",
      notesFromCustomer: "",
      dateTimeStamp: "",
      orderPackStatusId: OrderPackStatus.Generated,
      versionNumber: 1,
      filesCount: 0,
      sizesChanged: false
    };
  }

  async sendOrderPackApprovalEmail() {
    let isDirty = document.querySelectorAll(".dirty");
    if (isDirty.length > 0) {
      this.$message({showClose: true, type: 'error', duration: 0, message: 'Please save your changes before sending an order pack approval request.'})
    } else {
    if(this.originalOrder.requiresCustomerApproval && (this.originalOrder.orderStatusId === 'SignOffCustomerOrderPack')) {
      this.loading = true;
      await AdminOrderPacks.adminOrderPacksPost(this.originalOrder.id, this.notesToCustomer || undefined)
      .then((res) => {
          if (res.data.succeeded) {
            let newOrderPackId = res.data.resultData?.id as string
            this.$message.success("Order pack generated.");
            this.notesToCustomer = ''

              AdminOrderPacks.adminOrderPacksSendOrderPackToCustomerOrderPackIdPost(newOrderPackId)
              .then(async (res) => {
                if (res.data.succeeded) {
                  this.$message.success("Email sent successfully.");
                  await this.loadInitialData().then(() => {
                    this.$emit('reload')
                  })
                }
              })
              .catch((error) => {
                this.loading = false;
                this.loadInitialData()
                error.response.data.errors.map((e: any) => {
                  this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                  return e.friendlyMessage;
                });
              });
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      }
    }
    
    this.loading = false;
  }

  async loadInitialData() {
    if (this.originalOrder.id) {
      this.loading = true;

      await AdminOrderPacks.adminOrderPacksGet(
        this.pageNumber,
        this.itemPerPage,
        this.originalOrder.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.orderPacks = res.data
              .resultData as OrderPackAdminListViewModelPaginatedListApplicationResultResultData;
            this.notesToCustomer = ''
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.loading = false;
  }
}
