


































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminSizeGroups, AdminSizes } from "@/network/api";
import {
  SizeAdminListViewModelPaginatedList,
  SizeAdminViewModel,
  SizeGroupAdminListViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import { sortAscending } from "@/utils/sortAscending";
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"


@Component({
  name: "SizeGroups",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    SendToPage,
    ItemsPerPage,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  sizes: SizeAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  sizeItems: Array<SizeAdminViewModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  search: string | undefined = this.$route.params.search || "";
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this size?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  selectedItem: SizeAdminViewModel = {
    id: "",
    name: "",
    sizeGroupId: "",
  };
  allSizeGroupsArray: Array<SizeGroupAdminListViewModel> = [];
  sizeGroupFilter: Array<string> = [];
  loading = false;
  debounce: any = null;

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      sizeGroupFilter: this.sizeGroupFilter
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();
    this.loadSizeGroups();

    // this.$watch("search", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  get sortedSizeGroups() {
    return sortAscending(this.allSizeGroupsArray);
  }

  handleSendToPage(id: string, selectedPage: number, determinePage: number) {
    this.loading = true;
    let patchInfo: any = {};
    let itemId = 0;
    let newPageItems = [] as Array<any>;

    AdminSizes.adminSizesGet(
      determinePage,
      this.itemPerPage,
      this.search || undefined,
      this.sizeGroupFilter
    )
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!.items as Array<any>;

          if (selectedPage < this.pageNumber) {
            itemId = selectedPage > 1 ? newPageItems.length - 1 : 0;
          }

          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false;

          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminSizes.adminSizesIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);
          this.loadInitialData();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  clearSizeGroupFilter() {
    this.pageNumber = 1
    this.sizeGroupFilter = [];
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = "";
    this.clearSizeGroupFilter()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  handleSizeGroup(selected: string) {
    if (this.sizeGroupFilter.includes(selected)) {
      this.sizeGroupFilter = this.sizeGroupFilter.filter(
        (category) => {
          return category !== selected;
        }
      );
    } else {
      if(typeof this.sizeGroupFilter == 'string') {
        const arr = [this.sizeGroupFilter, selected];
        this.sizeGroupFilter = arr;
      } else {
        this.sizeGroupFilter.push(selected);
      }
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  loadSizeGroups() {
    this.loading = true;
    AdminSizeGroups.adminSizeGroupsGet(1, 9999999).then((res) => {
      if (res.data.succeeded) {
        const sizeGroups = res.data.resultData!.items;
        sizeGroups!.forEach((group) => {
          this.allSizeGroupsArray.push(group);
        });
      }
      this.loading = false;
    })
    .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true;
    await AdminSizes.adminSizesGet(
      this.pageNumber,
      this.itemPerPage,
      this.search || undefined,
      this.sizeGroupFilter
    )
      .then((res) => {
        if(res.data.succeeded) {
          this.sizes = res.data.resultData as SizeAdminListViewModelPaginatedList;
          this.sizeItems = this.sizes.items as Array<SizeAdminViewModel>;
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    if (this.selectedItem.id) {
      this.loading = true;
      AdminSizes.adminSizesIdDelete(this.selectedItem.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1;
            this.$message.info("Item deleted.");
            this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }
    this.dialogVisible = false;
    this.loading = false
    this.selectedItem = {
      id: "",
      name: "",
      sizeGroupId: "",
    };
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
