





































import {
  PageSeoSettingAdminCreateModel,
  PageSeoSettingAdminUpdateModel,
  PageSeoSettingAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from '@/utils/sortAscending'
import { editorToolbar } from '@/utils/editorToolbar'
import BasicDateField from "@/components/form-items/BasicDateField.vue";

const AppProps = Vue.extend({
  props: {
    url: {default: ''}
  }
})

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminSEO } from "@/network/api";

@Component({
  name: "NewSEOSettings",
  components: { BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, BasicInputField, BasicDateField },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  dirtyMavonTimer:any = null
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  headerImage: Array<any> = [];
  authorImage: Array<any> = [];
  showBanner: boolean = true;
  seoSettings: PageSeoSettingAdminViewModel = {
    url: '',
    canonicalUrl: '',
    noIndex: false,
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newSeoSettings: PageSeoSettingAdminCreateModel | PageSeoSettingAdminUpdateModel = {
    url: '',
    canonicalUrl: '',
    noIndex: false,
  };
  loading = false
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData().then((res) => {
      clearTimeout(this.dirtyMavonTimer)
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses()
      }, 1000);  
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    if(provider) {
      return provider!.validate();
    }     
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll('.dirty'); 
      
    if(mavonDirty.length) {
      for(let item of mavonDirty) {
        item.classList.remove('dirty')
      }
    }   
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'SEO') {
        this.$router.back()
      } else {
        this.$router.push({ name: "SEO"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true

    if (this.seoSettings.url) {
      AdminSEO.adminPageSeoSettingsPut(this.seoSettings.url, this.newSeoSettings)
        .then((res) => {
          if(res.data.succeeded) {
            this.dialogVisible = false;
            this.$message.success('Saved!')
            this.$router.push({ name: "SEO" });
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
        });
    } else {
      AdminSEO.adminPageSeoSettingsPost(this.newSeoSettings as PageSeoSettingAdminCreateModel)
        .then((res) => {
          if (res.data.succeeded) {
            this.dialogVisible = false;
            this.$message.success('Saved!')
            this.$router.push({ name: "SEO" });
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );             
        });
    }
  }

  async loadInitialData(): Promise<any> {
    this.loading = true
    if (this.url) {
      await AdminSEO.adminPageSeoSettingsSingleGet(this.url).then((res) => {
        this.seoSettings = res.data.resultData as PageSeoSettingAdminViewModel;
        this.url = this.seoSettings.url;
        this.newSeoSettings = (({ ...rest }) => rest)(this.seoSettings) as any;
      })
      .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
            });
    }
    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
    clearTimeout(this.dirtyMavonTimer)
  }
}
