













































































































































































































































import {
  CustomizationType,
  ExtraAdminCreateModel,
  ExtraAdminListViewModel,
  ExtraAdminUpdateModel,
  ExtraAdminViewModel,
  PriceAdminViewModel,
  ProductAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import PreviewImage from "@/components/form-items/PreviewImage.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminExtraCustomizedProductItemTemplate, AdminExtraPrices, AdminExtras, AdminProducts } from "@/network/api";
import PriceRange from "@/components/form-items/PriceRange.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";

const AppProps = Vue.extend({
  props: {
    id: { default: "" },
  },
});
@Component({
  name: "NewExtras",
  components: {
    BasicInputNumberField,
    PriceRange,
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicTooltipHeading,
    PreviewImage
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  dirtyTimer: any = null;
  extraName: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  frontImage: Array<any> = [];
  backImage: Array<any> = [];
  showBanner: boolean = true;
  extra: ExtraAdminViewModel = {
    id: "",
    name: "",
    code: "",
    previewImageUrl: "",
    frontShadowMaskUrl: "",
    backShadowMaskUrl: "",
    isDisabled: true,
    hasCustomizationText: false,
    customizationTextMaximumLength: null,
    customizedProductItemTemplate: {
      type: "",
      x: 0,
      y: 0,
      colour: "",
      rotate: 0,
      fontSize: 0,
      order: 0,
      text: "",
      view: 0,
      height: 0,
      width: 0,
      alignment: "",
      src: "",
      borderColour: "",
      borderStyle: 0,
      borderWidth: 0,
      fontWeight: "",
      fontStyle: "",
      textSvgUrl: "",
      frontPreviewImageUrl: "",
      backPreviewImageUrl: "",
      customization: {
        id: "",
        name: "",
        code: "",
        customizationTypeId: CustomizationType.Text
      }
    },
  };
  extras: Array<ExtraAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  newExtra: ExtraAdminCreateModel | ExtraAdminUpdateModel = {
    name: "",
    code: "",
    previewImageUrl: "",
    frontShadowMaskUrl: "",
    backShadowMaskUrl: "",
    isDisabled: true,
    hasCustomizationText: false,
    customizationTextMaximumLength: null,
  };
  fromPath = "";
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: "",
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: "",
    },
  ];
  products: Array<ProductAdminListViewModel> = [];
  selectedProductId = ""

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("textLength", {
      validate(value) {
        return {
          required: true,
          valid: value > 0,
        };
      },
      computesRequired: true,
      message: "The {_field_} field needs to be greater than 0.",
    });

    let self = this;
    extend("preview", {
      validate(value) {
        let uploader = self.$refs.previewUploader as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("front", {
      validate(value) {
        let uploader = self.$refs.frontUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("back", {
      validate(value) {
        let uploader = self.$refs.backUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField(field: any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if (provider) {
      return provider!.validate();
    }
  }

  handleUseEmpty(path: any, target: any) {
    this.newExtra[target] = path;
  }

  uploadImages(): Promise<any> {
    let previewUploader = this.$refs.previewUploader as ImageUploader;
    let frontUploader = this.$refs.frontUploader as ImageUploader;
    let backUploader = this.$refs.backUploader as ImageUploader;

    let previewPromise;
    let frontPromise;
    let backPromise;

    if (previewUploader) {
      previewPromise = previewUploader!.uploadImage();
    }
    if (frontUploader) {
      frontPromise = frontUploader!.uploadImage();
    }
    if (backUploader) {
      backPromise = backUploader!.uploadImage();
    }
    return Promise.all([previewPromise, frontPromise, backPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "preview") {
      this.newExtra.previewImageUrl = "";
    } else if (area == "front") {
      this.newExtra.frontShadowMaskUrl = "";
    } else if (area == "back") {
      this.newExtra.backShadowMaskUrl = "";
    }
  }

  beforeRemoveIndividualCustomization() {
    this.dialogMessage =
      "<span>Are you sure you want to remove the existing individual customization?</span>";
    this.confirmText = "Remove";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if (this.fromPath === "Extras") {
        this.$router.back();
      } else {
        this.$router.push({ name: "Extras" });
      }
    } else if(this.confirmText === "Remove"){
      this.deleteIndividualCustomization()
    } else {
      this.submitForm();
    }
  }

  //-------- Individual Customization Start

  addIndividualCustomization() {
    if(this.id && this.selectedProductId) {
      let link = `${process.env.VUE_APP_ROOT_WEB}/designer?product=${this.selectedProductId}&extra=${this.id}`;
      window.open(link, "_blank");
    }
  }

  deleteIndividualCustomization() {
    if(this.id && this.extra.customizedProductItemTemplate) {
      AdminExtraCustomizedProductItemTemplate.adminExtraCustomizedProductItemTemplateExtraIdDelete(this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message.success("Individual customization removed!");
          this.dialogVisible = false;
          this.loadInitialData()
        }
        this.loading = false;
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
        this.loading = false;
        this.dialogVisible = false;
      });
    }
  }

  //-------- Individual Customization End

  //-------- Price Range Start

  saveNewPriceRange(range: any) {
    this.loading = true;
    AdminExtraPrices.adminExtraPricesPost(range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price post success", res);
          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
        this.loading = false;
        this.dialogVisible = false;
      });
  }

  updatePriceRange(range: any) {
    this.loading = true;
    AdminExtraPrices.adminExtraPricesIdPut(range.id, range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price put success", res);
          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false;
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
        this.loading = false;
        this.dialogVisible = false;
      });
  }

  deletePriceRange(id: string) {
    this.loading = true;
    AdminExtraPrices.adminExtraPricesIdDelete(id)
      .then((res) => {
        this.$message.info("Item deleted.");
        this.loadPriceRanges();
        this.loading = false;
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
        this.loading = false;
        this.dialogVisible = false;
      });
  }

  loadPriceRanges() {
    this.loading = true;
    AdminExtraPrices.adminExtraPricesGet(1, 99999999, this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.priceRanges = res.data.resultData!.items;
          console.log("get price", this.priceRanges);
        }
        this.loading = false;
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
        this.loading = false;
        this.dialogVisible = false;
      });
  }

  //-------- Price Range End

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newExtra.previewImageUrl = results[0].imageUrl;
      }

      if (results[1].imageUrl) {
        this.newExtra.frontShadowMaskUrl = results[1].imageUrl;
      }

      if (results[2].imageUrl) {
        this.newExtra.backShadowMaskUrl = results[2].imageUrl;
      }

      if (!this.newExtra.hasCustomizationText) {
        this.newExtra.customizationTextMaximumLength = null;
      }

      if (this.extra.id) {
        console.log("before save", this.newExtra);

        AdminExtras.adminExtrasIdPut(this.extra.id, this.newExtra)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("success?", res);
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
            this.loading = false;
            this.dialogVisible = false;
          });
      } else {
        AdminExtras.adminExtrasPost(this.newExtra)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.id = res.data.resultData!.id;
              this.$router.replace({
                name: "EditExtras",
                params: { id: this.id },
              });
              this.loadInitialData().then(() => {
                this.clearDirtyClasses();
              });
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
            this.loading = false;
            this.dialogVisible = false;
          });
      }
    });
  }

  async loadProducts() {
    this.loading = true
    await AdminProducts.adminProductsGet(1, 99999)
      .then((res) => {
        if(res.data.succeeded) {
          this.products = res.data.resultData?.items as Array<ProductAdminListViewModel>
          this.loading = false
        }
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
      })
  }

  async loadInitialData() {
    if (this.id) {
      this.loadPriceRanges();
      this.loading = true;
      await AdminExtras.adminExtrasIdGet(this.id)
        .then(async (res) => {
          if (res.data.succeeded) {
            this.extra = res.data.resultData as ExtraAdminViewModel;
            this.extraName = this.extra.name;
            this.newExtra = (({ id, ...rest }) => rest)(this.extra) as any;
            console.log("new extra", this.newExtra);

            if (
              !this.newExtra.hasCustomizationText ||
              !this.newExtra.customizationTextMaximumLength
            ) {
              this.newExtra.customizationTextMaximumLength = 15;
            }

            if (this.newExtra.previewImageUrl) {
              this.previewImage = [
                {
                  imageUrl: this.newExtra.previewImageUrl,
                },
              ];
            }
            if (this.newExtra.frontShadowMaskUrl) {
              this.frontImage = [
                {
                  imageUrl: this.newExtra.frontShadowMaskUrl,
                },
              ];
            }
            if (this.newExtra.backShadowMaskUrl) {
              this.backImage = [
                {
                  imageUrl: this.newExtra.backShadowMaskUrl,
                },
              ];
            }

            await this.loadProducts()
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
          this.loading = false;
          this.dialogVisible = false;
        });
      this.loading = false;
    }
  }
}
