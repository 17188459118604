




























































































































import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import NewOrderTask from "@/components/orders/activity-tasks/NewOrderTask.vue";
import { Component, Vue } from "vue-property-decorator";
import { formatDate } from "@/utils/formatDate";
import {
  TaskAdminViewModel,
  TaskAdminListViewModel,
  TaskAdminListViewModelPaginatedListApplicationResultResultData,
  CliftonTaskStatus,
} from "@/api-client";
import { AdminTasks } from "@/network/api";
import Pagination from "@/components/Pagination.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";

const AppProps = Vue.extend({
  props: {
    taskType: {
      type: String,
      default: 'order',
    },
    resetSection: {
      type: Boolean,
      default: false,
    },
    // pages: {
    //   type: Object,
    //   default: null,
    // },
  },
});

@Component({
  name: "OrderTaskList",
  components: { BasicSelectorField, NewOrderTask, Pagination, ItemsPerPage },
})
export default class extends AppProps {
  id: string = this.$route.params.id;
  pageNumber = 1;
  itemPerPage = 5;
  tasks: TaskAdminListViewModelPaginatedListApplicationResultResultData = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  loading = false;
  taskItems: Array<TaskAdminListViewModel> = [];
  selectedTaskStatus: CliftonTaskStatus | undefined = 'Pending'
  section: "task-list" | "task" = "task-list";
  statusColor: any = {
    Pending: "rgb(207, 135, 0)",
    Resolved: "rgb(12, 165, 71)",
  };
  taskId = ''
  loadAttempts = 0;

  created() {
    this.loadInitialData()

    if (this.$route.query.taskId) {
      let task = this.$route.query.taskId as string
      this.openTask(task)
    }

    this.$watch('resetSection', () => {
      if(this.resetSection) {
        this.closeTask()
        this.$emit('resetSection')
      }
    })
  }

  handledate(date: any) {
    if (date) {
      return formatDate(date);
    }
    return "";
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  openTask(task: TaskAdminViewModel | string) {
    if(typeof task === 'string') {
      this.taskId = task
    } else {
      this.taskId = task.id
    }
    this.$emit('taskId', this.taskId)

    this.section = "task";

    if(!this.$route.query.taskId) {
      this.$router.push({
        path: this.$route.fullPath,
        query: { taskId: this.taskId },
      });
    }
  }

  closeTask() {
    this.taskId = ''
    this.$emit('taskId', this.taskId)
    if(this.$route.query.taskId) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: undefined
        }
      })
    }
    this.section = "task-list";
  }

  loadInitialData() {
    this.loading = true
    let orderId = this.taskType === 'order' ? this.id : undefined;
    let enquiryId = this.taskType === 'enquiry' ? this.id : undefined;

    AdminTasks.adminTasksGet(this.pageNumber, this.itemPerPage, false, orderId, enquiryId, undefined, this.selectedTaskStatus )
    .then((res) => {
        if(res.data.succeeded) {
          this.tasks = res.data.resultData as TaskAdminListViewModelPaginatedListApplicationResultResultData;
          this.taskItems = this.tasks.items as Array<TaskAdminListViewModel>;

          if((this.loadAttempts == 0) && !this.taskItems.length) {
            this.loadAttempts += 1;
            this.selectedTaskStatus = undefined;
            this.pageNumber = 1;
            this.loadInitialData()
          }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false

        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }
}
