



















































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminRoles, AdminUsers } from "@/network/api";
import {
  UserAdminListViewModel,
  UserAdminListViewModelPaginatedList,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: "Users",
  components: { ElTableDraggable, Pagination, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  users: UserAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  userItems: Array<UserAdminListViewModel> = [];
  searchName: string | undefined = this.$route.params.searchName || '';
  searchEmail: string | undefined = this.$route.params.searchEmail || '';
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  selectedItem: UserAdminListViewModel = {
    id: "",
    displayName: "",
    email: "",
    roles: [],
    isDefaultBankTransferOrderManager: false,
    isDefaultStripeOrderManager: false,
    isDefaultEnquiryOrderManager: false,
    isDefaultStoreOrderManager: false,
    isDefaultBankTransferSalesManager: false,
    isDefaultEnquirySalesManager: false,
    isDefaultStoreSalesManager: false,
    isDefaultStripeSalesManager: false
  };
  debounce: any = null;
  hasType?: boolean | undefined;
  showAllText: Array<string> = [];
  selectedRoles: Array<string> = [];
  roles: Array<string> = [];
  loading = false;
  selectedDefaultUserFilter = {
    ordersStripe: undefined,
    ordersEnquiries: undefined,
    ordersBankTransfer: undefined,
    ordersCustomStores: undefined,
    salesStripe: undefined,
    salesEnquiries: undefined,
    salesBankTransfer: undefined,
    salesCustomStores: undefined
  }
  defaultUserFilters: Array<{ name: string, id: string }> = [
    { id: 'ordersBankTransfer', name: 'Orders - Bank Transfer' },
    { id: 'ordersStripe', name: 'Orders - Stripe' },
    { id: 'ordersEnquiries', name: 'Orders - Enquiries' },
    { id: 'ordersCustomStores', name: 'Orders - Online Stores' },
    { id: 'salesBankTransfer', name: 'Sales - Bank Transfer' },
    { id: 'salesStripe', name: 'Sales - Stripe' },
    { id: 'salesEnquiries', name: 'Sales - Enquiries' },
    { id: 'salesCustomStores', name: 'Sales - Online Stores' },
  ]

  created() {
    let toQuery = {
      searchEmail: this.searchEmail,
      searchName: this.searchName,
      selectedRoles: this.selectedRoles,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      ['selectedDefaultUserFilter.ordersStripe']: this.selectedDefaultUserFilter.ordersStripe,
      ['selectedDefaultUserFilter.ordersEnquiries']: this.selectedDefaultUserFilter.ordersEnquiries,
      ['selectedDefaultUserFilter.ordersBankTransfer']: this.selectedDefaultUserFilter.ordersBankTransfer,
      ['selectedDefaultUserFilter.ordersCustomStores']: this.selectedDefaultUserFilter.ordersCustomStores,
      ['selectedDefaultUserFilter.salesStripe']: this.selectedDefaultUserFilter.salesStripe,
      ['selectedDefaultUserFilter.salesEnquiries']: this.selectedDefaultUserFilter.salesEnquiries,
      ['selectedDefaultUserFilter.salesBankTransfer']: this.selectedDefaultUserFilter.salesBankTransfer,
      ['selectedDefaultUserFilter.salesCustomStores']: this.selectedDefaultUserFilter.salesCustomStores
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch("searchName", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("searchName")!.focus();
    //     }) 
    //   }, 400);
    // });

    // this.$watch("searchEmail", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("searchEmail")!.focus();
    //     }) 
    //   }, 400);
    // });

    this.$watch("selectedRoles", () => {
      this.pageNumber = 1;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.loadInitialData();
      }, 400);
    });
  }

  handleSearch(id:string = 'search') {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById(id)!.focus();
    }) 
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        let object = null as any

        if(field.includes('.')) {
          object = field.split('.')
        }

        if(object?.length == 2) {
          this[object[0]][object[1]] = queries[field];
        } else {
          this[field] = queries[field];
        }
      }

      this.$watch(field, (val) => {
        handleQueries(field, val)
      })
    }
  }

  get hasSelectedDefaultUserFilter() {
    return Object.values(this.selectedDefaultUserFilter).some(
      (value) => value !== undefined
    );
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  clearRoleFilter() {
    this.pageNumber = 1
    this.selectedRoles = [];
    this.loadInitialData();
  }

  clearDefaultUserFilter() {
    this.pageNumber = 1
    this.selectedDefaultUserFilter = {
      ordersStripe: undefined,
      ordersEnquiries: undefined,
      ordersBankTransfer: undefined,
      ordersCustomStores: undefined,
      salesStripe: undefined,
      salesEnquiries: undefined,
      salesBankTransfer: undefined,
      salesCustomStores: undefined
    }
    this.loadInitialData();
  }

  clearAllFilters() {
    this.selectedRoles = []
    this.searchName = "";
    this.searchEmail = "";
    this.clearDefaultUserFilter()
  }

  handleName(name: string) {
    if(name) {
      const parsedName = name.toLowerCase().split(" ").join("-");
      return parsedName;
    } 
    return ''
  }

  handleDefaultUser(selected: string) {
    if(this.selectedDefaultUserFilter[selected]) {
      this.selectedDefaultUserFilter = {
        ordersStripe: undefined,
        ordersEnquiries: undefined,
        ordersBankTransfer: undefined,
        ordersCustomStores: undefined,
        salesStripe: undefined,
        salesEnquiries: undefined,
        salesBankTransfer: undefined,
        salesCustomStores: undefined
      }
    } else {
      this.selectedDefaultUserFilter = {
        ordersStripe: undefined,
        ordersEnquiries: undefined,
        ordersBankTransfer: undefined,
        ordersCustomStores: undefined,
        salesStripe: undefined,
        salesEnquiries: undefined,
        salesBankTransfer: undefined,
        salesCustomStores: undefined
      }
      this.selectedDefaultUserFilter[selected] = true;
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleFilterRole(selected: string) {
    if(this.selectedRoles.includes(selected)) {  
      this.selectedRoles = this.selectedRoles.filter(role => {
        return role !== selected
      })
    } else {
      if(typeof this.selectedRoles == 'string') {
        const arr = [this.selectedRoles, selected];
        this.selectedRoles = arr;
      } else {
        this.selectedRoles.push(selected);
      }
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  findDefaults(user:UserAdminListViewModel) {
    let defaults = [] as Array<string>
    if(user.isDefaultBankTransferOrderManager) {
      defaults.push('Orders - Bank Transfer')
    }

    if(user.isDefaultStripeOrderManager) {
      defaults.push('Orders - Stripe')
    }

    if(user.isDefaultEnquiryOrderManager) {
      defaults.push('Orders - Enquiries')
    }

    if(user.isDefaultStoreOrderManager) {
      defaults.push('Orders - Online Stores')
    }

    if(user.isDefaultBankTransferSalesManager) {
      defaults.push('Sales - Bank Transfer')
    }

    if(user.isDefaultStripeSalesManager) {
      defaults.push('Sales - Stripe')
    }

    if(user.isDefaultEnquirySalesManager) {
      defaults.push('Sales - Enquiries')
    }

    if(user.isDefaultStoreSalesManager) {
      defaults.push('Sales - Online Stores')
    }

    return defaults.join('<br>')
  }

  async loadInitialData() {
    this.loading = true
    
    await AdminRoles.adminRolesGet()
    .then((res) => {
      if (res.data.succeeded) {
        this.roles = res.data.resultData as Array<string>  
      }
    })
    .catch((error) => {
      this.loading = false
    error.response.data.errors.map((e: any) => {
      this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
      return e.friendlyMessage;
    });
    })   

    await AdminUsers.adminUsersGet(
      this.pageNumber,
      this.itemPerPage,
      this.searchName || undefined,
      this.searchEmail || undefined,
      this.selectedRoles,
      this.selectedDefaultUserFilter.ordersEnquiries,
      this.selectedDefaultUserFilter.salesEnquiries,
      this.selectedDefaultUserFilter.ordersStripe,
      this.selectedDefaultUserFilter.salesStripe,
      this.selectedDefaultUserFilter.ordersBankTransfer,
      this.selectedDefaultUserFilter.salesBankTransfer,
      this.selectedDefaultUserFilter.ordersCustomStores,
      this.selectedDefaultUserFilter.salesCustomStores
    )
      .then((res) => {
        this.users = res.data.resultData as UserAdminListViewModelPaginatedList;
        this.userItems = this.users.items as Array<UserAdminListViewModel>;
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
      this.loading = false
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
