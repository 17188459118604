






















































































































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicPhoneNumberField from "@/components/form-items/BasicPhoneNumberField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import {
  ValidationProvider, extend,
} from "vee-validate";

const AppProps = Vue.extend({
  props: {
    address: { type:Object, default: null },
    addressType: { default: '' },
    index: {default: null},
    isDisabled: {default: false},
    isGroupedDelivery: { type:Boolean, default: false }
  }
});

@Component({
  name: "AddressFields",
  components: {  ValidationProvider, BasicInputField, BasicSwitchField, BasicDateField, BasicPhoneNumberField },
})
export default class extends AppProps {
  autocomplete = ''

  remove(index:any) {
    let arr = index.split('-');
    this.$emit('remove', arr[1])
  }

  setPlace(place: any) {
    let streetNum = "";
    let streetName = "";
    let subPremise = "";
    this.address.addressLine1 = ''
    this.address.addressLine2 = ''
    this.address.area = ''
    this.address.country = ''
    this.address.postalCode = ''

    if(this.address) {
      place.address_components.forEach((component: any) => {
      if (component.types.includes("street_number")) {
        streetNum = component.long_name;
      }
      if (component.types.includes("route")) {
        streetName = component.long_name;
      }
      if (component.types.includes("subpremise")) {
        subPremise = component.long_name;
      }
      if (streetNum || streetName) {
        this.address.addressLine1 = `${streetNum}${streetNum && " "}${streetName}${subPremise && ", "}${subPremise}`;
        // this.autocomplete = `${streetNum}${streetNum && " "}${streetName}`;
      }
      if (component.types.includes("locality")) {
        this.address.addressLine2 = component.long_name;
      } else if (component.types.includes("postal_town")) {
        this.address.addressLine2 = component.long_name;
      }
      if (component.types.includes("administrative_area_level_1")) {
        this.address.area = component.long_name;
      } else if(component.types.includes("administrative_area_level_2")) {
        this.address.area = component.long_name;
      }
      if (component.types.includes("country")) {
        this.address.country = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        this.address.postalCode = component.long_name;
      }
    });
    }

  }
}
