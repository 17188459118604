



























































































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminProductionSpeedAdjustments } from '@/network/api'
import {
  ProductionSpeedAdjustmentViewModel,
  ProductionSpeedAdjustmentViewModelPaginatedList,
} from '@/api-client'
import DateRangeFilter from "@/components/form-items/DateRangeFilter.vue";
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"
import FileUploader from "@/components/form-items/FileUploader.vue";
import { formatDate } from "@/utils/formatDate"

@Component({
  name: 'ProductionSpeedAdjustments',
  components: { DateRangeFilter, Pagination, DialogBox, ItemsPerPage, FileUploader },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends Vue {
  productionSpeedAdjustments: ProductionSpeedAdjustmentViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  bulkUpload: Array<any> = [];
  productionSpeedAdjustmentItems: Array<ProductionSpeedAdjustmentViewModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  search: string | undefined = this.$route.params.search || '';
  dialogVisible = false;
  dialogMessage = '<span><strong>Are you sure you want to delete this production speed adjustment period?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedItem: ProductionSpeedAdjustmentViewModel = {
    id: "",
    name: "",
    description: null,
    startDate: "",
    endDate: "",
    deliveryDelay: 0,
    productionDelay: 0
  };
  debounce: any = null;
  loading = false;
  dateFilter: any = null;
  adjustmentPeriods: any = {
    startDate: undefined,
    endDate: undefined,
  };
  startDate = "";
  endDate = "";

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      ['adjustmentPeriods.startDate']: this.adjustmentPeriods.startDate,
      ['adjustmentPeriods.endDate']: this.adjustmentPeriods.endDate,
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }

  clearDateFilter() {
    this.pageNumber = 1;
    this.adjustmentPeriods = {
      startDate: undefined,
      endDate: undefined,
    };
    this.dateFilter = null;
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = '';
    this.clearDateFilter();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  async loadInitialData() {
    this.loading = true
    await AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsGet(this.pageNumber, this.itemPerPage, this.search || undefined, this.adjustmentPeriods.startDate || undefined, this.adjustmentPeriods.endDate || undefined)
      .then((res) => {
        if(res.data.succeeded) {
          this.productionSpeedAdjustments = res.data
            .resultData as ProductionSpeedAdjustmentViewModelPaginatedList
          this.productionSpeedAdjustmentItems = this.productionSpeedAdjustments
            .items as Array<ProductionSpeedAdjustmentViewModel>
          console.log('prod speed items',this.productionSpeedAdjustmentItems);
        }
        this.loading = false                
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
      })
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true
  }

  deleteItem() {
    if (this.selectedItem.id) {
      this.loading = true
      AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsIdDelete(this.selectedItem.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
            this.$message.info('Item deleted.')
            this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
        })
    }
    this.dialogVisible = false
    this.loading = false
    this.selectedItem = {
      id: "",
      name: "",
      description: null,
      startDate: "",
      endDate: "",
      deliveryDelay: 0,
      productionDelay: 0
    }
  }

  async exportAdjustmentPeriod() {
    await AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsExportGet()
      .then(async (res) => {
        if(res.status == 200) {
          await this.saveCsv(res)
        }
      })
      .catch((e) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: e.friendlyMessage,
        });
      });
  }

  async saveCsv(data:any) {
    console.log(data)
    var csvContent = unescape(encodeURIComponent(data.data));
    var csvBlob = new Blob ([csvContent], { type: "text/csv;charset=utf-8;"})
    
    var downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(csvBlob);
    downloadLink.download = `Production Speed Adjustment Periods - ${new Date().toISOString().replaceAll("T"," ").replaceAll("Z","").replaceAll(":","--")}.csv`;
    downloadLink.click();
  }

  async bulkAdjustmentPeriod(res, file) {
    this.loading = true;
    if(file.length < 1) {
      return false;
    }
    
    await AdminProductionSpeedAdjustments.adminProductionSpeedAdjustmentsImportPost(res.relativePath)
      .then((res) => {
        if(res.data.succeeded) {
          this.pageNumber = 1;
          this.$message.info('Import complete.');
          this.loadInitialData();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
      })

    this.bulkUpload = [];
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }

  handleDateRanges(value:any) {    
    let dates = {};   
    if(value) {
      let d1 = new Date(value[0])
      let d2 = new Date(value[1])

      //if(d1.getTime() == d2.getTime()) {
        d2.setDate(d2.getDate() + 1);   
      //}

      dates = {
        startDate: new Date(d1).toISOString(),
        endDate: new Date(d2).toISOString()
      }
    } else {
      dates = {
        startDate: undefined,
        endDate: undefined
      };
    }
    this.adjustmentPeriods = dates as any;
    this.pageNumber = 1;
    this.loadInitialData();
  }
}
