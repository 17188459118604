




































































































import {
  CliftonTaskPriority,
  CliftonTaskStatus,
  TaskAdminCreateModel,
  TaskAdminUpdateModel,
  TaskAdminViewModel,
  TaskMessageAdminListViewModel,
  TaskMessageAdminListViewModelPaginatedListApplicationResultResultData,
  TaskType,
  UserAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import TaskMessages from "@/components/orders/activity-tasks/TaskMessages.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminTaskMessages, AdminTasks, AdminUsers } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    taskId: {
      type: String,
      default: '',
    },
    taskType: {
      type: String,
      default: 'order',
    },
  },
});

@Component({
  name: "NewOrderTask",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicSelectorField,
    TaskMessages,
    BasicDateField
  },
})
export default class extends AppProps {
  id?: string = this.$route.params.id;
  search: string = this.$route.params.search;
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  task: TaskAdminViewModel = {
    id: "",
    title: "",
    fromAspNetUserId: "",
    fromAspNetUserDisplayName: "",
    taskPriorityId: CliftonTaskPriority.Standard,
    taskStatusId: CliftonTaskStatus.Pending,
    orderId: '',
    enquiryId: '',
    dateTimeStamp: "",
    taskRecipients: [],
    type: TaskType.Order,
    referenceNumber: '',
    dueDate: ''
  };
  taskRecipients: Array<string> = []
  admins: Array<UserAdminListViewModel> = []

  taskStatuses: any = [
    {
      name: 'Pending',
      id: CliftonTaskStatus.Pending
    },
    {
      name: 'Resolved',
      id: CliftonTaskStatus.Resolved
    },
  ]

  taskPriorities: any = [
    {
      name: 'Standard',
      id: CliftonTaskPriority.Standard
    },
    {
      name: 'Urgent',
      id: CliftonTaskPriority.Urgent
    },
  ]

  newTask: TaskAdminCreateModel | TaskAdminUpdateModel = {
    title: "",
    taskPriorityId: CliftonTaskPriority.Standard,
    taskStatusId: CliftonTaskStatus.Pending,
    orderId: '',
    enquiryId: '',
    taskRecipients: [],
    dueDate: ''
  };
  loading = false;
  dirtyTimer: any = null;

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  handledate(date: any) {
    if (date) {
      return formatDate(date);
    }
    return "";
  }

  created() {
    // if(!this.taskId) {
    //   if(this.taskType === 'order') {
    //     this.newTask.orderId = this.id;
    //     this.newTask.enquiryId = null;
    //   } else {
    //     this.newTask.enquiryId = this.id;
    //     this.newTask.orderId = null;
    //   }
    // }
    

    
    this.loadInitialData(true).then(() => {
      this.clearDirtyClasses();
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("recipients", {
      validate(value) {
        return {
          required: true,
          valid: (value.length > 0)
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  closeTask() {
    this.$emit('close')
  }

  watchStatuses() {
    this.$watch('newTask.taskStatusId', function (newVal, oldVal) {
      if(this.taskId && (newVal != oldVal)) {
        this.submitForm()
      }
    })

    this.$watch('newTask.taskPriorityId', function (newVal, oldVal) {
      if(this.taskId && (newVal != oldVal)) {
        this.submitForm()
      }
    })

     this.$watch('newTask.dueDate', function (newVal, oldVal) {
      if(this.taskId && (newVal != oldVal)) {
        this.submitForm()
      }
    })
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    }
  }

  submitForm() {
    this.newTask.taskRecipients = []
    this.taskRecipients.forEach((recipientId: string) => {
      this.newTask.taskRecipients.push({
        aspNetUserId: recipientId,
      })
    });

    if (this.taskId) {
      console.log("before save", this.newTask);
      this.loading = true;

      AdminTasks.adminTasksIdPut(
        this.taskId,
        this.newTask as TaskAdminUpdateModel
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.loading = false;
            this.dialogVisible = false;
            this.$message.success("Saved!");
            this.$emit('reload')
            this.loadInitialData().then(() => {
              this.clearDirtyClasses();
            });
          }
        })
        .catch((error) => {
            this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    } else {

      let createTask = {...this.newTask as TaskAdminCreateModel}
      if(this.taskType === 'order') {
        createTask.orderId = this.id;
        createTask.enquiryId = null;
      } else {
        createTask.enquiryId = this.id;
        createTask.orderId = null;
      }

      AdminTasks.adminTasksPost(createTask)
        .then((res) => {
          if (res.data.succeeded) {
            this.$emit('updateTaskId', res.data.resultData?.id)
            this.loading = false;
            this.dialogVisible = false;
            this.$message.success("Created!");
            // this.taskId = res.data.resultData?.id as string;
            this.$emit('reload')
            this.loadInitialData(true).then(() => {
              this.clearDirtyClasses();
            });
          }
        })
        .catch((error) => {
            this.loading = false;
          this.dialogVisible = false;

          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
  }

  async loadInitialData(callWatch = false) {
    this.loading = true;

    // if(this.roles.includes('Admin')) {
    //   await AdminUsers.adminUsersGet(1, 999999, undefined, undefined, ['Admin', 'Operations', 'Sales', 'Marketing & Design'])
    //   .then((res) => {
    //     if (res.data.succeeded) {
    //       this.admins = res.data.resultData!
    //         .items as Array<UserAdminListViewModel>;
    //     }
    //   })
    //   .catch((error) => {
    //     error.response.data.errors.map((e: any) => {
    //       this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
    //       return e.friendlyMessage;
    //     });
    //   }); 
    // } else
    if(this.roles.includes('Sales') || this.roles.includes('Sales Admin') || this.roles.includes('Operations') || this.roles.includes('Admin')) {
      await AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.admins = res.data.resultData!
            .items as Array<UserAdminListViewModel>;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      }); 
    }

    if (this.taskId) {
      await AdminTasks.adminTasksIdGet(this.taskId)
        .then((res) => {
          if (res.data.succeeded) {
            this.task = res.data.resultData as TaskAdminViewModel;
            this.newTask = (({ id, ...rest }) => rest)(this.task) as any;
            
            if(this.newTask.taskRecipients.length) {
              this.taskRecipients = []
              this.newTask.taskRecipients.forEach((recipient:any) => {
                this.taskRecipients.push(recipient.aspNetUserId)
              });
            }
            
            if(callWatch) {
              this.watchStatuses()
            }
          }
        })
        .catch((error) => {
            this.loading = false;
          this.dialogVisible = false;

          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      
    }

    this.loading = false;
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
