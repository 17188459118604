



































import {
  BlogCategoryAdminCreateModel,
  BlogCategoryAdminUpdateModel,
  BlogCategoryAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminBlogCategories } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewBlogPostCategory",
  components: { BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dirtyTimer: any = null
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  iconImage: Array<any> = [];
  showBanner: boolean = true;
  category: BlogCategoryAdminViewModel = {
    id: '',
    name: '',
    hexValue: ''
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newBlogPostCategory: BlogCategoryAdminCreateModel | BlogCategoryAdminUpdateModel = {
    name: '',
    hexValue: '#FF3D5C'
  };
  loading = false
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'BlogPosts') {
        this.$router.back()
      } else {
        this.$router.push({ name: "BlogPosts", query: {tab: 'categories'}});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    if(this.newBlogPostCategory.hexValue) {
        this.newBlogPostCategory.hexValue = this.newBlogPostCategory.hexValue.slice(1)
      }
      this.loading = true

        if (this.category.id) {
          console.log('before save', this.newBlogPostCategory);
          
          AdminBlogCategories.adminBlogCategoriesIdPut(this.category.id, this.newBlogPostCategory)
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.$message.success('Saved!')
              this.dialogVisible = false;
              this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
        } else {
          AdminBlogCategories.adminBlogCategoriesPost(this.newBlogPostCategory)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success('Saved!')
                this.dialogVisible = false;
                this.$router.push({ name: "BlogPosts", query: {tab: 'categories'}});
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
        }
  }

  async loadInitialData() {
    if (this.id) {
    this.loading = true

      await AdminBlogCategories.adminBlogCategoriesIdGet(this.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.category = res.data.resultData as BlogCategoryAdminViewModel;
        this.name = this.category.name;
        this.newBlogPostCategory = (({ id, ...rest }) => rest)(this.category) as any;
        console.log('new category', this.newBlogPostCategory);

        if(this.newBlogPostCategory.hexValue) {
          this.newBlogPostCategory.hexValue = `#${this.newBlogPostCategory.hexValue}`
        }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }

}
