var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container task-overview-container"},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('h1',[_vm._v("My Task Overview")])]),_c('el-col',{staticStyle:{"display":"flex","justify-content":"flex-end","align-items":"flex-end"},attrs:{"span":14}},[_c('router-link',{attrs:{"to":{ name: 'Orders' }}},[_c('button',{staticClass:"new-order",attrs:{"disabled":_vm.loading}},[_vm._v("To Orders")])]),_c('router-link',{attrs:{"to":{ name: 'Enquiries' }}},[_c('button',{staticClass:"new-order",attrs:{"disabled":_vm.loading}},[_vm._v("To Enquiries")])])],1)],1),_c('el-row',{attrs:{"type":"flex","justify":"'space-between'"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form',[_c('el-row',[_c('BasicSwitchField',{attrs:{"label":'Assigned To Me',"model":_vm.isAssignedToMe},on:{"update:model":function($event){_vm.isAssignedToMe=$event}}})],1)],1)],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.tasks.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
              _vm.itemPerPage = value;
              _vm.loadInitialData();
            },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":(_vm.tasks.totalCount > 10) ? 6 : 12}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.taskItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"90"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.taskTypes),function(type){return _c('li',{key:type.id,class:{
                    'selected-filter': _vm.selectedTaskType == type.id,
                  },on:{"click":function($event){return _vm.handleType(type.id)}}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearTaskTypeFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedTaskType },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Type"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.type, 'type'))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"110","prop":"dueDate","label":"Due Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleDate(row.dueDate, false)))])]}}])}),_c('el-table-column',{attrs:{"width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.taskPriorities),function(priority){return _c('li',{key:priority.id,class:{
                    'selected-filter': _vm.selectedTaskPriority == priority.id,
                  },on:{"click":function($event){return _vm.handlePriority(priority.id)}}},[_vm._v(" "+_vm._s(priority.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearTaskPriorityFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedTaskPriority },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Priority"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.taskPriorityId, 'priority'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"180","prop":"title","label":"Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"width":"100","prop":"referenceNumber","label":"Related To"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.referenceNumber))])]}}])}),_c('el-table-column',{attrs:{"width":"90"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.taskStatuses),function(status){return _c('li',{key:status.id,class:{
                    'selected-filter': _vm.selectedTaskStatus == status.id,
                  },on:{"click":function($event){return _vm.handleStatus(status.id)}}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearTaskStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedTaskStatus },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.taskStatusId, 'status'))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"150","prop":"statusUpdated","label":"Status Updated"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleDate(row.dateTimeStamp)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.users),function(user){return _c('li',{key:user.id,class:{
                    'selected-filter': _vm.selectedTaskFrom == user.id,
                  },on:{"click":function($event){return _vm.handleTaskFrom(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearTaskFromFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedTaskFrom },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Task Owner"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('el-tag',{staticStyle:{"font-size":"12px","padding":"0 5px","line-height":"19px","height":"20px","margin-right":"2px"},attrs:{"type":'info',"effect":"dark"}},[_vm._v(_vm._s(row.fromAspNetUserDisplayName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.users),function(user){return _c('li',{key:user.id,class:{
                    'selected-filter': _vm.selectedTaskRecipient == user.id,
                  },on:{"click":function($event){return _vm.handleTaskRec(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearTaskRecFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedTaskRecipient },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Recipients"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return _vm._l((row.taskRecipients),function(recipient){return _c('el-tag',{key:recipient.aspNetUserId,staticStyle:{"font-size":"12px","padding":"0 5px","line-height":"19px","height":"20px","margin-right":"2px"},attrs:{"effect":"dark"}},[_vm._v(" "+_vm._s(recipient.aspNetUserDisplayName)+" ")])})}}])}),_c('el-table-column',{attrs:{"width":"130","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('div',{staticClass:"clear-all-container",staticStyle:{"margin-bottom":"3px"},on:{"click":_vm.clearAllFilters}},[_c('p',{staticClass:"clear-all"},[_vm._v("Clear Filters")])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":_vm.handleRouteTo(row)}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("View")])],1)]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.tasks.totalCount,"totalPages":_vm.tasks.totalPages,"pageSize":_vm.tasks.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }