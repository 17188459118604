var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container generation-logs-container"},[_c('h1',{attrs:{"id":"title"}},[_vm._v("Generation Logs")]),_c('el-row',{attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.generationLogs.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1)],1),_c('ElTableDraggable',{attrs:{"disable":true}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.generationLogItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"95","prop":"productBuilderOptionsGenerationLogTypeId","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.productBuilderOptionsGenerationLogTypeId)}},[_vm._v(" "+_vm._s(row.productBuilderOptionsGenerationLogTypeId)+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"200","prop":"message","label":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.message))])]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"productId","label":"Product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.productId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditProduct', params: {id: row.productId}}}},[_vm._v(" "+_vm._s(row.productCode)+" ")]),_c('br'),_vm._v(_vm._s(row.productName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"styleId","label":"Style"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.styleId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditStyle', params: {id: row.styleId}}}},[_vm._v(" "+_vm._s(row.styleCode)+" ")]),_c('br'),_vm._v(_vm._s(row.styleName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"designId","label":"Design"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.designId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditDesign', params: {id: row.designId}}}},[_vm._v(" "+_vm._s(row.designCode)+" ")]),_c('br'),_vm._v(_vm._s(row.designName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"layerId","label":"Layer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.layerId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditLayer', params: {id: row.layerId}}}},[_vm._v(" "+_vm._s(row.layerCode)+" ")]),_c('br'),_vm._v(_vm._s(row.layerName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"fabricChannelId","label":"Fabric Channel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.fabricChannelId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditFabricChannel', params: {id: row.fabricChannelId}}}},[_vm._v(" "+_vm._s(row.fabricChannelInternalName)+" ")]),_c('br'),_vm._v(_vm._s(row.fabricChannelExternalName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"colourId","label":"Colour"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.colourId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditColour', params: {id: row.colourId}}}},[_vm._v(" "+_vm._s(row.colourCode)+" ")]),_c('br'),_vm._v(_vm._s(row.colourName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"extraId","label":"Extra"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.extraId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditExtras', params: {id: row.extraId}}}},[_vm._v(" "+_vm._s(row.extraCode)+" ")]),_c('br'),_vm._v(_vm._s(row.extraName)+" ")],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"customizationId","label":"Customization"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.customizationId)?_c('span',{staticClass:"log-tags"},[_c('router-link',{staticClass:"tag-bg",attrs:{"target":"_blank","to":{name: 'EditCustomization', params: {id: row.customizationId}}}},[_vm._v(" "+_vm._s(row.customizationCode)+" ")]),_c('br'),_vm._v(_vm._s(row.customizationName)+" ")],1):_vm._e()]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.generationLogs.totalCount,"totalPages":_vm.generationLogs.totalPages,"pageSize":_vm.generationLogs.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }