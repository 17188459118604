import { CategoryAdminListViewModel } from "@/api-client";

const filterArray = (toFilter:Array<CategoryAdminListViewModel>, category: CategoryAdminListViewModel) => {
  if (toFilter.filter((e:CategoryAdminListViewModel) => e.id === category.id).length === 0) {
    toFilter.push(category);
  } 
}

export function allCategories(node: any, array: any, ifProducts: boolean, checkChildren: boolean) {
      for (let j = 0; j < node.length; j++) {
        let parent = node[j];   
        if(parent.hasProducts === ifProducts || !parent.childCategories.length) {
          if(checkChildren === true) {
            if(!parent.childCategories.length) {
              filterArray(array, parent);
            }
          } else {
            if(parent.hasProducts === ifProducts) {
              filterArray(array, parent);
            }
          }    
        }

        if (parent.childCategories && !parent.hasProducts) {
          for (let i = 0; i < parent.childCategories.length; i++) {
            let child = parent.childCategories[i];
            if(child.hasProducts === ifProducts || !child.childCategories.length) {
              if(checkChildren === true) {
                if(!child.childCategories.length) {
                  filterArray(array, child);
                }
              } else {
                if(child.hasProducts === ifProducts) {
                  filterArray(array, child);
                }
              }                       
            }
            allCategories(child.childCategories, array, ifProducts, checkChildren);
          }
        }
      }
  }