/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'vouchers': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<defs><style>.cls-1 {fill: none;}</style></defs><g><g><path d="m127.97,94.86c0-1.62-1.01-3.07-2.55-3.59-7.44-2.53-12.8-9.57-12.8-17.87s5.37-15.36,12.83-17.88c1.52-.51,2.53-1.95,2.53-3.56-.02-5.19,0-10.38,0-15.57-.02-4.38-.43-4.77-4.91-4.77-19.67,0-39.33,0-59,0-19.91,0-39.82.03-59.74-.03-2.6,0-4.33.53-4.31,3.57.03,5.61.03,11.22.02,16.83,0,1.6,1.01,3.03,2.52,3.54,7.45,2.53,12.81,9.57,12.81,17.87s-5.35,15.33-12.79,17.87c-1.53.52-2.54,1.96-2.54,3.57.02,5.56.02,11.12-.02,16.68-.02,2.67,1.18,3.68,3.82,3.68,40.07-.04,80.14-.04,120.21,0,2.87,0,3.99-1.14,3.96-3.93-.05-5.47-.06-10.95-.03-16.42ZM30.35,54.77c0-3.77,1.35-7.04,4-9.72,2.66-2.69,5.92-4.05,9.7-4.05s7.04,1.36,9.7,4.05c2.66,2.69,4,5.96,4,9.73s-1.35,7-4.03,9.69c-2.67,2.69-5.93,4.05-9.67,4.05s-7-1.36-9.67-4.05c-2.67-2.69-4.03-5.95-4.03-9.69Zm20.92,46.69c-.84,1.31-2.27,2.1-3.83,2.1-1.69,0-3.18-.88-3.99-2.37-.81-1.48-.75-3.22.16-4.64l32.76-51.11c.84-1.31,2.27-2.1,3.83-2.1,1.69,0,3.18.89,3.99,2.37.81,1.48.75,3.22-.16,4.64l-32.76,51.11Zm42.34.42c-2.67,2.61-5.93,3.94-9.69,3.94s-7.06-1.33-9.73-3.94c-2.68-2.62-4.03-5.85-4.03-9.6s1.36-7.04,4.05-9.7c2.68-2.66,5.95-4,9.71-4s6.99,1.35,9.68,4.03c2.68,2.67,4.04,5.93,4.04,9.67s-1.36,6.98-4.03,9.6Z"/><rect class="cls-1" x="0" width="128" height="128"/></g></g>'
  }
})