





















































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminMedias } from '@/network/api'
import {
  MediaAdminListViewModel,
  MediaAdminListViewModelPaginatedList,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"


@Component({
  name: 'GeneralImagery',
  components: { ElTableDraggable, Pagination, DialogBox, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends Vue {
  generalImage: 	MediaAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  generalImageryItems: Array<MediaAdminListViewModel> = [];
  search: string = '';
  pageNumber = 1;
  itemPerPage = 10;
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this general image?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedImageId = '';
  debounce: any = null;
  showAllText: Array<string> = [];
  loading = false

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData()

    // this.$watch('search', () => {
    //   console.log(this.search);
      
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400)
    // })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }
  
  clearAllFilters() {
    this.pageNumber = 1
    this.search = '';
    this.loadInitialData()
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(`${this.uploadPath}/${path.imageUrl}`)
    this.$message.success('Path copied.')
  }

  async loadInitialData() {
    this.loading = true
    await AdminMedias.adminMediasGet(this.pageNumber, this.itemPerPage, this.search || undefined)
      .then((res) => {
        if(res.data.succeeded) {
          this.generalImage = res.data
          .resultData as MediaAdminListViewModelPaginatedList
        this.generalImageryItems = this.generalImage
          .items as Array<MediaAdminListViewModel>
        console.log('generalImage items', this.generalImageryItems)
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
      })
  }

  confirmDelete(id: string) {
    this.dialogVisible = true
    this.selectedImageId = id
  }

  deleteItem() {
    if (this.selectedImageId) {
      this.loading = true
      AdminMedias.adminMediasIdDelete(this.selectedImageId)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loading = false
          this.loadInitialData()
          }        
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
        })
    }
    this.loading = false
    this.dialogVisible = false
    this.selectedImageId = ''
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
