










































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminSEO } from '@/network/api'
import {
    PageSeoSettingAdminListViewModel,
    PageSeoSettingAdminListViewModelPaginatedList,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import { sortAscending } from '@/utils/sortAscending'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
    props: {
        reset: {default: false}
    }
})

@Component({
name: 'SEOList',
components: { ElTableDraggable, Pagination, DialogBox, ItemsPerPage },
filters: {
    statusFilter: (status: string) => {
    const statusMap: { [key: string]: string } = {
        false: 'danger',
        true: 'success'
    }
    return statusMap[status]
    },
    parseTime: (timestamp: string) => {
    return new Date(timestamp).toISOString()
    }
}
})
export default class extends AppProps {
    SEOEntryPages: PageSeoSettingAdminListViewModelPaginatedList = {
        hasNextPage: false,
        hasPreviousPage: false,
        items: [],
        pageIndex: 1,
        totalCount: 1,
        totalPages: 1,
        pageSize: 1
    };
    SEOEntries: Array<PageSeoSettingAdminListViewModel> = [];
    pageNumber = Number(this.$route.params.pageNumber) || 1;
    itemPerPage = Number(this.$route.params.itemPerPage) || 10;
    slugSearch: string | undefined = this.$route.params.slugSearch || "";
    dialogVisible = false;
    dialogMessage = '<span><stong>Are you sure you want to delete this blog post?</strong></span>';
    confirmText = 'Yes';
    cancelVisible = true;
    selectedId = '';
    debounce: any = null;
    showAllText: Array<string> = [];
    selectedFeaturedFilter = '';
    loading = false

    created() {
        let toQuery = {
            slugSearch: this.slugSearch,
            pageNumber: this.pageNumber,
            itemPerPage: this.itemPerPage,
        }
        this.setQueryWatch(toQuery)
        
        this.loadInitialData();

        this.$watch('reset', () => {
            this.clearAllFilters()
        })
    }

    setQueryWatch(fields: any) {
        let queries = this.$route.query;

        for (const field in fields) {
            if (queries[field]) {
            this[field] = queries[field];
            }

            this.$watch(field, (val) => {
                handleQueries(field, val)
            })
        }
    }

    handleSearch(id:string = 'search') {
        this.pageNumber = 1;
        this.loadInitialData().then(() => {
            document.getElementById(id)!.focus();
        }) 
    }

    handlePageNumberClick(page: any) {
        this.pageNumber = page
        this.loadInitialData();
    }

    truncateString(str: string, num: number) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num)
    }

    clearAllFilters() {
        this.slugSearch = '';
        this.loadInitialData();
    }

    async loadInitialData() {
        this.loading = true
        await AdminSEO.adminPageSeoSettingsGet(this.pageNumber, this.itemPerPage, this.slugSearch || undefined)
            .then((res) => {
                if(res.data.succeeded) {
                    this.SEOEntryPages = res.data
                        .resultData as PageSeoSettingAdminListViewModelPaginatedList
                    this.SEOEntries = this.SEOEntryPages
                        .items as Array<PageSeoSettingAdminListViewModel>
                }
                this.loading = false
            })
            .catch((error) => {
                this.loading = false
                this.dialogVisible = false;
                error.response.data.errors.map((e: any) => {
                        this.$message.error(e.friendlyMessage)
                        return e.friendlyMessage
                    });             
                })
    }

    confirmDelete(id: string) {
        this.dialogVisible = true
        this.selectedId = id
    }

    deleteItem() {
        if (this.selectedId) {
            this.loading = true
            AdminSEO.adminPageSeoSettingsDelete(this.selectedId)
                .then((res) => {
                    if(res.data.succeeded) {
                        this.pageNumber = 1
                        this.$message.info('Item deleted.')
                        this.loadInitialData();
                    }
                    this.loading = false
                })
                .catch((error) => {
                    this.loading = false
                    this.dialogVisible = false;
                    error.response.data.errors.map((e: any) => {
                        this.$message.error(e.friendlyMessage)
                        return e.friendlyMessage
                    });
                })
        }
        this.dialogVisible = false
        this.selectedId = ''
    }

    beforeUnmount() {
        clearTimeout(this.debounce)
    }
}
