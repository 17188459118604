/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'factory': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M128,95.48c0-3.81-.33-4.15-3.92-4.17-2.34,0-4.69,0-7,0,0-10-.16-34.64-.16-44.12,6-2.14,8-5,8-11.3,0-3.06,0-14.29,0-17.36a9.61,9.61,0,0,0-4.15-8.3,30.53,30.53,0,0,0-3.85-1.9c-.42-7-1.76-8.32-8.49-8.32H33.07C31.92,0,12.9,0,10.62,0,6.25.12,4.22,2.22,4.2,6.79c0,8-.1,24.25.06,32.29A7,7,0,0,0,6,43.25a110.94,110.94,0,0,0,10,9.87,17.91,17.91,0,0,1,4.15,4.64l.17,21.67h.11l1.07,1.94s0,0,0,.08l0,0,2.25,4,3.39-6.12h.12L27.21,57.1c.22-.25.45-.49.71-.74,4.11-3.84,8-7.93,12.15-11.72A6.57,6.57,0,0,1,44.13,43c14.36-.12,11.73-.07,26.1-.07h2.69c0,11.26.17,37.13.17,48.42H4c-3.61,0-4,.36-4,4.13,0,7.66,0,20.76,0,28.42C0,127.59.38,128,4,128q18.38,0,36.75,0h83c4,0,4.26-.3,4.26-4.38C128,116.05,128,103.05,128,95.48Z"/>'
  }
})

