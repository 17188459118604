






















































import {
  StyleAdminListViewModel,
  StyleGroupAdminCreateModel,
  StyleGroupAdminListViewModel,
  StyleGroupAdminUpdateModel,
  StyleGroupAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from '@/utils/sortAscending'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminStyleGroups, AdminStyles } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewStyleGroup",
  components: { BasicSelectorField, BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  styleGroup: StyleGroupAdminViewModel = {
    id: '',
    name: '',
    code: '',
    styleIds: [],
    isDisabled: true,
    isSystemManaged: false
  };
  styles: Array<StyleAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false
  newStyleGroup: StyleGroupAdminCreateModel | StyleGroupAdminUpdateModel = {
    name: '',
    code: '',
    styleIds: [],
    isDisabled: true
  };
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend('styles', () => {
      if (this.newStyleGroup.styleIds.length > 0) {
        return true
      }
      return 'The {_field_} field is required.'
    })

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedStyles() {
    return sortAscending(this.styles, true, 'code');
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Styles') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Styles"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true
        if (this.styleGroup.id) {
          console.log('before save', this.newStyleGroup);
          
          AdminStyleGroups.adminStyleGroupsIdPut(this.styleGroup.id, this.newStyleGroup)
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.$message.success('Saved!')
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
        } else {
          AdminStyleGroups.adminStyleGroupsPost(this.newStyleGroup)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success('Saved!')
                this.dialogVisible = false;
                this.$router.push({ name: "Styles" });
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );             
            });
        }
  }

  async loadStyles(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminStyles.adminStylesGet(1, 99999999)
    .then((res) => {
      if (res.data.succeeded) {
        this.styles = res.data.resultData!.items as Array<StyleAdminListViewModel>
        if (refresh) {
          this.loading = false;
          this.$message.success("Section Refreshed.");
        }
      }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;;
      error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
      );             
    });
  }

  async loadInitialData() {
    this.loading = true

    await this.loadStyles()
    
    if (this.id) {
      await AdminStyleGroups.adminStyleGroupsIdGet(this.id).then((res) => {
        this.styleGroup = res.data.resultData as StyleGroupAdminListViewModel;
        this.name = this.styleGroup.name;
        this.newStyleGroup = (({ id, ...rest }) => rest)(this.styleGroup) as any;
        console.log('new style', this.newStyleGroup);
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;;
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );             
      });
    }
    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
