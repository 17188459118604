




























































































































































import {
  CategoryAdminCreateModel,
  CategoryAdminListViewModel,
  CategoryAdminUpdateModel,
  CategoryAdminViewModel,
  ProductCategoryMenuGroupAdminListViewModel
} from "@/api-client";
import { AdminCategories, AdminProductCategoryMenuGroups } from "@/network/api";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import { allCategories } from "@/utils/recursive";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from '@/utils/sortAscending'

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewCategory",
  components: { BasicSelectorField, BasicSwitchField, DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  categoryName: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showThumbnail: boolean = true;
  thumbnailImage: Array<any> = [];
  bannerImage: Array<any> = [];
  showBanner: boolean = true;
  dirtyTimer: any = null
  loading = false
  category: CategoryAdminViewModel = {
    id: '',
    name: '',
    slug: '',
    seoTitle: '',
    isHidden: false,
    seoKeywords: '',
    seoDescription: '',
    isFeatured: false,
    isDisabled: true,
    blurb: '',
    thumbnailImages: [],
    bannerImages: [],
    productCategoryMenuGroupId: ''
  };
  categories: Array<CategoryAdminListViewModel> = [];
  allCategoriesArray: any = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  metaKeywordsArray: Array<string> = [];
  tagInputVisible: boolean = false;
  tagInputValue: string = "";

  newCategory: CategoryAdminCreateModel | CategoryAdminUpdateModel = {
    name: "",
    slug: "",
    parentCategoryId: "",
    blurb: "",
    isHidden: false,
    isDisabled: true,
    isFeatured: false,
    thumbnailImages: [],
    bannerImages: [],
    seoTitle: "",
    seoKeywords: "",
    seoDescription: "",
    productCategoryMenuGroupId: ''
  };
  fromPath = ''

  key = 0

  menuPositions: Array<ProductCategoryMenuGroupAdminListViewModel> = []

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  

  created() {
    this.loadInitialData();

    this.$watch(() => this.$route.params,
      (toParams, previousParams) => {
        if(!toParams.id) {
          this.$router.go(0)
        }       
      }
    )

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("thumbnail", {
      validate(value) {
        let uploader = self.$refs.thumbnailUploader as any;
        
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("banner", {
      validate(value) {
        let uploader = self.$refs.bannerUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };

      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get sortedCategories() {
    let sorted = sortAscending(this.allCategoriesArray);
    //remove current category form array
    sorted = sorted.filter(c => c.id !== this.id);
    return sorted;
  }

  get sortedMetaTags() {
    return sortAscending(this.metaKeywordsArray, false)
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  uploadImages(): Promise<any> {
    let thumbnailUploader = this.$refs.thumbnailUploader as ImageUploader;
    let bannerUploader = this.$refs.bannerUploader as ImageUploader;
    let thumbPromise;
    let bannerPromise;
    if(thumbnailUploader) {
      thumbPromise = thumbnailUploader!.uploadImage()
    }
    if(bannerUploader) {
      bannerPromise = bannerUploader!.uploadImage();
    }
    return Promise.all([thumbPromise, bannerPromise])
  }


  handleClose(tag: any) {
    this.metaKeywordsArray.splice(this.metaKeywordsArray.indexOf(tag), 1);
  }

  showInput() {
    this.tagInputVisible = true;
    this.$nextTick(() => {
      let saveInput = this.$refs.saveTagInput as any;
      saveInput.$refs.input.focus();
    });
  }

  handleInputConfirm() {
    let inputValue = this.tagInputValue;
    if (inputValue && !this.metaKeywordsArray.includes(inputValue)) {
      this.metaKeywordsArray.push(inputValue);
    }
    this.tagInputVisible = false;
    this.tagInputValue = "";
  }

  getHeightAndWidthFromDataUrl = (dataURL:any) => new Promise(resolve => {
    const img = new Image()
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width
        })
      }
      img.src = dataURL
  })

  beforeImageUpload = async (file: any) => {
    const isType = (file.type === 'image/jpeg') || (file.type === 'image/png');
    const isLt2M = file.size / 1024 / 1024 < 2;
    
    if (!isType) {
      this.$message.error('Image must be JPG or PNG format.');
    }
    if (!isLt2M) {
      this.$message.error('Image size can not exceed 2MB.');
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // debugger
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;   

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;
      
    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   debugger
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   debugger
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // debugger
    // return result;
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string){
    if (area == "thumbnail") {
      this.newCategory.thumbnailImages = [];
    } else if (area == "banner") {
      this.newCategory.bannerImages = [];
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Categories') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Categories"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true
    this.uploadImages().then((results) => {
      if(results[0]) {
        this.newCategory!.thumbnailImages![0] = results[0];
      }

      if(results[1]) {
        this.newCategory!.bannerImages![0] = results[1];
      }

        if (!this.newCategory.slug) {
          this.newCategory.slug = this.newCategory
            .name!.trim()
            .split(" ")
            .join("-")
            .toLowerCase();
        }
        if (!this.newCategory.parentCategoryId) {
          this.newCategory.parentCategoryId = null;
        }
        if (this.metaKeywordsArray.length) {
          this.newCategory.seoKeywords = this.metaKeywordsArray.join(", ");
        }
        //Give menu position a valid value if 'None' selected but remove from menu display
        //If valid value selected, add to menu display
        if (this.category.id) {
          console.log('before save', this.newCategory);
          
          AdminCategories.adminCategoriesIdPut(this.category.id, this.newCategory)
            .then((res) => {
              if(res.data.succeeded) {
                console.log("success?", res);
              this.$message.success('Saved!')
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
        } else {
          AdminCategories.adminCategoriesPost(this.newCategory)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success('Saved!')
                this.dialogVisible = false;
                this.$router.push({ name: "Categories" });
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.dialogVisible = false;
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
            });
        }

    });
  }

  async loadInitialData() {
    this.loading = true
    await AdminCategories.adminCategoriesGet()
      .then((res) => {
        this.categories = res.data
          .resultData as Array<CategoryAdminListViewModel>;
        allCategories(this.categories, this.allCategoriesArray, false, false);
        console.log(this.allCategoriesArray);
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
      });

      await AdminProductCategoryMenuGroups.adminProductCategoryMenuGroupsGet(1, 99999)
      .then((res) => {
        this.menuPositions = res.data
          .resultData?.items as Array<ProductCategoryMenuGroupAdminListViewModel>;
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
      });

    if (this.id) {
      await AdminCategories.adminCategoriesIdGet(this.id).then((res) => {
        this.category = res.data.resultData as CategoryAdminViewModel;
        this.categoryName = this.category.name;
        this.newCategory = (({ id, ...rest }) => rest)(this.category) as any;
        if (this.newCategory.seoKeywords) {
          this.metaKeywordsArray = this.newCategory.seoKeywords.split(", ");
        }
        if (this.newCategory.thumbnailImages!.length) {
          this.thumbnailImage = [];
          this.thumbnailImage.push(this.newCategory.thumbnailImages![0]);
        }
        if (this.newCategory.bannerImages!.length) {
          this.bannerImage = [];
          this.bannerImage.push(this.newCategory.bannerImages![0]);
        }
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
      });
    }
    this.loading = false
  }
}
