



























































import {
  CountryViewModel,
  DeliveryType,
  OrderQuoteCreateModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import {
  AdminOrders,
  PublicCountries,
} from "@/network/api";
import { Message } from "element-ui";

const AppProps = Vue.extend({
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "NewQuote",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    BasicDateField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputNumberField,
    BasicInputField,
  },
})
export default class extends AppProps {
  pageNumber: string = this.$route.params.pageNumber;
  itemPerPage: string = this.$route.params.itemPerPage;
  search: string = this.$route.params.search;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  popImage: Array<any> = [];
  showBanner: boolean = true;
  quote: OrderQuoteCreateModel = {
    countryId: "",
    deliveryTypeId: DeliveryType.Standard,
    enquiryId: "",
    useEnquiryProducts: true,
    quoteExpiryDate: null
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newQuote: OrderQuoteCreateModel = {
    countryId: "",
    deliveryTypeId: DeliveryType.Standard,
    enquiryId: "",
    useEnquiryProducts: true,
    quoteExpiryDate: null
  };
  loading = false;
  dirtyTimer: any = null;
  countries: Array<CountryViewModel> = [];
  deliveryTypes: Array<{ name: string; id: DeliveryType }> = [
    { id: DeliveryType.Standard, name: "Standard" },
    { id: DeliveryType.Express, name: "Express" },
  ];
  $refs!: {
    newQuotObserver: InstanceType<typeof ValidationObserver>;
  };
  messageInstance: any = Message;


  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  // get symbol() {
  //   if(this.enquiryId && this.newQuote.id) {
  //     return `${this.newQuote.currency.code} ${this.newQuote.currency.symbol}`;
  //   }

  //   if(this.orderCurrency.id) {
  //     return `${this.orderCurrency.code} ${this.orderCurrency.symbol}`;
  //   }

  //   return ''
  // }
  
  created() {
    this.loadInitialData();

    this.$watch("$route", () => {
      if (this.$route.query.tab === "quotes") {
        this.$emit("showList");
      }
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
    this.$refs.newQuotObserver;
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    this.$emit("list");
    // let isDirty = document.querySelectorAll(".dirty");
    // this.confirmText = "Yes";

    // if (isDirty.length > 0) {
    //   this.dialogMessage =
    //     "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    //   this.dialogVisible = true;
    // } else {
    //   this.handleConfirm();
    // }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$emit("list");
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.messageInstance.closeAll();
    this.$refs.newQuotObserver.validateWithInfo().then(({ isValid, errors }) => {
      if(isValid) {
        this.loading = true;
        this.newQuote.enquiryId = this.enquiry.id

        if(!this.enquiry.customizedProducts.length) {
          this.newQuote.useEnquiryProducts = false
        }
        
        AdminOrders.adminOrdersCreateOrderQuotePost(this.newQuote)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.loading = false;
            this.dialogVisible = false;
            this.$emit("list");
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false;
          this.dialogVisible = false;
        });
      }
    })
  }

  async loadInitialData() {
    await PublicCountries.countriesGet()
      .then((res) => {
        if (res.data.succeeded) {
          this.countries = res.data.resultData as Array<CountryViewModel>;

          if (this.enquiry.countryId) {
            this.newQuote.countryId = this.enquiry.countryId;
          } else if (this.countries.length > 0) {
            this.newQuote.countryId = this.countries[0].id;
          }
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  beforeDestroy() {
    this.messageInstance.closeAll();
    clearTimeout(this.dirtyTimer);
  }
}
