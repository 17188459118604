var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container layers-container"},[_c('h1',{attrs:{"id":"title"}},[_vm._v("Layers")]),_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{ name: 'NewLayer' }}},[_c('button',{staticClass:"new-layer"},[_vm._v("New Layer")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.layers.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {_vm.itemPerPage = value; _vm.loadInitialData()},"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search name & code","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ElTableDraggable',{attrs:{"handle":'.handle'},on:{"reorder":_vm.handleReorder}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.layerItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"min-width":"180","prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"100","prop":"code","label":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.code))])]}}])}),_c('el-table-column',{attrs:{"width":"90"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},[_c('li',{class:{
                    'selected-filter': _vm.selectedStatusFilter === 'Active',
                  },on:{"click":function($event){return _vm.handleStatus('Active')}}},[_vm._v(" Active ")]),_c('li',{class:{
                    'selected-filter': _vm.selectedStatusFilter === 'Inactive',
                  },on:{"click":function($event){return _vm.handleStatus('Inactive')}}},[_vm._v(" Inactive ")])])]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-category': _vm.selectedStatusFilter },staticStyle:{"width":"60px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.isDisabled)}},[_vm._v(" "+_vm._s(row.isDisabled ? "Inactive" : "Active")+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Reorder","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('SendToPage',{attrs:{"id":row.id,"pageNumber":_vm.pageNumber,"totalPages":_vm.layers.totalPages},on:{"send":_vm.handleSendToPage}})]}}])}),_c('el-table-column',{attrs:{"width":"170","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear Filters ")])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditLayer',
              params: {
                id: row.id,
              },
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.layers.totalCount,"totalPages":_vm.layers.totalPages,"pageSize":_vm.layers.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }