










import { Component, Vue } from "vue-property-decorator";
import PaymentsList from '@/components/orders/payments/PaymentsList.vue'
import NewPayment from '@/components/orders/payments/NewPayment.vue'
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    balance: {
      type: Number,
      default: 0
    },
    orderCurrency: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    clearReasons: {
      type: Boolean,
      default: false
    }
  },
});

@Component({
  name: "PaymentsTab",
  components: {
    PaymentsList,
    NewPayment,
    BasicTooltipHeading
  }
})
export default class extends AppProps {
  currentScreen: 'list' | 'edit' = 'list'
  paymentId:string = ''

  get heading() {
    return (this.currentScreen === 'list') ? 'All Payments' : this.paymentId ? 'Edit Payment' : 'New Payment'
  }

  reload() {
    this.$emit('reload')
  }

  reloadBalance(balance:number) {
    this.$emit("reloadBalance", balance)
  }


  resetClear() {
    this.$emit('resetClear')
  }

  attemptPaymentEmail(response:any) {
    this.$emit('attemptPaymentEmail', response)
  }

  editPayment(id: string) {
    this.paymentId = id;
    this.currentScreen = 'edit'
  }

  toList() {
    this.paymentId = '';
    this.currentScreen = 'list'
    this.$emit("reloadBalance")
  }
}
