














































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import DialogBox from "@/components/DialogBox.vue";
import GeneralTab from "@/components/enquiries/general/GeneralTab.vue";
import {
  CountryViewModel,
  EnquiriesCreateEnquiryPostRequest,
} from "@/api-client";
import { AdminEnquiries, PublicCountries } from "@/network/api";
import { email } from "vee-validate/dist/rules";
import { Message } from "element-ui";
import { formatDate } from "@/utils/formatDate";
import FileUploader from "@/components/form-items/FileUploader.vue"
import MultipleFileUploader from "@/components/form-items/MultipleFileUploader.vue"

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "NewEnquiry",
  components: {
    ValidationProvider,
    ValidationObserver,
    DialogBox,
    GeneralTab,
    FileUploader,
    MultipleFileUploader
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  activeTab: any = "general";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false;
  enquiry: EnquiriesCreateEnquiryPostRequest  = {
    customerName: "",
    customerEmail: "",
    customerContactNumber: "",
    leadIn: null,
    businessName: null,
    ponumber: null,
    message: "",
    attachments: [],
    customizedProducts: [],
    countryId: '',
    targetDate: null,
    cartId: null,
    expectedQuantity: null,
    storeId: null,
    googleRecaptchaResponse: ""
  };
  enquiryClone: EnquiriesCreateEnquiryPostRequest = {
    customerName: "",
    customerEmail: "",
    customerContactNumber: "",
    leadIn: null,
    businessName: null,
    ponumber: null,
    message: "",
    attachments: [],
    customizedProducts: [],
    countryId: '',
    targetDate: null,
    cartId: null,
    expectedQuantity: null,
    storeId: null,
    googleRecaptchaResponse: ""
  };
  attachments: any[] = []
  clearDirty: any = null;
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  messageInstance: any = Message;
  fromPath = ''
  allCountries: Array<CountryViewModel> = [];
  contactNumber = {
    code: '',
    number: ''
  };

  created() {
    this.loadCountries().then(() => {
      this.clearDirty = setTimeout(() => {
        this.clearDirtyClasses();
      }, 500);
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    extend("email", {
      ...email,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
    this.$refs.observer;
  }

  handledate(date: any) {
    if (date) {
      return formatDate(date);
    }
    return "";
  }

  handleSubmit() {
    this.messageInstance.closeAll();
    let errorMessages = [] as Array<string>;
    this.$refs.observer.validateWithInfo().then(({ isValid, errors }) => {
      if (isValid) {
        this.submitForm();
      } else {
        for (let error in errors) {
          if (errors[error].length > 0) {
            errorMessages.push(error);
          }
        }
        let finalMessage = `The following fields are required:<br>`;
        errorMessages.forEach((message, index: any) => {
          if (index < 5) {
            finalMessage += `<br>- ${message}`;
          }
        });
        if (errorMessages.length > 5) {
          let remaining = errorMessages.length - 5;
          finalMessage += `<br><br>and ${remaining} others...`;
        }
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: finalMessage,
          dangerouslyUseHTMLString: true,
        });
      }
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    this.confirmText = "Yes";
    if (isDirty.length > 0) {
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(['OrderEnquirySearch', 'Enquiries','TaskOverview'].includes(this.fromPath)) {
        this.$router.back()
      } else {
        this.$router.push({ name: "Enquiries"});
      }
    } else {
      this.submitForm();
    }
  }

  async submitForm() {
    let expectedQuantity: number | null | undefined = null;
    this.loading = true;
    this.enquiryClone.customerContactNumber = `${this.contactNumber.code}-${this.contactNumber.number}`
    if(this.attachments.length) {
      this.enquiryClone.attachments = this.attachments.map((url:string) => {
        return {fileUrl: url}
      })
    }
    if(![0, '0', null, undefined].includes(this.enquiryClone.expectedQuantity)) {
      expectedQuantity = this.enquiryClone.expectedQuantity;
    }

    await AdminEnquiries.adminEnquiriesCreateEnquiryAdminPost(
      {...this.enquiryClone, expectedQuantity: expectedQuantity}
    ) 
    .then((res) => {
      if (res.data.succeeded) {
        console.log("success?", res);
        this.$message.success("Saved!");
        this.$router.push({ name: "EditEnquiry", params: { id: res.data.resultData } });
      }
    })
    .catch((error) => {
      this.loading = false;
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });
    });
    this.loading = false;
    this.dialogVisible = false;
  }

  async loadCountries() {
    this.loading = true;
    await PublicCountries.countriesGet().then((res) => {
      if (res.data.succeeded) {
        this.allCountries = res.data.resultData as Array<CountryViewModel>;
      }
      this.loading = false;
    })
    .catch((error) => {
      error.response.data.errors.map((e: any) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: e.friendlyMessage,
        });
        return e.friendlyMessage;
      });
      this.loading = false;
      this.dialogVisible = false;
    });
  }

  beforeUnmount() {
    this.messageInstance.closeAll();
    clearTimeout(this.clearDirty);
  }
}
