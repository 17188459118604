var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container size-groups-container"},[_c('h1',{attrs:{"id":"title"}},[_vm._v("Size Groups")]),_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{ name: 'NewSizeGroup' }}},[_c('button',{staticClass:"new-item",attrs:{"disabled":_vm.loading}},[_vm._v("New Size Group")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.sizeGroups.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {_vm.itemPerPage = value; _vm.loadInitialData()},"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search name","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ElTableDraggable',{attrs:{"handle":'.handle'},on:{"reorder":_vm.handleReorder}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.sizeGroupItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"prop":"displayName","label":"Display Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.displayName))])]}}])}),_c('el-table-column',{attrs:{"label":"Reorder","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('SendToPage',{attrs:{"id":row.id,"pageNumber":_vm.pageNumber,"totalPages":_vm.sizeGroups.totalPages},on:{"send":_vm.handleSendToPage}})]}}])}),_c('el-table-column',{attrs:{"width":"180px","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v("Clear Filters")])]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditSizeGroup',
              params: {
                id: row.id,
              },
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.sizeGroups.totalCount,"totalPages":_vm.sizeGroups.totalPages,"pageSize":_vm.sizeGroups.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }