var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"blog-posts-category-list-container"},[_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('router-link',{attrs:{"to":{name: 'NewBlogPostCategory'}}},[_c('button',{staticClass:"new-category",attrs:{"disabled":_vm.loading}},[_vm._v("New Category")])])],1),_c('el-col',{staticStyle:{"margin-top":"15px"},attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.category.totalCount,"label":'Items per page',"model":_vm.itemPerPage},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
          _vm.itemPerPage = value;
          _vm.loadInitialData();
        },"reset":function($event){_vm.pageNumber = 1}}})],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"search-bars"},[_c('el-input',{attrs:{"id":"search","size":"mini","placeholder":"Search name","clearable":"","disabled":_vm.loading},on:{"clear":_vm.handleSearch},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('ElTableDraggable',{attrs:{"handle":'.handle'},on:{"reorder":_vm.handleReorder}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.categoryItems,"row-key":"id","fit":"","stripe":"","border":"","resizable":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"prop":"colour","label":"Colour","width":100},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"hex-block",style:(("background-color:#" + (scope.row.hexValue) + ";margin:0 auto;"))})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"label":"Reorder","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('SendToPage',{attrs:{"id":row.id,"pageNumber":_vm.pageNumber,"totalPages":_vm.category.totalPages},on:{"send":_vm.handleSendToPage}})]}}])}),_c('el-table-column',{attrs:{"width":"180px","align":"right"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v("Clear Filters")])]}},{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'EditBlogPostCategory',
              params: {
                id: scope.row.id,
              }
            }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1),_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(scope.row.id)}}},[_vm._v("Delete")])]}}])})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.category.totalCount,"totalPages":_vm.category.totalPages,"pageSize":_vm.category.pageSize,"currentPage":_vm.pageNumber},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }