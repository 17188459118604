








import { Component, Vue } from "vue-property-decorator";
import sortable from "sortablejs";

@Component({
  name: "ElTableDraggable",
  props: {
    handle: {
      type: String,
      default: "",
    },
    animate: {
      type: Number,
      default: 100,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
})
export default class extends Vue {
  tableKey: number = 0;
  handle?: string;
  animate?: number;
  disable?: boolean;

  created() {
    this.$watch("tableKey", () => {
      this.$nextTick(() => {
        this.makeTableSortAble();
        this.keepWrapperHeight(false);
      });
    });
  }

  mounted() {
    this.makeTableSortAble();
  }

  // reorderArray(e:any) {
  //   const arr = this.$children[0].$props.data;
  //   debugger
  //   const previous = arr[e.newIndex]; 
  //   const movedItem = arr.splice(e.oldIndex, 1)[0];
  //   debugger
  //   if(previous) {
  //     const previousNewIndex = arr.findIndex((item:any) => item === previous);
  //     arr.splice(previousNewIndex + 1, 0, movedItem);
  //   } else {
  //     arr.splice(0, 0, movedItem);
  //   }

  //   return arr;
  // }  

  makeTableSortAble() {
    const table = this.$children[0].$el.querySelector(
      ".el-table__body-wrapper tbody"
    ) as HTMLElement;
    sortable.create(table, {
      handle: this.handle,
      animation: this.animate,
      disabled: this.disable,
      onStart: () => {
        this.$emit("drag");
      },
      onMove: (evt, originEvt) => {
        console.log(evt);
        console.log(originEvt);
      },
      onEnd: (e) => {
        this.keepWrapperHeight(true);
        this.tableKey = Math.random() as number;
        const arr = this.$children[0].$props.data;
        const targetRow = arr.splice(e.oldIndex, 1)[0];
        arr.splice(e.newIndex, 0, targetRow);
        this.$emit("drop", { targetObject: targetRow, list: arr });
        let previous = arr[e.newIndex! - 1];
        let next = arr[e.newIndex! + 1];

        let patchInfo = {
          target: targetRow.id,
          previous: previous && next ? previous.id : previous ? previous.id : undefined,
          next: previous && next ? undefined : next ? next.id : undefined
        }

        console.log(arr, targetRow);
        console.log(patchInfo.target, patchInfo.previous, patchInfo.next);
        //const reorderedArray = this.reorderArray(e);

        this.$emit('arrayReorder', arr)
        this.$emit('reorder', patchInfo)
      },
    });
  }

  keepWrapperHeight(keep: boolean): void {
    // eslint-disable-next-line prefer-destructuring
    const wrapper = this.$refs.wrapper as HTMLElement;
    if (keep) {
      wrapper.style.minHeight = `${wrapper.clientHeight}px`;
    } else {
      wrapper.style.minHeight = "auto";
    }
  }
}
