

































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminColourGroups, AdminColours } from "@/network/api";
import {
  ColourGroupAdminListViewModel,
  ColourGroupAdminListViewModelPaginatedList,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"


@Component({
  name: "Colours",
  components: { ElTableDraggable, Pagination, DialogBox,SendToPage, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  colours: ColourGroupAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  colourItems: Array<ColourGroupAdminListViewModel> = [];
  pageNumber = 1;
  itemPerPage = 10;
  search: string | undefined = "";
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this colour?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  selectedItem: ColourGroupAdminListViewModel = {
    id: "",
    name: "",
    code: "",
    isDisabled: false,
    colourIds: [],
    isSystemManaged: false,
  };
  debounce: any = null;
  selectedStatusFilter = "";
  selectedTypeFilter = "";
  showAllText: Array<string> = [];
  loading = false;

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      selectedTypeFilter: this.selectedTypeFilter,
      selectedStatusFilter: this.selectedStatusFilter
    }
    this.setQueryWatch(toQuery)
    this.loadInitialData();

    // this.$watch("search", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("search")!.focus();
    //     });
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  clearStatusFilter() {
    this.selectedStatusFilter = "";
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearTypeFilter() {
    this.selectedTypeFilter = "";
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearAllFilters() {
    this.search = "";
    this.selectedStatusFilter = "";
    this.clearTypeFilter();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  handleStatus(selected: string) {
    if(selected === this.selectedStatusFilter) {
      this.selectedStatusFilter = ''
    } else {
      this.selectedStatusFilter = selected
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleType(selected: string) {
    if(selected === this.selectedTypeFilter) {
      this.selectedTypeFilter = ''
    } else {
      this.selectedTypeFilter = selected
    }
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleSendToPage(id: string, selectedPage: number, determinePage: number) {
    this.loading = true;
    let patchInfo: any = {};
    let itemId = 0;
    let newPageItems = [] as Array<any>;
    const type = !this.selectedTypeFilter ? undefined : this.selectedTypeFilter === 'Colour' ? true : false
    const status = !this.selectedStatusFilter ? undefined : this.selectedStatusFilter === 'Inactive' ? true : false

    AdminColourGroups.adminColourGroupsGet(
      determinePage,
      this.itemPerPage,
      this.search || undefined,
      status,
      type
    )
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!.items as Array<any>;

          if (selectedPage < this.pageNumber) {
            itemId = selectedPage > 1 ? newPageItems.length - 1 : 0;
          }

          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false;

          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminColourGroups.adminColourGroupsIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true
    const type = !this.selectedTypeFilter ? undefined : this.selectedTypeFilter === 'Colour' ? true : false
    const status = !this.selectedStatusFilter ? undefined : this.selectedStatusFilter === 'Inactive' ? true : false
    await AdminColourGroups.adminColourGroupsGet(
      this.pageNumber,
      this.itemPerPage,
      this.search || undefined,
      status,
      type
    )
      .then((res) => {
        if(res.data.succeeded) {
          this.colours = res.data
          .resultData as ColourGroupAdminListViewModelPaginatedList;
        this.colourItems = this.colours
          .items as Array<ColourGroupAdminListViewModel>;
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    this.loading = true
    if (this.selectedItem.id && this.selectedItem.isSystemManaged) {
      AdminColours.adminColoursIdDelete(this.selectedItem.colourIds[0])
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1;
            this.$message.info('Item deleted.')
            this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          
        });
    } else if (this.selectedItem.id) {
      AdminColourGroups.adminColourGroupsIdDelete(this.selectedItem.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1;
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          
        });
    }
    this.loading = false
    this.dialogVisible = false;
    this.selectedItem = {
      id: "",
      name: "",
      code: "",
      isDisabled: false,
      colourIds: [],
      isSystemManaged: false,
    };
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
