
















































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminClients } from '@/network/api'
import {
  ClientAdminListViewModel, ClientAdminListViewModelPaginatedList,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import { sortAscending } from "@/utils/sortAscending";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: 'ClientLogos',
  components: { ElTableDraggable, Pagination, DialogBox, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends Vue {
  clientLogos: 	ClientAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  clientLogoItems: Array<ClientAdminListViewModel> = [];
  clientSearch: string | undefined = '';
  clientPageNumber = 1;
  clientItemPerPage = 5;
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this client logo?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedImageId = '';
  debounce: any = null;
  showAllText: Array<string> = [];
  loading = false;
  bgIsDark = true;

  created() {
    let toQuery = {
      clientSearch: this.clientSearch,
      clientPageNumber: this.clientPageNumber,
      clientItemPerPage: this.clientItemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData()

    // this.$watch('clientSearch', () => {
    //   console.log(this.clientSearch);
      
    //   this.clientPageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("clientSearch")!.focus();
    //     })
    //   }, 400)
    // })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch(id:string = 'search') {
    this.clientPageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById(id)!.focus();
    }) 
  }

  clearAllFilters() {
    this.clientPageNumber = 1
    this.clientSearch = '';
    this.loadInitialData()
  }

  get sortedClientLogos() {
    return sortAscending(this.clientLogoItems);
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handlePageNumberClick(page: any) {
    this.clientPageNumber = page
    this.loadInitialData()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  async loadInitialData() {
    this.loading = true
    await AdminClients.adminClientsGet(this.clientPageNumber, this.clientItemPerPage, this.clientSearch || undefined)
      .then((res) => {
        this.clientLogos = res.data
          .resultData as ClientAdminListViewModelPaginatedList
        this.clientLogoItems = this.clientLogos
          .items as Array<ClientAdminListViewModel>
        console.log('clientLogos items', this.clientLogoItems)
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
      })
  }

  confirmDelete(id: string) {
    this.dialogVisible = true
    this.selectedImageId = id
  }

  deleteItem() {
    if (this.selectedImageId) {
      this.loading = true
      AdminClients.adminClientsIdDelete(this.selectedImageId)
        .then((res) => {
          if(res.data.succeeded) {
            this.clientPageNumber = 1
            this.loading = false
            this.$message.info('Item deleted.')
            this.loadInitialData()
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
        })
    }
    this.dialogVisible = false
    this.selectedImageId = ''
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
