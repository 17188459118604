






















































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { ElUpload } from "element-ui/types/upload";

const AppProps = Vue.extend({
  props: {
    image: { type: [Object, String], default: null },
    event: {
      type: Function,
    },
    label: {
      type: String,
      default: "",
    },
    area: {
      type: String,
      default: "",
    },
    uploadCriteria: {
      type: Function,
      default: null,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    emptyMaskCheckbox: {
      type: Boolean,
      default: false,
    },
    disableMaskCheckbox: {
      type: Boolean,
      default: false,
    },
    allowVideoLink: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    }
  },
});

@Component({
  name: "ImageUploader",
  model: {
    prop: "image",
    event: "removed",
  },
})
export default class extends AppProps {
  showThumbnail: boolean = true;
  disabled: boolean = true;
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  validVideoUrl = ['player.vimeo.com']
  _selectedFile: string | null = null;
  videoUrl: string | null = null
  useVideoUrl = false
  resolveUpload: Function | null = null;
  errorUpload: Function | null = null;

  useEmptyMask = false;
  emptyMaskUrl = "7a44dbce0f78407ba837ea335cf706e2db8b5d12/empty_mask.png";

  originalImagePath: any = null;

  dialogImageUrl= ''
  dialogVisible= false

  canPreview = true

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get showTextArea() {
    return this.allowVideoLink && this.useVideoUrl && (!this.image || (this.image && this.imagePath?.includes(this.validVideoUrl)))
  }

  get emptyMaskPath() {
    return `${this.uploadPath}/${this.emptyMaskUrl}`;
  }

  get imagePath() {
    return (
      this.image &&
      `${this.uploadPath}/${
        this.image.imageUrl ? this.image.imageUrl : this.image
      }`
    );
  }

  get selectedFile() {
    return this._selectedFile;
  }

  // get fileExtension() {
  //   return this.getFileExtension(this.imagePath)
  // }

  // getFileExtension(fileName: string) {
  //   if(fileName) {
  //     if(fileName.includes('player.vimeo.com')) {
  //       return 'player.vimeo.com'
  //     }

  //     const arr = fileName.split('.');

  //     return arr[arr.length - 1];
  //   }
  // }

  setFallbackImage({target}) {
    if(target) {
      target.src = '/img/fallback-file-image.png'
      this.canPreview = false
    }
  }

  handlePreview(url:string) {
    this.dialogImageUrl = url;
    this.dialogVisible = true;
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(`${path}`)
    this.$message.success('Path copied.')
  }

  created() {
    if(this.allowVideoLink && (this.imagePath?.includes(this.validVideoUrl))) {
      this.videoUrl = this.image.imageUrl ? this.image.imageUrl : this.image;
      this.useVideoUrl = true;
    }

    this.$watch('image', () => {
      if (this.image) {
      this.useEmptyMask = this.image.imageUrl
        ? (this.image.imageUrl === this.emptyMaskUrl)
        : (this.image === this.emptyMaskUrl);     
      }
    })
  }

  handleEmptyMask() {
    //debugger
    if (this.image !== this.emptyMaskUrl) {
      this.originalImagePath = this.image.imageUrl
        ? this.image.imageUrl
        : this.image;
    }
    if (this.useEmptyMask) {
      this.$emit("useEmpty", this.emptyMaskUrl);    
      if(this._selectedFile) {
        this.handleRemove()
      }   
    } else {
      this.$emit("useEmpty", this.originalImagePath);            
    }
  }

  handleChange(file: any, fileList: Array<any>) {
    console.log("on change", file);

    this.showThumbnail = false;
    this._selectedFile = file;
    if (file) {
      this.showThumbnail = false;
    }
    this.$emit('validate')
  }

  handleVideoUrl() {
    this.$emit("success", this.videoUrl);
  }

  handleSuccess(res: any, _rawFile: any) {
    if (!this.autoUpload) {
      this.resolveUpload!({ imageUrl: res.relativePath });
    }
    this.showThumbnail = false;
    this.$emit("success", res.relativePath);
  }

  handleError(err: any, _rawFile: any) {
    this.errorUpload!(err);
    this.showThumbnail = false;
  }

  handleRemove() {
    console.log("remove");
    this._selectedFile = null;
    this.videoUrl = null;
    //this.$emit("removed", this.area);
    setTimeout(() => {
      this.showThumbnail = true;
    }, 400);
  }

  removeUploadedImage(data: any) {
    console.log("remove uploaded");
    this.useEmptyMask = false;
    this._selectedFile = null;
    this.videoUrl = null;
    this.$emit("removed", this.area);
    this.showThumbnail = true;
  }

  async uploadImage() {
    let uploader = this.$refs.upload as ElUpload;
    return new Promise<void>((resolve, reject) => {
      this.resolveUpload = resolve;
      this.errorUpload = reject;
      if (uploader && this._selectedFile) {
        uploader!.submit();
      } else {
        console.log("in upload image", this.image);
        if (this.image) {
          this.resolveUpload({ imageUrl: this.image.imageUrl });
        } else {
          this.resolveUpload({ imageUrl: null });
        }
      }
    });
  }
}
