


















































































































import { Component, Vue } from "vue-property-decorator";
import { AdminStyleSizeInconsistenciesApi, AdminStyles } from "@/network/api";
import {
  StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData,
  StyleSizeInconsistenciesAdminViewModel, StyleAdminListViewModel, StyleSizeAdminUpdateModel
} from "@/api-client";
import Pagination from "@/components/Pagination.vue";
import { sortAscending } from "@/utils/sortAscending";
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"


@Component({
  name: "SizeGroups",
  components: {
    Pagination,
    SendToPage,
    ItemsPerPage,
  }
})
export default class extends Vue {
  sizes: StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  sizeItems: Array<StyleSizeInconsistenciesAdminViewModel> = [];
  sizeMapping: Array<StyleSizeAdminUpdateModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  loading = false;
  debounce: any = null;
  sizeMappingId: string | undefined = undefined;
  sizeMappingSelection: Array<string> = [];
  styles: Array<StyleAdminListViewModel> = []
  selectedStyle: Array<string> = [];

  created() {
    let toQuery = {
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
    }
    this.setQueryWatch(toQuery)
    
    this.loadStyles()
    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleStyleFilter(selected: string = "") {
    const filterIndex = this.isSizeFiltered(selected)
    if (selected == "") {
      this.selectedStyle = [];
    }
    else if (filterIndex > -1) {
      this.selectedStyle.splice(filterIndex, 1);
    }
    else {
      this.selectedStyle.push(selected);
    }
    this.pageNumber = 1;
    this.loadInitialData();
  }

  isSizeFiltered(id: string) {
    return this.selectedStyle.findIndex((filter) => filter == id);
  }

  async loadInitialData() {
    this.loading = true;
    await AdminStyleSizeInconsistenciesApi.adminStyleSizeInconsistenciesGet(
      this.pageNumber,
      this.itemPerPage,
      this.selectedStyle
    )
      .then((res) => {
        if(res.data.succeeded) {
          this.sizes = res.data.resultData as StyleSizeInconsistenciesAdminViewModelPaginatedListApplicationResultResultData;
          this.sizeItems = this.sizes.items;
          this.innitMapping();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadStyles() {
    await AdminStyles.adminStylesGet(
      1,
      99999,
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.styles = res.data
            .resultData?.items as StyleAdminListViewModel[];
          this.styles.sort((a:StyleAdminListViewModel, b:StyleAdminListViewModel) => a.name.localeCompare(b.name));
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  mappingUpdateModel() {
    return this.sizeMapping.filter(item => item.newSizeId != "");
  }

  innitMapping() {
    this.sizeMapping = [];
    for (const index in this.sizeItems) {
      this.sizeMapping.push({
        styleId: this.sizeItems[index].id,
        oldSizeId: this.sizeItems[index].sizeInconsistency.id,
        newSizeId: ""
      });
    }
  }

  updateMappingObject(index, value) {
    this.sizeMapping[index].newSizeId = value;
  }

  async applyMapping() {
    this.loading = true;
    await AdminStyleSizeInconsistenciesApi.adminStyleSizeInconsistenciesPut(this.mappingUpdateModel())
      .then((res) => {
        this.loadInitialData();
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
