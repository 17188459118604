


















































































































































































































































































































































































































































































import {
  ColourGroupAdminListViewModel,
  ConstructionType,
  DesignAdminListViewModel,
  DesignGroupAdminListViewModel,
  FabricAdminListViewModel,
  FabricAdminViewModel,
  LayerAdminCreateModel,
  LayerAdminUpdateModel,
  LayerAdminViewModel,
  LayerFabricAdminCreateModel,
  LayerFabricAdminListViewModel,
  LayerFabricAdminListViewModelPaginatedList,
  LayerFabricAdminUpdateModel,
  ProductAdminListViewModel,
  StyleAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import Pagination from "@/components/Pagination.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import {
  AdminColourGroups,
  AdminDesignGroups,
  AdminDesigns,
  AdminFabrics,
  AdminLayerFabrics,
  AdminProducts,
  AdminStyleGroups,
  AdminStyles,
} from "@/network/api";
import { sortAscending } from "@/utils/sortAscending";

type FilterOption = "product" | "style" | "design";
type BulkFabricSearch = {
  fabricId: string,
  productId: string | null,
  styleId: string | null,
  designId: string | null,
};

const AppProps = Vue.extend({

});
@Component({
  name: "BulkFabricSearchLayers",
  components: {
    Pagination,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ItemsPerPage,
    BasicSelectorField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  fabricId = ""
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  frontImage: Array<any> = [];
  backImage: Array<any> = [];
  showBanner: boolean = true;
  bulkFabricSearch: BulkFabricSearch = {
    fabricId: "",
    productId: null,
    styleId: null,
    designId: null,
  }
  layer: LayerAdminViewModel = {
    id: "",
    name: "",
    code: "",
    isDisabled: true,
    frontMaskUrl: "",
    backMaskUrl: "",
    hasProductBuilderOptionsGenerationLogs: false,
    fabricChannelId: null,
    fabricChannelInternalName: null,
  };
  fabrics: Array<FabricAdminViewModel> = [];
  products: Array<ProductAdminListViewModel> = [];
  styles: Array<StyleAdminListViewModel> = [];
  designs: Array<DesignAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newLayer: LayerAdminCreateModel | LayerAdminUpdateModel = {
    name: "",
    code: "",
    isDisabled: true,
    frontMaskUrl: "",
    backMaskUrl: "",
    fabricChannelId: null,
  };
  layerFabricsPageNumber = 1;
  tempPageNumber = 1;
  layerFabricsItemPerPage = 10;
  disable = false;
  dirtyTimer: any = null;
  layerFabrics: LayerFabricAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  layerFabricItems: Array<LayerFabricAdminListViewModel> = [];
  newLayerFabric: LayerFabricAdminCreateModel | LayerFabricAdminUpdateModel = {
    layerId: "",
    fabricId: "",
    colourGroupIds: [],
    constructionTypeId: ConstructionType.CutAndSew,
    price: 0,
    customPatternPrice: 0,
    productsIds: [],
  };
  selectedId = "";
  bulkSelectedId: Array<any> = [];
  originalLayerId = "";
  originalColourArray: Array<any> = [];
  originalProductsArray: Array<any> = [];
  originalConstructionTypeId = "";
  originalPrice = 0;
  originalCustomPatternPrice = 0;
  fabricLayerOptions: Array<FabricAdminListViewModel> = [];
  layerColourOptions: Array<FabricAdminListViewModel> = [];
  colourGroups: Array<ColourGroupAdminListViewModel> = [];
  currentFabricId = "";
  selectedColours: Array<string> = [];
  selectedColoursList: Array<Array<string>> = [];
  toggleColourOptionValue = false;
  toggleLayerOptionValue = false;
  loading = false;
  constructionTypes = [
    {
      id: ConstructionType.CutAndSew,
      name: "Cut and Sew",
    },
    {
      id: ConstructionType.Sublimation,
      name: "Sublimation",
    },
  ];
  layerProducts: Array<ProductAdminListViewModel> = [];
  fromPath = "";

  get sortedFabrics() {
    return sortAscending(this.fabrics, true, 'code');
  }

  get sortedProducts() {
    return sortAscending(this.products, true, 'code');
  }

  get sortedStyles() {
    return sortAscending(this.styles, true, 'code');
  }

  get sortedDesigns() {
    return sortAscending(this.designs, true, 'code');
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("front", {
      validate(value) {
        let uploader = self.$refs.frontUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("back", {
      validate(value) {
        let uploader = self.$refs.backUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField(field: any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if (provider) {
      return provider!.validate();
    }
  }

  disableFilter(filter: FilterOption) {
    
    const options: FilterOption[] = ["product", "style", "design"];
    let disable = false; 

    if(!this.bulkFabricSearch.fabricId) {
      disable = true;
    } else if(!this.bulkFabricSearch[`${filter}Id`]) {
      options.forEach((option) => {
        if(filter !== option && !disable && this.bulkFabricSearch[`${option}Id`]) {
          disable = true;
        }
      })
    } 
    return disable;
  }

  handlePageNumberClick(page: any) {
    if (this.bulkSelectedId.length > 0) {
      this.dialogMessage = `
        <div><stong>By changing pages, your ${this.bulkSelectedId.length} selected fabric ${this.bulkSelectedId.length > 1 ? 'layers' : 'layer'} will be discarded.</strong></div>
        <div><strong>Do you wish to continue?</strong></div>
      `;
      this.confirmText = "Change Page";
      this.dialogVisible = true;
      this.tempPageNumber = page;
      return false;
    }
    this.dialogVisible = false;
    this.layerFabricsPageNumber = page;
    this.loadLayerFabrics();
  }

  selectFabricRow(selected: Array<any>) {
    this.bulkSelectedId = selected.map(row => row.id);
  }

  toggleLayerOption(visible: boolean) {
    this.toggleLayerOptionValue = visible;
  }

  toggleColourOption(visible: boolean) {
    this.toggleColourOptionValue = visible;
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.deleteRow();
    } else if (this.confirmText === "Continue") {
      this.deleteBulk();
    }  else if (this.confirmText === "Change Page") {
      this.bulkSelectedId = [];
      this.handlePageNumberClick(this.tempPageNumber);
    } else {
      this.submitForm();
    }
  }

  async fetchColours() {
    // this.selectedColours = [];
    // console.log("fetch row", row);

    // if (row.colourGroups) {
    //   row.colourGroups = [];
    // }

    // if (row.fabricId) {
    //   this.currentFabricId = row.fabricId;
    // } else {
    //   this.currentFabricId = row;
    // }

    if (this.fabricId) {
      this.loading = true;
      await AdminLayerFabrics.adminLayerFabricsGetColourGroupsForFabricGet(
        this.fabricId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get cols for layers", res);
            this.layerColourOptions = res.data
              .resultData as Array<FabricAdminListViewModel>;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
  }

  editField(row: any, index: any) {
    console.log(row);

    if (
      this.selectedId === row.id &&
      row.layerId !== "" &&
      row.colourGroups.length
    ) {
      this.disable = false;
      this.saveLayerFabric(row);
    } else {
      this.loadOptions(row.id);
      this.loadLayerProducts(row.layerId)
      this.originalLayerId = row.layerId;
      this.originalConstructionTypeId = row.constructionTypeId;
      this.originalPrice = row.price;
      this.originalCustomPatternPrice = row.customPatternPrice;
      this.selectedId = row.id;
      this.originalColourArray = [...row.colourGroups];
      this.originalProductsArray = [...row.productsIds];
      this.disable = true;
    }
  }

  addRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadOptions();

    if (this.layerFabricItems.length)
      this.layerFabricItems.map((item) => {
        if (item.fabricId === "" || !item.colourGroups.length) {
          emptyName += 1;
        }
      });

    if (emptyName === 0) {
      this.layerFabricItems.push({
        id: tempId,
        layerId: this.layer.id,
        layerName: "",
        layerCode: "",
        fabricId: "",
        fabricName: "",
        colourGroups: [],
        constructionTypeId: ConstructionType.CutAndSew,
        price: 0,
        customPatternPrice: 0,
        productsIds: [],
      });
      console.log("items", this.layerFabricItems);
      this.originalLayerId = "";
      this.originalConstructionTypeId = "";
      this.originalPrice = 0;
      this.originalCustomPatternPrice = 0;
      this.originalColourArray = [];
      this.originalProductsArray = [];
      this.selectedColours = [];
      this.selectedId = tempId;
      this.disable = true;
    }
  }

  saveLayerFabric(data: any) {
    if (data.layerId !== "" && data.colourGroups.length > 0) {
      this.loading = true;
      let regex = /^(temp).*/g;
      if (!data.id.match(regex)) {
        let colourIds: Array<string> = [];
        data.colourGroups.map((item: any) => {
          colourIds.push(item.id);
        });
        this.newLayerFabric = {
          fabricId: this.fabricId,
          layerId: data.layerId,
          colourGroupIds: [...colourIds],
          constructionTypeId: data.constructionTypeId,
          price: data.price,
          customPatternPrice: data.customPatternPrice,
          productsIds: [...data.productsIds],
        };

        console.log("before put", this.newLayerFabric);
        AdminLayerFabrics.adminLayerFabricsIdPut(data.id, this.newLayerFabric)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Put success", res);
              this.$message.success("Saved!");
              this.loadLayerFabrics();
              // this.loadOptions();
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.dialogVisible = false;
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
          });
      } else {
        let colourIds: Array<string> = [];
        data.colourGroups.map((item: any) => {
          colourIds.push(item.id);
        });
        this.newLayerFabric = {
          fabricId: this.fabricId,
          layerId: data.layerId,
          colourGroupIds: [...colourIds],
          constructionTypeId: data.constructionTypeId,
          price: data.price,
          customPatternPrice: data.customPatternPrice,
          productsIds: [...data.productsIds],
        };

        console.log("before post", this.newLayerFabric);
        AdminLayerFabrics.adminLayerFabricsPost(this.newLayerFabric)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("fabcol Post success", res);
              this.loading = false;
              this.$message.success("Saved!");
              this.loadLayerFabrics();
              // this.loadOptions();
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.$message({
                showClose: true,
                type: "error",
                duration: 0,
                message: e.friendlyMessage,
              });
              return e.friendlyMessage;
            });
            this.dialogVisible = false;
          });
      }
      this.loading = false;
      this.disable = false;
      this.selectedId = "";
    }
  }

  confirmDelete(data: any) {
    this.dialogMessage =
      "<span><stong>Are you sure you want to delete this fabric layer?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if (this.selectedId === data.id && !this.selectedId.match(regex)) {
      data.layerId = this.originalLayerId;
      data.constructionTypeId = this.originalConstructionTypeId;
      data.price = this.originalPrice;
      data.customPatternPrice = this.originalCustomPatternPrice;
      data.colourGroups = [...this.originalColourArray];
      data.productsIds = [...this.originalProductsArray];
      this.selectedId = "";
      this.disable = false;
      this.layerProducts = [];
    } else {
      console.log("in delete", data);

      this.selectedId = data.id;
      this.dialogVisible = true;
    }
  }

  confirmBulkDelete() {
    this.dialogMessage = `
      <div><stong>You are about to delete ${this.bulkSelectedId.length} fabric ${this.bulkSelectedId.length > 1 ? 'layers' : 'layer'}.</strong></div>
      <div><strong>Do you wish to continue?</strong></div>
    `;
    this.confirmText = "Continue";
    this.dialogVisible = true;
  }

  deleteBulk() {
    AdminLayerFabrics.adminLayerFabricsDeleteLayerFabricsDelete(this.bulkSelectedId)
      .then((res) => {
        if (res.data.succeeded) {
          this.$message.info("Items deleted.");
          this.bulkSelectedId = [];
          this.loadLayerFabrics(true);
        }
        this.dialogVisible = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  deleteRow() {
    let regex = /^(temp).*/g;
    let index = this.layerFabricItems.length - 1;
    if (this.selectedId.match(regex)) {
      this.layerFabricItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.layerFabricItems.splice(index, 1);
    } else {
      this.loading = true;
      AdminLayerFabrics.adminLayerFabricsIdDelete(this.selectedId)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.info("Item deleted.");

            this.loadLayerFabrics(true);
            this.loadOptions();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
    this.selectedId = "";
    this.originalLayerId = "";
    this.originalConstructionTypeId = "";
    this.originalPrice = 0;
    this.originalCustomPatternPrice = 0;
    this.originalColourArray = [];
    this.layerProducts = [];
    this.originalProductsArray = [];
    this.dialogVisible = false;
    this.loading = false;
    this.disable = false;
    this.currentFabricId = "";
  }

  async submitForm() {
    if(this.bulkFabricSearch.fabricId) {
      this.loading = true;
      this.selectedId = "";
      this.originalLayerId = "";
      this.originalConstructionTypeId = "";
      this.originalPrice = 0;
      this.originalCustomPatternPrice = 0;
      this.originalColourArray = [];
      this.originalProductsArray = []; 
      this.layerProducts = [];
      this.fabricLayerOptions = [];
      this.dialogVisible = false;
      this.disable = false;
      this.currentFabricId = "";
      await this.loadLayerFabrics(true)
      .then(async () => {
        // await this.loadOptions()
        await this.fetchColours();
      })
    }
  }

  async loadOptions(layerFabricId: string | undefined = undefined) {
    if (this.fabricId) {
      this.loading = true;
      await AdminLayerFabrics.adminLayerFabricsGetLayersForFabricGet(
        this.fabricId,
        this.newLayerFabric.productsIds,
        layerFabricId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get fabs for layers", layerFabricId, res);
            this.fabricLayerOptions = res.data
              .resultData as Array<FabricAdminListViewModel>;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
  }

  async loadLayerProducts(layerId:string) {
    if(layerId) {
      await AdminLayerFabrics.adminLayerFabricsGetProductsForLayerGet(layerId)
      .then((res) => {
        if (res.data.succeeded) {
          this.layerProducts = res.data
            .resultData as Array<ProductAdminListViewModel>;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
    }
  }

  async loadLayerFabrics(resetPg = false) {
    this.loading = true;
    if (this.bulkFabricSearch.fabricId) {
      if(resetPg) {
        this.layerFabricsPageNumber = 1;
      }
      
      await AdminLayerFabrics.adminLayerFabricsGetFabricsLayerByFabricIdAdminGet(this.layerFabricsPageNumber, this.layerFabricsItemPerPage,
        this.bulkFabricSearch.fabricId,
        this.bulkFabricSearch.productId || undefined,
        this.bulkFabricSearch.styleId || undefined,
        this.bulkFabricSearch.designId || undefined,
      )
      .then(async (res) => {
        if (res.data.succeeded) {
          this.layerFabrics = res.data
            .resultData as LayerFabricAdminListViewModelPaginatedList;
          this.layerFabricItems = this.layerFabrics.items;
          this.fabricId = this.bulkFabricSearch.fabricId;
          this.selectedColoursList = [];
          if (this.layerFabricItems.length) {
            this.layerFabricItems.map((item: any) => {
              this.selectedColoursList.push(item.colourGroups);
            });
            console.log("col list", this.selectedColoursList);
          }

          this.newLayerFabric = {
            fabricId: this.fabricId,
            colourGroupIds: [],
            layerId: "",
            constructionTypeId: ConstructionType.CutAndSew,
            price: 0,
            customPatternPrice: 0,
            productsIds: [],
          };
        
        } else {
          this.fabricId = "";
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.fabricId = "";
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
    }
  }

  async loadFabrics() {
    this.loading = true
    await AdminFabrics.adminFabricsGet(1, 9999999, undefined)
    .then((res) => {
      if(res.data.succeeded) {
        this.fabrics = res.data.resultData?.items as Array<FabricAdminViewModel>;          
      }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
      );
    })
    this.loading = false
  }

  async loadProducts() {
    this.loading = true
    await AdminProducts.adminProductsGet(1, 9999999, undefined)
    .then((res) => {
      if(res.data.succeeded) {
        this.products = res.data.resultData?.items as Array<ProductAdminListViewModel>;          
      }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
      );
    })
    this.loading = false
  }

  async loadStyles() {
    await AdminStyles.adminStylesGet(1, 999999).then((res) => {
      if (res.data.succeeded) {
        this.styles = res.data.resultData?.items as Array<StyleAdminListViewModel>;
      }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });    
    });
  }

  async loadDesigns() {
    await AdminDesigns.adminDesignsGet(1, 999999).then((res) => {
      if (res.data.succeeded) {
        this.designs = res.data.resultData?.items as Array<DesignAdminListViewModel>;
      }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });    
    });
  }

  async loadInitialData() {
    if (this.fabricId) {
      this.currentFabricId = "";
      // this.loadOptions();
      this.loading = true;

      await AdminColourGroups.adminColourGroupsGet(1, 9999999)
        .then((res) => {
          if (res.data.succeeded) {
            this.colourGroups = res.data.resultData!.items;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });

      // await this.loadLayerFabrics();
      // this.loading = true;

      this.loading = false;
    }
    await this.loadFabrics();
    await this.loadProducts();
    await this.loadStyles();
    await this.loadDesigns();

    this.loading = false;
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
