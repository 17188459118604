





















































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  OrderStatus,
  TaskAdminListViewModelPaginatedList,
  OrderStatusListViewModel,
  TaskAdminListViewModel,
  CliftonTaskPriority,
  CliftonTaskStatus,
  TaskType,
  UserSelectModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import DateRangeFilter from "@/components/form-items/DateRangeFilter.vue";
import { AdminOrders, AdminTasks, AdminUsers } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: "TaskOverview",
  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    DateRangeFilter,
    BasicSwitchField
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Cart: "brand",
        Received: "success",
        AwaitingPayment: "danger",
      };
      return statusMap[status];
    },
    paymentFilter: (payment: number) => {
      const paymentMap: { [key: string]: string } = {
        even: "success",
        over: "danger",
        under: "warning",
      };
      let paymentStatus = "";
      if (payment > 0) {
        paymentStatus = "over";
      } else if (payment < 0) {
        paymentStatus = "under";
      } else {
        paymentStatus = "even";
      }
      return paymentMap[paymentStatus];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  tasks: TaskAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  taskItems: Array<TaskAdminListViewModel> = [];
  //searchEmail: string | undefined = this.$route.params.searchEmail || "";
  search: string | undefined = this.$route.params.search || "";
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  // dialogVisible = false;
  // dialogMessage =
  //   "<span><stong>Are you sure you want to delete this order?</strong></span>";
  // confirmText = "Yes";
  // cancelVisible = true;
  selectedItem: TaskAdminListViewModel = {
    id: "",
    title: "",
    fromAspNetUserId: "",
    fromAspNetUserDisplayName: "",
    taskPriorityId: CliftonTaskPriority.Standard,
    taskStatusId: CliftonTaskStatus.Pending,
    orderId: "",
    dateTimeStamp: "",
    taskRecipients: [],
    type: TaskType.Order,
    referenceNumber: '',
    dueDate: ''
  };
  debounce: any = null;
  selectedTaskStatus: CliftonTaskStatus | string = CliftonTaskStatus.Pending;
  selectedTaskPriority: CliftonTaskPriority | string = "";
  selectedTaskType: TaskType | string = "";
  orderStatus?: undefined | OrderStatus;
  expectedDelivery = {
    startDate: undefined,
    endDate: undefined,
  };
  orderPlacement = {
    startDate: undefined,
    endDate: undefined,
  };
  hasType?: boolean | undefined;
  showAllText: Array<string> = [];
  loading = false;
  selection: Array<any> = [];
  taskStatuses: Array<{ name: string, id: CliftonTaskStatus }> = [
    { id: CliftonTaskStatus.Pending, name: 'Pending' },
    { id: CliftonTaskStatus.Resolved, name: 'Resolved' }
  ]
  taskPriorities: Array<{ name: string, id: CliftonTaskPriority }> = [
    { id: CliftonTaskPriority.Standard, name: 'Standard' },
    { id: CliftonTaskPriority.Urgent, name: 'Urgent' }
  ]
  taskTypes: Array<{ name: string, id: TaskType }> = [
    { id: TaskType.Order, name: 'Order' },
    { id: TaskType.Enquiry, name: 'Enquiry' }
  ]
  users:Array<UserSelectModel> = []
  selectedTaskRecipient:string = ''
  selectedTaskFrom:string = ''
  isAssignedToMe = true

  created() {
    if (this.$route.query.status) {
      this.selectedTaskStatus = this.$route.query.status as CliftonTaskStatus;
    }

    if (this.$route.query.priority) {
      this.selectedTaskPriority = this.$route.query.priority as CliftonTaskPriority;
    }

    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage,
      selectedTaskFrom: this.selectedTaskFrom,
      selectedTaskStatus: this.selectedTaskStatus,
      selectedTaskPriority: this.selectedTaskPriority,
      selectedTaskType: this.selectedTaskType,
      selectedTaskRecipient: this.selectedTaskRecipient
    }
    this.setQueryWatch(toQuery)

    this.$watch('isAssignedToMe', () => {
      this.loadInitialData()
    })

    this.loadInitialData();

    // this.$watch("searchEmail", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("searchEmail")!.focus();
    //     });
    //   }, 400);
    // });

    // this.$watch("search", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("search")!.focus();
    //     });
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  // handleTriggerReminder() {
  //   //TODO comlete this logic once api set up
  //   console.log("trigger reminder for", this.selection);
  // }

  handleRouteTo(row:TaskAdminListViewModel) {
    if(row.orderId) {
      return { name: 'EditOrder', params: { id: row.orderId},  query: { taskId: row.id }}
    } else if(row.enquiryId) {
      return { name: 'EditEnquiry', params: { id: row.enquiryId},  query: { taskId: row.id }}
    }
  }

  handleRecipients(recs:Array<any>) {
    let recipients = recs.map(recipient => {
      return recipient.aspNetUserDisplayName
    }).join(', ')
    return recipients
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  appendLeadingZeroes(n:any){
      if(n <= 9){
        return "0" + n;
      }
      return n
    }

  handleDate(date: any, hours = true) {
    let hour = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();

    return hours ? `${formatDate(date)} - ${this.appendLeadingZeroes(hour)}:${this.appendLeadingZeroes(minutes)}` : `${formatDate(date)}`;
  }

  handleStatusName(id: string, statusType: 'status' | 'priority' | 'type') {
      let match = statusType === 'status'
        ? this.taskStatuses.find((task) => id == task.id)
        : statusType === 'priority'
        ? this.taskPriorities.find((task) => id == task.id)
        : this.taskTypes.find((task) => id == task.id);
      if (match) {
        return match.name;
      }
    return id;
  }

  clearTaskStatusFilter() {
    this.selectedTaskStatus = "";
    this.pageNumber = 1
    //this.$router.replace({ name: "Orders" });
    this.loadInitialData();
  }

  clearTaskPriorityFilter() {
    this.selectedTaskPriority = "";
    this.pageNumber = 1
    //this.$router.replace({ name: "Orders" });
    this.loadInitialData();
  }

  clearTaskTypeFilter() {
    this.selectedTaskType = "";
    this.pageNumber = 1
    //this.$router.replace({ name: "Orders" });
    this.loadInitialData();
  }

  clearTaskRecFilter() {
    this.selectedTaskRecipient = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearTaskFromFilter() {
    this.selectedTaskFrom = '';
    this.pageNumber = 1
    this.loadInitialData();
  }

  clearAllFilters() {
    //this.searchEmail = "";
    // this.expectedDelivery = {
    //   startDate: undefined,
    //   endDate: undefined,
    // };
    // this.orderPlacement = {
    //   startDate: undefined,
    //   endDate: undefined,
    // };

    this.search = "";
    this.selectedTaskPriority = "";
    this.selectedTaskType = "";
    this.selectedTaskRecipient = '';
    this.selectedTaskFrom = '';
    this.clearTaskStatusFilter();
  }

  get areAllSelected() {
    let unselected = 0;
    this.taskItems.forEach((item) => {
      if (item.orderId && !this.selection.includes(item.id)) {
        unselected += 1;
      }
    });

    return unselected === 0 ? true : false;
  }

  handleSelectAll() {
    if (!this.areAllSelected) {
      this.taskItems.forEach((order) => {
        if (!this.selection.includes(order.id) && order.orderId) {
          this.selection.push(order.id);
        }
      });
    } else {
      this.taskItems.forEach((order) => {
        this.selection = this.selection.filter((item) => item !== order.id);
      });
    }

    console.log(this.selection);
  }

  handleSelect(id: any) {
    if (this.selection.includes(id)) {
      this.selection = this.selection.filter((item) => item !== id);
    } else {
      this.selection.push(id);
    }

    //TODO handle what happens here when call available from liam
    console.log(this.selection);
  }

  handleName(name: string) {
    if (name) {
      const parsedName = name.toLowerCase().split(" ").join("-");
      return parsedName;
    }
    return "";
  }

  handleStatus(selected: string) {
    this.selectedTaskStatus = this.selectedTaskStatus == selected ? "" : selected;
    this.pageNumber = 1
    this.loadInitialData();
  }

  handlePriority(selected: string) {
    this.selectedTaskPriority = this.selectedTaskPriority == selected ? "" : selected;
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleType(selected: string) {
    this.selectedTaskType = this.selectedTaskType == selected ? "" : selected;
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleTaskRec(selected: string) {
    this.selectedTaskRecipient = this.selectedTaskRecipient == selected ? "" : selected;
    this.pageNumber = 1
    this.loadInitialData();
  }

  handleTaskFrom(selected: string) {
    this.selectedTaskFrom = this.selectedTaskFrom == selected ? "" : selected;
    this.pageNumber = 1
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;
    await AdminTasks.adminTasksGet(
      this.pageNumber,
      this.itemPerPage,
      this.isAssignedToMe,
      undefined,
      undefined,
      this.selectedTaskFrom || undefined,
      this.selectedTaskStatus as CliftonTaskStatus || undefined,
      this.selectedTaskPriority as CliftonTaskPriority || undefined,
      this.selectedTaskType as TaskType || undefined,
      this.search || undefined,
      this.selectedTaskRecipient || undefined
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.tasks = res.data
            .resultData as TaskAdminListViewModelPaginatedList;
          this.taskItems = this.tasks.items as Array<TaskAdminListViewModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

      await AdminUsers.adminUsersGetOrderManagersGet(1, 99999)
      .then((res) => {
        if (res.data.succeeded) {
          this.users = res.data.resultData?.items as Array<UserSelectModel>;
        }
      })
      .catch((error) => {
        //this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

    this.loading = false;
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
