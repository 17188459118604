








































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminProductTags } from "@/network/api";
import {
  ProductTagAdminCreateModel,
  ProductTagAdminUpdateModel,
  ProductTagViewModel,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import DialogBox from "@/components/DialogBox.vue";
import { ValidationProvider, extend, configure } from "vee-validate";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
@Component({
  name: "Tags",
  components: { ElTableDraggable, DialogBox, ValidationProvider },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  tagName: string | undefined = "";
  parentProductTag: ProductTagViewModel = {
    id: "",
    isChild: false,
    name: "",
    childProductTags: [],
  };
  childProductTags: Array<ProductTagViewModel> = [];
  row: any;
  dialogVisible: boolean = false;
  dialogMessage: string =
    "<span><stong>Are you sure you want to delete this product tag?</strong></span>";
  confirmText: string = "Yes";
  cancelVisible: boolean = true;
  selectedRowId: string = "";
  originalTagName: string = "";
  search: string = this.$route.params.search;
  loading = false
  disable = false;
  dirtyTimer: any = null
  newParentProductTag: any = {
    isChild: false,
    name: "",
    childProductTags: [],
  };
  fromPath = ''

  newChildProductTag: any = {
    isChild: true,
    name: "",
    childProductTags: [],
  };

  created() {
    this.loadInitialData();
    console.log("tag id", this.id);

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        required: "validation-error",
      },
    });
  }

  handleReorder(data: any) {
    this.loading = true
    AdminProductTags.adminProductTagsIdPatch(
      data.target,
      data.previous,
      data.next
    )
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.loadInitialData();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }
  

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if(isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  handleConfirm() {
    if (this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>") {

      if(this.fromPath === 'Tags') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Tags"});
      }
    } else if(this.confirmText === 'Yes') {
      this.deleteTag();
    }
  }

  editField(data: any) {
    if ((this.selectedRowId === data.id) && data.name !== '') {
      this.disable = false;
      this.saveChildTag(data);
    } else {
      this.originalTagName = data.name;
      this.selectedRowId = data.id;
      this.disable = true
    }
  }

  addTagRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyTags = 0;

    if(this.childProductTags)
      this.childProductTags.map(tag => {
        if(tag.name === '') {
          emptyTags += 1;
        }
      })

      if(emptyTags === 0) {
        this.childProductTags.push({
        id: tempId,
        name: "",
        isChild: true,
        childProductTags: [],
      });
      this.originalTagName = '';
      this.selectedRowId = tempId;
      this.disable = true
    }
  }

  saveChildTag(data: any) {
    if (data.name !== '') {
      let regex = /^(temp).*/g;
      this.loading = true
      if (!data.id.match(regex)) {
        let newTag = {
          name: data.name,
          parentProductTagId: this.id,
        };

        AdminProductTags.adminProductTagsIdPut(data.id, newTag)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("childPut success", res);
              this.$message.success('Saved!')
              this.loadInitialData();
            }
            this.loading = false
          })
          .catch((error) => {
            this.dialogVisible = false;
            this.loading = false
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      } else {
        let newTag: ProductTagAdminCreateModel = {
          name: data.name,
          parentProductTagId: this.id,
        };
        AdminProductTags.adminProductTagsPost(newTag)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("childPost success", res);
              this.$message.success('Saved!')
              this.loadInitialData();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      }
      this.selectedRowId = "";
      this.disable = false;
    }
  }

  saveParentTag(name: string, parentId: string | null) {
    if (name.length > 0) {
      this.loading = true
      if (this.id) {
        let newTag: ProductTagAdminUpdateModel = {
          name: name,
          parentProductTagId: parentId,
        };
        AdminProductTags.adminProductTagsIdPut(this.id, newTag)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("parentPut success", res);
              this.$message.success('Saved!')
              this.parentProductTag = res.data.resultData as ProductTagViewModel;
              this.tagName = this.parentProductTag.name;
              this.newParentProductTag.name =  this.parentProductTag.name;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
                }
                this.loading = false
              })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      } else {
        let newTag: ProductTagAdminCreateModel = {
          name: name,
          parentProductTagId: parentId,
        };
        AdminProductTags.adminProductTagsPost(newTag)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("parentPost success", res);
              this.$message.success('Saved!')
              this.parentProductTag = res.data.resultData as ProductTagViewModel;
              this.id = this.parentProductTag.id
              this.$router.replace({name: 'EditTag', params: {id: this.id } })
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
          });
      }
    }
    this.disable = false;
  }

  handleName(name: string) {
    let parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true
      await AdminProductTags.adminProductTagsIdGet(this.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.parentProductTag = res.data.resultData as ProductTagViewModel;
          this.childProductTags = [];
          this.parentProductTag.childProductTags!.map(
            (child: ProductTagViewModel) => {
              this.childProductTags.push(child);
            }
          );
          this.tagName = this.parentProductTag.name;
          this.newParentProductTag = (({ id, ...rest }) => rest)(
            this.parentProductTag
          );
          console.log(this.childProductTags);
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        });
    }
  }

  confirmDelete(data: any) {
    let regex = /^(temp).*/g;
    if ((this.selectedRowId === data.id) && !this.selectedRowId.match(regex)) {
      data.name = this.originalTagName;
      this.selectedRowId = "";
      this.disable = false;
    } else {
      this.selectedRowId = data.id;
      this.dialogVisible = true;
    }
  }

  deleteTag() {
    let regex = /^(temp).*/g;
    let index = this.childProductTags.length -1;
    if (this.selectedRowId.match(regex)) {
      this.childProductTags.map((child, i) => {
        if (this.selectedRowId === child.id) {
          index = i;
        }
      });
      this.childProductTags.splice(index, 1);
    } else {
      this.loading = true
      AdminProductTags.adminProductTagsIdDelete(this.selectedRowId)
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        });
    }
    this.selectedRowId = "";
    this.originalTagName = "";
    this.dialogVisible = false;
    this.disable = false;
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
