










































































































import {
  AdminCampaignsClientsGetRequest,
  AdminCampaignsClientsIdDeleteRequest,
  CampaignAdminListViewModel,
  CampaignClientAdminViewModel,
  ClientAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminCampaigns, AdminClients } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewCampaignClientLogo",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicSelectorField,
    BasicSwitchField
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  campaignId: string = '';
  selectedCampaignId: string = '';
  copyExisting = false
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  showBanner: boolean = true;
  clientLogo: CampaignClientAdminViewModel = {
    id: "",
    name: "",
    imageUrl: "",
    campaignId: ""
  };
  existingClientLogos: Array<ClientAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  existingClientLogoId = "";
  newClientLogo: AdminCampaignsClientsGetRequest | AdminCampaignsClientsIdDeleteRequest = {
    campaignId: "",
    name: "",
    imageUrl: "",
  };
  loading = false;
  dirtyTimer: any = null
  fromPath = ''
  campaigns: CampaignAdminListViewModel[] = []

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.campaignId = this.selectedCampaignId = (this.$route.query.campaignId as string) || ""

    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("preview", {
      validate(value) {
        let uploader = self.$refs.previewUploader as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  uploadImages(): Promise<any> {
    if(!this.copyExisting) {
      let previewUploader = this.$refs.previewUploader as ImageUploader;

      let previewPromise;

      if (previewUploader) {
        previewPromise = previewUploader!.uploadImage();
      }
      return Promise.all([previewPromise]);
    } else {
      return Promise.resolve()
    }
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "preview") {
      this.newClientLogo.imageUrl = "";
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'EditCampaign') {
        this.$router.back()
      } else {
        this.$router.push({ name: "EditCampaign", params: {id: this.clientLogo.campaignId || this.campaignId }});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      if (!this.copyExisting && results[0].imageUrl) {
        this.newClientLogo.imageUrl = results[0].imageUrl;
      }
      if (this.clientLogo.id) {
        console.log("before save", this.newClientLogo);

        AdminCampaigns.adminCampaignsClientsIdPut(this.clientLogo.id, this.newClientLogo)
          .then((res) => {
            console.log("success?", res);
            this.$message.success("Saved!");
            this.loading = false;
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false;
            this.dialogVisible = false;
          });
      } else {
        if(this.copyExisting) {
          AdminCampaigns.adminCampaignsClientsCopyPost(this.existingClientLogoId, this.selectedCampaignId)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success("Saved!");
                this.dialogVisible = false;
                this.loading = false;
                this.copyExisting = false;
                this.existingClientLogoId = "";
                this.clientLogo = res.data.resultData as CampaignClientAdminViewModel;
                this.name = this.clientLogo.name as string;
                this.newClientLogo = (({ id, ...rest }) => rest)(
                  this.clientLogo
                ) as any;
                
                if(res.data.resultData) {
                  this.$router.push({ name: "EditCampaignClientLogo", params: { id: res.data.resultData?.id } });
                }
              }
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                return e.friendlyMessage;
              });
              this.loading = false;
              this.dialogVisible = false;
            });
        } else {
          debugger
          (this.newClientLogo as AdminCampaignsClientsGetRequest).campaignId = this.selectedCampaignId;
          AdminCampaigns.adminCampaignsClientsPost(this.newClientLogo as AdminCampaignsClientsGetRequest )
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.$message.success("Saved!");
                this.dialogVisible = false;
                this.loading = false;
                
                if(res.data.resultData) {
                  this.$router.push({ name: "EditCampaignClientLogo", params: { id: res.data.resultData?.id } });
                }
              }
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
                return e.friendlyMessage;
              });
              this.loading = false;
              this.dialogVisible = false;
            });
          }
      }
    });
  }

  async loadCampaigns() {
    this.loading = true
    await AdminCampaigns.adminCampaignsGet(
      1,
      99999,
    )
      .then((res) => {
        if (res.data.succeeded) {
          this.campaigns = res.data
            .resultData?.items as CampaignAdminListViewModel[];
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
      });
  }

  async loadExistingClientLogos() {
    this.loading = true
    await AdminClients.adminClientsGet(1, 99999)
      .then((res) => {
        this.existingClientLogos = res.data.resultData?.items as Array<ClientAdminListViewModel>
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
      );
      this.dialogVisible = false;
      })
  }

  async loadInitialData() {
    await this.loadCampaigns()
    await this.loadExistingClientLogos()

    if (this.id) {
      this.loading = true;
      await AdminCampaigns.adminCampaignsClientsIdGet(this.id)
        .then((res) => {
          if (res.data.succeeded) {
            this.clientLogo = res.data.resultData as CampaignClientAdminViewModel;
            this.name = this.clientLogo.name as string;
            this.newClientLogo = (({ id, ...rest }) => rest)(
              this.clientLogo
            ) as any;
            console.log("new logo", this.newClientLogo);
            this.campaignId = this.selectedCampaignId = this.clientLogo.campaignId

            // if (this.newClientLogo.imageUrl) {
            //   this.previewImage = [
            //     {
            //       imageUrl: this.newClientLogo.imageUrl,
            //     },
            //   ];
            // }

            this.loading = false;
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.loading = false;
          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
