














































import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import { sortAscending } from '@/utils/sortAscending'
import { editorToolbar } from '@/utils/editorToolbar'
import { miscContentItems, MiscContentItem } from "@/assets/misc-site-data/misc-site-data"

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminGeneralSettings } from "@/network/api";

@Component({
  name: "NewMiscContent",
  components: { DialogBox, ValidationProvider, ValidationObserver },
})
export default class extends AppProps {
  dirtyTimer: any = null
  dirtyMavonTimer:any = null
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  miscContentItem: MiscContentItem = {
    id: '',
    name: '',
    value: '',
    type: 'markup'
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newMiscContentItem: MiscContentItem = {
    id: '',
    name: '',
    value: '',
    type: 'markup'
  };
  mavenToolbar = editorToolbar;
  loading = false

  created() {
    this.loadInitialData().then((res) => {
      clearTimeout(this.dirtyMavonTimer)
      this.dirtyMavonTimer = setTimeout(() => {
        this.clearDirtyMavonClasses()
      }, 1000);  
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll('.dirty'); 
      
    if(mavonDirty.length) {
      for(let item of mavonDirty) {
        item.classList.remove('dirty')
      }
    }   
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$router.push({ name: "MiscSiteContent"});
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true          
    AdminGeneralSettings.adminGeneralSettingsKeyPut(this.newMiscContentItem.id, this.newMiscContentItem.value)
    .then((res) => {
      if(res.data.succeeded) {
        console.log("success?", res);
      this.dialogVisible = false;
      this.$message.success('Saved!')
      this.loadInitialData()
      .then(() => {
        this.clearDirtyClasses();
      })
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
      );
    });
  }

  async loadInitialData(): Promise<any> {
    this.loading = true
    await AdminGeneralSettings.adminGeneralSettingsKeyGet(this.$props.id)
    .then((res) => {
      if (res.data.succeeded) {
        const itemToUse = miscContentItems.find(item => this.$props.id === item.id)

        if(res.data.resultData && itemToUse) {
          this.newMiscContentItem = {
            id: itemToUse.id,
            name: itemToUse.name,
            value: res.data.resultData,
            type: itemToUse.type
          }
          this.name = itemToUse.name;
        } else {
          this.$message.error("Content not found.")
        }
      }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
      );             
    });

    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
    clearTimeout(this.dirtyMavonTimer)
  }
}
