var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"enquiries-search-container"},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('h1',{staticStyle:{"margin":"0","margin-top":"15px"},style:((_vm.enquiries.totalCount > 5) ? 'margin-bottom:0;' : 'margin-bottom:20px;')},[_vm._v("Enquiries")])])],1),_c('el-row',{attrs:{"type":"flex","justify":"space-between","align":"middle"}},[_c('el-col',{staticStyle:{"margin-bottom":"8px"},attrs:{"span":10}},[_c('el-checkbox',{attrs:{"true-label":"true","false-label":"false"},on:{"change":_vm.loadInitialData},model:{value:(_vm.calculateQuoteTotals),callback:function ($$v) {_vm.calculateQuoteTotals=$$v},expression:"calculateQuoteTotals"}},[_c('span',{staticStyle:{"line-height":"20px","font-weight":"400","font-size":"16px"}},[_vm._v("Include Quote Totals")])])],1),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"uniqueMinItem":5,"disabled":_vm.loading,"totalCount":_vm.enquiries.totalCount,"label":'Items per page',"model":_vm.enquiryItemPerPage},on:{"update:model":function($event){_vm.enquiryItemPerPage=$event},"handleChange":function (value) {
            _vm.enquiryItemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.enquiryPageNumber = 1}}})],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.enquiryItems,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"width":"120","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear Filters ")])]}},{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditEnquiry',
            params: {
              id: row.id,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("View")])],1)]}}])}),_c('DateRangeFilter',{attrs:{"width":130,"heading":'Date Received',"name":'dateTimeStamp',"isSelected":_vm.dateReceived.startDate !== undefined},on:{"updateDate":function (x) {(_vm.dateReceived = x), _vm.loadInitialData();}}}),_c('DateRangeFilter',{attrs:{"width":130,"heading":'Last Modified',"name":'lastModifiedDate',"isSelected":_vm.enquiryPlacement.startDate !== undefined},on:{"updateDate":function (x) {(_vm.enquiryPlacement = x), _vm.loadInitialData();}}}),_c('el-table-column',{attrs:{"width":"150","prop":"referenceNumber","label":"Enquiry Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.referenceNumber))])]}}])}),_c('el-table-column',{attrs:{"width":"120","prop":"cartReferenceNumber","label":"Cart Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.cartReferenceNumber))])]}}])}),_c('el-table-column',{attrs:{"min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderManagers),function(user){return _c('li',{key:user.id,class:{
                  'selected-filter': _vm.enquirySelectedOrderManager == user.id,
                },on:{"click":function($event){return _vm.handleOrderManager(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearOrderManagerFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.enquirySelectedOrderManager },staticStyle:{"width":"130px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Order Manager"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.orderManagerAspNetUserDisplayName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"130"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.orderManagers),function(user){return _c('li',{key:user.id,class:{
                  'selected-filter': _vm.enquirySelectedSalesManager == user.id,
                },on:{"click":function($event){return _vm.handleSalesManager(user.id)}}},[_vm._v(" "+_vm._s(user.displayName)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearSalesManagerFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.enquirySelectedSalesManager },staticStyle:{"width":"130px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Sales Manager"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.salesManagerAspNetUserDisplayName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"customerName","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.customerName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"businessName","label":"Company Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.businessName))])]}}])}),_c('el-table-column',{attrs:{"min-width":"160","prop":"customerEmail","label":"Customer Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',{staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(row.customerEmail))])]}}])}),_c('el-table-column',{attrs:{"min-width":"145"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.enquiryTypes),function(type){return _c('li',{key:type.id,class:{
                  'selected-filter': _vm.selectedEnquiryTypes.includes(type.id)
                },on:{"click":function($event){return _vm.handleEnquiryType(type.id)}}},[_vm._v(" "+_vm._s(type.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearTypeFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedEnquiryTypes.length },staticStyle:{"width":"100px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Enquiry Type"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.enquiryTypeId, 'type'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"145"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.enquiryStatuses),function(status){return _c('li',{key:status.id,class:{
                  'selected-filter': _vm.selectedEnquiryStatuses.includes(status.id)
                },on:{"click":function($event){return _vm.handleEnquiryStatus(status.id)}}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearStatusFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedEnquiryStatuses.length },staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Enquiry Status"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleStatusName(row.enquiryStatusId, 'status'))+" ")])]}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"enquiryCustomerTypeId","label":"Customer Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handleCustType(row.enquiryCustomerTypeId)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"110","prop":"qualityScore","label":"Quality Score"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.qualityScore))])]}}])}),_c('el-table-column',{attrs:{"min-width":"110","prop":"targetDate","label":"Target Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handledate(row.targetDate)))])]}}])}),_c('el-table-column',{attrs:{"min-width":"160","prop":"confirmedDeadline","label":"Confirmed Deadline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.handledate(row.confirmedDeadline)))])]}}])}),(_vm.calculateQuoteTotals === 'true')?_c('el-table-column',{attrs:{"min-width":"170","prop":"preparingQuotesTotal","label":"Preparing Quote Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(("GBP £" + (row.preparingQuotesTotal.toFixed(2) || 0))))])]}}],null,false,3439495807)}):_vm._e(),(_vm.calculateQuoteTotals === 'true')?_c('el-table-column',{attrs:{"min-width":"170","prop":"providedQuotesTotal","label":"Provided Quote Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(("GBP £" + (row.providedQuotesTotal.toFixed(2) || 0))))])]}}],null,false,325002860)}):_vm._e(),(_vm.calculateQuoteTotals === 'true')?_c('el-table-column',{attrs:{"min-width":"130","prop":"lostQuotesTotal","label":"Lost Quote Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(("GBP £" + (row.lostQuotesTotal.toFixed(2) || 0))))])]}}],null,false,3636662655)}):_vm._e(),_c('el-table-column',{attrs:{"min-width":"250","prop":"internalNotes","label":"Internal Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return (row.internalNotes)?[_c('span',{attrs:{"id":row.id}},[_vm._v(_vm._s(_vm.showAllText.includes(row.id) ? row.internalNotes : _vm.truncateString(row.internalNotes, 100)))]),((row.internalNotes.length > 100) && !_vm.showAllText.includes(row.id))?_c('span',{staticClass:"elipsis",on:{"click":function($event){return _vm.showAllText.push(row.id)}}},[_vm._v("...")]):_vm._e()]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"115","prop":"expectedQuantity","label":"Exp. Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.expectedQuantity))])]}}])}),(_vm.canViewCampaigns)?_c('el-table-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},_vm._l((_vm.campaigns),function(campaign){return _c('li',{key:campaign.id,class:{
                  'selected-filter': _vm.selectedCampaign == campaign.id
                },on:{"click":function($event){return _vm.handleCampaignFilter(campaign.id)}}},[_vm._v(" "+_vm._s(campaign.name)+" ")])}),0)]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearCampaignFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-status': _vm.selectedCampaign },staticStyle:{"cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Campaign"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [(row.campaignId)?_c('router-link',{staticClass:"tag-bg",attrs:{"to":{ name: 'EditCampaign', params: {id: row.campaignId}}}},[_vm._v(" "+_vm._s(row.campaignName)+" ")]):_vm._e()]}}],null,false,1171090860)}):_vm._e(),_c('el-table-column',{attrs:{"width":"170","prop":"storeId","label":"Online Store Enquiry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")((row.storeId ? true : false))}},[_vm._v(" "+_vm._s(row.storeId ? "Yes" : "No")+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"140","prop":"hasCustomizedProducts","label":"Includes Designs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.hasCustomizedProducts)}},[_vm._v(" "+_vm._s(row.hasCustomizedProducts ? "Yes" : "No")+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"110","prop":"hasAttachments","label":"Attachments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.hasAttachments)}},[_vm._v(" "+_vm._s(row.hasAttachments ? "Yes" : "No")+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"120","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('button',{staticClass:"clear-filter",on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear Filters ")])]}},{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('router-link',{staticClass:"anchor",staticStyle:{"margin":"0"},attrs:{"to":{
            name: 'EditEnquiry',
            params: {
              id: row.id,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("View")])],1)]}}])})],1),_c('Pagination',{attrs:{"totalItems":_vm.enquiries.totalCount,"totalPages":_vm.enquiries.totalPages,"pageSize":_vm.enquiries.pageSize,"currentPage":_vm.enquiryPageNumber,"disableToTop":true},on:{"change":_vm.handlePageNumberClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }