







































































import {
  OrderPaymentAdminCreateModel,
  OrderPaymentAdminUpdateModel,
  OrderPaymentAdminViewModel,
  PaymentProvider,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminOrderPayments } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    paymentId: {
      type: String,
      default: "",
    },
    orderCurrency: {
      type: Object,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "NewPayment",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    BasicDateField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputNumberField,
    BasicInputField
  },
})
export default class extends AppProps {
  pageNumber: string = this.$route.params.pageNumber;
  itemPerPage: string = this.$route.params.itemPerPage;
  search: string = this.$route.params.search;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  popImage: Array<any> = [];
  showBanner: boolean = true;
  payment: OrderPaymentAdminViewModel = {
    id: "",
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    totalAmount: 0,
    convertedTotalAmount: 0,
    paymentProviderId: PaymentProvider.Eft,
    paymentProviderPaymentId: "",
    paymentProviderResponse: "",
    proofOfPaymentUrl: "",
    isSuccessful: null,
    lastModifiedDate: '',
    paymentDate: ''
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  newPayment: OrderPaymentAdminViewModel = {
    id: "",
    currency: {
      id: "",
      name: "",
      code: "",
      symbol: "",
      pricingMultiplier: 0,
      decimalPlaces: 0,
    },
    totalAmount: 0,
    convertedTotalAmount: 0,
    paymentProviderId: PaymentProvider.Eft,
    paymentProviderPaymentId: "",
    paymentProviderResponse: "",
    proofOfPaymentUrl: "",
    isSuccessful: null,
    lastModifiedDate: '',
    paymentDate: ''
  };
  loading = false;
  dirtyTimer: any = null;

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get symbol() {
    if(this.paymentId && this.newPayment.id) {
      return `${this.newPayment.currency.code} ${this.newPayment.currency.symbol}`;
    }

    if(this.orderCurrency.id) {
      return `${this.orderCurrency.code} ${this.orderCurrency.symbol}`;
    }

    return ''
  }

  created() {
    this.loadInitialData();

    this.$watch("$route", () => {
      if (this.$route.query.tab === "costs") {
        this.$emit("showList");
      }
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }


  uploadImages(): Promise<any> {
    let popUploader = this.$refs.popUploader as ImageUploader;

    let popPromise;

    if (popUploader) {
      popPromise = popUploader!.uploadImage();
    }
    return Promise.all([popPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // debugger
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;

    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   debugger
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   debugger
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // debugger
    // return result;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    this.$emit('list');
    // let isDirty = document.querySelectorAll(".dirty");
    // this.confirmText = "Yes";

    // if (isDirty.length > 0) {
    //   this.dialogMessage =
    //     "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
    //   this.dialogVisible = true;
    // } else {
    //   this.handleConfirm();
    // }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "pop") {
      this.newPayment.proofOfPaymentUrl = "";
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$emit('list');
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true;
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newPayment.proofOfPaymentUrl = results[0].imageUrl;
      }

      let paymentToSave = {
        orderId: this.order.id,
        currencyId: this.newPayment.currency.id || this.orderCurrency.id,
        convertedTotalAmount: this.newPayment.convertedTotalAmount,
        proofOfPaymentUrl: this.newPayment.proofOfPaymentUrl,
        paymentDate: this.newPayment.paymentDate,
        internalReason: this.newPayment.internalReason,
        externalReason: this.newPayment.externalReason
      } as OrderPaymentAdminCreateModel | OrderPaymentAdminUpdateModel;
      if (this.paymentId) {
        console.log("before save", paymentToSave);

        AdminOrderPayments.adminOrderPaymentsIdPut(this.paymentId, paymentToSave)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("success?", res);
              this.$message.success("Saved!");
              this.loading = false;
              this.dialogVisible = false;
              this.$emit('list');
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.loading = false;
            this.dialogVisible = false;
          });
      } else {
        AdminOrderPayments.adminOrderPaymentsPost(paymentToSave as OrderPaymentAdminCreateModel)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.loading = false;
              this.dialogVisible = false;
              this.$emit('list');
            }
            this.loading = false;
          })
          .catch((error) => {
            error.response.data.errors.map((e: any) => {
              this.loading = false;
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            this.dialogVisible = false;
          });
      }
    });
  }

  async loadInitialData() {
    if (this.paymentId) {
      this.loading = true;
      await AdminOrderPayments.adminOrderPaymentsIdGet(this.paymentId)
        .then((res) => {
          if (res.data.succeeded) {
            this.payment = res.data.resultData as OrderPaymentAdminViewModel;
            this.newPayment = (({ id, ...rest }) => rest)(this.payment) as any;

            if (this.newPayment.proofOfPaymentUrl) {
              this.popImage = [
                {
                  imageUrl: this.newPayment.proofOfPaymentUrl,
                },
              ];
            }
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });

          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
