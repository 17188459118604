var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order)?_c('div',{staticClass:"invoice-list-container"},[_c('DialogBox',{attrs:{"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage,"cancelVisible":_vm.cancelVisible},on:{"confirm":_vm.deleteOrVoidItem,"close":function($event){_vm.dialogVisible = false}}}),_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"span":10}},[_c('button',{staticClass:"new-invoice",attrs:{"disabled":_vm.loading || _vm.readOnly},on:{"click":_vm.editInvoice}},[_vm._v(" New Invoice ")])]),_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.loading,"totalCount":_vm.orderInvoicesPages.totalCount,"label":'Items per page',"model":_vm.itemPerPage,"uniqueMinItem":5},on:{"update:model":function($event){_vm.itemPerPage=$event},"handleChange":function (value) {
            _vm.itemPerPage = value;
            _vm.loadInitialData();
          },"reset":function($event){_vm.pageNumber = 1}}})],1)],1),_c('ElTableDraggable',{attrs:{"disable":true}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.orderInvoicesPages.items,"row-key":"id","fit":"","stripe":"","border":"","resizable":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"width":"120px","prop":"orderReferenceNumber","label":"Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(row.orderReferenceNumber)+"-"+_vm._s(row.invoiceNumber))])]}}],null,false,2079982911)}),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","trigger":"click","visible-arrow":false,"popper-class":"status-popper"}},[_c('div',[_c('ul',{staticClass:"filter-status"},[_c('li',{class:{
                    'selected-filter': _vm.selectedInvoiceType === 'Invoice',
                  },on:{"click":function($event){return _vm.handleInvoiceTypeFilter('Invoice')}}},[_vm._v(" Invoice ")]),_c('li',{class:{
                    'selected-filter': _vm.selectedInvoiceType === 'Proforma',
                  },on:{"click":function($event){return _vm.handleInvoiceTypeFilter('Proforma')}}},[_vm._v(" Proforma ")])])]),_c('div',{staticStyle:{"text-align":"center"},on:{"click":_vm.clearInvoiceTypeFilter}},[_c('button',{staticClass:"clear-filter"},[_vm._v("Clear")])]),_c('p',{class:{ 'filtered-category': _vm.selectedInvoiceType },staticStyle:{"width":"110px","cursor":"pointer"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" Invoice Type"),_c('i',{staticClass:"el-icon-arrow-down",staticStyle:{"cursor":"pointer","font-size":"12px","transform":"scale(0.75)"}})])])]}},{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('el-tag',{attrs:{"type":_vm._f("statusFilter")(row.orderInvoiceTypeId)}},[_vm._v(" "+_vm._s(row.orderInvoiceTypeId)+" ")]),(row.voidedDate)?_c('el-tag',{staticStyle:{"margin-left":"5px"},attrs:{"type":"danger"}},[_vm._v(" Void ")]):_vm._e()]}}],null,false,3083590147)}),_c('el-table-column',{attrs:{"prop":"startDateTime","label":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleDate(row.startDateTime)))])]}}],null,false,2061986097)}),_c('el-table-column',{attrs:{"prop":"endDateTime","label":"End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.handleDate(row.endDateTime)))])]}}],null,false,3622371326)}),_c('el-table-column',{attrs:{"prop":"voidedDate","label":"Voided Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return (row.voidedDate)?[_c('span',[_vm._v(_vm._s(_vm.handleDate(row.voidedDate)))])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"width":_vm.roles.includes('Admin') ? '250px' : '180px',"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
              name: 'ViewInvoice',
              params: {
                slug: _vm.handleName(row.id),
                id: row.id
              },
            }}},[_c('el-button',{attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){(row)}}},[_vm._v("View")])],1),_c('el-button',{attrs:{"plain":"","type":"warning","size":"mini"},on:{"click":function($event){return _vm.confirmDelete(row, 'void')}}},[_vm._v("Void")]),(_vm.roles.includes('Admin'))?_c('el-button',{attrs:{"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v("Delete")]):_vm._e()]}}],null,false,1926668335)})],1)],1),_c('Pagination',{attrs:{"totalItems":_vm.orderInvoicesPages.totalCount,"totalPages":_vm.orderInvoicesPages.totalPages,"pageSize":_vm.orderInvoicesPages.pageSize,"currentPage":_vm.pageNumber,"disableToTop":true},on:{"change":_vm.handlePageNumberClick}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }