

































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import {
  ValidationProvider,
} from "vee-validate";
import { CourierAdminListViewModel, DeliveryType, VoucherAdminViewModel, CountryGroupedOrderAddressListViewModel, CountryGroupedOrderAddressListViewModelPaginatedList } from "@/api-client";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import { AdminCouriers, AdminOrders, AdminVouchers, AdminCountryGroupedOrderAddresses } from "@/network/api";
import { formatDate } from "@/utils/formatDate"
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
  }
});

@Component({
  name: "GeneralTab",
  components: {  ValidationProvider, BasicInputField, BasicSwitchField, BasicDateField, BasicSelectorField, BasicInputNumberField, BasicTooltipHeading },
})
export default class extends AppProps {
  deliveryType = false;
  noFail = true
  courierItems: Array<CourierAdminListViewModel> = [];
  dummyValue = ''
  emptyAdditionalNotes = ''
  availableVouchers: Array<VoucherAdminViewModel> = []
  voucherId = null;
  groupedAddress: Array<CountryGroupedOrderAddressListViewModel> = [];

  get roles() {
      return store.getters["user_new/role"] as Array<string>;
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get courierRequired() {
    if(this.order) {
      return this.order.orderStatusId.toLowerCase() === 'dispatched';
    }
    return false
  }

  get isMarketingOptOut() {
    return !this.order.marketingOptIn;
  }

  created() {
    this.loadInitialData()

    this.$watch('order', () => {
      if(this.order.id) {
        if(this.order.deliveryTypeId == DeliveryType.Express) {
          this.deliveryType = true;
        } else {
          this.deliveryType = false;
        }

        if(this.roles && this.roles.includes('Admin')) {
          this.loadVouchers();
        }
      }

      if(this.order.voucher) {
        this.voucherId = this.order.voucher.id
      }
    })

    this.$watch('deliveryType', () => {
      if(this.order) {
        if(this.deliveryType) {
          this.order.deliveryTypeId = DeliveryType.Express
        } else {
          this.order.deliveryTypeId = DeliveryType.Standard
        }
      }
    })

    this.$watch('voucherId', () => {
      this.$emit('updateVoucher', this.voucherId)
    })
  }

  handleDate(date:any) {
    return formatDate(date);
  }

  handleTabChange(to:string) {
    this.$emit('query', to)
  }

  getGroupAddress(countryCode: string) {
    const address = this.groupedAddress.find(group => {
      return group.countryCode == countryCode;
    });

    if (address) {
      return [
        address.addressLine1,
        address.addressLine2,
        address.area,
        address.country,
        address.postalCode
      ].filter(n => n).join(", ");
    }
    else {
      return "not found";
    }
  }

  handlePaymentProvider(value: boolean, paymentProvider: "Stripe" | "EFT") {
    if(value && !this.order.allowedPaymentProviders.includes(paymentProvider)) {
      this.order.allowedPaymentProviders.push(paymentProvider)
    } else if(!value && this.order.allowedPaymentProviders.includes(paymentProvider)) {
      const index = this.order.allowedPaymentProviders.indexOf(paymentProvider);
      this.order.allowedPaymentProviders.splice(index, 1)
    }
  }

  copySharedCartLink() {
    let link = `${process.env.VUE_APP_ROOT_WEB}/cart/${this.order.id}/${this.order.accessKey}`;
    navigator.clipboard.writeText(link);
    this.$message.success("Public cart link copied.");
  }

  copyCourierLink() {
    let link = ''
    if(this.order.courierLink) {
      link = this.order.courierLink
    } else {
      link = `${this.order.courier.trackingUrlFormat.replace('{CourierReference}', this.order.courierReference)}`
    }
    navigator.clipboard.writeText(link);
    this.$message.success("Tracking link copied.");
  }

  sendQuoteReminder() {
    let isDirty = document.querySelectorAll(".dirty");

    if(isDirty.length > 0) {
      this.$message({showClose: true, type: 'error', duration: 0, message: 'Please save your changes before proceeding with this action.'})
    } else {
    AdminOrders.adminOrdersSendQuoteReminderOrderIdPost(this.order.id)
      .then((res) => {
        if(res.data.succeeded) {
          this.$message({showClose: true, type: 'success', message: 'Checkout reminder sent!'})
          this.$emit('reload')
        }
      })
      .catch((error) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: 'Error sending checkout reminder.'})
          error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage);
          return e.friendlyMessage;
        });
      });
    }
  }

  async loadInitialData() {
    await AdminCouriers.adminCouriersGet(
      1,
      99999999,
    )
      .then((res) => {
        if(res.data.succeeded) {
          this.courierItems = res.data.resultData!.items as Array<CourierAdminListViewModel>;
          console.log("courier items", this.courierItems);
        }

      })
      .catch((error) => {
          error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage);
          return e.friendlyMessage;
        });
      });

    // could use adminCountryGroupedOrderAddressesCountryCodeGet as a future enhancement
    // i.e. getting a specific grouped address by country code (related to country associated to the order, if available)
    await AdminCountryGroupedOrderAddresses.adminCountryGroupedOrderAddressesGet(1, 10)
      .then((res) => {
          if (res.data.succeeded) {
            const results = res.data.resultData as CountryGroupedOrderAddressListViewModelPaginatedList;
            this.groupedAddress = results.items as Array<CountryGroupedOrderAddressListViewModel>;
          }
      })
      .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage});
            return e.friendlyMessage;
          })
      });
  }

  loadVouchers() {
    AdminVouchers.adminVouchersGet(1, 99999, true, undefined, undefined, this.order.voucher?.id || undefined)
      .then((res) => {
        if(res.data.succeeded) {
        this.availableVouchers = res.data.resultData?.items as Array<VoucherAdminViewModel>
        console.log('vouchers available', this.availableVouchers);

        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
      })
  }
}
