










































































































































































































import {
  SizeAdminCreateModel,
  SizeAdminUpdateModel,
  SizeAdminViewModel,
  SizeGroupAdminViewModel,
  SizeGroupAttributeAdminListViewModel,
  SizeGroupAttributeSizeValueAdminCreateModel,
  SizeGroupAttributeSizeValueAdminListViewModelPaginatedList,
  SizeGroupAttributeSizeValueAdminUpdateModel,
  SizeGroupAttributeSizeValueAdminViewModel
} from "@/api-client"
import { Component, Vue } from "vue-property-decorator"
import DialogBox from "@/components/DialogBox.vue"
import ImageUploader from "@/components/form-items/ImageUploader.vue"
import FileUploader from "@/components/form-items/FileUploader.vue"
import BasicInputField from "@/components/form-items/BasicInputField.vue"
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue"
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"
import Pagination from '@/components/Pagination.vue'
import { sortAscending } from '@/utils/sortAscending'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import SizeGroupAttributeOverride from "@/views/sizing/size-groups/SizeGroupAttributeOverride.vue"
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure
} from "vee-validate";
import { AdminSizeGroupAttributeSizeValues, AdminSizeGroups, AdminSizes } from "@/network/api";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    id: { default: '' }
  }
})

@Component({
  name: "NewSize",
  components: {
    DialogBox,
    BasicSelectorField,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    FileUploader,
    Pagination,
    ItemsPerPage,
    BasicTooltipHeading,
    SizeGroupAttributeOverride
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null
  name: string | undefined = "";
  dialogVisible = false;
  overrideDialogVisible = false;
  dialogMessage = "";
  disabled = false;
  showBanner = true;
  size: SizeAdminViewModel = {
    id: "",
    name: "",
    sizeGroupId: ""
  };

  newSize: SizeAdminCreateModel | SizeAdminUpdateModel = {
    name: '',
    sizeGroupId: ""
  };

  fileList: Array<any> = [];
  confirmText = "Ok";
  cancelVisible = true;
  cancelVisibleOverride = true;
  attributeValues: SizeGroupAttributeSizeValueAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };

  attributePageNumber = 1;
  attributeItemPerPage = 5;
  attributeValueItems: Array<SizeGroupAttributeSizeValueAdminViewModel> = [];
  newAttributeValue: SizeGroupAttributeSizeValueAdminCreateModel | SizeGroupAttributeSizeValueAdminUpdateModel = {
    value: "",
    sizeGroupAttributeId: '',
    sizeId: this.id,
    overrideValues: []
  };

  selectedId = '';
  sizeGroupAttributeValueId: string | null = null;
  sizeGroupAttributeName: string | null = null;
  originalName = '';
  originalId = '';
  sizeGroups: Array<SizeGroupAdminViewModel> = []
  toggleOptionValue = false;
  sizeGroupAttributeItems: Array<SizeGroupAttributeAdminListViewModel> = []
  loading = false
  disable = false
  // attributeSearch: string | undefined = "";
  debounce: any = null;
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get sortedSizeGroups() {
    return sortAscending(this.sizeGroups);
  }

  toggleOption(visible: boolean) {
    console.log('option', visible);
    
    this.toggleOptionValue = visible;
  }

  created() {
    const toQuery = {
      attributePageNumber: this.attributePageNumber,
      attributeItemPerPage: this.attributeItemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch("attributeSearch", () => {
    //   this.attributePageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400);
    // });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required."
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error"
      }
    });
  }

  setQueryWatch(fields: any) {
    const queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
        handleQueries(field, val)
      })
    }
  }

  handlePageNumberClick(page: any) {
    this.attributePageNumber = page
    this.loadSizeGroupAttributes();
  }

  beforeBack() {
    const isDirty = document.querySelectorAll(".dirty");
    
    this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if (isDirty.length > 0) {
      this.confirmText = "Yes";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>") {
      if (this.fromPath === 'Sizes') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Sizes" });
      }
    } else if (this.confirmText === 'Yes') {
      this.deleteRow();
    } else {
      this.submitForm();
    }
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      const dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (const item of dirty) {
          item.classList.remove("dirty");
        }
        const clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }

  editField(data: any) {
    if ((this.selectedId === data.id) &&
    data.name !== '') {
      this.disable = false
      this.saveAttributeValue(data);
    } else {
      this.loadOptions(data.id);
      this.originalId = data.sizeGroupAttributeId;
      this.selectedId = data.id;
      this.originalName = data.name;
      this.disable = true
    }
  }

  async editOverrides(sizeGroupAttributeValue: any) {
    if (!this.overrideDialogVisible && sizeGroupAttributeValue.name !== '') {
      this.sizeGroupAttributeValueId = sizeGroupAttributeValue.id;
      this.sizeGroupAttributeName = sizeGroupAttributeValue.sizeGroupAttributeName;
      this.overrideDialogVisible = true;
    }
  }

  addRow() {
    const tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadOptions();

    if (this.attributeValueItems.length) {
      this.attributeValueItems.map(item => {
        if (item.value === '') {
          emptyName += 1;
        }
      }) 
    }

    if (emptyName === 0) {
      this.attributeValueItems.push({
        id: tempId,
        sizeGroupAttributeId: '',
        sizeId: this.id,
        value: '',
        overrideValues: []
      });
      console.log('items', this.attributeValueItems);     
      this.originalId = '';
      this.originalName = '';
      this.selectedId = tempId;
      this.disable = true
    }
  }

  saveAttributeValue(data: any) {
    const regex = /^(temp).*/g;
    this.loading = true
    if (!data.id.match(regex)) {
      this.newAttributeValue = {
        sizeGroupAttributeId: data.sizeGroupAttributeId,
        sizeId: this.id,
        value: data.value,
        overrideValues: data.overrideValues
      } as SizeGroupAttributeSizeValueAdminUpdateModel;

      AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesIdPut(this.selectedId, this.newAttributeValue)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("att Put success", res);
            this.$message.success('Saved!')
            this.loadSizeGroupAttributes();
            this.loadOptions()
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
        });
    } else {
      this.newAttributeValue = {
        sizeGroupAttributeId: data.sizeGroupAttributeId,
        sizeId: this.id,
        value: data.value,
        overrideValues: []
      };
      AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesPost(this.newAttributeValue)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("att Post success", res);
            this.$message.success('Saved!')
            this.loadSizeGroupAttributes();
            this.loadOptions()
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
        });
    }
    this.selectedId = "";
    this.loading = false
    this.disable = false;
    
  }

  confirmDelete(data: any) {
    this.dialogMessage = "<span><stong>Are you sure you want to delete this attribute value?</strong></span>";
    this.confirmText = "Yes";
    const regex = /^(temp).*/g;
    if ((this.selectedId === data.id) && !this.selectedId.match(regex)) {
      data.sizeGroupAttributeId = this.originalId;
      data.name = this.originalName;
      this.selectedId = "";
      this.disable = false
    } else {
      console.log('in delete', data);
      
      this.selectedId = data.id;
      this.dialogVisible = true;
    }
    this.loadOptions();
  }

  deleteRow() {
    const regex = /^(temp).*/g;
    let index = this.attributeValueItems.length - 1;
    if (this.selectedId.match(regex)) {
      this.attributeValueItems.map((child, i) => {
        if (this.selectedId === child.id) {
          index = i;
        }
      });
      this.attributeValueItems.splice(index, 1);
    } else {
      this.loading = true
      AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesIdDelete(this.selectedId)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.info('Item deleted.')
            this.loadSizeGroupAttributes();
            this.loadOptions()
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
        });
    }
    this.disable = false
    this.selectedId = "";
    this.originalId = "";
    this.originalName = ""
    this.dialogVisible = false;
  }

  submitForm() {
    this.loading = true
    if (this.size.id) {
      console.log("before save", this.newSize);

      AdminSizes.adminSizesIdPut(this.size.id, this.newSize)
        .then((res) => {
          if (res.data.succeeded) {
            console.log("success?", res);
            this.$message.success('Saved!')
            this.dialogVisible = false;
            this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })         
          }
          this.loading = false          
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
        });
    } else {
      AdminSizes.adminSizesPost(this.newSize)
        .then((res) => {
          console.log("success?", res);
          if (res.data.succeeded) {
            this.$message.success('Saved!')
            this.dialogVisible = false;
            this.id = res.data.resultData!.id;
            this.$router.replace({ name: 'EditSize', params: { id: this.id } })
            this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );            
        });
    }
    this.selectedId = '';
  }

  loadOptions(attributeValueId: string | undefined = undefined) {
    this.loading = true
    AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesGetSizeGroupAttributesForSizeGet(this.id, attributeValueId)
      .then((res) => {
        if (res.data.succeeded) {
          console.log('get fabs for cols', res);
          this.sizeGroupAttributeItems = res.data.resultData as Array<SizeGroupAttributeAdminListViewModel>
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
        );            
      });
  }

  async loadSizeGroupAttributes() {
    this.loading = true
    await AdminSizeGroupAttributeSizeValues.adminSizeGroupAttributeSizeValuesGet(this.attributePageNumber, this.attributeItemPerPage, this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.attributeValues = res.data.resultData as SizeGroupAttributeSizeValueAdminListViewModelPaginatedList;
          this.attributeValueItems = this.attributeValues.items as Array<SizeGroupAttributeSizeValueAdminViewModel>;
          this.newAttributeValue = {
            sizeGroupAttributeId: '',
            sizeId: this.id,
            value: '',
            overrideValues: []
          }
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
        );
        this.dialogVisible = false;   
      })
  }

  async loadInitialData() {
    this.loading = true
    
    await AdminSizeGroups.adminSizeGroupsGet(1, 999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.sizeGroups = res.data
            .resultData!.items as Array<SizeGroupAdminViewModel>
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage)
          return e.friendlyMessage
        }
        );
      })

    if (this.id) {
      this.loadOptions();
      this.selectedId = '';

      await this.loadSizeGroupAttributes()
      this.loading = true      

      await AdminSizes.adminSizesIdGet(this.id).then((res) => {
        this.size = res.data.resultData as SizeAdminViewModel;
        this.name = this.size.name;
        this.newSize = (({ id, ...rest }) => rest)(this.size) as any;
        console.log("new fabric", this.newSize);
      })
        .catch((error) => {
          this.loading = false
          error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
          );
          this.dialogVisible = false;      
        })
    }
    this.loading = false
  }
}
