






































































































































































































































































































































































































































































































































































































































import {
  AdjustmentAdminListViewModel,
  AdjustmentTypeAdminListViewModel,
  DesignGroupAdminListViewModel,
  ExtraAdminListViewModel,
  FactoryAdminListViewModel,
  PriceAdminViewModel,
  SizeAdminListViewModel,
  SizeGroupAdminListViewModel,
  StyleAdjustmentTypeAdminCreateModel,
  StyleAdjustmentTypeAdminListViewModel,
  StyleAdjustmentTypeAdminListViewModelPaginatedList,
  StyleAdjustmentTypeAdminUpdateModel,
  StyleAdminCreateModel,
  StyleAdminListViewModel,
  StyleAdminUpdateModel,
  StyleAdminViewModel,
  StyleSizeGroupAdminCreateModel,
  StyleSizeGroupAdminListViewModel,
  StyleSizeGroupAdminListViewModelPaginatedList,
  StyleSizeGroupAdminUpdateModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";
import { sortAscending } from "@/utils/sortAscending";
import PriceRange from "@/components/form-items/PriceRange.vue";
import Pagination from "@/components/Pagination.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import {
  AdminDesignGroups,
  AdminExtraGroups,
  AdminFactories,
  AdminStyleAdjustmentTypes,
  AdminStylePrices,
  AdminStyles,
  AdminStyleSizeGroups,
} from "@/network/api";
import store from "@/store";

@Component({
  name: "NewStyle",
  components: {
    Pagination,
    PriceRange,
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    ItemsPerPage,
    BasicTooltipHeading
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  dirtyTimer: any = null;
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  frontImage: Array<any> = [];
  backImage: Array<any> = [];
  showBanner: boolean = true;
  sizeItemPerPage = 5;
  sizePageNumber = 1;
  adjustmentsItemPerPage = 5;
  adjustmentsPageNumber = 1;
  style: StyleAdminViewModel = {
    id: "",
    name: "",
    code: "",
    previewImageUrl: "",
    frontShadowMaskUrl: "",
    backShadowMaskUrl: "",
    isDisabled: true,
    extraGroupIds: [],
    designGroupIds: [],
    manufacturerReference: "",
    factoryId: "",
    hasProductBuilderOptionsGenerationLogs: false
  };
  styles: Array<StyleAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  designs: Array<DesignGroupAdminListViewModel> = [];
  //overlays: Array<OverlayAdminListViewModel> = [];
  extras: Array<ExtraAdminListViewModel> = [];
  newStyle: StyleAdminCreateModel | StyleAdminUpdateModel = {
    name: "",
    code: "",
    previewImageUrl: "",
    frontShadowMaskUrl: "",
    backShadowMaskUrl: "",
    isDisabled: true,
    extraGroupIds: [],
    designGroupIds: [],
    manufacturerReference: "",
    factoryId: "",
  };
  loading = false;
  priceRanges: Array<PriceAdminViewModel> = [
    {
      id: "",
      minimumQuantity: null,
      maximumQuantity: null,
      price: 0,
      linkedEntityId: "",
    },
  ];

  selectedSizeGroupId = "";
  originalSizeGroupId = "";
  originalSizesArray: Array<any> = [];
  currentSizeGroupId = "";
  styleSizeGroups: StyleSizeGroupAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  styleSizeGroupItems: Array<StyleSizeGroupAdminListViewModel> = [];
  sizeGroupOptions: Array<SizeGroupAdminListViewModel> = [];
  sizeOptions: Array<SizeAdminListViewModel> = [];
  selectedSizes: Array<string> = [];
  selectedSizesList: Array<Array<string>> = [];
  newStyleSizeGroup:
    | StyleSizeGroupAdminCreateModel
    | StyleSizeGroupAdminUpdateModel = {
    sizeGroupId: "",
    styleId: this.id,
    sizeIds: [],
  };
  toggleSizeGroupOptionValue = false;
  toggleSizeOptionValue = false;

  selectedAdjustmentTypeId = "";
  originaladjustmentTypeId = "";
  originalAdjustmentsArray: Array<any> = [];
  currentAdjustmentTypeId = "";
  adjustmentTypes: StyleAdjustmentTypeAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  styleAdjustmentTypeItems: Array<StyleAdjustmentTypeAdminListViewModel> = [];
  adjustmentTypeOptions: Array<AdjustmentTypeAdminListViewModel> = [];
  adjustmentOptions: Array<AdjustmentAdminListViewModel> = [];
  selectedAdjustments: Array<string> = [];
  selectedAdjustmentsList: Array<Array<string>> = [];
  newStyleAdjustmentType:
    | StyleAdjustmentTypeAdminCreateModel
    | StyleAdjustmentTypeAdminUpdateModel = {
    adjustmentTypeId: "",
    styleId: this.id,
    adjustmentIds: [],
  };
  factories: Array<FactoryAdminListViewModel> = []
  toggleAdjustmentTypeOptionValue = false;
  toggleAdjustmentOptionValue = false;
  disable = false
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  created() {
    let toQuery = {
      sizePageNumber: this.sizePageNumber,
      sizeItemPerPage: this.sizeItemPerPage,
      adjustmentsPageNumber: this.adjustmentsPageNumber,
      adjustmentsItemPerPage: this.adjustmentsItemPerPage,
    }
    this.setQueryWatch(toQuery)
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    extend("preview", {
      validate(value) {
        let uploader = self.$refs.previewUploader as any;

        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("front", {
      validate(value) {
        let uploader = self.$refs.frontUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("back", {
      validate(value) {
        let uploader = self.$refs.backUploader as any;
        return {
          valid: uploader._selectedFile || uploader.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("extras", () => {
      if (this.newStyle.extraGroupIds.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    // extend('overlays', () => {
    //   if (this.newStyle.overlayGroupIds.length > 0) {
    //     return true
    //   }
    //   return 'The {_field_} field is required.'
    // })

    extend("designs", () => {
      if (this.newStyle.designGroupIds.length > 0) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  get sortedDesigns() {
    return sortAscending(this.designs, false);
  }

  get sortedFactories() {
    return sortAscending(this.factories, false);
  }

  get sortedExtras() {
    return sortAscending(this.extras, false);
  }

  selectAll(row:any) {
    row.sizes = [...this.sizeOptions]
  }

  validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

  handleUseEmpty(path:any, target:any) {
    this.newStyle[target] = path; // user correct variable for page
  }

  uploadImages(): Promise<any> {
    let previewUploader = this.$refs.previewUploader as ImageUploader;
    let frontUploader = this.$refs.frontUploader as ImageUploader;
    let backUploader = this.$refs.backUploader as ImageUploader;

    let previewPromise;
    let frontPromise;
    let backPromise;

    if (previewUploader) {
      previewPromise = previewUploader!.uploadImage();
    }
    if (frontUploader) {
      frontPromise = frontUploader!.uploadImage();
    }
    if (backUploader) {
      backPromise = backUploader!.uploadImage();
    }
    return Promise.all([previewPromise, frontPromise, backPromise]);
  }

  getHeightAndWidthFromDataUrl = (dataURL: any) =>
    new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.src = dataURL;
    });

  beforeImageUpload = async (file: any) => {
    const isType = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isType) {
      this.$message.error("Image must be JPG or PNG format.");
    }
    if (!isLt2M) {
      this.$message.error("Image size can not exceed 2MB.");
    }

    return isType && isLt2M;

    // //remember to pass func to imageuploader
    // debugger
    // const fileAsDataURL = window.URL.createObjectURL(file)
    // let height = 0;
    // let width = 0;
    // //Get the dimensions
    // let result = await this.getHeightAndWidthFromDataUrl(fileAsDataURL).then((dimensions:any) => {
    //   height = dimensions.height;
    //   width = dimensions.width;

    //   const isType = file.type === ('image/jpeg' || 'image/png');
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   const isHeight = height === 1000;
    //   const isWidth = width === 1197;

    //   if (!isHeight || !isWidth) {
    //     this.$message.error(`Image dimensions must be 1197 x 1000. Current image is ${width} x ${height}.`);
    //   }
    //   if (!isType) {
    //     this.$message.error('Image must be JPG or PNG format.');
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('Image size can not exceed 2MB.');
    //   }
    //   debugger
    //   console.log('here');
    //   return Promise.resolve(false);
    //   // return isType && isLt2M && isHeight && isWidth;
    // })
    // .catch((error) => {
    //   debugger
    //   console.log(error);
    //   return false;
    // })
    // console.log('result', result);
    // debugger
    // return result;
  };

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";
    this.dialogMessage =
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";

    if (isDirty.length > 0) {
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  removeImage(area: string) {
    if (area == "preview") {
      this.newStyle.previewImageUrl = "";
    } else if (area == "front") {
      this.newStyle.frontShadowMaskUrl = "";
    } else if (area == "back") {
      this.newStyle.backShadowMaskUrl = "";
    }
  }

  handleConfirm() {
    if (
      this.dialogMessage ===
      "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>"
    ) {
      if(this.fromPath === 'Styles') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Styles"});
      }
    } else if (
      this.dialogMessage ===
      "<span><stong>Are you sure you want to delete this size?</strong></span>"
    ) {
      this.deleteSizeGroupRow();
    } else if (
      this.dialogMessage ===
      "<span><stong>Are you sure you want to delete this adjustment type?</strong></span>"
    ) {
      this.deleteAdjustmentTypeRow();
    } else {
      this.submitForm();
    }
  }

  //-------- Size Groups Start

  toggleSizeGroupOption(visible: boolean) {
    this.toggleSizeGroupOptionValue = visible;
  }

  toggleSizeOption(visible: boolean) {
    this.toggleSizeOptionValue = visible;
  }

  fetchSizes(row: any) {
    this.selectedSizes = [];
    console.log("fetch row", row);

    if (row.sizes) {
      row.sizes = [];
    }

    if (row.sizeGroupId) {
      this.currentSizeGroupId = row.sizeGroupId;
    } else {
      this.currentSizeGroupId = row;
    }

    if (this.currentSizeGroupId) {
      this.loading = true;
      AdminStyleSizeGroups.adminStyleSizeGroupsGetSizesForSizeGroupGet(
        this.currentSizeGroupId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get sizes for sizegroups", res);
            this.sizeOptions = res.data
              .resultData as Array<SizeAdminListViewModel>;
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
  }

  handleSizePageNumberClick(page: any) {
    this.sizePageNumber = page;
    this.loadSizes();
  }

  editSizeField(row: any, index: any) {
    console.log(row);

    if (
      this.selectedSizeGroupId === row.id &&
      row.sizeGroupId !== "" &&
      row.sizes.length
    ) {
      this.disable = false;
      this.saveSizeGroup(row);
    } else {
      this.loadSizeGroupOptions(row.id);
      this.fetchSizes(row.sizeGroupId);
      this.originalSizeGroupId = row.sizeGroupId;
      this.selectedSizeGroupId = row.id;
      this.originalSizesArray = [...row.sizes];
      this.disable = true;
    }
  }

  addSizeGroupRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadSizeGroupOptions();

    if (this.styleSizeGroupItems.length)
      this.styleSizeGroupItems.map((item) => {
        if (item.sizeGroupId === "" || !item.sizes.length) {
          emptyName += 1;
        }
      });

    if (emptyName === 0) {
      this.styleSizeGroupItems.push({
        id: tempId,
        styleId: this.style.id,
        sizeGroupId: "",
        sizeGroupName: "",
        sizes: [],
      });
      console.log("items", this.styleSizeGroupItems);
      this.originalSizeGroupId = "";
      this.originalSizesArray = [];
      this.selectedSizes = [];
      this.selectedSizeGroupId = tempId;
      this.disable = true;
    }
  }

  loadSizeGroupOptions(sizeGroupId: string | undefined = undefined) {
    this.loading = true;
    AdminStyleSizeGroups.adminStyleSizeGroupsGetSizeGroupsForStyleGet(
      this.id,
      sizeGroupId
    )
      .then((res) => {
        if (res.data.succeeded) {
          console.log("get size for style", sizeGroupId, res);
          this.sizeGroupOptions = res.data
            .resultData as Array<SizeGroupAdminListViewModel>;
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  saveSizeGroup(data: any) {
    if (data.sizeGroupId !== "" && data.sizes.length > 0) {
      let sizeIds: Array<string> = [];
      data.sizes.map((item: any) => {
        sizeIds.push(item.id);
      });
      this.newStyleSizeGroup = {
        sizeGroupId: data.sizeGroupId,
        styleId: this.id,
        sizeIds: [...sizeIds],
      };

      let regex = /^(temp).*/g;
      this.loading = true;
      if (!data.id.match(regex)) {
        AdminStyleSizeGroups.adminStyleSizeGroupsIdPut(
          data.id,
          this.newStyleSizeGroup
        )
          .then((res) => {
            if (res.data.succeeded) {
              console.log("stylesize Put success", res);
              this.$message.success("Saved!");
              this.loadSizes();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false;
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
          });
      } else {
        console.log("before post", this.newStyleSizeGroup);
        AdminStyleSizeGroups.adminStyleSizeGroupsPost(this.newStyleSizeGroup)
          .then((res) => {
            if (res.data.succeeded) {
              console.log("stylesize Post success", res);
              this.$message.success("Saved!");
              this.loadSizes();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      }
      this.selectedSizeGroupId = "";
      this.disable = false;
    }
  }

  confirmSizeDelete(data: any) {
    this.dialogMessage =
      "<span><stong>Are you sure you want to delete this size?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if (
      this.selectedSizeGroupId === data.id &&
      !this.selectedSizeGroupId.match(regex)
    ) {
      data.sizeGroupId = this.originalSizeGroupId;
      data.sizes = [...this.originalSizesArray];
      this.selectedSizeGroupId = "";
      this.disable = false;
    } else {
      console.log("in delete", data);

      this.selectedSizeGroupId = data.id;
      this.dialogVisible = true;
    }
    this.loadSizeGroupOptions();
  }

  deleteSizeGroupRow() {
    let regex = /^(temp).*/g;
    let index = this.styleSizeGroupItems.length - 1;
    if (this.selectedSizeGroupId.match(regex)) {
      this.styleSizeGroupItems.map((child, i) => {
        if (this.selectedSizeGroupId === child.id) {
          index = i;
        }
      });
      this.styleSizeGroupItems.splice(index, 1);
    } else {
      this.loading = true;
      AdminStyleSizeGroups.adminStyleSizeGroupsIdDelete(
        this.selectedSizeGroupId
      )
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.info("Item deleted.");
          this.loadSizes();
          }
          
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          
        });
    }
    this.selectedSizeGroupId = "";
    this.originalSizeGroupId = "";
    this.originalSizesArray = [];
    this.dialogVisible = false;
    this.currentSizeGroupId = "";
    this.disable = false;
  }

  //-------- Size Groups End

  //-------- Adjustment Types Start

  toggleAdjustmentTypeOption(visible: boolean) {
    this.toggleAdjustmentTypeOptionValue = visible;
  }

  toggleAdjustmentOption(visible: boolean) {
    this.toggleAdjustmentOptionValue = visible;
  }

  handleAdjustmentsPageNumberClick(page: any) {
    this.adjustmentsPageNumber = page;
    this.loadAdjustmentTypes();
  }

  fetchAdjustments(row: any) {
    this.selectedAdjustments = [];
    console.log("fetch row", row);

    if (row.adjustments) {
      row.adjustments = [];
    }

    if (row.adjustmentTypeId) {
      this.currentAdjustmentTypeId = row.adjustmentTypeId;
    } else {
      this.currentAdjustmentTypeId = row;
    }

    if (this.currentAdjustmentTypeId) {
      this.loading = true;
      AdminStyleAdjustmentTypes.adminStyleAdjustmentTypesGetAdjustmentsForAdjustmentTypeGet(
        this.currentAdjustmentTypeId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get adjs for types", res);
            this.adjustmentOptions = res.data
              .resultData as Array<AdjustmentAdminListViewModel>;
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
  }

  editAdjustmentField(row: any, index: any) {
    console.log(row);

    if (
      this.selectedAdjustmentTypeId === row.id &&
      row.adjustmentTypeId !== "" &&
      row.adjustments.length
    ) {
      this.disable = false;
      this.saveAdjustmentType(row);
    } else {
      this.loadAdjustmentTypeOptions(row.id);
      this.fetchAdjustments(row.adjustmentTypeId);
      this.originaladjustmentTypeId = row.adjustmentTypeId;
      this.selectedAdjustmentTypeId = row.id;
      this.originalAdjustmentsArray = [...row.adjustments];
      this.disable = true;
    }
  }

  addAdjustmentTypeRow() {
    let tempId = "temp" + Math.floor(Math.random() * 99999);
    let emptyName = 0;
    this.loadAdjustmentTypeOptions();

    if (this.styleAdjustmentTypeItems.length)
      this.styleAdjustmentTypeItems.map((item) => {
        if (item.adjustmentTypeId === "" || !item.adjustments.length) {
          emptyName += 1;
        }
      });

    if (emptyName === 0) {
      this.styleAdjustmentTypeItems.push({
        id: tempId,
        styleId: this.style.id,
        adjustmentTypeId: "",
        adjustmentTypeName: "",
        adjustments: [],
      });
      console.log("items", this.styleAdjustmentTypeItems);
      this.originaladjustmentTypeId = "";
      this.originalAdjustmentsArray = [];
      this.selectedAdjustments = [];
      this.selectedAdjustmentTypeId = tempId;
      this.disable = true;
    }
  }

  loadAdjustmentTypeOptions(adjustmentTypeId: string | undefined = undefined) {
    this.loading = true;
    AdminStyleAdjustmentTypes.adminStyleAdjustmentTypesGetAdjustmentTypesForStyleGet(
      this.id,
      adjustmentTypeId
    )
      .then((res) => {
        if (res.data.succeeded) {
          console.log("get adj types for style", adjustmentTypeId, res);
          this.adjustmentTypeOptions = res.data
            .resultData as Array<AdjustmentTypeAdminListViewModel>;
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  saveAdjustmentType(data: any) {
    if (data.adjustmentTypeId !== "" && data.adjustments.length > 0) {
      let adjustmentIds: Array<string> = [];
      data.adjustments.map((item: any) => {
        adjustmentIds.push(item.id);
      });
      this.newStyleAdjustmentType = {
        adjustmentTypeId: data.adjustmentTypeId,
        styleId: this.id,
        adjustmentIds: [...adjustmentIds],
      };

      let regex = /^(temp).*/g;
      this.loading = true;
      if (!data.id.match(regex)) {
        AdminStyleAdjustmentTypes.adminStyleAdjustmentTypesIdPut(
          data.id,
          this.newStyleAdjustmentType
        )
          .then((res) => {
            if (res.data.succeeded) {
              console.log("adjtype Put success", res);
              this.$message.success("Saved!");
              this.loadAdjustmentTypes();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      } else {
        console.log("before post", this.newStyleAdjustmentType);
        AdminStyleAdjustmentTypes.adminStyleAdjustmentTypesPost(
          this.newStyleAdjustmentType
        )
          .then((res) => {
            if (res.data.succeeded) {
              console.log("adjtype Post success", res);
              this.$message.success("Saved!");
              this.loadAdjustmentTypes();
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      }
      this.selectedAdjustmentTypeId = "";
      this.disable = false;
    }
  }

  confirmAdjustmentDelete(data: any) {
    this.dialogMessage =
      "<span><stong>Are you sure you want to delete this adjustment type?</strong></span>";
    this.confirmText = "Yes";
    let regex = /^(temp).*/g;
    if (
      this.selectedAdjustmentTypeId === data.id &&
      !this.selectedAdjustmentTypeId.match(regex)
    ) {
      data.adjustmentTypeId = this.originaladjustmentTypeId;
      data.adjustments = [...this.originalAdjustmentsArray];
      this.selectedAdjustmentTypeId = "";
      this.disable = false;
    } else {
      console.log("in delete", data);

      this.selectedAdjustmentTypeId = data.id;
      this.dialogVisible = true;
    }
    this.loadAdjustmentTypeOptions();
  }

  deleteAdjustmentTypeRow() {
    let regex = /^(temp).*/g;
    let index = this.styleAdjustmentTypeItems.length - 1;
    if (this.selectedAdjustmentTypeId.match(regex)) {
      this.styleAdjustmentTypeItems.map((child, i) => {
        if (this.selectedAdjustmentTypeId === child.id) {
          index = i;
        }
      });
      this.styleAdjustmentTypeItems.splice(index, 1);
    } else {
      this.loading = true;
      AdminStyleAdjustmentTypes.adminStyleAdjustmentTypesIdDelete(
        this.selectedAdjustmentTypeId
      )
        .then((res) => {
          if(res.data.succeeded) {
            this.$message.info("Item deleted.");
          this.loadAdjustmentTypes();
          }
          
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          
        });
    }
    this.selectedAdjustmentTypeId = "";
    this.originaladjustmentTypeId = "";
    this.originalAdjustmentsArray = [];
    this.dialogVisible = false;
    this.currentAdjustmentTypeId = "";
    this.disable = false;
  }

  //-------- Adjustment Types End

  //-------- Price Range Start

  saveNewPriceRange(range: any) {
    this.loading = true;
    AdminStylePrices.adminStylePricesPost(range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price post success", res);
          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
        
      });
  }

  updatePriceRange(range: any) {
    this.loading = true;
    AdminStylePrices.adminStylePricesIdPut(range.id, range)
      .then((res) => {
        if (res.data.succeeded) {
          console.log("price put success", res);
          this.$message.success("Saved!");
          this.loadPriceRanges();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          this.loadPriceRanges();
          return e.friendlyMessage;
        });
        
      });
  }

  deletePriceRange(id: string) {
    this.loading = true;
    AdminStylePrices.adminStylePricesIdDelete(id)
      .then((res) => {
        if(res.data.succeeded) {
          this.$message.info("Item deleted.");
        this.loadPriceRanges();
        }
        
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  loadPriceRanges() {
    if (this.id) {
      this.loading = true;
      AdminStylePrices.adminStylePricesGet(1, 99999999, this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.priceRanges = res.data.resultData!.items;
          console.log("get price", this.priceRanges);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
    }
  }

  //-------- Price Range End

  submitForm() {
    this.uploadImages().then((results) => {
      if (results[0].imageUrl) {
        this.newStyle.previewImageUrl = results[0].imageUrl;
      }

      if (results[1].imageUrl) {
        this.newStyle.frontShadowMaskUrl = results[1].imageUrl;
      }

      if (results[2].imageUrl) {
        this.newStyle.backShadowMaskUrl = results[2].imageUrl;
      }
      this.loading = true;
      if (this.style.id) {
        console.log("before save", this.newStyle);

        AdminStyles.adminStylesIdPut(this.style.id, this.newStyle)
          .then((res) => {
            if(res.data.succeeded) {
              console.log("success?", res);
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses();
            })
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      } else {
        AdminStyles.adminStylesPost(this.newStyle)
          .then((res) => {
            console.log("success?", res);
            if (res.data.succeeded) {
              this.$message.success("Saved!");
              this.dialogVisible = false;
              this.id = res.data.resultData!.id
              this.$router.replace({ name: "EditStyle", params: {id: this.id} });
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.dialogVisible = false;
            error.response.data.errors.map((e: any) => {
              this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
              return e.friendlyMessage;
            });
            
          });
      }
    });
  }

  async loadExtras(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminExtraGroups.adminExtraGroupsGet(1, 999999).then((res) => {
      if (res.data.succeeded) {
        this.extras = res.data.resultData!.items as Array<any>;
        console.log("extras", this.extras);
      }
      if (refresh) {
          this.loading = false;
          this.$message.success("Section Refreshed.");
        }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });      
    });
  }

  async loadDesigns(refresh = false) {
    if (refresh) {
      this.loading = true;
    }
    await AdminDesignGroups.adminDesignGroupsGet(1, 999999).then((res) => {
      if (res.data.succeeded) {
        this.designs = res.data.resultData!
          .items as Array<DesignGroupAdminListViewModel>;
      }
      if (refresh) {
          this.loading = false;
          this.$message.success("Section Refreshed.");
        }
    })
    .catch((error) => {
      this.loading = false
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
        return e.friendlyMessage;
      });    
    });
  }

  async loadSizes() {
    if(this.id) {
    this.loading = true;
    
    await AdminStyleSizeGroups.adminStyleSizeGroupsGet(
        this.sizePageNumber,
        this.sizeItemPerPage,
        this.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.styleSizeGroups = res.data
              .resultData as StyleSizeGroupAdminListViewModelPaginatedList;
            this.styleSizeGroupItems = this.styleSizeGroups.items;
            console.log("items NB", this.styleSizeGroupItems);

            this.selectedSizesList = [];
            if (this.styleSizeGroupItems.length) {
              this.styleSizeGroupItems.map((item: any) => {
                this.selectedSizesList.push(item.sizes);
              });
              console.log("size list", this.selectedSizesList);
            }

            this.newStyleSizeGroup = {
              sizeGroupId: "",
              styleId: this.id,
              sizeIds: [],
            };
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
      }
  }

  async loadAdjustmentTypes() {
    if(this.id) {
    this.loading = true;
    await AdminStyleAdjustmentTypes.adminStyleAdjustmentTypesGet(
        this.adjustmentsPageNumber,
        this.adjustmentsItemPerPage,
        this.id
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.adjustmentTypes = res.data
              .resultData as StyleAdjustmentTypeAdminListViewModelPaginatedList;
            this.styleAdjustmentTypeItems = this.adjustmentTypes.items;
            console.log("items NB", this.styleAdjustmentTypeItems);

            this.selectedAdjustmentsList = [];
            if (this.styleAdjustmentTypeItems.length) {
              this.styleAdjustmentTypeItems.map((item: any) => {
                this.selectedAdjustmentsList.push(item.adjustments);
              });
              console.log("size list", this.selectedAdjustmentsList);
            }

            this.newStyleAdjustmentType = {
              adjustmentTypeId: "",
              styleId: this.id,
              adjustmentIds: [],
            };
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
      }
  }


  async loadInitialData() {
    this.loading = true;

    await this.loadExtras()

    await this.loadDesigns()  
    
    if(this.roles.includes('Admin')) {
      await AdminFactories.adminFactoriesGet(1, 999999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.factories = res.data
            .resultData?.items as Array<FactoryAdminListViewModel>;
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    } else if(this.roles.includes('Marketing & Design')) {
      await AdminFactories.adminFactoriesGetFactoryNamesGet(1, 999999999)
      .then((res) => {
        if (res.data.succeeded) {
          this.factories = res.data
            .resultData?.items as Array<FactoryAdminListViewModel>;
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
    }     

    if (this.id) {
      this.loadPriceRanges();
      
      this.currentSizeGroupId = "";
      this.currentAdjustmentTypeId = "";

      this.loadSizeGroupOptions();

      this.loadAdjustmentTypeOptions();

      this.loadSizes()

      this.loadAdjustmentTypes()
      this.loading = true;

      await AdminStyles.adminStylesIdGet(this.id)
        .then((res) => {
          this.style = res.data.resultData as StyleAdminViewModel;
          this.name = this.style.name;
          this.newStyle = (({ id, ...rest }) => rest)(this.style) as any;
          console.log("new style", this.newStyle);

          if (this.newStyle.previewImageUrl) {
            this.previewImage = [
              {
                imageUrl: this.newStyle.previewImageUrl,
              },
            ];
          }
          if (this.newStyle.frontShadowMaskUrl) {
            this.frontImage = [
              {
                imageUrl: this.newStyle.frontShadowMaskUrl,
              },
            ];
          }
          if (this.newStyle.backShadowMaskUrl) {
            this.backImage = [
              {
                imageUrl: this.newStyle.backShadowMaskUrl,
              },
            ];
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    this.loading = false
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
