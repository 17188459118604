























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminOrderCosts, AdminOrders } from "@/network/api";
import {
  OrderCostAdminListViewModelPaginatedList,
  OrderCostAdminListViewModel,
  OrderCostType,
  DecimalApplicationResult,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from "@/components/DialogBox.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import { extend, configure } from "vee-validate";
import { formatDate } from "@/utils/formatDate"


const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    clearReasons: {
      type: Boolean,
      default: false
    }
  },
});

@Component({
  name: "CostsList",

  components: {
    ElTableDraggable,
    Pagination,
    DialogBox,
    ItemsPerPage,
    BasicInputField,
    ImageUploader,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        Adhoc: "danger",
        Product: "success",
        Delivery: "",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends AppProps {
  pageNumber = 1;
  itemPerPage =  5;
  dialogVisible = false;
  dialogMessage =
    "<span><stong>Are you sure you want to delete this cost?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  debounce: any = null;
  loading = false;
  selection: Array<any> = [];
  selectedItem: OrderCostAdminListViewModel = {
    id: '',
    orderId: '',
    totalAmount: 0,
    convertedTotalAmount: 0,
    quantity: 0,
    orderItemId: '',
    orderCostTypeId: OrderCostType.Adhoc,
    reason: '',
    lastModifiedDate: '',
  };

  costTypeFilter: OrderCostType | undefined = undefined;
  selectedCostType = '';

  orderCostPages: OrderCostAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  orderCosts: Array<OrderCostAdminListViewModel> = [];
  convertedOrderBalance = 0;
  internalReason = ''
  externalReason = ''

  // get showLastRequested() {
  //   if (!this.order) {
  //     return false;
  //   }
  //   return (this.order.orderStatusId !== 'AwaitingAdditionalPayment' && this.order.orderStatusId !== 'AwaitingResponse') || !this.order.responseRequestedDate
  // }


  created() {
    if (this.order) {
      this.loadInitialData();
    }

    this.$watch("order", () => {
      this.loadInitialData();
    });

    this.$watch("$route", () => {
      if (this.$route.query.tab === "costs") {
        this.$emit("showList");
        this.loadInitialData();
      }
    });

    this.$watch("clearReasons", () => {
      if(this.clearReasons) {
        this.internalReason = this.externalReason = ''
        this.$emit('resetClear')
      }
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  get disableSendPayment() {
    const disabledStatuses = ['Cancelled', 'OnHold', 'Lost', 'AwaitingResponse', 'AwaitingAdditionalPayment']

    if(disabledStatuses.includes(this.order.orderStatusId)) {
      return true
    }
    return false
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handleDate(date:any) {
    if(date) {
      return formatDate(date);
    }
    return ''
  }

  handleCostTypeFilter(selected: string) {
    if (selected === "Delivery" && this.selectedCostType !== "Delivery") {
      this.costTypeFilter = "Delivery";
      this.selectedCostType = "Delivery";
    } else if (
      selected === "Product" &&
      this.selectedCostType !== "Product"
    ) {
      this.costTypeFilter = "Product";
      this.selectedCostType = "Product";
    } else if (
      selected === "Adhoc" &&
      this.selectedCostType !== "Adhoc"
    ) {
      this.costTypeFilter = "Adhoc";
      this.selectedCostType = "Adhoc";
    } else {
      this.costTypeFilter = undefined;
    this.selectedCostType = "";
    }
    this.loadInitialData();
  }

  clearCostTypeFilter() {
    this.selectedCostType = "";
    this.costTypeFilter = undefined;
    this.loadInitialData();
  }

  copyLinkPath() {
    let link = `${process.env.VUE_APP_ROOT_WEB}/order-payments/${this.order.id}`;
    navigator.clipboard.writeText(`${link}`)
    this.$message.success('Path copied.')
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  editCost(row: OrderCostAdminListViewModel) {
    this.$emit("edit", row.id);
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  deleteItem() {
    AdminOrderCosts.adminOrderCostsIdDelete(this.selectedItem.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.pageNumber = 1;
          this.$message.info("Item deleted.");
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });

      this.loading = false;
      this.dialogVisible = false;
      this.selectedItem = {
        id: '',
        orderId: '',
        totalAmount: 0,
        convertedTotalAmount: 0,
        quantity: 0,
        orderItemId: '',
        orderCostTypeId: OrderCostType.Adhoc,
        reason: '',
        lastModifiedDate: ''
      };
  }

  sendPaymentEmail() {
    let isDirty = document.querySelectorAll(".dirty");
    if (isDirty.length > 0) {
      this.$message({showClose: true, type: 'error', duration: 0, message: 'Please save your changes before sending a payment request.'})
    } else {
      if((this.order.orderStatusId == 'BankTransferPending') || (this.order.orderStatusId == 'ResponseReceived')) {
        this.$emit('attemptPaymentEmail', {internal: this.internalReason, external: this.externalReason})
      } else {
        AdminOrders.adminOrdersSendOutstandingPaymentEmailOrderIdPost(this.order.id, this.internalReason, this.externalReason)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.success('Email sent successfully.');
            this.$emit('reload')
            this.internalReason = this.externalReason = ''
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
      }
    }
  }

  async loadInitialData() {
    if (this.order?.id) {
    this.loading = true;

      await AdminOrderCosts.adminOrderCostsGet(this.pageNumber, this.itemPerPage, this.order.id, this.costTypeFilter)
        .then((res) => {
          if (res.data.succeeded) {
            this.orderCostPages = res.data
              .resultData as OrderCostAdminListViewModelPaginatedList;
            this.orderCosts = this.orderCostPages.items;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });

      await AdminOrders.adminOrdersGetConvertedOrderBalanceIdGet(this.order.id)
        .then((res) => {
          if (res.data.succeeded) {
            let oldBalance = this.convertedOrderBalance
            this.convertedOrderBalance = res.data.resultData as number;

            if(oldBalance != this.convertedOrderBalance) {
              this.$emit('reloadBalance', this.convertedOrderBalance)
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }

    this.loading = false;
  }
}
