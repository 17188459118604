
































import {
  ProductCategoryMenuGroupAdminCreateModel,
  ProductCategoryMenuGroupAdminUpdateModel,
  ProductCategoryMenuGroupAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminProductCategoryMenuGroups } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewMenuGroup",
  components: {
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  menuGroup: ProductCategoryMenuGroupAdminViewModel = {
    id: '',
    name: '',
  };
  confirmText: string = "Ok";
  cancelVisible: boolean = true;

  newMenuGroup: ProductCategoryMenuGroupAdminCreateModel | ProductCategoryMenuGroupAdminUpdateModel = {
    name: '',
  };
  loading = false
  dirtyTimer: any = null
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData()
    .then(() => {
      this.clearDirtyClasses()
    })

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'MenuGroups') {
        this.$router.back()
      } else {
        this.$router.push({ name: "MenuGroups"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
     if (this.menuGroup.id) {
      console.log("before save", this.newMenuGroup);
      this.loading = true

      AdminProductCategoryMenuGroups.adminProductCategoryMenuGroupsIdPut(this.menuGroup.id, this.newMenuGroup)
        .then((res) => {
          if(res.data.succeeded) {
            this.loading = false
            this.$message.success("Saved!");
            this.dialogVisible = false;
            this.loadInitialData()
            .then(() => {
              this.clearDirtyClasses()
            })
          }
          
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    } else {
      AdminProductCategoryMenuGroups.adminProductCategoryMenuGroupsPost(this.newMenuGroup)
        .then((res) => {
          if (res.data.succeeded) {
            this.$message.success("Saved!");
            this.loading = false
            this.dialogVisible = false;
            this.$router.push({ name: "MenuGroups" });
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true
      await AdminProductCategoryMenuGroups.adminProductCategoryMenuGroupsIdGet(this.id)
      .then((res) => {
        if (res.data.succeeded) {
          this.menuGroup = res.data.resultData as ProductCategoryMenuGroupAdminViewModel;
          this.name = this.menuGroup.name;
          this.newMenuGroup = (({ id, ...rest }) => rest)(this.menuGroup) as any;    
          this.loading = false 
        } 
      })
      .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
          this.dialogVisible = false;
        });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
