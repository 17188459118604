













































import {
  FontAdminCreateModel,
  FontAdminUpdateModel,
  FontAdminViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import FileUploader from "@/components/form-items/FileUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";

import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminFonts } from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: {default: ''}
  }
})

@Component({
  name: "NewFonts",
  components: { DialogBox, ValidationProvider, ValidationObserver, ImageUploader, BasicInputField, FileUploader },
  beforeRouteEnter(to, from, next) {
    next((vm:any) => {
      vm.fromPath = from.name
    })
  }
})
export default class extends AppProps {
  fontName: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  previewImage: Array<any> = [];
  showBanner: boolean = true;
  font: FontAdminViewModel = {
    id: '',
    name: '',
    fontFileUrl: ''
  };
  fonts: Array<FontAdminViewModel> = [];
  fileList: Array<any> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  loading = false
  newFont: FontAdminCreateModel | FontAdminUpdateModel = {
    name: '',
    fontFileUrl: ''
  };
  dirtyTimer: any = null
  fromPath = ''

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    let self = this;
    // extend("fontFile", {
    //   validate(value) {
    //     let uploader = self.$refs.updateFileList as any;
        
    //     return {
    //       valid: uploader._selectedFile || uploader.files,
    //     };
    //   },
    //   computesRequired: true,
    //   message: "A {_field_} is required.",
    // });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  updateFileList(file: any) {
    this.fileList = file   
  }

  uploadFiles(): Promise<any> {
    let fileUploader = this.$refs.fontUploader as FileUploader;

    let filePromise;

    if(fileUploader) {
      filePromise = fileUploader!.uploadFile()
    }
    return Promise.all([filePromise])
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer)
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  } 

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if(isDirty.length > 0) {
      this.dialogMessage =
        "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  // removeImage(area: string){
  //   if (area == "preview") {
  //     this.newFont.fontFileUrl = '';
  //   } 
  // }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.fromPath === 'Fonts') {
        this.$router.back()
      } else {
        this.$router.push({ name: "Fonts"});
      }
    } else {
      this.submitForm();
    }
  }

  submitForm() {
    this.loading = true
    this.uploadFiles().then((results) => {     
      if(results[0]) {
        this.newFont.fontFileUrl = results[0].fileUrl;
      }

        if (this.font.id) {
          console.log('before save', this.newFont);
          
          AdminFonts.adminFontsIdPut(this.font.id, this.newFont)
            .then((res) => {
              console.log("success?", res);
              this.loading = false
              this.$message.success('Saved!')
              this.dialogVisible = false;
              this.loadInitialData()
              .then(() => {
                this.clearDirtyClasses();
              })
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
            });
        } else {
          AdminFonts.adminFontsPost(this.newFont)
            .then((res) => {
              console.log("success?", res);
              if (res.data.succeeded) {
                this.loading = false
                this.$message.success('Saved!')
                this.dialogVisible = false;
                this.$router.push({ name: "Fonts" });
              }
            })
            .catch((error) => {
              error.response.data.errors.map((e: any) => {
                this.loading = false
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
            });
        }

    });
  }

  async loadInitialData() {
    if (this.id) {
      this.loading = true
      await AdminFonts.adminFontsIdGet(this.id).then((res) => {
        if(res.data.succeeded) {
          this.font = res.data.resultData as FontAdminViewModel;
          this.fontName = this.font.name;
          this.newFont = (({ id, ...rest }) => rest)(this.font) as any;
          console.log('new font', this.newFont);
          this.loading = false
          

          if (this.newFont.fontFileUrl) {
            let fileName = this.newFont.fontFileUrl.split("/")
            this.fileList = [{
              name: fileName[1],
              fileUrl: this.newFont.fontFileUrl,
              status: 'success'
            }];          
          }
        }
        
      })
      .catch((error) => {
        this.loading = false
error.response.data.errors.map((e: any) => {
            this.$message.error(e.friendlyMessage)
            return e.friendlyMessage
          }
        );
        this.dialogVisible = false;
      });
    }
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer)
  }
}
