



















































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminBlogCategories } from '@/network/api'
import {
  BlogCategoryAdminListViewModel,
  BlogCategoryAdminListViewModelPaginatedList,
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

const AppProps = Vue.extend({
  props: {
    reset: {default: false}
  }
})

@Component({
  name: 'BlogPostCategoryList',
  components: { ElTableDraggable, Pagination, DialogBox, SendToPage, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    systemFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        true: 'info'
      }
      return statusMap[status]
    },

  }
})
export default class extends AppProps {
  category: BlogCategoryAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  categoryItems: Array<BlogCategoryAdminListViewModel> = [];
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  search: string | undefined = this.$route.params.search || "";
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this blog category?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedCategoryId = '';
  debounce: any = null;
  loading = false

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch('search', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("search")!.focus();
    //     });
    //   }, 400);
    // })
  
    this.$watch('reset', () => {
        this.search = ''
        this.pageNumber = 1
    })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }

  clearAllFilters() {
    this.pageNumber = 1
    this.search = '';
    this.loadInitialData()
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  handleSendToPage(id: string, selectedPage: number, determinePage: number) {
    this.loading = true;
    let patchInfo: any = {};
    let itemId = 0;
    let newPageItems = [] as Array<any>;

    AdminBlogCategories.adminBlogCategoriesGet(determinePage, this.itemPerPage, this.search || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!.items as Array<any>;

          if (selectedPage < this.pageNumber) {
            itemId = selectedPage > 1 ? newPageItems.length - 1 : 0;
          }

          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false;

          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        this.dialogVisible = false;
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminBlogCategories.adminBlogCategoriesIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);
          this.loadInitialData();
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  async loadInitialData() {
    this.loading = true
    await AdminBlogCategories.adminBlogCategoriesGet(this.pageNumber, this.itemPerPage, this.search)
      .then((res) => {
        if(res.data.succeeded) {
          this.category = res.data
          .resultData as BlogCategoryAdminListViewModelPaginatedList
        this.categoryItems = this.category
          .items as Array<BlogCategoryAdminListViewModel>
          console.log('category items',this.categoryItems);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
      })
  }

  confirmDelete(id: string) {
    this.dialogVisible = true
    this.selectedCategoryId = id
  }

  deleteItem() {
    if (this.selectedCategoryId) {
      this.loading = true
      AdminBlogCategories.adminBlogCategoriesIdDelete(this.selectedCategoryId)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              
        })
    }
    this.dialogVisible = false
    this.selectedCategoryId = ''
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
