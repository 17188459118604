







































import { Component, Vue } from "vue-property-decorator";
import { miscContentItems } from "@/assets/misc-site-data/misc-site-data"

@Component({
  name: "MiscContent",
})
export default class extends Vue {
  miscContent = miscContentItems;
}
