export type FactoryNoteTemplate = {
  id: number,
  name: string;
  content: string;
};

export const factoryNoteTemplates: FactoryNoteTemplate[] = [
  {
    id: 1,
    name: "QING Blazer",
    content: `XXX 

-------------------------------------------------- <BR>

**NEW BOTTLE OPENER:** YES / NO


**NEW BLAZER BAG:** YES/NO


**BODY FABRIC:** please source this (fabric codes are on PDF) / you will receive from our Dongguan factory on XX (XX metres)


++**LINING**++
**BODY:** please use plain BLACK fabric / please source this (fabric codes are on PDF) / you will receive from our Dongguan factory on XX (XX metres)

**ARMS:** please use plain BLACK fabric / please source this (fabric codes are on PDF) / you will receive from our Dongguan factory on XX (XX metres)

**PIPING:** NO piping / please source this (fabric codes are on PDF)


**CHEST PATCH POCKET EMBROIDERY:** please cut pockets & send to Dongguan for embroidery / please do embroidery yourself (DST files are in download link) for $4 per blazer


**NAME PATCH EMBROIDERY:** please produce & apply individual name patches (spelling must match spreadsheet!) / none

**LABELS:** This will be the same for all our orders, there must be:
A) our label inside the left chest pocket
B) label with blazer size and name on outside of packing bag


**SIZING:** The above link has a PDF showing mockup of the blazers, it is important to note that the FRONT buttons must be differently applied on men's and women's blazers.

**BUTTONS:** please use below buttons:
STANDARD: 
![standard_button](https://ci3.googleusercontent.com/meips/ADKq_NbENNOvxUnaqOC4xTkakbphDSHFYVQjbm87SIkecn6Axq5CrV_0GaUWQ_OHRddj4CawJcY08e1rcoFayYPYcWv1kxP0h1oGesCkze4=s0-d-e1-ft#https://i.ibb.co/K59f6Xd/Buttons-We-Use-on-Blazers.png)

GOLD:
![gold_buttons](https://ci3.googleusercontent.com/meips/ADKq_NZiAil0uDZJvpysgJTlb1WArQxR47do4hmgic7GFtlycgDPtHSHdINu4qXcysi_hCog9NO7nthFwYLazeKiRdGnul4pOeLviVzdEzklAJ1CCATp8JgILwC06ZXqmgdEoiA6xwxGGVD0kmM=s0-d-e1-ft#https://i.ibb.co/PwsgmKb/Gold-Style-Buttons-chargeable-extra-updated-23-Mar-2021.png)


**SPEC:** Same as per my previous email unless in blue:

**Canvas style:** fused C-00C1

**Lapel style:** C-0001 notch lapel / C-0002 peak lapel / C-0004 semi-peak lapel / C-0089 notch lapel high gorge

**Lapel width:** Regular = 8cm/3" lapel (C-00X6) / Thin = 5cm/2" lapel (C-00W1) / Wide = 11cm/4" lapel (C-00S2) 
**Lapel buttonhole:** C-0544 (no buttonhole) / C0541 1 x left lapel buttonhole

**Felt undercollar style:** None / C-0671 match felt undercollar
**Front button:** C-0A11 (2 buttons) / C-0013 (3 buttons)

**Chest dart style:** C-0522 (no darts) / C-0521 (with chest darts)

**Breast Pocket:** this must be a single LEFT chest pocket (without slant)

**Lower Pocket Style:** besom pockets with ticket pocket (on both sides) NO FLAP / besom pockets (on both sides) WITH FLAP / besom pockets (on both sides) NO FLAP / patch pockets (on both sides) WITH FLAP / patch pockets (on both sides) NO FLAP / C-0201 welt slant pockets (on both sides) WITH FLAP

**Below the pocket style:** C-0302

**Vent:** C-04N2 (double vents) / C-04M0 accordion pleats, no vent / C-04N1 central vent

**Lining:** C05C1

**Facing style:** C-0702

**Pic-Stitching:** none

**Back inseam:** no seam on back (pattern is striped, is important no back seam in centre) / C-05Z4 0.15cm top stitch on back center seam (to bottom)

**Shoulder shape:** C-0601

**Shoulder pad:** C-0600J no shoulder pad / C-060K Normal shoulder pad

**Sleeve Style:** C-0613 (no slit, no button hole) / C-06X9 (real sleeve slit and single-faced sleeve vent and real slant buttonhole)

**Sleeve Buttons:** C-09X8 (3 sleeve buttons, last button 0.6cm from cuff edge) / C-09X4 (4 sleeve buttons, last button 0.6cm from cuff edge) / C-0622 (four kissing buttons)

**Sweat Pad:** C-074G

**Inner Pocket Style:** C-0801

**Collar Tab Style:** C-0882

**Labelling:** C-087W + C-088F + C-088D + C-0695


**Required to be sent for approval PRE-dispatch:**
- photo of the finished product next to original mockup (front, back, details)
- measurements check
- each blazer within 1 bag (1 bag per blazer) with order number (1, 2, 3 etc)
- packing list 

**Note that if any of these instructions are not followed and/or not provided we will not be able to accept the order.**

**Please confirm receipt of this order and instructions.**`,
  },
  {
    id: 2,
    name: "WEI Fabric Blazer",
    content: `**1. Fabric for Body:** XX metres of fabric  PTLX131 (2.5 metres per blazer / body = 2 metres ; lapels = 1 metre ; collar = 0.6 metre) ; design in download link

**2. Fabric for Lining:** XX metres of fabric PMSF08 (1 metre per blazer) ; design in download link

**3. Embroidery:** please prepare embroidery (files in download link) ; they will then send you the pockets to apply embroidery`,
  },
  {
    id: 3,
    name: "WEI Fabric Waistcoat",
    content: `**1. Fabric for Front:** XX metres of fabric  PTLX131 (0.8 metres per waistcoat) ; design in download link

**2. Fabric for Back:** XX metres of fabric PMSF08 (0.8 metres per waistcoat) ; design in download link

**3. Embroidery:** please prepare embroidery (files in download link) ; they will then send you the pockets to apply embroidery`,
  },
  {
    id: 4,
    name: "CPF Fabric",
    content: `**Fabric for Blazer:** XX metres of fabric CT3030 (2.5 metres per blazer / body = 2 metres ; lapels = 1 metre ; collar = 0.6 metre) ; design in download link

**Fabric for Shirt:**> XX metres of fabric CP3372 (2 metres per shirt) ; design in download link`,
  },
];
