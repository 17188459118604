var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"price-range-container"},[_c('DialogBox',{attrs:{"cancelVisible":_vm.cancelVisible,"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage},on:{"confirm":_vm.handleConfirm,"close":function($event){_vm.dialogVisible = false}}}),_c('div',[(_vm.id)?_c('el-row',{staticStyle:{"padding-top":"10px"}},[_c('el-table',{attrs:{"data":_vm.priceRangeItems,"row-key":"id","default-expand-all":"","fit":"","stripe":"","resizable":"","border":"","highlight-current-row":"","cell-class-name":"fabric-cell"}},[_c('el-table-column',{attrs:{"label-class-name":"col-label","label":"Minimum Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input-number',{class:{ 'disabled-field': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":"small","placeholder":row.minimumQuantity === null ? 'No minimum' : '',"value":row.minimumQuantity,"controls":false,"min":null,"type":'number'},on:{"change":_vm.handleChange},model:{value:(row.minimumQuantity),callback:function ($$v) {_vm.$set(row, "minimumQuantity", $$v)},expression:"row.minimumQuantity"}}),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,false,3574522775)}),_c('el-table-column',{attrs:{"label-class-name":"col-label","label":"Maximum Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input-number',{class:{ 'disabled-field': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":"small","placeholder":row.maximumQuantity === null ? 'No maximum' : '',"value":row.maximumQuantity,"controls":false,"min":null,"type":'number'},on:{"change":_vm.handleChange},model:{value:(row.maximumQuantity),callback:function ($$v) {_vm.$set(row, "maximumQuantity", $$v)},expression:"row.maximumQuantity"}}),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,false,3205438935)}),_c('el-table-column',{attrs:{"label-class-name":"col-label","label":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticStyle:{"margin":"4px 0 0 0"}},[_vm._v("£")]),_c('el-input-number',{class:{ 'disabled-field': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":"small","value":row.price,"controls":false,"precision":2,"type":'number'},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", $$v)},expression:"row.price"}})],1),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,false,1297088901)}),_c('el-table-column',{attrs:{"width":"160","label-class-name":"buttons-label","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"disabled":_vm.selectedId !== row.id && _vm.selectedId !== '',"plain":"","size":"mini","type":"primary"},on:{"click":function($event){return _vm.editField(row)}}},[_vm._v(_vm._s(_vm.selectedId !== row.id ? "Edit" : "Save"))]),_c('el-button',{attrs:{"disabled":_vm.selectedId !== row.id && _vm.selectedId !== '',"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v(_vm._s(_vm.selectedId !== row.id ? "Delete" : "Cancel"))])]}}],null,false,3753289938)})],1)],1):_vm._e()],1),_c('el-button',{staticClass:"new-price",on:{"click":_vm.addRow}},[_vm._v("New Price Range")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }