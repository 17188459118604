














































import {
    CountryGroupedOrderAddressListViewModel,
    CountryGroupedOrderAddressListViewModelPaginatedList
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import AddressFields from "@/components/orders/billing-delivery/AddressFields.vue";
import {
    ValidationProvider,
    ValidationObserver,
    extend,
    configure,
} from "vee-validate";
import { AdminCountryGroupedOrderAddresses } from "@/network/api";

const AppProps = Vue.extend({
    props: {
        id: {default: ''}
    }
})

@Component({
    name: "GroupedDeliveryAddresses",
    components: {
        DialogBox,
        ValidationProvider,
        ValidationObserver,
        AddressFields
    }
})
export default class extends AppProps {
    name: string | undefined = "";
    dialogVisible: boolean = false;
    dialogMessage: string = "";
    disabled: boolean = false;
    showBanner: boolean = true;
    groupedAddress: Array<CountryGroupedOrderAddressListViewModel> = [];
    confirmText: string = "Ok";
    cancelVisible: boolean = true;

    loading = false;
    dirtyTimer: any = null

    created() {
        this.loadInitialData();

        extend("required", {
            validate(value) {
                return {
                    required: true,
                    valid: ["", null, undefined].indexOf(value) === -1,
                };
            },
            computesRequired: true,
            message: "The {_field_} field is required.",
        });

        configure({
            classes: {
                failed: "validation-error",
                invalid: "validation-error",
                required: "validation-error",
            },
        });
    }

    clearDirtyClasses() {
        clearTimeout(this.dirtyTimer);
        this.dirtyTimer = setTimeout(() => {
            let dirty = document.querySelectorAll(".dirty");

            if (dirty.length) {
                for (let item of dirty) {
                    item.classList.remove("dirty");
                }
                let clear = document.querySelectorAll(".dirty");
            }
        }, 500);
    }

    beforeBack() {
        let isDirty = document.querySelectorAll(".dirty");
        this.confirmText = "Yes";

        if (isDirty.length > 0) {
            this.dialogMessage =
            "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
            this.dialogVisible = true;
        } else {
            this.handleConfirm();
        }
    }

    beforeSave() {
        this.dialogMessage =
            "<span>Are you sure you want to save all changes?</span>";
        this.confirmText = "Save";
        this.dialogVisible = true;
    }

    handleConfirm() {
        if (this.confirmText === "Yes") {
            this.$router.back();
        } else {
            this.submitForm();
        }
    }

    getGroup(countryCode: string) {
        return this.groupedAddress.find(group => {
            return group.countryCode == countryCode;
        });
    }

    async submitGroupedAddress(address: CountryGroupedOrderAddressListViewModel) {
        await AdminCountryGroupedOrderAddresses.adminCountryGroupedOrderAddressesCountryCodePut(address.countryCode, address)
            .then((res) => {
                return true;
            })
            .catch((error) => {
                error.response.data.errors.map((e: any) => {
                    this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage});
                    return e.friendlyMessage;
                });
                this.dialogVisible = false;
            });
    }

    async submitForm() {
        this.loading = true;

        const usAddress = this.getGroup('US');
        const gbAddress = this.getGroup('GB');

        if (usAddress) {
            await this.submitGroupedAddress(usAddress);
        }
        if (gbAddress) {
            await this.submitGroupedAddress(gbAddress);
        }

        setTimeout(() => {
            this.$message.success("Saved!");
            this.loading = false;
            this.dialogVisible = false;
            this.loadInitialData();
        }, 500);
    }

    async loadInitialData() {
        this.loading = true;

        await AdminCountryGroupedOrderAddresses.adminCountryGroupedOrderAddressesGet(1, 10)
            .then((res) => {
                if (res.data.succeeded) {
                    const results = res.data.resultData as CountryGroupedOrderAddressListViewModelPaginatedList;
                    this.groupedAddress = results.items as Array<CountryGroupedOrderAddressListViewModel>;
                }
            })
            .catch((error) => {
                error.response.data.errors.map((e: any) => {
                    this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage});
                    return e.friendlyMessage;
                })
            });

        this.loading = false;
    }

    beforeDestroy() {
        clearTimeout(this.dirtyTimer);
    }
}
