/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'enquire': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path d="M124,0H4A4,4,0,0,0,0,4V92.08a4,4,0,0,0,4,4H73.88l30.28,31.06a2.82,2.82,0,0,0,4.84-2V96.08h15a4,4,0,0,0,4-4V4A4,4,0,0,0,124,0ZM75,80.17c-5,3.12-10.48,5-14.62,5-2.76,0-4.82-.8-6.11-2.38-1.54-1.87-1.93-4.8-1.15-8.67l5.23-28.77-6.12-3.85a1,1,0,0,1-.46-.85v-1a1,1,0,0,1,.73-1L71,33.49a1.24,1.24,0,0,1,.27,0,1,1,0,0,1,.6.2l.41.3a1,1,0,0,1,.38,1L65.81,73.12c-.32,1.68-.28,2.56.12,3,.9,1.11,4.56,1.34,8.38,1.43a1,1,0,0,1,.94.72l.21.72A1,1,0,0,1,75,80.17Zm-7.32-52c-3.74,0-6.45-2.93-6.45-7,0-4.95,3.5-10.26,8.72-10.26,3.73,0,6.24,2.8,6.24,7C76.21,22.85,72.8,28.15,67.7,28.15Z"/>'
  }
})




