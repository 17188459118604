




















































































































































































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicPhoneNumberField from "@/components/form-items/BasicPhoneNumberField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import {
  ValidationProvider, extend, configure
} from "vee-validate";
import { DeliveryType, OrderCustomerContactViewModel, UserAdminListViewModel } from "@/api-client";
import { PropType } from "vue";

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    users: {
      type: Array as PropType<UserAdminListViewModel[]>,
      default: () => [],
    }
  }
});

@Component({
  name: "CustomerTab",
  components: {  ValidationProvider, BasicInputField, BasicSwitchField, BasicDateField, BasicPhoneNumberField },
})
export default class extends AppProps {
  deliveryType = false;
  userPhoneNumber = {
    code: '',
    number: ''
  };
  primaryPhoneNumber = {
    code: '',
    number: ''
  };
  additionalPhoneNumbers:Array<Object> = [];
  debounceAdditional:any = null;
  debouncePrimary:any = null;
  debounceUser:any = null;
  changeUserAccountActive = false;
  newUserAccountId: null | string = null;

  get userAccount() {
    const user = this.users.find((user: UserAdminListViewModel) => this.order.aspNetUserId === user.id)
    return user ? `${user.displayName} - ${user.email}` : 'Guest User'
  }

  created() {   
    this.$watch('order.primaryCustomerContact', () => {
      this.setDetails()    
    })

    this.$watch('order.user', () => {
      this.setDetails()    
      this.changeUserAccountActive = false;
      this.newUserAccountId = null;
      this.$emit('updateUserAccount', null);
    })

    this.$watch('primaryPhoneNumber', function handler(newValue) {
      if(this.order.primaryCustomerContact) {
        clearTimeout(this.debouncePrimary)
        this.debouncePrimary = setTimeout(() => {
          this.order.primaryCustomerContact.phoneNumber = `${newValue.code}-${newValue.number}`;
        }, 400)
      }
      },
      {deep: true}
    )

    this.$watch('additionalPhoneNumbers', function handler(newValue) {
      if(this.order.primaryCustomerContact) {
        clearTimeout(this.debounceAdditional)
        this.debounceAdditional = setTimeout(() => {
        newValue.forEach((contact:any, index:any) => {
          this.order.additionalCustomerContacts[index].phoneNumber = `${contact.code}-${contact.number}`;
        });
        }, 400)
      }
      
      },
      {deep: true}
      
    )

    extend("userPhone", () => {
      if (this.userPhoneNumber.number) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("primaryPhone", () => {
      if (this.primaryPhoneNumber.number) {
        return true;
      }
      return "The {_field_} field is required.";
    });

    extend("additionalPhone", {
      validate(value) {
        return {
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  setDetails() {
    if(this.order) {
      if(this.order.user?.phoneNumber) {
        let array = this.order.user.phoneNumber.split("-");
        if (array.length >= 2) {
          this.userPhoneNumber.code = array[0];
          array.shift()
          this.userPhoneNumber.number = array.join('-');
        }
      } else {
        this.userPhoneNumber = {
          code: '',
          number: ''
        };
      }

      if(this.order.primaryCustomerContact?.phoneNumber) {
        let array = this.order.primaryCustomerContact.phoneNumber.split("-");
        if (array.length >= 2) {
          this.primaryPhoneNumber.code = array[0];
          array.shift()
          this.primaryPhoneNumber.number = array.join('-');
        }
      } else {
        this.primaryPhoneNumber = {
          code: '',
          number: ''
        };
      }

      if(this.order.additionalCustomerContacts?.length) {
        this.additionalPhoneNumbers = [];
        this.order.additionalCustomerContacts.forEach((contact:OrderCustomerContactViewModel) => {
          let array = contact.phoneNumber.split("-");
          if (array.length >= 2) {
            let arrCode = array[0];
            array.shift()
            let arrNum = array.join('-');
            
            this.additionalPhoneNumbers.push(
              {
                code: arrCode,
                number: arrNum
              }
            )
          } else {
            this.additionalPhoneNumbers.push(
              {
                code: '',
                number: ''
              }
            )
          }
        });
      } else {
        this.additionalPhoneNumbers = [];
      }
    }
  }

  addContact() {
    this.order.additionalCustomerContacts.push({
      name: "",
      email: "",
      phoneNumber: ""
    })
    this.additionalPhoneNumbers.push(
      {
        code: '+44',
        number: ''
      }
    )
  }

  removeContact(index:any) {
    this.order.additionalCustomerContacts.splice(index, 1);
    this.additionalPhoneNumbers.splice(index, 1);
  }

  beforeDestroy() {
    clearTimeout(this.debounceAdditional)
    clearTimeout(this.debouncePrimary)
  }
}
