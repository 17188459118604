





























































































































































import {
  AdminLayerFabricsGetRequest,
  ConstructionType,
  FabricAdminListViewModel,
  FabricAdminViewModel,
  ProductAdminListViewModel,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { sortAscending } from "@/utils/sortAscending";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import {
  AdminCurrencies,
  AdminFabrics,
  AdminLayerFabrics,
  AdminProducts,
  AdminRoles,
  AdminUsers,
} from "@/network/api";

const AppProps = Vue.extend({
  props: {
    id: { default: "" },
  },
});

@Component({
  name: "newLayerFabric",
  components: {
    BasicSelectorField,
    BasicSwitchField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
    BasicInputField,
    BasicInputNumberField,
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      vm.fromPath = from.name;
    });
  },
})
export default class extends AppProps {
  name: string | undefined = "";
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  fabrics: Array<FabricAdminViewModel> = [];
  products: Array<ProductAdminListViewModel> = [];
  fabricColours: Array<FabricAdminListViewModel> = [];
  layerOptions: Array<FabricAdminListViewModel> = [];
  confirmText: string = "Ok";
  cancelVisible: boolean = true;
  constructionTypes = [
    {
      id: ConstructionType.CutAndSew,
      name: "Cut and Sew",
    },
    {
      id: ConstructionType.Sublimation,
      name: "Sublimation",
    },
  ];
  newLayerFabric: AdminLayerFabricsGetRequest = {
    layerId: "",
    fabricId: "",
    colourGroupIds: [],
    constructionTypeId: ConstructionType.CutAndSew,
    price: 0,
    customPatternPrice: 0,
    productsIds: [],
  };
  layerIds: string[] = [];
  loading = false;
  dirtyTimer: any = null;
  fromPath = "";
  responses: { success: string[]; fail: string[] } = {
    success: [],
    fail: [],
  };
  productsIdsClone: string[] = []

  get sortedFabrics() {
    return sortAscending(this.fabrics, true, "code");
  }

  get sortedProducts() {
    return sortAscending(this.products, true, "code");
  }

  get sortedlayerOptions() {
    return sortAscending(this.layerOptions, true, "code");
  }

  get sortedFabricColours() {
    return sortAscending(this.fabricColours, true, "code");
  }

  async handleProductChange(visible: boolean) {
    if(visible) {
      this.productsIdsClone = this.newLayerFabric.productsIds;
    } else {
      if(JSON.stringify(this.productsIdsClone) !== JSON.stringify(this.newLayerFabric.productsIds)) {
        await this.loadLayerOptions();
      }
      this.productsIdsClone = [];
    }
  }

  created() {
    this.loadInitialData().then(() => {
      this.clearDirtyClasses();
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });

    this.$watch("newLayerFabric.fabricId", async () => {
      await this.loadLayerOptions();
      await this.loadColoursForFabric()
    });
  }

  clearDirtyClasses() {
    clearTimeout(this.dirtyTimer);
    this.dirtyTimer = setTimeout(() => {
      let dirty = document.querySelectorAll(".dirty");

      console.log("dirty", dirty);

      if (dirty.length) {
        for (let item of dirty) {
          item.classList.remove("dirty");
        }
        let clear = document.querySelectorAll(".dirty");
        console.log("cleared?", clear);
      }
    }, 500);
  }

  beforeSave() {
    this.dialogMessage =
      "<span>Are you sure you want to save all changes?</span>";
    this.confirmText = "Save";
    this.dialogVisible = true;
  }

  async handleConfirm() {
    await this.submitForm();
  }

  async submitForm() {
    let sucessful: string[] = [];
    let failed: string[] = [];
    this.loading = true;
    if (this.newLayerFabric.fabricId && this.layerIds.length) {
      await Promise.allSettled(
        this.layerIds.map((item: string) => {
          return new Promise<string>(async (resolve, reject) => {
            const payload = { ...this.newLayerFabric };
            payload.layerId = item;
            await AdminLayerFabrics.adminLayerFabricsPost(payload)
              .then((res) => {
                if (res.data.succeeded) {
                  sucessful.push(item);
                  this.loading = false;
                  this.dialogVisible = false;
                  resolve(item)
                }
              })
              .catch((error) => {
                failed.push(item);
                this.loading = false;
                this.dialogVisible = false;
                reject(item)
              });
          });
        })
      ).then(() => {
        this.handleResponses(sucessful, failed).then(async () => {
          this.layerIds = [];
          await this.loadLayerOptions();
        });
      });
    }
  }

  async handleResponses(successful: string[], failed: string[]) {
    this.loading = true;
    this.responses = {
      success: [],
      fail: [],
    };

    successful.map((layerId) => {
      const layer = this.layerOptions.find((item) => item.id == layerId);
      if (layer) {
        this.responses.success.push(`${layer.code} - ${layer.name}`);
        console.log("success push, ", `${layer.code} - ${layer.name}`);
      }
    });

    failed.map((layerId) => {
      const layer = this.layerOptions.find((item) => item.id == layerId);
      if (layer) {
        this.responses.fail.push(`${layer.code} - ${layer.name}`);
        console.log("fail push, ", `${layer.code} - ${layer.name}`);
      }
    });
    this.loading = false;
  }

  async loadFabrics() {
    this.loading = true;
    await AdminFabrics.adminFabricsGet(1, 9999999, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.fabrics = res.data.resultData
            ?.items as Array<FabricAdminViewModel>;
        }
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message.error(e.friendlyMessage);
          return e.friendlyMessage;
        });
      });
    this.loading = false;
  }

  async loadProducts(refresh = false) {
    this.loading = true;
    await AdminProducts.adminProductsGet(1, 99999999)
      .then((res) => {
        this.products = res.data.resultData!.items;
        if (refresh) {
          this.$message.success("Section Refreshed.");
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({
            showClose: true,
            type: "error",
            duration: 0,
            message: e.friendlyMessage,
          });
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadColoursForFabric(refresh = false) {
    this.loading = true;
    this.newLayerFabric.colourGroupIds = [];
    AdminLayerFabrics.adminLayerFabricsGetColourGroupsForFabricGet(this.newLayerFabric.fabricId)
    .then((res) => {
      if (res.data.succeeded) {
        this.fabricColours = res.data.resultData as Array<FabricAdminListViewModel>;
        if (refresh) {
          this.$message.success("Section Refreshed.");
        }
        this.loading = false;
      }
    })
    .catch((error) => {
      this.loading = false;
      this.dialogVisible = false;
      error.response.data.errors.map((e: any) => {
        this.$message({
          showClose: true,
          type: "error",
          duration: 0,
          message: e.friendlyMessage,
        });
        return e.friendlyMessage;
      });
    });
  }

  async loadLayerOptions(layerFabricId: string | undefined = undefined) {
    this.layerIds = [];
    if (this.newLayerFabric.fabricId) {
      this.loading = true;
      debugger
      await AdminLayerFabrics.adminLayerFabricsGetLayersForFabricGet(
        this.newLayerFabric.fabricId,
        this.newLayerFabric.productsIds,
        layerFabricId
      )
        .then((res) => {
          if (res.data.succeeded) {
            console.log("get fabs for layers", layerFabricId, res);
            this.layerOptions = res.data
              .resultData as Array<FabricAdminListViewModel>;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          error.response.data.errors.map((e: any) => {
            this.$message({
              showClose: true,
              type: "error",
              duration: 0,
              message: e.friendlyMessage,
            });
            return e.friendlyMessage;
          });
        });
    }
  }

  async loadInitialData() {
    await this.loadFabrics();
    await this.loadProducts();
  }

  beforeDestroy() {
    clearTimeout(this.dirtyTimer);
  }
}
