


































































import {
  EnquiryType,
  UserAdminListViewModel,
} from "@/api-client";
import OrderTaskList from "@/components/orders/activity-tasks/OrderTaskList.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import { Component, Vue } from "vue-property-decorator";
import { AdminUsers } from "@/network/api";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
    enquiryStatuses: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});

@Component({
  name: "OrderTasks",
  components: { BasicSelectorField, OrderTaskList },
})
export default class extends AppProps {
  openList = false;
  openMessages = false;
  admins: Array<UserAdminListViewModel> = []
  taskId = ''
  enquiryTypes: Array<{ name: string, id: EnquiryType }> = [
    { id: EnquiryType.NotYetAssigned, name: 'Not Yet Assigned' },
    { id: EnquiryType.Sales, name: 'Sales' },
    { id: EnquiryType.CustomerService, name: 'Customer Service' },
    { id: EnquiryType.Store, name: 'Store' },
    { id: EnquiryType.Other, name: 'Other' }
  ]
  resetSection = false

  get roles() {
    return store.getters["user_new/role"] as Array<string>;
  }

  created() {
    this.loadInitialData()

    if (this.$route.query.taskId) {
      this.openList = true
    }

    this.$watch('enquiry.enquiryTypeId', ()=> {
      this.$emit('updateStatusArray', this.enquiry.enquiryTypeId)
    })
  }

  setTaskId() {
    if(this.taskId) {
      this.$router.push({
        path: this.$route.fullPath,
        query: { taskId: this.taskId },
      });
    }
  }

  clearTaskIdQuery() {
    if(this.$route.query.taskId) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          taskId: undefined
        }
      })
    }
    this.taskId = ''
    this.resetSection = true
  }

  async loadInitialData() {
    if(this.roles.includes('Sales') || this.roles.includes('Sales Admin') || this.roles.includes('Operations') || this.roles.includes('Admin')) {
      await AdminUsers.adminUsersGetOrderManagersGet(1, 999999, undefined, undefined)
      .then((res) => {
        if (res.data.succeeded) {
          this.admins = res.data.resultData!
            .items as Array<UserAdminListViewModel>;
        }
      })
      .catch((error) => {
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      }); 
    }
  }
}
