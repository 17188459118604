import store from "@/store";
import axios, { AxiosStatic } from "axios";

// login call will use this
// import NodeRSA from 'node-rsa';
// const key = new NodeRSA(process.env.VUE_APP_PUBLIC_KEY!, 'pkcs1-public', {encryptionScheme:"pkcs1"});
// var encryptedPassword = key.encrypt(password, 'base64');

function isValid(date:any) {
  return date && new Date().getTime() < new Date(date).getTime()
}

export function ConfigureAxios() {

  axios.interceptors.request.use(
    (req) => {
      const url = new URL(req.url!);

      if (url.pathname.includes("/Identity/Authentication")) return req;

      let tkn = store.getters["auth/token"];

      if(tkn !== null){
        if(!isValid(tkn.refreshTokenExpiration)) {
          store.dispatch("auth/logout") //clear login data (token, profile etc) without actually logging out
          return req
          //tkn = null
        }
        if(!isValid(tkn.expiration)) {
          // debugger
          return store.dispatch("auth/refresh").then((res) => {
            tkn = store.getters["auth/token"];
            req.headers!.Authorization = `Bearer ${tkn.encodedToken}`;
            return req;
          });
        }
        if(isValid(tkn.expiration) && tkn.encodedToken){
          req.headers!.Authorization = `Bearer ${tkn.encodedToken}`;
        }
      }
      return req;
    },
    async (error) => {
      console.log("ded");
      return Promise.reject(error);
    }
  );
  //TODO https://dev.to/franciscomendes10866/how-to-use-axios-interceptors-b7d



  axios.interceptors.response.use(
    (response) => {
      // console.info(response);
      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : null;
      if (status === 401) {
        return store.dispatch("auth/refresh").then((tkn) => {
          // console.log("Here");
          return axios.request(error.config);
        });
      }
      return Promise.reject(error);
    }
  );
}
