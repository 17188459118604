



































































































import { Component, Vue } from "vue-property-decorator";
import {
  CourierAdminListViewModel,
  CourierAdminListViewModelPaginatedList,
} from "@/api-client";
import ElTableDraggable from "@/components/ElTableDraggable.vue";
import Pagination from "@/components/Pagination.vue";
import DialogBox from '@/components/DialogBox.vue'
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { AdminCouriers } from "@/network/api";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: "Couriers",
  components: { ElTableDraggable, Pagination, DialogBox,ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: "success",
        true: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  couriers: CourierAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1,
  };
  courierItems: Array<CourierAdminListViewModel> = [];
  search: string = '';
  pageNumber = 1;
  itemPerPage = 10;
  debounce: any = null;
  selectedStatusFilter = "";
  showAllText: Array<string> = [];
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this courier?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedItem: CourierAdminListViewModel = {
    id: '',
    name: '',
    trackingUrlFormat: ''
  };
  loading = false;

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch("search", () => {
    //   this.pageNumber = 1;
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  confirmDelete(item: any) {
    this.selectedItem = item;
    this.dialogVisible = true;
  }

  clearAllFilters() {
    this.pageNumber = 1
    this.search = '';
    this.loadInitialData()
  }


  deleteItem() {
    if (this.selectedItem.id) {
      this.loading = true
      AdminCouriers.adminCouriersIdDelete(this.selectedItem.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          this.loading = false
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
              this.$message.error(e.friendlyMessage)
              return e.friendlyMessage
            }
          );
          this.dialogVisible = false;
        })
    }
    this.dialogVisible = false
    this.selectedItem = {
      id: '',
      name: '',
      trackingUrlFormat: ''
    }
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  async loadInitialData() {
    this.loading = true
    await AdminCouriers.adminCouriersGet(
      this.pageNumber,
      this.itemPerPage,
      this.search || undefined
    )
      .then((res) => {
        if(res.data.succeeded) {
          this.couriers = res.data.resultData as CourierAdminListViewModelPaginatedList;
          this.courierItems = this.couriers.items as Array<CourierAdminListViewModel>;
          console.log("courier items", this.courierItems);
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
      });
  }

  beforeUnmount() {
    clearTimeout(this.debounce);
  }
}
