










































































import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

const AppProps = Vue.extend({
  props: {
    label: { default: "" },
    formItemStyle: { default: "" },
    model: { default: '' },
    placeholder: { default: "" },
    span: { default: 12 },
    multiple: { default: false },
    name: { default: "" },
    rules: { default: "" },
    mode: { default: "eager" },
    array: { default: [] },
    initialOption: { default: false },
    clearable: { default: false },
    uniqueLabel: { default: "" },
    customLabel: { default: "" },
    groupLabel: { default: false },
    disabled: {default: false},
    valueKey: {default: 'id'},
    clickable: {default: false},
    mainRoute: {default: ''},
    selectValueKey: {default: undefined},
    hasGroup: {
      type: Object,
        default() {
            return { show: false, prop: '' }
        }
    }
  },
})

@Component({
  name: "BasicSelectorField",
  components: { ValidationProvider, ValidationObserver },
})
export default class extends AppProps {
  selectedTags:Array<any> = []
  showTags = false

  get labelNoWhitespace() {
    if(this.label) {
      return this.label.replace(/\s/g, "");
    }
    return ''
  }

  created() {
    this.$watch('model', () => {
      if(this.model && this.model.length && this.clickable) {
        this.selectedTags = []
        let modelArray = [...this.model] as Array<string>
        modelArray.map((item:any) => {
          let match = this.array.find((x:any) => {return x.id === item})
          if(match) {
            this.selectedTags.push(match)
          }
        })
      } else {
        this.selectedTags = []
      }    
    })
  }

  openCreate(isGroup:boolean) {
    let routeName = '';
    
    isGroup ? routeName = `New${this.mainRoute}Group` :  routeName = `New${this.mainRoute}`;

    let routeData = this.$router.resolve({name: routeName});
    
    window.open(routeData.href, '_blank');
  }

  handleTagClick(e:any, item:any) {
    let routeName = '';
    let itemId = ''

    if(this.hasGroup.show) {
      if(item.isSystemManaged) {
        routeName = `Edit${this.mainRoute}`
        itemId = item[this.hasGroup.prop][0]
      } else {
        routeName = `Edit${this.mainRoute}Group`;
        itemId = item.id
      }
    } else {
      routeName = `Edit${this.mainRoute}`;
      itemId = item.id
    }    

    let routeData = this.$router.resolve({name: routeName, params: {id: itemId}});

    window.open(routeData.href, '_blank');
  }

  refresh() {
    this.$emit('refresh', true)
  }
}
