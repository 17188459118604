






































































































import { Component, Vue } from "vue-property-decorator";
import { AdminProductTags } from "@/network/api";
import { ProductTagViewModel } from "@/api-client";
import NestedElTableDraggable from "@/components/NestedElTableDraggable.vue";
import DialogBox from "@/components/DialogBox.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: "Tags",
  components: { NestedElTableDraggable, DialogBox },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        published: "success",
        draft: "gray",
        deleted: "danger",
      };
      return statusMap[status];
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString();
    },
  },
})
export default class extends Vue {
  searchVal = ''
  productTags: Array<ProductTagViewModel> = [];
  row: any;
  dialogVisible = false;
  selectedTagId = "";
  dialogMessage =
    "<span><stong>Are you sure you want to delete this product tag?</strong></span>";
  confirmText = "Yes";
  cancelVisible = true;
  search: string | undefined = this.$route.params.search || "";
  refresh = 0;
  debounce: any = null;
  loading = false

  created() {
    if(this.$route.query.search) {
      this.searchVal = this.$route.query.search as string
    }

    let toQuery = {
      search: this.search,
    }
    this.setQueryWatch(toQuery)
    this.loadInitialData();

    // this.$watch("search", () => {
    //   clearTimeout(this.debounce);
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData().then(() => {
    //       document.getElementById("search")!.focus();
    //     });
    //   }, 400);
    // });
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch(e:any) {
    if(e) {
      this.searchVal = e.target.value
      this.search = e.target.value
    } else {
      this.searchVal = ''
      this.search = ''
    }
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(" ").join("-");
    return parsedName;
  }

  clearAllFilters() {
    this.search = '';
    this.searchVal = ''
    this.loadInitialData()
  }

  get filteredTag() {
    const childTags: Array<any> = [];
    this.productTags.forEach((parent) => {
      parent.childProductTags.forEach((tag) => {
        if (this.search && (tag.name.toLowerCase().includes(this.search.toLowerCase()) &&
          !childTags.includes(parent.name.toLowerCase())
          )
        ) {
          childTags.push(parent.name.toLowerCase());
        }
      });
    });

    return this.productTags.filter(
      (data) =>
        !this.search ||
        data.name!.toLowerCase().includes(this.search.toLowerCase()) ||
        childTags.includes(data.name.toLowerCase())
    );
  }

  handleReorder(data: any) {
    this.loading = true
    AdminProductTags.adminProductTagsIdPatch(
      data.itemId,
      data.previousItemId,
      data.nextItemId
    )
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.loadInitialData();
          this.refresh += 1;
        }
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });
  }

  handleOpenRows(level: any) {
    let rows = document.querySelectorAll(`.el-table__row--level-${level + 1}`);

    if (rows.length > 1) {
      rows.forEach((element: any) => {
        if (element.style.display !== "none") {
          element.style.display = "none";
        }
      });
      this.handleOpenRows(level + 1);
    }
  }

  onMove(evt: any, originEvt: any, { dragged, related }: any) {
    // if we want to limit what levels can be dragged we can do it here

    // checking dragged.children.length == 0 means we are on a leaf node on the 🌳

    // if (dragged.level !== 2) {
    //     return false;
    // }
    this.handleOpenRows(dragged.level);

    if (dragged.parent === related.parent && dragged.id !== related.id) {
      console.log("Move Allowed", dragged.level, related.level);
      console.log("dragged", dragged);
      console.log("related", related);
      return true;
    }
    return false;
  }

  async loadInitialData() {
    this.loading = true
    await AdminProductTags.adminProductTagsGet()
      .then((res) => {
        if(res.data.succeeded) {
          this.productTags = res.data.resultData as Array<ProductTagViewModel>;
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });     
      });
  }

  confirmDelete(id: string) {
    this.dialogVisible = true;
    this.selectedTagId = id;
  }

  deleteTag() {
    this.loading = true
    AdminProductTags.adminProductTagsIdDelete(this.selectedTagId)
      .then((res) => {
        if(res.data.succeeded) {
          console.log(res);
        this.$message.info("Item deleted.");
        this.loadInitialData();
        }
        this.loading = false
      })
      .catch((error) => {
        this.loading = false
        this.dialogVisible = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        
      });

    this.dialogVisible = false;
    this.selectedTagId = "";
  }
}
