



























































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
} from "vee-validate";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import store from "@/store";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import { FactoryAdminProductionNotesModel } from "@/api-client";
import { factoryNoteTemplates, type FactoryNoteTemplate } from "@/data/factoryNoteTemplates"

const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
  }
});

@Component({
  name: "FactoriesTab",
  components: {  ValidationProvider, BasicInputField, BasicSwitchField },
})
export default class extends AppProps {
  mavenToolbar = {
    bold: true,
    italic: true,
    header: false,
    underline: false,
    strikethrough: false,
    mark: false,
    superscript: false,
    subscript: false,
    quote: false,
    ol: false,
    ul: false,
    link: true,
    imagelink: true,
    code: false,
    table: false,
    fullscreen: true,
    readmodel: true,
    htmlcode: false,
    help: false,
    undo: true,
    redo: true,
    trash: true,
    save: false,
    navigation: false,
    alignleft: false,
    aligncenter: false,
    alignright: false,
    subfield: false,
    preview: true,
  };
  selectedTemplateId: number | null = null

  get roles() {
      return store.getters["user_new/role"] as Array<string>;
  }

  get templates() {
    return factoryNoteTemplates
  }

  handleTemplateSelect(factory: FactoryAdminProductionNotesModel) {
    factory.productionNotes = factoryNoteTemplates.find(item => item.id === this.selectedTemplateId)?.content || ""
    this.selectedTemplateId = null
  }
}
