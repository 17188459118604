






























import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";

@Component({
  name: "BasicInputField",
  components: { ValidationProvider, ValidationObserver },
  props: {
    label: { default: '' },
    model: { default: '' },
    placeholder: { default: '' },
    span: { default: 12 },
    name: { default: '' },
    rules: { default: '' },
    mode: { default: 'eager' },
    type: { default: '' },
    size: { default: '' },
    autosize: { default: true },
    maxlength: { default: null },
    isDisabled: { default: false },
    id: { default: '' },
  }
})
export default class extends Vue {}
