

































import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

@Component({
  name: "BasicDateField",
  components: { ValidationProvider, ValidationObserver },
  props: {
    label: { default: '' },
    model: { default: '' },
    placeholder: { default: 'Select date' },
    span: { default: 12 },
    name: { default: '' },
    rules: { default: '' },
    mode: { default: 'eager' },
    type: { default: '' },
    size: { default: '' },
    maxlength: { default: null },
    isDisabled: { default: false },
    showEST: { default: true }
  }
})
export default class extends Vue {
  get estEdtMidnight() {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const targetTimezone = '-05:00'; // To consider daylight savings time, use "America/New_York" instead
    // Set midnight in the target timezone
    const midnightInEST = dayjs.tz('2024-08-26 00:00:00', targetTimezone);
    // Convert to the current timezone
    const localTime = midnightInEST.local().format('HH:mm:ss');
    return localTime;
  }
}
