
















































import { Component, Vue } from "vue-property-decorator";

const AppProps = Vue.extend({
  props: {
    totalPages: { default: 1 },
    pageNumber: { default: 1 },
    id: {default: ''}
  },
})
@Component({
  name: "SendToPage",
})
export default class extends AppProps {
  handleSubmit(e:any) {
    let handle = document.getElementById('title');   
    let selectedPage = e.target[0].value;
    let errorMessage = this.checkForErrors(selectedPage);
    let determinePage = 0;

    if(errorMessage) {

      this.$message.error(errorMessage);

    } else {

      if(selectedPage < this.pageNumber) {
        determinePage = ((selectedPage - 1) > 1) ? selectedPage - 1 : 1    
      } else {
        determinePage = selectedPage;
      }
      handle!.click()
      this.$emit('send', this.id, selectedPage, determinePage)    
    }
  }

  checkForErrors(page: any) {
    if(page == this.pageNumber) {
      return 'You cannot move an item to the same page.'
    } else if ((page > this.totalPages) || (page < 1)) {
      return 'You cannot move an item to a page that does not exist.'
    }

    return null;
  }
}
