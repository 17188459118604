var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container new-fabric-container"},[_c('DialogBox',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"cancelVisible":_vm.cancelVisible,"confirmText":_vm.confirmText,"dialogVisible":_vm.dialogVisible,"message":_vm.dialogMessage},on:{"confirm":_vm.handleConfirm,"close":function($event){_vm.dialogVisible = false}}}),_c('h1',[_c('span',[_vm._v(_vm._s(_vm.name ? "Edit" : "New"))]),_vm._v(" Fabric "),(_vm.name)?_c('span',[_vm._v("- "+_vm._s(_vm.name))]):_vm._e()]),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",attrs:{"model":_vm.newFabric,"label-position":"top"}},[_c('el-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"type":"flex","gutter":50}},[_c('BasicInputField',{attrs:{"rules":'required',"name":'name',"label":'Name*',"model":_vm.newFabric.name},on:{"update:model":function($event){return _vm.$set(_vm.newFabric, "name", $event)}}}),_c('BasicInputField',{attrs:{"rules":'required',"name":'code',"label":'Code*',"model":_vm.newFabric.code},on:{"update:model":function($event){return _vm.$set(_vm.newFabric, "code", $event)}}})],1),_c('el-row',{attrs:{"type":"flex","gutter":50}},[_c('BasicInputField',{attrs:{"span":24,"label":'Description*',"type":'textarea',"model":_vm.newFabric.description,"autosize":{ minRows: 3 }},on:{"update:model":function($event){return _vm.$set(_vm.newFabric, "description", $event)}}})],1),_c('el-row',{style:(_vm.id && 'padding-bottom: 30px;'),attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{staticStyle:{"padding-top":"10px"},attrs:{"span":14}},[_c('BasicTooltipHeading',{attrs:{"heading":'Fabric Colours'}})],1),(_vm.id)?_c('el-col',{attrs:{"span":4}},[_c('ItemsPerPage',{attrs:{"disabled":_vm.disable,"uniqueMinItem":5,"totalCount":_vm.fabricColours.totalCount,"label":'Items per page',"model":_vm.fabricColourItemPerPage},on:{"update:model":function($event){_vm.fabricColourItemPerPage=$event},"handleChange":function (value) {
          _vm.fabricColourItemPerPage = value;
          _vm.loadFabricColours();
        },"reset":function($event){_vm.fabricColourPageNumber = 1}}})],1):_vm._e()],1),(_vm.id)?_c('el-row',[_c('el-table',{attrs:{"data":_vm.fabricColourItems,"row-key":"id","default-expand-all":"","fit":"","stripe":"","highlight-current-row":"","cell-class-name":"fabric-cell"}},[_c('el-table-column',{attrs:{"label-class-name":"col-label","label":"Fabric Colours"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
        var $index = ref.$index;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"fabric colour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-select',{class:{ 'disabled-field hide-select-suffix': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":'small',"id":row.colourId,"filterable":"","placeholder":"Select a fabric colour"},on:{"visible-change":_vm.toggleOption},model:{value:(row.colourId),callback:function ($$v) {_vm.$set(row, "colourId", $$v)},expression:"row.colourId"}},[(_vm.fabricColourItems[$index].colourId && _vm.fabricColourItems[$index].colourName && !_vm.toggleOptionValue)?_c('el-option',{attrs:{"label":_vm.fabricColourItems[$index].colourName,"value":_vm.fabricColourItems[$index].colourId,"size":'small'}}):_vm._e(),_vm._l((_vm.fabricColourOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":((item.code) + " - " + (item.name)),"value":item.id,"size":'small'}})})],2),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"label-class-name":"col-label","label":"Reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('ValidationProvider',{attrs:{"mode":"eager","slim":"","name":"reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('el-input',{class:{ 'disabled-field': _vm.selectedId !== row.id },attrs:{"disabled":_vm.selectedId !== row.id,"size":"small","value":row.referenceCode},model:{value:(row.referenceCode),callback:function ($$v) {_vm.$set(row, "referenceCode", $$v)},expression:"row.referenceCode"}}),_c('span',{class:'validation-fabric-colours',staticStyle:{"display":"none"}},[_c('p',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"label-class-name":"buttons-label","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('el-button',{attrs:{"disabled":_vm.selectedId !== row.id && _vm.selectedId !== '',"plain":"","size":"mini","type":"primary"},on:{"click":function($event){return _vm.editField(row)}}},[_vm._v(_vm._s(_vm.selectedId !== row.id ? "Edit" : "Save"))]),_c('el-button',{attrs:{"disabled":_vm.selectedId !== row.id && _vm.selectedId !== '',"plain":"","size":"mini","type":"danger"},on:{"click":function($event){return _vm.confirmDelete(row)}}},[_vm._v(_vm._s(_vm.selectedId !== row.id ? "Delete" : "Cancel"))])]}}],null,true)})],1),_c('Pagination',{attrs:{"totalItems":_vm.fabricColours.totalCount,"showScrollToTop":_vm.fabricColourItemPerPage > 5 && _vm.fabricColours.totalCount > 5,"totalPages":_vm.fabricColours.totalPages,"pageSize":_vm.fabricColours.pageSize,"currentPage":_vm.fabricColourPageNumber},on:{"change":_vm.handlefabricColourPageNumberClick}}),_c('el-button',{staticClass:"primary-buttons new-tag",attrs:{"disabled":_vm.disable || (_vm.fabricColourOptions.length === 0 || ((_vm.fabricColourOptions.length === 1) && (_vm.selectedId !== '')))},on:{"click":_vm.addRow}},[_vm._v("New Fabric Colour")])],1):_c('div',[_c('p',{staticStyle:{"line-height":"1.5em"}},[_vm._v("Fabric colours can only be added to existing fabrics.")])])],1),_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.submitForm)}}},[_vm._v("Save Changes")]),_c('el-button',{staticClass:"primary-buttons",attrs:{"disabled":_vm.loading},on:{"click":_vm.beforeBack}},[_vm._v("Back")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }