export type MiscContentItem = {
  id: string,
  name: string,
  value: string,
  // More types can be added as we need them, if we need them
  // If more are added, EditMiscSiteContent.vue will possibly need to be updated to allow this
  type: "markup"
}

export let miscContentItems: MiscContentItem[] = [
  // !! These must first be created using Admin GeneralSettings POST (on QA & prod)
  // !! 'id' must be the GeneralSettings key
  // !! 'name' can be anything, its just for display in admin screens
  {
    id: 'shipping-and-returns',
    name: 'Shipping & Returns Page',
    value: '',
    type: 'markup'
  }
];