


























import { Component, Vue } from "vue-property-decorator";
import QuotesList from '@/components/enquiries/quotes/QuotesList.vue'
import NewQuote from '@/components/enquiries/quotes/NewQuote.vue'
import BasicTooltipHeading from "@/components/form-items/BasicTooltipHeading.vue";

const AppProps = Vue.extend({
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "QuotesTab",
  components: {
    QuotesList,
    NewQuote,
    BasicTooltipHeading
  }
})
export default class extends AppProps {
  currentScreen: 'list' | 'edit' = 'list'
  quoteId:string = ''

  get heading() {
    return (this.currentScreen === 'list') ? 'All Quotes' : 'New Quote'
  }

  editQuote(id: string) {
    this.quoteId = id;
    this.currentScreen = 'edit'
  }

  toList() {
    this.quoteId = '';
    this.currentScreen = 'list'
  }
}
