
















































import {
  TaskMessageAdminCreateModel,
  TaskMessageAdminListViewModel,
  TaskMessageAdminListViewModelPaginatedListApplicationResultResultData,
} from "@/api-client";
import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
} from "vee-validate";
import { AdminTaskMessages } from "@/network/api";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";

const AppProps = Vue.extend({
  props: {
    taskId: {
      type: String,
      default: "",
    },
    // thisUser: {
    //   type: String,
    //   default: "",
    // }
  },
});

@Component({
  name: "TaskMessages",
  components: {
    ValidationProvider,
    ValidationObserver,
    BasicInputField,
  },
})
export default class extends AppProps {
  slug?: string = this.$route.params.slug;
  id?: string = this.$route.params.id;
  pageNumber = 1;
  itemPerPage = 10;
  search: string = this.$route.params.search;
  name: string | undefined = "";
  disabled: boolean = false;
  showBanner: boolean = true;
  messagePages: TaskMessageAdminListViewModelPaginatedListApplicationResultResultData =
    {
      hasNextPage: false,
      hasPreviousPage: false,
      items: [],
      pageIndex: 1,
      totalCount: 1,
      totalPages: 1,
      pageSize: 1,
    };
  messages: Array<TaskMessageAdminListViewModel> = [];
  newMessage: TaskMessageAdminCreateModel = {
    taskId: this.taskId,
    text: "",
  };
  scrollCalled = false
  loading = false;
  emojis: Array<string> = [
    '🙂',
    '😃',
    '😎',
    '😂',
    '🙁',
    '👍',
    '👎',
    '👏',
    '⭐️',
  ]
  get thisUserId() {
    return store.getters['user_new/userId']
  }

  created() {
    this.messages = [];
    this.loadInitialData();

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
      this.$watch("loading", () => {
        this.scroll();
      })
    }

  addEmoji(emoji: string) {
    this.newMessage.text += emoji;
  }

  handledate(date: any) {
    if (date) {
      let d = new Date(date)

      let hours: string | number = this.leadingZero(d.getHours())
      let minutes: string | number = this.leadingZero(d.getMinutes())

      let time = `${hours}:${minutes}`
      return `${time}, ${formatDate(date)}`;
    }
    return "";
  }

  leadingZero(num: number) {
    if(num < 10) {
        return '0' + num
    }
    return num
  }

  determinePadding(index:any) {
    if(this.messages.length == 1) {
      return 'padding:15px;'
    } else if(index == 0) {
      return 'padding-bottom:15px;'
    } else if (index == (this.messages.length - 1)) {
      return 'padding-top:15px;'
    }
  }

  isScrolledIntoView(el: any) {
    let rect = el.getBoundingClientRect() as DOMRect;
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  scroll() {
    let allRefs = this.$refs as any;
    let scrolledTo = allRefs.top;

    if(scrolledTo && !this.scrollCalled) {
      !this.scrollCalled && (this.scrollCalled = true);

      allRefs.chatbox.onscroll = () => {
        if (scrolledTo && this.isScrolledIntoView(scrolledTo)) {
          this.loadMoreMessages();
        }
      };
    }
  }

  sendMessage() {
    if (this.taskId && this.newMessage.text) {
      this.loading = true;
      AdminTaskMessages.adminTaskMessagesPost(this.newMessage)
        .then((res) => {
          if (res.data.succeeded) {
            // this.$message.success("Saved!");
            this.newMessage = {
              taskId: this.taskId,
              text: "",
            };
            this.loading = false;
            this.messages = [];
            this.loadInitialData();
          }
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false;
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
    // if (this.courier.id) {
    //   console.log("before save", this.newMessage);
    //   this.loading = true;

    //   AdminCouriers.adminCouriersIdPut(
    //     this.courier.id,
    //     this.newMessage as CourierAdminUpdateModel
    //   )
    //     .then((res) => {
    //       if (res.data.succeeded) {
    //         this.loading = false;
    //         this.$message.success("Saved!");
    //         this.loadInitialData();
    //       }
    //     })
    //     .catch((error) => {
    //       error.response.data.errors.map((e: any) => {
    //         this.loading = false;
    //         this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
    //         return e.friendlyMessage;
    //       });
    //     });
    // } else {
    //   AdminCouriers.adminCouriersPost(
    //     this.newMessage as CourierAdminCreateModel
    //   )
    //     .then((res) => {
    //       if (res.data.succeeded) {
    //         this.$message.success("Saved!");
    //         this.loading = false;
    //         this.$router.push({ name: "Couriers" });
    //       }
    //     })
    //     .catch((error) => {
    //       error.response.data.errors.map((e: any) => {
    //         this.loading = false;
    //         this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
    //         return e.friendlyMessage;
    //       });
    //     });
    // }
  }

  async loadInitialData(pageNumber = 1) {
    if (this.taskId) {
      this.loading = true;
      await AdminTaskMessages.adminTaskMessagesGet(
        pageNumber,
        this.itemPerPage,
        this.taskId
      )
        .then((res) => {
          if (res.data.succeeded) {
            this.messagePages = res.data
              .resultData as TaskMessageAdminListViewModelPaginatedListApplicationResultResultData;
              //for lazy loading
            this.messagePages.items.forEach((item: any) => {
              this.messages.push(item);
            })
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          error.response.data.errors.map((e: any) => {
            this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
            return e.friendlyMessage;
          });
        });
    }
  }

  loadMoreMessages() {
    if ((this.loading === false) && (this.messagePages.hasNextPage === true)) {
      this.loading = true;
      this.pageNumber += 1;
      this.loadInitialData(this.pageNumber);
    }
  }

  beforeUnmount() {
    this.messages = [];
  }
}
