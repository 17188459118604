
















import { Component, Vue } from 'vue-property-decorator'
import BlogPostsList from './BlogPostsList.vue'
import BlogPostsCategoryList from './BlogPostsCategoryList.vue'

@Component({
  name: 'BlogPosts',
  components: { BlogPostsList, BlogPostsCategoryList }
})
export default class extends Vue {
  activeTab: any = "posts";

  created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "posts"
    }

    this.$watch("$route", () => {
      if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      this.activeTab = "posts"
    }
    });
  }

  handleTabClick(tab:any) {
    this.activeTab = tab.name;
    this.$router.replace({ name: "BlogPosts", query: { tab: this.activeTab } });
  }
}
