import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout/index.vue";
import EmptyLayout from "@/layout/emptyPage.vue";

Vue.use(Router);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  mode: "history", // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      //did not include if statement previously because it was causing the page to jump to the top
      if (to.path != from.path) {
        return { x: 0, y: 0 };
      }
      
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: 'Login',
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/auth/Login.vue"),
      meta: {
        title: "Login",
        hidden: true,
      },
    },
    // {
    //   path: "/debug",
    //   component: () => import(/* webpackChunkName: "debug" */ "@/views/DebugPage.vue"),
    //   meta: {
    //     title: "DEBUG",
    //     hidden: true,
    //   },
    // },
    {
      path: "/404",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
      meta: {
        title: "Page Not Found",
        hidden: true,
      },
    },
    {
      path: "/orderpack/approval/:id",
      props: true,
      name: "OrderPackApproval",
      component: () =>
        import(
          /* webpackChunkName: "table" */ "@/views/orders/OrderPackApproval.vue"
        ),
      meta: {
        title: "Order Pack Approval - CLIFTON Clothing",
        hidden: true,
      },
    },
    {
      path: "/orderpack/factory/:id",
      props: true,
      name: "OrderPackFactory",
      component: () =>
        import(
          /* webpackChunkName: "table" */ "@/views/orders/OrderPackFactory.vue"
        ),
      meta: {
        title: "Factory Order Pack - CLIFTON Clothing",
        hidden: true,
      },
    },
    {
      path: "/",
      component: Layout,
      redirect: "/dashboard",
      name: "Dashboard",
      meta: {
        title: "Dashboard"
      },
      children: [
        {
          path: "dashboard",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"
            ),
          meta: {
            title: "Dashboard",
            breadcrumb: false,
            icon: "dashboard-2",
          },
        },
      ],
    },
    {
      path: "/task-overview",
      component: Layout,
      redirect: "/task-overview",
      meta: {
        title: "Task Overview"
      },
      children: [
        {
          path: "/task-overview",
          name: "TaskOverview",
          component: () =>
            import(
              /* webpackChunkName: "task-overview" */ "@/views/task-overview/TaskOverview.vue"
            ),
          meta: {
            breadcrumb: false,
            title: "Task Overview",
            icon: "table",
          },
        },
      ],
    },
    {
      path: "/order-enquiry-search",
      component: Layout,
      redirect: "/order-enquiry-search",
      meta: {
        title: "Order & Enquiry Search",
        hidden: true,
      },
      children: [
        {
          path: "/order-enquiry-search",
          name: "OrderEnquirySearch",
          component: () =>
            import(
              /* webpackChunkName: "order-enquiry-search" */ "@/views/order-enquiry-search/OrderEnquirySearch.vue"
            ),
          meta: {
            breadcrumb: false,
            title: "Order & Enquiry Search",
            hidden: true,
          },
        },
      ],
    },

    // ENQUIRIES PARENT

    {
      path: "/enquiries",
      component: Layout,
      redirect: "/enquiries/all",
      meta: {
        title: "Enquiries",
        icon: "enquire",
      },
      children: [
        // enquiries

        {
          path: "/enquiries/all",
          name: "Enquiries",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/enquiries/Enquiries.vue"
            ),
          meta: {
            title: "Enquiries",
            icon: "enquire",
            breadcrumb: false,
          },
        },
        {
          path: "/enquiries/edit-enquiry/:id",
          props: true,
          name: "EditEnquiry",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/enquiries/EditEnquiry.vue"
            ),
          meta: {
            title: "Edit Enquiry",
            hidden: true,
          },
        }, 
        {
          path: "/enquiries/new-enquiry",
          props: true,
          name: "NewEnquiry",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/enquiries/NewEnquiry.vue"
            ),
          meta: {
            title: "New Enquiry",
            hidden: true,
          },
        },       
      ],
    },

    // ORDERS PARENT

    {
      path: "/orders",
      component: Layout,
      redirect: "/orders/all",
      meta: {
        title: "Orders",
        icon: "orders",
      },
      children: [
        // ORDERS

        {
          path: "/orders/all",
          name: "Orders",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/orders/Orders.vue"
            ),
          meta: {
            title: "Orders",
            icon: "orders",
            breadcrumb: false,
          },
        },
        {
          path: "/orders/edit-order/:id",
          props: true,
          name: "EditOrder",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/orders/EditOrder.vue"
            ),
          meta: {
            title: "Edit Order",
            hidden: true,
          },
        },
        {
          path: "/orders/factory/edit-order/:id",
          props: true,
          name: "EditFactoryOrder",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/orders/EditFactoryOrder.vue"
            ),
          meta: {
            title: "Edit Order",
            hidden: true,
          },
        },
        {
          path: "/orders/invoice/:id",
          props: true,
          name: "ViewInvoice",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/orders/ViewInvoice.vue"
            ),
          meta: {
            title: "View Invoice",
            hidden: true,
          },
        },
        {
          path: "/orderpack/:id",
          props: true,
          name: "Orderpack",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/orders/OrderPack.vue"
            ),
          meta: {
            title: "Order Pack - CLIFTON Clothing",
            hidden: true,
          },
        },
        
      ],
    },

    // CUSTOM / ONLINE STORES PARENT

    {
      path: "/online-stores",
      component: Layout,
      redirect: "/online-stores/all",
      meta: {
        title: "Online Stores",
        icon: "custom-store",
      },
      children: [
        // CUSTOM / ONLINE STORES

        {
          path: "/online-stores/all",
          name: "CustomStores",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/custom-stores/CustomStores.vue"
            ),
          meta: {
            title: "Online Stores",
            icon: "custom-store",
            breadcrumb: false,
          },
        },
        {
          path: "/online-stores/edit-online-store/:id",
          props: true,
          name: "EditCustomStore",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/custom-stores/EditCustomStore.vue"
            ),
          meta: {
            title: "Edit Online Store",
            hidden: true,
          },
        },      
      ],
    },

    // SITE CONTENT PARENT

    {
      path: "/site-content",
      component: Layout,
      redirect: "/site-content/general-settings",
      meta: {
        title: "Site Content",
        icon: "site",
        breadcrumb: false,
      },
      children: [
        // GENERAL SETTINGS

        {
          path: "/site-content/general-settings",
          name: "GeneralSettings",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/general-settings/GeneralSettings.vue"
            ),
          meta: {
            title: "General Settings",
            icon: "bullet",
          },
        },

        // MENU GROUPS
        {
          path: "/site-content/menu-groups",
          component: EmptyLayout,
          meta: {
            title: "Menu Groups",
            icon: "bullet",
          },
          children: [
            {
              path: "/site-content/menu-groups",
              name: "MenuGroups",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/menu-groups/MenuGroups.vue"
                ),
              meta: {
                title: "Menu Groups",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/site-content/menu-groups/new-menu-group",
              name: "NewMenuGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/menu-groups/NewMenuGroup.vue"
                ),
              meta: {
                title: "New Menu Group",
                hidden: true,
              },
            },
            {
              path: "/site-content/menu-groups/edit-menu-group/:id",
              props: true,
              name: "EditMenuGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/menu-groups/NewMenuGroup.vue"
                ),
              meta: {
                title: "Edit Menu Group",
                hidden: true,
              },
            }
          ]
        },

        // SEO
        {
          path: "/site-content/seo",
          component: EmptyLayout,
          meta: {
            title: "SEO",
            icon: "bullet",
          },
          children: [
            {
              path: "/site-content/seo",
              name: "SEO",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/seo/SEOList.vue"
                ),
              meta: {
                title: "SEO",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/site-content/seo/new-seo-settings",
              name: "NewSEOSettings",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/seo/NewSEOSettings.vue"
                ),
              meta: {
                title: "New SEO Settings",
                hidden: true,
              },
            },
            {
              path: "/site-content/seo/edit-seo-settings/:url",
              props: true,
              name: "EditSEOSettings",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/seo/NewSEOSettings.vue"
                ),
              meta: {
                title: "Edit SEO Settings",
                hidden: true,
              },
            }
          ]
        },

        // HOMEPAGE
        {
          path: "/site-content/home-page",
          name: "HomePage",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/HomePage.vue"
            ),
          meta: {
            title: "Home Page",
            icon: "bullet",
          },
        },
        {
          path: "/site-content/home-page/new-home-page-banner",
          name: "NewHomePageBanner",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/NewHomePageBanner.vue"
            ),
          meta: {
            title: "New Home Page Banner",
            hidden: true,
          },
        },
        {
          path: "/site-content/home-page/edit-home-page-banner/:id",
          props: true,
          name: "EditHomePageBanner",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/NewHomePageBanner.vue"
            ),
          meta: {
            title: "Edit Home Page Banner",
            hidden: true,
          },
        },
        {
          path: "/site-content/home-page/new-home-page-carousel-slide",
          name: "NewHomePageCarouselSlide",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/NewHomePageCarouselSlide.vue"
            ),
          meta: {
            title: "New Home Page Carousel Slide",
            hidden: true,
          },
        },
        {
          path: "/site-content/home-page/edit-home-page-carousel-slide/:id",
          props: true,
          name: "EditHomePageCarouselSlide",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/NewHomePageCarouselSlide.vue"
            ),
          meta: {
            title: "Edit Home Page Carousel Slide",
            hidden: true,
          },
        },
        {
          path: "/site-content/home-page/new-client-logo",
          name: "NewClientLogo",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/NewClientLogo.vue"
            ),
          meta: {
            title: "New Client Logo",
            hidden: true,
          },
        },
        {
          path: "/site-content/home-page/edit-client-logo/:id",
          props: true,
          name: "EditClientLogo",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/home-page/NewClientLogo.vue"
            ),
          meta: {
            title: "Edit Client Logo",
            hidden: true,
          },
        },

        // BANNER MESSAGES

        {
          path: "/site-content/banner-messages",
          component: EmptyLayout,
          meta: {
            title: "Banner Messages",
            icon: "bullet",
          },
          children: [
            {
              path: "/site-content/banner-messages",
              name: "BannerMessages",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/banner-messages/BannerMessages.vue"
                ),
              meta: {
                title: "Banner Messages",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/site-content/banner-messages/new-banner-message",
              name: "NewBannerMessage",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/banner-messages/NewBannerMessage.vue"
                ),
              meta: {
                title: "New Banner Message",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/site-content/banner-messages/edit-banner-message/:id",
              props: true,
              name: "EditBannerMessage",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/banner-messages/NewBannerMessage.vue"
                ),
              meta: {
                title: "Edit Banner Message",
                hidden: true,
              },
            }
          ]
        },

        // BLOG POSTS
        {
          path: "/site-content/blog-posts",
          component: EmptyLayout,
          meta: {
            title: "Blog Posts",
            icon: "bullet",
          },
          children: [
            {
              path: "/site-content/blog-posts",
              name: "BlogPosts",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/blog-posts/BlogPosts.vue"
                ),
              meta: {
                title: "All Blog Posts",
                icon: "empty-bullet",
                breadcrumb: false,
              },
            },
            {
              path: "/site-content/blog-posts/new-blog-post",
              name: "NewBlogPost",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/blog-posts/NewBlogPost.vue"
                ),
              meta: {
                title: "New Blog Post",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/site-content/blog-posts/edit-blog-post/:id",
              props: true,
              name: "EditBlogPost",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/blog-posts/NewBlogPost.vue"
                ),
              meta: {
                title: "Edit Blog Post",
                hidden: true,
              },
            },
            {
              path: "/site-content/blog-posts/?tab=categories",
              name: "BlogPostCategories",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/blog-posts/BlogPosts.vue"
                ),
              meta: {
                title: "All Blog Categories",
                icon: "empty-bullet",
                breadcrumb: false,
              },
            },
            {
              path: "/site-content/blog-posts/new-blog-post-category",
              name: "NewBlogPostCategory",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/blog-posts/NewBlogPostCategory.vue"
                ),
              meta: {
                title: "New Blog Category",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/site-content/blog-posts/edit-blog-post-category/:id",
              props: true,
              name: "EditBlogPostCategory",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/site-content/blog-posts/NewBlogPostCategory.vue"
                ),
              meta: {
                title: "Edit Blog Post Category",
                hidden: true,
              },
            },
          ]
        },
        
        // MISC SITE CONTENT
        {
          path: "/site-content/misc",
          name: "MiscSiteContent",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/misc/MiscSiteContent.vue"
            ),
          meta: {
            title: "Misc",
            icon: "bullet",
          },
        },
        {
          path: "/site-content/misc/edit-misc-content/:id",
          props: true,
          name: "EditMiscSiteContent",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/misc/EditMiscSiteContent.vue"
            ),
          meta: {
            title: "Edit Misc Site Content",
            hidden: true,
          },
        },
      ],
    },

    // CAMPAIGNS PARENT

    {
      path: "/campaigns",
      component: Layout,
      redirect: "/campaigns/all",
      meta: {
        title: "Campaigns",
        icon: "campaign",
      },
      children: [
        {
          path: "/campaigns/all",
          name: "Campaigns",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/Campaigns.vue"
            ),
          meta: {
            title: "Campaigns",
            breadcrumb: false,
            icon: "campaign",
          },
        },
        {
          path: "/campaigns/new-campaign",
          name: "NewCampaign",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/NewCampaign.vue"
            ),
          meta: {
            title: "New Campaign",
            icon: "empty-bullet",
            hidden: true
          },
        },
        {
          path: "/campaigns/edit-campaign/:id",
          props: true,
          name: "EditCampaign",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/NewCampaign.vue"
            ),
          meta: {
            title: "Edit Campaign",
            hidden: true,
          },
        },
        {
          path: "/campaigns/new-campaign-banner",
          name: "NewCampaignBanner",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/NewCampaignBanner.vue"
            ),
          meta: {
            title: "New Campaign Banner",
            hidden: true,
          },
        },
        {
          path: "/campaigns/edit-campaign-banner/:id",
          props: true,
          name: "EditCampaignBanner",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/NewCampaignBanner.vue"
            ),
          meta: {
            title: "Edit Campaign Banner",
            hidden: true,
          },
        },
        {
          path: "/campaigns/new-campaign-client-logo",
          name: "NewCampaignClientLogo",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/NewCampaignClientLogo.vue"
            ),
          meta: {
            title: "New Campaign Client Logo",
            hidden: true,
          },
        },
        {
          path: "/campaigns/edit-campaign-client-logo/:id",
          props: true,
          name: "EditCampaignClientLogo",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/site-content/campaigns/NewCampaignClientLogo.vue"
            ),
          meta: {
            title: "Edit Campaign Client Logo",
            hidden: true,
          },
        },
      ]
    },

    // PRODUCT PARENT

    {
      path: "/products",
      component: Layout,
      redirect: "/products/categories",
      meta: {
        title: "Products",
        icon: "products",
        breadcrumb: false,
      },
      children: [
        // CATEGORIES

        {
          path: "/products/categories",
          component: EmptyLayout,
          meta: {
            title: "Categories",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/categories",
              name: "Categories",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/categories/Categories.vue"
                ),
              meta: {
                title: "Categories",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/categories/new-category",
              name: "NewCategory",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/categories/NewCategory.vue"
                ),
              meta: {
                title: "New Category",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/categories/edit-category/:id",
              props: true,
              name: "EditCategory",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/categories/NewCategory.vue"
                ),
              meta: {
                title: "Edit Category",
                hidden: true,
              },
            },
          ]
        },
        

        // PRODUCT LIST

        {
          path: "/products/product-list",
          component: EmptyLayout,
          meta: {
            title: "Product List",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/product-list",
              name: "ProductList",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/productList/ProductList.vue"
                ),
              meta: {
                title: "All Products",
                breadcrumb: false,
                icon: "empty-bullet",
              },
            },
            {
              path: "/products/product-list/new-product",
              name: "NewProduct",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/productList/NewProduct.vue"
                ),
              meta: {
                title: "New Product",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/product-list/edit-product/:id",
              props: true,
              name: "EditProduct",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/productList/NewProduct.vue"
                ),
              meta: {
                title: "Edit Product",
                hidden: true,
              },
            },
            {
              path: "/products/product-list/?tab=bundles",
              name: "ProductBundlesList",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/productList/ProductList.vue"
                ),
              meta: {
                title: "All Product Bundles",
                breadcrumb: false,
                icon: "empty-bullet",
              },
            },
            {
              path: "/products/product-list/new-bundle",
              name: "NewProductBundle",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/productList/NewProductBundle.vue"
                ),
              meta: {
                title: "New Product Bundle",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/product-list/edit-bundle/:id",
              props: true,
              name: "EditProductBundle",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/productList/NewProductBundle.vue"
                ),
              meta: {
                title: "Edit Product Bundle",
                hidden: true,
              },
            },
          ]
        },
        

        // PRODUCTION SPEEDS

        {
          path: "/products/production-speeds",
          component: EmptyLayout,
          meta: {
            title: "Production Speeds",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/production-speeds",
              name: "ProductionSpeeds",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/production-speeds/ProductionSpeeds.vue"
                ),
              meta: {
                title: "Production Speeds",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/production-speeds/new-production-speed",
              name: "NewProductionSpeed",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/production-speeds/NewProductionSpeed.vue"
                ),
              meta: {
                title: "New Production Speed",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/production-speeds/edit-production-speed/:id",
              props: true,
              name: "EditProductionSpeed",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/production-speeds/NewProductionSpeed.vue"
                ),
              meta: {
                title: "Edit Production Speed",
                hidden: true,
              },
            },
            {
              path: "/products/production-speed-adjustment-periods",
              name: "ProductionSpeedAdjustment",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/production-speeds/ProductionSpeedAdjustment.vue"
                ),
              meta: {
                title: "Adjustment Periods",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/production-speed-adjustment-periods/new-adjustment-period",
              name: "NewProductionSpeedAdjustment",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/production-speeds/NewProductionSpeedAdjustment.vue"
                ),
              meta: {
                title: "New Production Speed Adjustment Periods",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/production-speed-adjustment-periods/edit-adjustment-period/:id",
              props: true,
              name: "EditProductionSpeedAdjustment",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/production-speeds/NewProductionSpeedAdjustment.vue"
                ),
              meta: {
                title: "Edit Production Speed Adjustment Periods",
                hidden: true,
              },
            },
          ]
        },
        

        // PRODUCT TAGS

        {
          path: "/products/tags",
          component: EmptyLayout,
          meta: {
            title: "Tags",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/tags",
            name: "Tags",
            component: () =>
              import(
                /* webpackChunkName: "table" */ "@/views/products/tags/Tags.vue"
              ),
            meta: {
              title: "Tags",
              breadcrumb: false,
                icon: "bullet",
            },
          },
          {
            path: "/products/tags/new-tag",
            name: "NewTag",
            component: () =>
              import(
                /* webpackChunkName: "table" */ "@/views/products/tags/NewTag.vue"
              ),
            meta: {
              title: "New Tag",
              icon: "empty-bullet",
              hidden: true
            },
          },
          {
            path: "/products/tags/edit-tag/:id",
            props: true,
            name: "EditTag",
            component: () =>
              import(
                /* webpackChunkName: "table" */ "@/views/products/tags/NewTag.vue"
              ),
            meta: {
              title: "Edit Tag",
              hidden: true,
            },
          },
          ]
        },
        

        // STYLES

        {
          path: "/products/styles",
          component: EmptyLayout,
          meta: {
            title: "Styles",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/styles",
              name: "Styles",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/styles/Styles.vue"
                ),
              meta: {
                title: "Styles",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/styles/new-style",
              name: "NewStyle",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/styles/NewStyle.vue"
                ),
              meta: {
                title: "New Style",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/styles/edit-style/:id",
              props: true,
              name: "EditStyle",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/styles/NewStyle.vue"
                ),
              meta: {
                title: "Edit Style",
                hidden: true,
              },
            },
            {
              path: "/products/styles/new-style-group",
              name: "NewStyleGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/styles/NewStyleGroup.vue"
                ),
              meta: {
                title: "New Style Group",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/styles/edit-style-group/:id",
              props: true,
              name: "EditStyleGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/styles/NewStyleGroup.vue"
                ),
              meta: {
                title: "Edit Style Group",
                hidden: true,
              },
            },
          ]
        },
       

        // DESIGNS

        {
          path: "/products/designs",
          component: EmptyLayout,
          meta: {
            title: "Designs",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/designs",
              name: "Designs",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/designs/Designs.vue"
                ),
              meta: {
                title: "Designs",
                icon: "bullet",
                breadcrumb: false,
              },
            },
            {
              path: "/products/designs/new-design",
              name: "NewDesign",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/designs/NewDesign.vue"
                ),
              meta: {
                title: "New Design",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/designs/edit-design/:id",
              props: true,
              name: "EditDesign",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/designs/NewDesign.vue"
                ),
              meta: {
                title: "Edit Design",
                hidden: true,
              },
            },
            {
              path: "/products/designs/new-design-group",
              name: "NewDesignGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/designs/NewDesignGroup.vue"
                ),
              meta: {
                title: "New Design Group",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/designs/edit-design-group/:id",
              props: true,
              name: "EditDesignGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/designs/NewDesignGroup.vue"
                ),
              meta: {
                title: "Edit Design Group",
                hidden: true,
              },
            },
          ]
        },
        

        // LAYERS

        {
          path: "/products/layers",
          component: EmptyLayout,
          meta: {
            title: "Layers",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/layers",
              name: "Layers",
              component: () =>
                import(
                  /* webpackChunkName: "Layers" */ "@/views/products/layers/Layers.vue"
                ),
              meta: {
                title: "Layers",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/layers/new-layer",
              name: "NewLayer",
              component: () =>
                import(
                  /* webpackChunkName: "NewLayer" */ "@/views/products/layers/NewLayer.vue"
                ),
              meta: {
                title: "New Layer",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/layers/edit-layer/:id",
              props: true,
              name: "EditLayer",
              component: () =>
                import(
                  /* webpackChunkName: "EditLayer" */ "@/views/products/layers/NewLayer.vue"
                ),
              meta: {
                title: "Edit Layer",
                hidden: true,
              },
            },
          ]
        },

        // FABRIC CHANNELS

        {
          path: "/products/fabric-channels",
          component: EmptyLayout,
          meta: {
            title: "Fabric Channels",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/fabric-channels",
              name: "FabricChannels",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/fabric-channels/FabricChannels.vue"
                ),
              meta: {
                title: "Fabric Channels",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/fabric-channels/new-fabric-channel",
              name: "NewFabricChannel",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/fabric-channels/NewFabricChannel.vue"
                ),
              meta: {
                title: "New Fabric Channel",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/fabric-channels/edit-fabric-channel/:id",
              props: true,
              name: "EditFabricChannel",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/fabric-channels/NewFabricChannel.vue"
                ),
              meta: {
                title: "Edit Fabric Channel",
                hidden: true,
              },
            },
          ]
        },
        

        // FABRICS

        {
          path: "/products/fabrics",
          component: EmptyLayout,
          meta: {
            title: "Fabrics",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/fabrics",
              name: "Fabrics",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/fabrics/Fabrics.vue"
                ),
              meta: {
                title: "Fabrics",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/fabrics/new-fabric",
              name: "NewFabric",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/fabrics/NewFabric.vue"
                ),
              meta: {
                title: "New Fabric",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/fabrics/edit-fabric/:id",
              props: true,
              name: "EditFabric",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/fabrics/NewFabric.vue"
                ),
              meta: {
                title: "Edit Fabric",
                hidden: true,
              },
            },
          ]
        },
        

        // COLOURS

        {
          path: "/products/colours",
          component: EmptyLayout,
          meta: {
            title: "Colours",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/colours",
              name: "Colours",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/colours/Colours.vue"
                ),
              meta: {
                title: "Colours",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/colours/new-colour",
              name: "NewColour",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/colours/NewColour.vue"
                ),
              meta: {
                title: "New Colour",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/colours/edit-colour/:id",
              props: true,
              name: "EditColour",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/colours/NewColour.vue"
                ),
              meta: {
                title: "Edit Colour",
                hidden: true,
              },
            },
            {
              path: "/products/colours/new-colour-group",
              name: "NewColourGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/colours/NewColourGroup.vue"
                ),
              meta: {
                title: "New Colour Group",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/colours/edit-colour-group/:id",
              props: true,
              name: "EditColourGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/colours/NewColourGroup.vue"
                ),
              meta: {
                title: "Edit Colour Group",
                hidden: true,
              },
            },
          ]
        },
        
        

        // OVERLAYS - section removed due to priced colours meeting requirements

        // {
        //   path: "/products/overlays",
        //   component: EmptyLayout,
        //   meta: {
        //     title: "Overlays",
        //     icon: "bullet",
        //   },
        //   children: [
        //     {
        //       path: "/products/overlays",
        //       name: "Overlays",
        //       component: () =>
        //         import(
        //           /* webpackChunkName: "table" */ "@/views/products/overlays/Overlays.vue"
        //         ),
        //       meta: {
        //         title: "All Overlays",
        //         breadcrumb: false,
        //         icon: "empty-bullet",
        //       },
        //     },
        //     {
        //       path: "/products/overlays/new-overlay",
        //       name: "NewOverlay",
        //       component: () =>
        //         import(
        //           /* webpackChunkName: "table" */ "@/views/products/overlays/NewOverlay.vue"
        //         ),
        //       meta: {
        //         title: "New Overlay",
        //         icon: "empty-bullet",
        //       },
        //     },
        //     {
        //       path: "/products/overlays/edit-overlay/:id",
        //       name: "EditOverlay",
        //       component: () =>
        //         import(
        //           /* webpackChunkName: "table" */ "@/views/products/overlays/NewOverlay.vue"
        //         ),
        //       meta: {
        //         title: "Edit Overlay",
        //         hidden: true,
        //       },
        //     },
        //     {
        //       path: "/products/overlays/new-overlay-group",
        //       name: "NewOverlayGroup",
        //       component: () =>
        //         import(
        //           /* webpackChunkName: "table" */ "@/views/products/overlays/NewOverlayGroup.vue"
        //         ),
        //       meta: {
        //         title: "New Overlay Group",
        //         icon: "empty-bullet",
        //       },
        //     },
        //     {
        //       path: "/products/overlays/edit-overlay-group/:id",
        //       name: "EditOverlayGroup",
        //       component: () =>
        //         import(
        //           /* webpackChunkName: "table" */ "@/views/products/overlays/NewOverlayGroup.vue"
        //         ),
        //       meta: {
        //         title: "Edit Overlay Group",
        //         hidden: true,
        //       },
        //     },
        //   ]
        // },
        

        // EXTRAS

        {
          path: "/products/extras",
          component: EmptyLayout,
          meta: {
            title: "Extras",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/extras",
              name: "Extras",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/extras/Extras.vue"
                ),
              meta: {
                title: "Extras",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/extras/new-extra",
              name: "NewExtras",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/extras/NewExtras.vue"
                ),
              meta: {
                title: "New Extra",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/extras/edit-extra/:id",
              props: true,
              name: "EditExtras",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/extras/NewExtras.vue"
                ),
              meta: {
                title: "Edit Extra",
                hidden: true,
              },
            },
            {
              path: "/products/extras/new-extras-group",
              name: "NewExtrasGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/extras/NewExtrasGroup.vue"
                ),
              meta: {
                title: "New Extras Group",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/extras/edit-extras-group/:id",
              props: true,
              name: "EditExtrasGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/extras/NewExtrasGroup.vue"
                ),
              meta: {
                title: "Edit Extras Group",
                hidden: true,
              },
            },
          ]
        },
        

        // CUSTOMIZATIONS

        {
          path: "/products/customizations",
          component: EmptyLayout,
          meta: {
            title: "Customizations",
            icon: "bullet",
          },
          children: [
            {
              path: "/products/customizations",
              name: "Customizations",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/customizations/Customizations.vue"
                ),
              meta: {
                title: "Customizations",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/products/customizations/new-customization",
              name: "NewCustomization",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/customizations/NewCustomization.vue"
                ),
              meta: {
                title: "New Customization",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/customizations/edit-customization/:id",
              props: true,
              name: "EditCustomization",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/customizations/NewCustomization.vue"
                ),
              meta: {
                title: "Edit Customization",
                hidden: true,
              },
            },
            {
              path: "/products/customizations/new-customization-group",
              name: "NewCustomizationGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/customizations/NewCustomizationGroup.vue"
                ),
              meta: {
                title: "New Customization Group",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/products/customizations/edit-customization-group/:id",
              props: true,
              name: "EditCustomizationGroup",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/customizations/NewCustomizationGroup.vue"
                ),
              meta: {
                title: "Edit Customization Group",
                hidden: true,
              },
            },
          ]
        },
        
        // BULK EDIT

        {
          path: "/bulk-actions/product-list",
          component: EmptyLayout,
          meta: {
            title: "Bulk Actions",
            icon: "bullet",
          },
          children: [
            {
              path: "/bulk-actions/fabric-layer-add",
              name: "BulkFabricLayersAdd",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/bulk-actions/FabricLayerAdd.vue"
                ),
              meta: {
                title: "Fabric Layer Add",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/bulk-actions/fabric-layer-edit",
              name: "BulkFabricLayersEdit",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/products/bulk-actions/FabricLayerEdit.vue"
                ),
              meta: {
                title: "Fabric Layer Edit",
                breadcrumb: false,
                icon: "bullet",
              },
            },
          ]
        },
      ],
    },

    //  SIZING PARENT

    {
      path: "/sizing",
      component: Layout,
      redirect: "/sizing/size-groups",
      meta: {
        title: "Sizing",
        icon: "sizing",
        breadcrumb: false,
      },
      children: [
        // SIZE GROUPS

        {
          path: "/sizing/size-groups",
          component: EmptyLayout,
          meta: {
            title: "Size Groups",
            icon: "bullet",
          },
          children: [
            {
              path: "/sizing/size-groups",
              name: "SizeGroups",
              component: () =>
                import(
                  /* webpackChunkName: "new-size-group" */ "@/views/sizing/size-groups/SizeGroups.vue"
                ),
              meta: {
                title: "Size Groups",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/sizing/size-groups/new-size-group",
              name: "NewSizeGroup",
              component: () =>
                import(
                  /* webpackChunkName: "new-size-group" */ "@/views/sizing/size-groups/NewSizeGroup.vue"
                ),
              meta: {
                title: "New Size Group",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/sizing/size-groups/edit-size-group/:id",
              props: true,
              name: "EditSizeGroup",
              component: () =>
                import(
                  /* webpackChunkName: "edit-size-group" */ "@/views/sizing/size-groups/NewSizeGroup.vue"
                ),
              meta: { title: "Edit Size Group", hidden: true },
            },
          ],
        },

        // SIZES

        {
          path: "/sizing/sizes",
          component: EmptyLayout,
          meta: { title: "Sizes", icon: "bullet" },
          children: [
            {
              path: "/sizing/sizes",
              name: "Sizes",
              component: () =>
                import(
                  /* webpackChunkName: "all-sizes" */ "@/views/sizing/sizes/Sizes.vue"
                ),
              meta: { title: "Sizes", icon: "bullet", breadcrumb: false, },
            },
            {
              path: "/sizing/sizes/new-size",
              name: "NewSize",
              component: () =>
                import(
                  /* webpackChunkName: "new-size" */ "@/views/sizing/sizes/NewSize.vue"
                ),
              meta: { title: "New Size Group", icon: "empty-bullet", hidden: true },
            },
            {
              path: "/sizing/sizes/edit-size/:id",
              props: true,
              name: "EditSize",
              component: () =>
                import(
                  /* webpackChunkName: "edit-size" */ "@/views/sizing/sizes/NewSize.vue"
                ),
              meta: { title: "Edit Size", hidden: true },
            },
          ],
        },

        // ADJUSTMENT TYPES

        {
          path: "/sizing/adjustment-types",
          component: EmptyLayout,
          meta: { title: "Adjustment Types", icon: "bullet" },
          children: [
            {
              path: "/sizing/adjustment-types",
              name: "AdjustmentTypes",
              component: () =>
                import(
                  /* webpackChunkName: "all-adjustment-types" */ "@/views/sizing/adjustment-types/AdjustmentTypes.vue"
                ),
              meta: { title: "Adjustment Types", icon: "bullet", breadcrumb: false, },
                },
                {
                  path: "/sizing/adjustment-types/new-adjustment-type",
                  name: "NewAdjustmentType",
                  component: () =>
                    import(
                      /* webpackChunkName: "new-adjustment-type" */ "@/views/sizing/adjustment-types/NewAdjustmentType.vue"
                    ),
                  meta: { title: "New Adjustment Type", icon: "empty-bullet", hidden: true },
                },
                {
                  path: "/sizing/adjustment-types/edit-adjustment-type/:id",
                  props: true,
                  name: "EditAdjustmentType",
                  component: () =>
                    import(
                      /* webpackChunkName: "edit-adjustment-type" */ "@/views/sizing/adjustment-types/NewAdjustmentType.vue"
                    ),
                  meta: { title: "Edit Adjustment Type", hidden: true },
                },
          ]
        },
        {
          path: "/sizing/size-mapping",
          component: EmptyLayout,
          meta: { title: "Size Mapping", icon: "bullet" },
          children: [
            {
              path: "/sizing/size-mapping",
              name: "SizeMapping",
              component: () =>
                import(
                  /* webpackChunkName: "size-mapping" */ "@/views/sizing/size-mapping/SizeMapping.vue"
                ),
              meta: { title: "Size Mapping", icon: "bullet", breadcrumb: false },
            }
          ]
        }
      ],
    },    

    // FACTORIES PARENT

    {
      path: "/factories",
      component: Layout,
      redirect: "/factories/all",
      meta: {
        title: "Factories",
        icon: "factory",
      },
      children: [
        // FACTORIES

        {
          path: "/factories/all",
          name: "Factories",
          component: () =>
            import(/* webpackChunkName: "table" */ "@/views/factories/Factories.vue"),
          meta: {
            title: "Factories",
            icon: "factory",
            breadcrumb: false,
          },
        },
        {
          path: "/factories/new-factory",
          name: "NewFactory",
          component: () =>
            import(/* webpackChunkName: "table" */ "@/views/factories/NewFactory.vue"),
          meta: {
            title: "New Factory",
            icon: "empty-bullet",
            hidden: true
          },
        },
        {
          path: "/factories/edit-factory/:id",
          props: true,
          name: "EditFactory",
          component: () =>
            import(/* webpackChunkName: "table" */ "@/views/factories/NewFactory.vue"),
          meta: {
            title: "Edit Factory",
            hidden: true,
          },
        },
      ],
    },

    // CURRENCIES PARENT

    {
      path: "/currencies",
      component: Layout,
      redirect: "/currencies/all",
      meta: {
        title: "Currencies",
        icon: "currency",
      },
      children: [
        // CURRENCIES

        {
          path: "/currencies/all",
          name: "Currencies",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/currencies/Currencies.vue"
            ),
          meta: {
            title: "Currencies",
            icon: "currency",
            breadcrumb: false,
          },
        },
        {
          path: "/currencies/new-currency",
          name: "NewCurrency",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/currencies/NewCurrency.vue"
            ),
          meta: {
            title: "New Currency",
            icon: "empty-bullet",
            hidden: true,
          },
        },
        {
          path: "/currencies/edit-currency/:id",
          props: true,
          name: "EditCurrency",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/currencies/NewCurrency.vue"
            ),
          meta: {
            title: "Edit Currency",
            hidden: true,
          },
        },
      ],
    },

    // COUNTRY + DELIVERY PARENT


    {
      path: "/country-delivery",
      component: Layout,
      redirect: "/country-delivery/countries",
      meta: {
        title: "Country & Delivery",
        icon: "country",
        breadcrumb: false,
      },
      children: [
        // COUNTRIES

        {
          path: "/country-delivery/countries",
          component: EmptyLayout,
          meta: {
            title: "Countries",
            icon: "bullet",
          },
          children: [
            {
              path: "/country-delivery/countries",
              name: "Countries",
              component: () =>
                import("@/views/country-delivery/Countries.vue"
                ),
              meta: {
                title: "Countries",
                breadcrumb: false,
                icon: "bullet",
              },
            },
            {
              path: "/country-delivery/countries/new-country",
              name: "NewCountry",
              component: () =>
                import(
                  /* webpackChunkName: "new-size-group" */ "@/views/country-delivery/NewCountry.vue"
                ),
              meta: {
                title: "New Country",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/country-delivery/countries/edit-country/:id",
              props: true,
              name: "EditCountry",
              component: () =>
                import(
                  /* webpackChunkName: "edit-size-group" */ "@/views/country-delivery/NewCountry.vue"
                ),
              meta: { title: "Edit Country", hidden: true },
            },
          ],
        },

        // COURIERS

        {
          path: "/country-delivery/couriers",
          component: EmptyLayout,
          meta: { title: "Couriers", icon: "bullet" },
          children: [
            {
              path: "/country-delivery/couriers",
              name: "Couriers",
              component: () =>
                import(
                  /* webpackChunkName: "all-sizes" */ "@/views/country-delivery/couriers/Couriers.vue"
                ),
              meta: { title: "Couriers", icon: "bullet", breadcrumb: false, },
            },
            {
              path: "/country-delivery/couriers/new-courier",
              name: "NewCourier",
              component: () =>
                import(
                  /* webpackChunkName: "new-size" */ "@/views/country-delivery/couriers/NewCourier.vue"
                ),
              meta: { title: "New Courier", icon: "empty-bullet", hidden: true },
            },
            {
              path: "/country-delivery/couriers/edit-courier/:id",
              props: true,
              name: "EditCourier",
              component: () =>
                import(
                  /* webpackChunkName: "edit-size" */ "@/views/country-delivery/couriers/NewCourier.vue"
                ),
              meta: { title: "Edit Courier", hidden: true },
            },
          ],
        },

        // GROUPED DELIVERY ADDRESSES

        {
          path: "/country-delivery/grouped-delivery-addresses",
          component: EmptyLayout,
          meta: { title: "Grouped Delivery Addresses", icon: "bullet" },
          children: [
            {
              path: "/country-delivery/grouped-delivery-addresses",
              name: "GroupedDeliveryAddresses",
              component: () =>
                import(
                  /* webpackChunkName: "all-sizes" */ "@/views/country-delivery/grouped-delivery-address/GroupedDeliveryAddress.vue"
                ),
              meta: { title: "Grouped Delivery Addresses", icon: "bullet", breadcrumb: false, },
            },
          ]
        },
      ]
    },

    // VOUCHERS PARENT

    {
      path: "/vouchers",
      component: Layout,
      redirect: "/vouchers/all",
      meta: {
        title: "Vouchers",
        icon: "vouchers",
      },
      children: [
        // VOUCHERS

        {
          path: "/vouchers/all",
          name: "Vouchers",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/vouchers/Vouchers.vue"
            ),
          meta: {
            title: "Vouchers",
            icon: "vouchers",
            breadcrumb: false,
          },
        },
        {
          path: "/vouchers/new-voucher",
          name: "NewVoucher",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/vouchers/NewVoucher.vue"
            ),
          meta: {
            title: "New Voucher",
            icon: "empty-bullet",
            hidden: true,
          },
        },
        {
          path: "/vouchers/edit-voucher/:id",
          props: true,
          name: "EditVoucher",
          component: () =>
            import(
              /* webpackChunkName: "table" */ "@/views/vouchers/NewVoucher.vue"
            ),
          meta: {
            title: "Edit Voucher",
            hidden: true,
          },
        },
      ],
    },

    // MEDIA PARENT

    {
      path: "/media",
      redirect: "/media/art-assets",
      component: Layout,
      meta: {
        title: "Media",
        icon: "media",
        breadcrumb: false,
      },
      children: [
        // ART ASSETS
        {
          path: "/media/art-assets",
          component: EmptyLayout,
          meta: {
            title: "Art Assets",
            icon: "bullet",
          },
          children: [
            {
              path: "/media/art-assets",
              name: "ArtAssets",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/media/art-assets/ArtAssets.vue"
                ),
              meta: {
                title: "All Art Assets",
                breadcrumb: false,
                icon: "empty-bullet",
              },
            },
            {
              path: "/media/art-assets/new-art-asset",
              name: "NewArtAsset",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/media/art-assets/NewArtAsset.vue"
                ),
              meta: {
                title: "New Art Asset",
                icon: "empty-bullet",
                hidden: true
              },
            },
            {
              path: "/media/art-assets/edit-art-asset/:id",
              props: true,
              name: "EditArtAsset",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/media/art-assets/NewArtAsset.vue"
                ),
              meta: {
                title: "Edit Art Asset",
                hidden: true,
              },
            },
            {
              path: "/media/art-assets/?tab=categories",
              name: "ArtAssetCategories",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/media/art-assets/ArtAssets.vue"
                ),
              meta: {
                title: "All Art Asset Categories",
                breadcrumb: false,
                icon: "empty-bullet",
              },
            },
            {
              path: "/media/art-assets/new-art-asset-category",
              name: "NewArtAssetCategory",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/media/art-assets/NewArtAssetCategory.vue"
                ),
              meta: {
                title: "New Art Asset Category",
                icon: "empty-bullet",
                hidden: true,
              },
            },
            {
              path: "/media/art-assets/edit-art-asset-category/:id",
              props: true,
              name: "EditArtAssetCategory",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/media/art-assets/NewArtAssetCategory.vue"
                ),
              meta: {
                title: "Edit Art Asset Category",
                hidden: true,
              },
            },
          ],
        },

        // FONTS

        {
          path: "/media/fonts",
          //name: "Fonts",
          component: EmptyLayout,
          meta: {
            title: "Fonts",
            icon: "bullet",
          },
          children: [
            {
              path: "/media/fonts",
              name: "Fonts",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/media/fonts/Fonts.vue"
                ),
              meta: {
                title: "Fonts",
                icon: "bullet",
                breadcrumb: false,
              },
            },
            {
              path: "/media/fonts/new-font",
              name: "NewFont",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/media/fonts/NewFont.vue"
                ),
              meta: {
                title: "New Font",
                icon: "empty-bullet",
                hidden: true,
              },
            },
            {
              path: "/media/fonts/edit-font/:id",
              props: true,
              name: "EditFont",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/media/fonts/NewFont.vue"
                ),
              meta: {
                title: "Edit Font",
                hidden: true,
              },
            },
          ],
        },

        // GENERAL IMAGERY

        {
          path: "/media/general-imagery",
          //name: "GeneralImagery",
          component: EmptyLayout,
          meta: {
            title: "General Imagery",
            icon: "bullet",
          },
          children: [
            {
              path: "/media/general-imagery",
              name: "GeneralImagery",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/media/general-imagery/GeneralImagery.vue"
                ),
              meta: {
                title: "General Imagery",
                icon: "bullet",
                breadcrumb: false,
              },
            },
            {
              path: "/media/general-imagery/new-general-image",
              name: "NewGeneralImage",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/media/general-imagery/NewGeneralImage.vue"
                ),
              meta: {
                title: "New General Image",
                icon: "empty-bullet",
                hidden: true,
              },
            },
            {
              path: "/media/general-imagery/edit-general-image/:id",
              props: true,
              name: "EditGeneralImage",
              component: () =>
                import(
                  /* webpackChunkName: "table" */ "@/views/media/general-imagery/NewGeneralImage.vue"
                ),
              meta: {
                title: "Edit General Image",
                hidden: true,
              },
            },
          ],
        },
      ],
    },

    // GENERATION LOGS PARENT

    {
      path: "/generation-logs",
      component: Layout,
      redirect: "/generation-logs/all",
      meta: {
        title: "GenerationLogs",
        icon: "nested",
      },
      children: [
        // GENERATION LOGS

        {
          path: "/generation-logs/all",
          name: "GenerationLogs",
          component: () =>
            import(/* webpackChunkName: "example" */ "@/views/generation-logs/GenerationLogs.vue"),
          meta: {
            title: "Generation Logs",
            icon: "nested",
            breadcrumb: false,
          },
        },
        // {
        //   path: "/generation-logs/new-users",
        //   name: "NewUser",
        //   component: () =>
        //     import(/* webpackChunkName: "table" */ "@/views/generation-logs/NewUser.vue"),
        //   meta: {
        //     title: "New User",
        //     icon: "empty-bullet",
        //     hidden: true
        //   },
        // },
        // {
        //   path: "/generation-logs/edit-users/:id",
        //   props: true,
        //   name: "EditUser",
        //   component: () =>
        //     import(/* webpackChunkName: "table" */ "@/views/generation-logs/NewUser.vue"),
        //   meta: {
        //     title: "Edit User",
        //     hidden: true,
        //   },
        // },
      ],
    },

    // USERS PARENT

    {
      path: "/users",
      component: Layout,
      redirect: "/users/all",
      meta: {
        title: "Users",
        icon: "users",
      },
      children: [
        // USERS

        {
          path: "/users/all",
          name: "Users",
          component: () =>
            import(/* webpackChunkName: "table" */ "@/views/users/Users.vue"),
          meta: {
            title: "Users",
            icon: "users",
            breadcrumb: false,
          },
        },
        {
          path: "/users/new-users",
          name: "NewUser",
          component: () =>
            import(/* webpackChunkName: "table" */ "@/views/users/NewUser.vue"),
          meta: {
            title: "New User",
            icon: "empty-bullet",
            hidden: true
          },
        },
        {
          path: "/users/edit-users/:id",
          props: true,
          name: "EditUser",
          component: () =>
            import(/* webpackChunkName: "table" */ "@/views/users/NewUser.vue"),
          meta: {
            title: "Edit User",
            hidden: true,
          },
        },
      ],
    },

    // {
    //   path: '/form',
    //   component: Layout,
    //   children: [
    //     {
    //       path: 'index',
    //       component: () => import(/* webpackChunkName: "form" */ '@/views/form/FormExample.vue'),
    //       meta: {
    //         title: 'Form',
    //         icon: 'form'
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: '/nested',
    //   component: Layout,
    //   redirect: '/nested/menu1',
    //   meta: {
    //     title: 'Nested',
    //     icon: 'nested'
    //   },
    //   children: [
    //     {
    //       path: 'menu1',
    //       component: () => import(/* webpackChunkName: "menu1" */ '@/views/nested/menu1/index.vue'),
    //       redirect: '/nested/menu1/menu1-1',
    //       meta: { title: 'Menu1' },
    //       children: [
    //         {
    //           path: 'menu1-1',
    //           component: () => import(/* webpackChunkName: "menu1-1" */ '@/views/nested/menu1/menu1-1/index.vue'),
    //           meta: { title: 'Menu1-1' }
    //         },
    //         {
    //           path: 'menu1-2',
    //           component: () => import(/* webpackChunkName: "menu1-2" */ '@/views/nested/menu1/menu1-2/index.vue'),
    //           redirect: '/nested/menu1/menu1-2/menu1-2-1',
    //           meta: { title: 'Menu1-2' },
    //           children: [
    //             {
    //               path: 'menu1-2-1',
    //               component: () => import(/* webpackChunkName: "menu1-2-1" */ '@/views/nested/menu1/menu1-2/menu1-2-1/index.vue'),
    //               meta: { title: 'Menu1-2-1' }
    //             },
    //             {
    //               path: 'menu1-2-2',
    //               component: () => import(/* webpackChunkName: "menu1-2-2" */ '@/views/nested/menu1/menu1-2/menu1-2-2/index.vue'),
    //               meta: { title: 'Menu1-2-2' }
    //             }
    //           ]
    //         },
    //         {
    //           path: 'menu1-3',
    //           component: () => import(/* webpackChunkName: "menu1-3" */ '@/views/nested/menu1/menu1-3/index.vue'),
    //           meta: { title: 'Menu1-3' }
    //         }
    //       ]
    //     },
    //     {
    //       path: 'menu2',
    //       component: () => import(/* webpackChunkName: "menu2" */ '@/views/nested/menu2/index.vue'),
    //       meta: { title: 'Menu2' }
    //     }
    //   ]
    // },
    // {
    //   path: 'external-link',
    //   component: Layout,
    //   children: [
    //     {
    //       path: 'https://nologostudios.com',
    //       meta: {
    //         title: 'External Link Example',
    //         icon: 'link'
    //       }
    //     }
    //   ]
    // },
    {
      path: "*",
      redirect: "/404",
      meta: { hidden: true },
    },
  ],
});
