var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container misc-site-content-container"},[_c('el-row',{staticStyle:{"flex-wrap":"wrap"},attrs:{"type":"flex","justify":"space-between"}},[_c('el-col',{attrs:{"xs":24,"md":10}},[_c('h1',{attrs:{"id":"title"}},[_vm._v("Misc Site Content")])])],1),_c('el-table',{attrs:{"data":_vm.miscContent,"row-key":"id","fit":"","stripe":"","highlight-current-row":"","resizable":"","border":""}},[_c('el-table-column',{attrs:{"min-width":"200","prop":"name","label":"Name"}}),_c('el-table-column',{attrs:{"width":"105","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"anchor",attrs:{"to":{
            name: 'EditMiscSiteContent',
            params: {
              id: row.id,
            },
          }}},[_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"}},[_vm._v("Edit")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }