






















































































































































































import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import { ValidationProvider } from "vee-validate";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicInputNumberField from "@/components/form-items/BasicInputNumberField.vue";
import { OrderItemAdminViewModel } from "@/api-client";
import PreviewImage from "@/components/form-items/PreviewImage.vue";
import SizeNameSelector from "@/components/orders/sizing/SizeNameSelector.vue";
import AssetsTab from "@/components/orders/products/AssetsTab.vue";


const AppProps = Vue.extend({
  props: {
    order: {
      type: Object,
      default: null,
    },
    originalOrder: {
      type: Object,
      default: null,
    },
  },
});

@Component({
  name: "ProductsTab",
  components: {
    ValidationProvider,
    BasicInputField,
    BasicSelectorField,
    BasicInputNumberField,
    PreviewImage,
    SizeNameSelector,
    AssetsTab
  },
})
export default class extends AppProps {
  selectedItemId: string | null = null;
  activeTab = "general";


  created() {
    if (this.$route.query.section) {
      this.activeTab = this.$route.query.section as string;
    } else {
      this.activeTab = "general";
    }

    if (this.$route.query.product) {
      this.selectedItemId = this.$route.query.product as string;
    }

    this.$watch("$route", () => {
      if (this.$route.query.section) {
        this.activeTab = this.$route.query.section as string;
      } else {
        this.activeTab = "general";
      }
    });

    this.$watch("order", () => {
      if (!this.selectedItemId && this.order.orderItems.length) {
        this.selectedItemId = this.order.orderItems[0].id;
        // this.$route.query.product = this.selectedItemId as string;
      }
    });
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get selectedItem() {
    if (this.selectedItemId) {
      return this.order.orderItems.find(
        (item: OrderItemAdminViewModel) => item.id === this.selectedItemId
      );
    }
    return null;
  }

  selectProduct() {
    this.$router.replace({ name: "EditOrder", params: {id: this.order.id}, query: { tab: 'products', product: this.selectedItemId, section: this.activeTab } });
  }

  handleTabClick(tab: any) {
    this.activeTab = tab.name;
    tab.name ? this.activeTab = tab.name : this.activeTab = tab;
    this.$router.replace({ name: "EditOrder", params: {id: this.order.id}, query: { tab: 'products', product: this.selectedItemId, section: this.activeTab } });
  }

  copyLink(copy: string, isHex = false) {
    let link = isHex ? copy : `${this.uploadPath}/${copy}`;
    // Write to the clipboard
    navigator.clipboard.writeText(link);
    this.$message.success("Value copied.");
  }

  toDesigner(id: string) {
    let link = `${process.env.VUE_APP_ROOT_WEB}/designer?saved-design=${id}&order=${this.order.id}&country=${this.order.countryId}`;
    window.open(link, "_blank");
  }
}
