





























































































































import { Component, Vue } from 'vue-property-decorator'
import { AdminFonts } from '@/network/api'
import {
  FontAdminListViewModelPaginatedList,
  FontAdminViewModel
} from '@/api-client'
import ElTableDraggable from '@/components/ElTableDraggable.vue'
import Pagination from '@/components/Pagination.vue'
import DialogBox from '@/components/DialogBox.vue'
import SendToPage from "@/components/form-items/SendToPage.vue";
import ItemsPerPage from "@/components/form-items/ItemsPerPage.vue";
import { handleQueries } from "@/utils/handleQueries"

@Component({
  name: 'Fonts',
  components: { ElTableDraggable, Pagination, DialogBox, SendToPage, ItemsPerPage },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'success',
        true: 'danger'
      }
      return statusMap[status]
    },
    parseTime: (timestamp: string) => {
      return new Date(timestamp).toISOString()
    }
  }
})
export default class extends Vue {
  fonts: FontAdminListViewModelPaginatedList = {
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageIndex: 1,
    totalCount: 1,
    totalPages: 1,
    pageSize: 1
  };
  fontItems: Array<FontAdminViewModel> = [];
  search: string | undefined = this.$route.params.search || '';
  pageNumber = Number(this.$route.params.pageNumber) || 1;
  itemPerPage = Number(this.$route.params.itemPerPage) || 10;
  dialogVisible = false;
  dialogMessage = '<span><stong>Are you sure you want to delete this font?</strong></span>';
  confirmText = 'Yes';
  cancelVisible = true;
  selectedFont: FontAdminViewModel = {
    id: '',
    name: '',
    fontFileUrl: ''
  };
  debounce: any = null;
  loading = false;

  created() {
    let toQuery = {
      search: this.search,
      pageNumber: this.pageNumber,
      itemPerPage: this.itemPerPage
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();

    // this.$watch('search', () => {
    //   this.pageNumber = 1
    //   clearTimeout(this.debounce)
    //   this.debounce = setTimeout(() => {
    //     this.loadInitialData()
    //     .then(() => {
    //       document.getElementById("search")!.focus();
    //     })
    //   }, 400)
    // })
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        this[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleSearch() {
    this.pageNumber = 1;
    this.loadInitialData().then(() => {
      document.getElementById('search')!.focus();
    }) 
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page
    this.loadInitialData();
  }


  clearAllFilters() {
    this.pageNumber = 1
    this.search = '';
    this.loadInitialData()
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  handleName(name: string) {
    const parsedName = name.toLowerCase().split(' ').join('-')
    return parsedName
  }

  handleSendToPage(id:string, selectedPage:number, determinePage:number) {
    this.loading = true
    let patchInfo: any = {}
    let itemId = 0
    let newPageItems = [] as Array<any>   

    AdminFonts.adminFontsGet(determinePage, this.itemPerPage, this.search || undefined)
      .then((res) => {
        if (res.data.succeeded) {
          newPageItems = res.data.resultData!
            .items as Array<any>;

          if(selectedPage < this.pageNumber) {
            itemId = (selectedPage > 1) ? newPageItems.length - 1 : 0;
          }
          
          patchInfo = {
            target: id,
            previous: determinePage > 1 ? newPageItems[itemId].id : undefined,
            next: determinePage > 1 ? undefined : newPageItems[itemId].id,
          };

          this.loading = false
          
          this.handleReorder(patchInfo, selectedPage).then(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
        }
      })
      .catch((error) => {
        this.loading = false
error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async handleReorder(data: any, page: number = this.pageNumber) {
    this.loading = true;
    await AdminFonts.adminFontsIdPatch(data.target, data.previous, data.next)
      .then((res) => {
        if (res.data.succeeded) {
          console.log(res);
          this.$message.success("Reorder successful.");
          this.pageNumber = Number(page);
          this.loading = false;
          this.loadInitialData();
        }
      })
      .catch((error) => {
        this.loading = false;
        error.response.data.errors.map((e: any) => {
          this.$message({showClose: true, type: 'error', duration: 0, message: e.friendlyMessage})
          return e.friendlyMessage;
        });
        this.dialogVisible = false;
      });
  }

  async loadInitialData() {
    this.loading = true
    await AdminFonts.adminFontsGet(this.pageNumber, this.itemPerPage, this.search || undefined)
      .then((res) => {
        if(res.data.succeeded) {
          this.fonts = res.data
                    .resultData as FontAdminListViewModelPaginatedList
          this.fontItems = this.fonts
            .items as Array<FontAdminViewModel>
            this.loading = false
        }
        
      })
      .catch((error) => {
        this.loading = false
error.response.data.errors.map((e: any) => {
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
      })
  }

  confirmDelete(item: any) {
    this.dialogVisible = true
    this.selectedFont = item;
  }

  deleteItem() {
    if (this.selectedFont.id) {
      this.loading = true
      AdminFonts.adminFontsIdDelete(this.selectedFont.id)
        .then((res) => {
          if(res.data.succeeded) {
            this.pageNumber = 1
            this.loading = false
          this.$message.info('Item deleted.')
          this.loadInitialData();
          }
          
        })
        .catch((error) => {
          error.response.data.errors.map((e: any) => {
            this.loading = false
                  this.$message.error(e.friendlyMessage)
                  return e.friendlyMessage
                }
              );
              this.dialogVisible = false;
        })
    }
    this.dialogVisible = false
    this.selectedFont = {
      id: '',
      name: '',
      fontFileUrl: ''
    }
  }

  beforeUnmount() {
    clearTimeout(this.debounce)
  }
}
